import {share} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from './http.service';

import {Product} from '../classes/product';
import {ShoppingCartLine} from '../classes/shoppingcartline';
import {Observable, Subject} from 'rxjs';
import {BaseService} from './base.service';
import {ApplicationService} from './application.service';
import {ActiveOrder} from '../classes/active.order';
import {UserService} from './user.service';
import {Logger} from '../logging/default-log.service';
import {ActiveOrderService} from './active.order.service';
import {ConfigurationHelper} from '../helper/configuration.helper';

@Injectable()
export class ShoppingCartService extends BaseService {
	private shoppingCartSubject = new Subject<ShoppingCartLine[]>();
	private shoppingCartObservable = this.shoppingCartSubject.asObservable();

	private _activeShoppingCartLines: ShoppingCartLine[];

	constructor(private http: HttpClient,
	            private applicationService: ApplicationService,
	            private activeOrderService: ActiveOrderService,
	            private userService: UserService, logger: Logger) {
		super(logger);

		if (!userService.getUser()) {
			return;
		}

		this.activeOrderService.selectedOrderChangedSubject.subscribe(newActiveOrder => {
				this.updateShoppingCartForActiveOrder(newActiveOrder);
			}, error => this.logger.error('Failed to subscribe to order changed', error)
		);
	}

	get activeShoppingCartLines(): ShoppingCartLine[] {
		return this._activeShoppingCartLines;
	}

	public getShoppingCartObservable() {
		return this.shoppingCartObservable;
	}

	/**
	 * Check if the given shoppingCartLine can still be edited.
	 * It can be determined by:
	 * - The edit time is still in the future
	 * - The order/shoppingcartline has already been confirmed manually by the user
	 *
	 * @param shoppingCartLine
	 * @returns {boolean}
	 */
	isShoppingCartLineEditable(shoppingCartLine: ShoppingCartLine): boolean {
		const shoppingCartLineExpiryDate: Date = this.getShoppingCartLineExpiryDate(shoppingCartLine);

		if (shoppingCartLineExpiryDate === null) {
			return false;
		}

		if (shoppingCartLine.confirmed > 0 || shoppingCartLine.expired) {
			return false;
		}

		return new Date() < shoppingCartLineExpiryDate;
	}

	getShoppingCartLineExpiryDate(shoppingCartLine: ShoppingCartLine): Date {
		const inserted = shoppingCartLine.inserted;
		let waitTimeInSeconds = this.userService.getUserCartWaitTimeInSeconds();

		if (waitTimeInSeconds) {
			waitTimeInSeconds = waitTimeInSeconds * 1000;
			const expiryDate = inserted + waitTimeInSeconds;
			return new Date(expiryDate);
		}

		return null;
	}

	getShoppingCart(addressCode: string, shipmentDate: string, sorting: string, sortingDirection: string): Observable<ShoppingCartLine[]> {
		const params: Array<string> = [];

		params.push(`shipToCode=${encodeURIComponent(addressCode)}`);
		params.push(`shipmentDate=${shipmentDate}`);

		if (sorting && sortingDirection) {
			params.push(`sorting=${sorting}`);
			params.push(`sortingDirection=${sortingDirection}`);
		}

		const paramsJoined: string = params.join('&');

		return this.getLinesForSpecificCart(paramsJoined);
	}

	getPendingOrderLines(addressCode: string, shipmentDate: string,
	                     sorting: string, sortingDirection: string): Observable<ShoppingCartLine[]> {
		const params: Array<string> = [];

		params.push(`shipToCode=${encodeURIComponent(addressCode)}`);
		params.push(`shipmentDate=${shipmentDate}`);

		if (sorting && sortingDirection) {
			params.push(`sorting=${sorting}`);
			params.push(`sortingDirection=${sortingDirection}`);
		}

		const paramsJoined: string = params.join('&');

		return this.getLinesForSpecificPendingOrder(paramsJoined);
	}

	updateShoppingCartForActiveOrder(newActiveOrder: ActiveOrder) {
		const params: Array<string> = [];

		if (newActiveOrder) {
			params.push(`shipToCode=${encodeURIComponent(newActiveOrder.shipToCode)}`);
			params.push(`shipmentDate=${newActiveOrder.shipmentDate}`);
		}

		const urlParams = params.join('&');

		this.getLinesForSpecificCart(urlParams).subscribe(
			data => {
				this._activeShoppingCartLines = data;
				this.shoppingCartSubject.next(this._activeShoppingCartLines);
			},
			error => {
				this.shoppingCartSubject.error(error);
				this.resetActiveShoppingCart();
			}
		);
	}

	/**
	 * Order a product. If there's no active order selected yet,
	 * the user is first to set an active or new order.
	 * After selecting it, the product is added to the new active order.
	 *
	 * @param product
	 * @param quantity
	 */
	orderProduct(product: Product, quantity: number, buyAll: boolean) {
		const selectedOrder = this.activeOrderService.selectedOrder;

		if (selectedOrder) {
			if (buyAll) {
				return this.buyAll(product, selectedOrder);
			} else {
				return this.saveOrderProduct(product, quantity, selectedOrder);
			}
		}

		this.applicationService.openPopup(() => {
			// When the save action is done, the new order is active.
			// So when calling the orderProduct method again, we can successfully finish the ordering.
			const $productOrderDone = this.orderProduct(product, quantity, buyAll);

			// Can be null if the user had first to select an (new) active order.
			if ($productOrderDone == null) {
				return;
			}

			$productOrderDone.subscribe(() => {
				this.applicationService.closePopup();
			}, () => {
				// Also close the 'start new order popup' if the ordering failed.
				// The active order is successfully set, only the shoppingcart is not updated.
				this.applicationService.closePopup();
			});

		}, () => {
		}, true, product);

		return null;
	}

	removeProduct(shoppingCartLine: ShoppingCartLine) {
		const url = ConfigurationHelper.getWebshopUrl('/rest/shoppingcart/remove');

		this.http.post(url,
			JSON.stringify({
				priceListLineNo: shoppingCartLine.priceListLineNo,
				priceListNo: shoppingCartLine.priceListNo,
				type: shoppingCartLine.type,
				shipToCode: shoppingCartLine.shipToCode,
				shipmentDate: shoppingCartLine.shipmentDate,
				shoppingCartNo: shoppingCartLine.shoppingCartNo,
				shoppingCartMode: shoppingCartLine.shoppingCartMode
			})).subscribe(
			result => this.notifyShoppingCartChange(shoppingCartLine.shipToCode, shoppingCartLine.shipmentDate),
			error => this.handleError(error)
		);
	}

	changeQuantity(shoppingCartLine: ShoppingCartLine) {
		const url = ConfigurationHelper.getWebshopUrl('/rest/shoppingcart/changeQuantity');

		this.http.post(url,
			JSON.stringify({
				priceListLineNo: shoppingCartLine.priceListLineNo,
				priceListNo: shoppingCartLine.priceListNo,
				quantity: shoppingCartLine.quantity,
				type: shoppingCartLine.type,
				shipToCode: shoppingCartLine.shipToCode,
				shipmentDate: shoppingCartLine.shipmentDate,
				shoppingCartNo: shoppingCartLine.shoppingCartNo,
				shoppingCartMode: shoppingCartLine.shoppingCartMode
			})).subscribe(
			result => this.notifyShoppingCartChange(shoppingCartLine.shipToCode, shoppingCartLine.shipmentDate),
			error => this.handleError(error)
		);
	}

	/**
	 * Notifies the listeners that the shopping cart has changed
	 */
	public notifyShoppingCartChange(addressCode, shipmentDate) {
		this.getShoppingCart(addressCode, shipmentDate, null, null).subscribe(
			lines => {
				this._activeShoppingCartLines = lines;
				this.shoppingCartSubject.next(lines);
			},
			error => this.handleError(error)
		);
	}

	getNumberOfItemsInOrderForProduct(product: Product): number {
		if (this._activeShoppingCartLines) {
			const activeLine = this._activeShoppingCartLines.find(line => line.priceListLineNo === product.priceListLineNo
				&& line.priceListNo === product.priceListNo);

			if (activeLine !== undefined &&
				(activeLine.shoppingCartMode || this.isShoppingCartLineEditable(activeLine))) {
				return activeLine.quantity;
			}
		}

		return 0;
	}

	buyAll(product: Product, activeOrder: ActiveOrder) {
		const url = ConfigurationHelper.getWebshopUrl('/rest/shoppingcart/buyall');

		const $xhr = this.http.post(url,
			JSON.stringify({
				priceListLineNo: product.priceListLineNo,
				priceListNo: product.priceListNo,
				type: product.type,
				shipmentDate: activeOrder.shipmentDate,
				shipToCode: encodeURIComponent(activeOrder.shipToCode),
				shoppingCartMode: !this.userService.isUseFastCheckout()
			})).pipe(share());

		$xhr.subscribe(
			result => this.notifyShoppingCartChange(activeOrder.shipToCode, activeOrder.shipmentDate),
			error => this.handleError(error)
		);

		return $xhr;
	}

	/**
	 * Confirm all shopping cart lines for the given active order.
	 *
	 * @param activeOrder
	 */
	confirmAll(activeOrder: ActiveOrder): Observable<boolean> {
		const url = ConfigurationHelper.getWebshopUrl('/rest/shoppingcart/confirmall');

		return this.http.post<boolean>(url,
			JSON.stringify({
				shipmentDate: activeOrder.shipmentDate,
				shipToCode: encodeURIComponent(activeOrder.shipToCode)
			}));
	}

	private resetActiveShoppingCart(): void {
		this._activeShoppingCartLines = null;
	}

	private getLinesForSpecificCart(params: string): Observable<ShoppingCartLine[]> {
		let url = ConfigurationHelper.getWebshopUrl('/rest/shoppingcart');

		if (params) {
			url += '?' + params;
		}

		return this.http.get<ShoppingCartLine[]>(url)
			.pipe(share());
	}

	private getLinesForSpecificPendingOrder(params: string): Observable<ShoppingCartLine[]> {
		let url = ConfigurationHelper.getWebshopUrl('/rest/pendingorderdetails');

		if (params) {
			url += '?' + params;
		}

		return this.http.get(url);
	}

	/**
	 * this is the call to the server which adds the product
	 *
	 * @param product
	 * @param quantity
	 * @param activeOrder
	 */
	private saveOrderProduct(product: Product, quantity: number, activeOrder: ActiveOrder) {
		const url = ConfigurationHelper.getWebshopUrl('/rest/shoppingcart/order');

		const $xhr = this.http.post(url,
			JSON.stringify({
				priceListLineNo: product.priceListLineNo,
				priceListNo: product.priceListNo,
				type: product.type,
				quantity: quantity,
				shipmentDate: activeOrder.shipmentDate,
				shipToCode: encodeURIComponent(activeOrder.shipToCode),
				shoppingCartMode: !this.userService.isUseFastCheckout()
			})).pipe(share());

		$xhr.subscribe(
			result => this.notifyShoppingCartChange(activeOrder.shipToCode, activeOrder.shipmentDate),
			error => this.logger.error('Failed to add product to order', error)
		);

		return $xhr;
	}
}
