"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var default_log_service_1 = require("../logging/default-log.service");
var AnalyticsService = (function () {
    function AnalyticsService(logger) {
        this.logger = logger;
    }
    AnalyticsService.prototype.analyticsTrackEvent = function (category, action, label, value, noninteraction) {
        var windowObj = window;
        var _gaq = windowObj._gaq !== undefined ? windowObj._gaq : [];
        var opt_label = label ? label : null;
        var opt_value = value ? value : null;
        var opt_interaction = noninteraction ? noninteraction : false;
        if (typeof windowObj._gaq === undefined) {
            this.logger.info("Analytics library could not be found");
            this.logger.info("_trackEvent - " + category + " - " + action + " - " + opt_label + " - " + opt_value + " - " + opt_interaction);
        }
        _gaq.push(['_trackEvent', category, action, opt_label, opt_value, opt_interaction]);
    };
    AnalyticsService.prototype.trackProductViewDetail = function (productTitle, url) {
        this.analyticsTrackEvent('Products', 'View product detail', productTitle + " - " + url, null, true);
    };
    return AnalyticsService;
}());
exports.AnalyticsService = AnalyticsService;
