"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../generic/component/planthunter/planthunter.amount.of.new.hunts.component.ngfactory");
var i2 = require("../generic/component/planthunter/planthunter.amount.of.new.hunts.component");
var i3 = require("../generic/service/planthunter.service");
var i4 = require("./planthunter.amount.of.new.hunts.wrapper.component");
var styles_PlanthunterAmountOfNewHuntsWrapperComponent = [];
var RenderType_PlanthunterAmountOfNewHuntsWrapperComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PlanthunterAmountOfNewHuntsWrapperComponent, data: {} });
exports.RenderType_PlanthunterAmountOfNewHuntsWrapperComponent = RenderType_PlanthunterAmountOfNewHuntsWrapperComponent;
function View_PlanthunterAmountOfNewHuntsWrapperComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "planthunter-amountofnewhunts-component", [], null, null, null, i1.View_PlanthunterAmountOfNewHuntsComponent_0, i1.RenderType_PlanthunterAmountOfNewHuntsComponent)), i0.ɵdid(1, 49152, null, 0, i2.PlanthunterAmountOfNewHuntsComponent, [i3.PlanthunterService], null, null)], null, null); }
exports.View_PlanthunterAmountOfNewHuntsWrapperComponent_0 = View_PlanthunterAmountOfNewHuntsWrapperComponent_0;
function View_PlanthunterAmountOfNewHuntsWrapperComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "planthunter-amountofnewhunts-wrapper-component", [], null, null, null, View_PlanthunterAmountOfNewHuntsWrapperComponent_0, RenderType_PlanthunterAmountOfNewHuntsWrapperComponent)), i0.ɵdid(1, 49152, null, 0, i4.PlanthunterAmountOfNewHuntsWrapperComponent, [], null, null)], null, null); }
exports.View_PlanthunterAmountOfNewHuntsWrapperComponent_Host_0 = View_PlanthunterAmountOfNewHuntsWrapperComponent_Host_0;
var PlanthunterAmountOfNewHuntsWrapperComponentNgFactory = i0.ɵccf("planthunter-amountofnewhunts-wrapper-component", i4.PlanthunterAmountOfNewHuntsWrapperComponent, View_PlanthunterAmountOfNewHuntsWrapperComponent_Host_0, {}, {}, []);
exports.PlanthunterAmountOfNewHuntsWrapperComponentNgFactory = PlanthunterAmountOfNewHuntsWrapperComponentNgFactory;
