import {Injectable} from '@angular/core';

@Injectable()
export class DateService {

    /**
     * Get the iso string for the given date
     *
     * @param date
     * @returns {any}
     */
    public getIsoString(date: Date): string {
        if (date) {
            const slicedIsoDate = this.treatAsUTC(date).toISOString().slice(0, 10);

            return slicedIsoDate;
        }

        return null;
    }

    /**
     * Calculate the days between two dates
     *
     * @param startDate
     * @param endDate
     * @returns {number}
     */
    public getDaysBetween(startDate: Date, endDate: Date): number {
        const millisecondsPerDay = 24 * 60 * 60 * 1000;

        // Make sure the dates are compared without time component
        startDate.setHours(0, 0, 0, 0);

        const diff = this.treatAsUTC(endDate).getTime() - this.treatAsUTC(startDate).getTime();

        return Math.floor((diff) / millisecondsPerDay);
    }

    /**
     * Take time zone offset in account when working with a date.
     *
     * @param date
     * @returns {Date}
     */
    public treatAsUTC(date): Date {
        const result = new Date(date);
        result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
        return result;
    }
}
