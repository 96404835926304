"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var ng_lazy_load_1 = require("ng-lazy-load");
var product_1 = require("../../classes/product");
var shoppingcart_service_1 = require("../../service/shoppingcart.service");
var active_order_service_1 = require("../../service/active.order.service");
var user_service_1 = require("../../service/user.service");
var product_service_1 = require("../../service/product.service");
var productitem_common_component_1 = require("./productitem.common.component");
var product_prices_1 = require("../../classes/product.prices");
var configuration_helper_1 = require("../../helper/configuration.helper");
var browser_service_1 = require("../../service/browser.service");
var window_ref_service_1 = require("../../service/window-ref.service");
var analytics_service_1 = require("../../service/analytics.service");
var ProductItemGridComponent = (function (_super) {
    __extends(ProductItemGridComponent, _super);
    function ProductItemGridComponent(lazyLoadService, shoppingCartService, formBuilder, element, activeOrderService, userService, analyticsService, changeDetectorRef, productService) {
        var _this = _super.call(this) || this;
        _this.lazyLoadService = lazyLoadService;
        _this.shoppingCartService = shoppingCartService;
        _this.formBuilder = formBuilder;
        _this.element = element;
        _this.activeOrderService = activeOrderService;
        _this.userService = userService;
        _this.analyticsService = analyticsService;
        _this.changeDetectorRef = changeDetectorRef;
        _this.productService = productService;
        _this.showMoreInfoButton = false;
        _this.productMatchedFragmentEvent = new core_1.EventEmitter();
        _this.viewProductDetailClickEvent = new core_1.EventEmitter();
        _this.itemsInOrder = 0;
        _this.buttonShowPressed = false;
        _this.showConfirmation = false;
        _this.showProduct = false;
        return _this;
    }
    ProductItemGridComponent.prototype.doLoad = function (state) {
        this.showProduct = true;
    };
    ProductItemGridComponent.prototype.triggerProductMatchedFragmentEvent = function (anchorValue) {
        this.productMatchedFragmentEvent.emit(anchorValue);
    };
    ProductItemGridComponent.prototype.getProduct = function () {
        return this.product;
    };
    ProductItemGridComponent.prototype.getProductService = function () {
        return this.productService;
    };
    ProductItemGridComponent.prototype.getScrollToId = function () {
        return this.scrollToId;
    };
    ProductItemGridComponent.prototype.ngOnDestroy = function () {
        if (this.shoppingcartSubscription) {
            this.shoppingcartSubscription.unsubscribe();
        }
        this.changeDetectorRef.detach();
    };
    ProductItemGridComponent.prototype.isFastCheckout = function () {
        return this.userService.isUseFastCheckout();
    };
    ProductItemGridComponent.prototype.isWebsite = function () {
        var location = ProductItemGridComponent.winRef.nativeWindow.location.pathname;
        var regexLocation = /^\/(site)\/([a-z]{2})\//gm;
        return location.match(regexLocation);
    };
    ProductItemGridComponent.prototype.ngAfterViewInit = function () {
        _super.prototype.ngAfterViewInit.call(this);
    };
    ProductItemGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        if (browser_service_1.BrowserService.isEdgeBrowser() || this.isWebsite()) {
            this.showProduct = true;
        }
        else {
            this.lazyLoadService.isDevMode = false;
            this.lazyLoadService.loadAheadCount = 10;
        }
        this.form = this.formBuilder.group({
            'numberOfUnits': ['', forms_1.Validators.required],
            'unit': [this.product.qtyByBox, forms_1.Validators.required]
        });
        this.itemsPerUnit = this.product.qtyByBox;
        this.minimumOrderQuantity = this.getMinimumOrderQuantity();
        this.maxValue = this.getMaxValue();
        this.quantityValue = this.productService.getMinimumOrderQuantity(this.product, this.product.qtyByBox);
        this.stockProduct = this.isStockProduct();
        if (this.loggedIn) {
            this.shoppingcartSubscription = this.shoppingCartService.getShoppingCartObservable().subscribe(function (lines) { return _this.updateItemsInOrder(lines); });
        }
        this.productPrices = new product_prices_1.ProductPrices(this.product, this.itemsInOrder);
        if (this.productPrices.showUnitOption()) {
            this.selectedUnit = 'unit';
        }
        if (this.productPrices.showTrayOption()) {
            this.selectedUnit = 'tray';
        }
        this.setPriceAndItemsPerUnitBasedOnSelectedUnit();
        this.trolleyPriceWithDiscount = this.product.trolleyDiscountPrice;
        this.availableUnitOptions = this.createAvailableUnitOptions();
        this.form.get('numberOfUnits').setValue(this.quantityValue);
        this.form.get('unit').setValue(this.selectedUnit);
    };
    ProductItemGridComponent.prototype.onSelectedUnitChange = function ($event) {
        this.selectedUnit = $event.value;
        this.changeQuantity();
        this.setPriceAndItemsPerUnitBasedOnSelectedUnit();
    };
    ProductItemGridComponent.prototype.changeQuantity = function () {
        var minimum = this.minimumOrderQuantity;
        if (this.stockProduct) {
            var orderQuantity = this.quantityValue * this.itemsPerUnit;
            var availableQuantity = this.getAvailableQuantity();
            if (availableQuantity > 0 && this.quantityValue < minimum) {
                this.quantityValue = minimum;
                this.form.get('numberOfUnits').patchValue(this.quantityValue);
            }
            if (availableQuantity > 0 && orderQuantity > availableQuantity) {
                this.quantityValue = this.getMaximumOrderQuantity();
                this.form.get('numberOfUnits').patchValue(this.quantityValue);
            }
        }
        if (minimum > this.quantityValue || minimum > this.form.get('numberOfUnits').value) {
            this.quantityValue = minimum;
            this.form.get('numberOfUnits').patchValue(this.quantityValue);
        }
    };
    ProductItemGridComponent.prototype.getMaxValue = function () {
        if (this.stockProduct) {
            return this.getMaximumOrderQuantity();
        }
        return null;
    };
    ProductItemGridComponent.prototype.onSubmit = function (value) {
        var _this = this;
        if (this.buttonShowPressed) {
            return;
        }
        if (this.form.invalid) {
            return;
        }
        var numberOfItemsToOrder = value.numberOfUnits * this.itemsPerUnit;
        if (this.activeOrderService.hasSelectedOrder()) {
            this.buttonShowPressed = true;
            this.changeDetectorRef.detectChanges();
        }
        var productOrderDone = this.shoppingCartService.orderProduct(this.product, numberOfItemsToOrder, false);
        if (productOrderDone == null) {
            return;
        }
        productOrderDone.subscribe(function () {
            if (_this.buttonShowPressed === true) {
                _this.showConfirmation = true;
                _this.changeDetectorRef.detectChanges();
                _this.productService.triggerProductAddedToCartEvent(_this.product);
                setTimeout(function () {
                    _this.showConfirmation = false;
                    _this.buttonShowPressed = false;
                    if (!_this.changeDetectorRef.destroyed) {
                        _this.changeDetectorRef.detectChanges();
                    }
                }, 2000);
            }
        }, function () {
            _this.buttonShowPressed = false;
            _this.changeDetectorRef.detectChanges();
        });
    };
    ProductItemGridComponent.prototype.isStockProduct = function () {
        return this.product.type === this.productService.TYPE_STOCK;
    };
    ProductItemGridComponent.prototype.getAvailableQuantity = function () {
        var available = this.product.availableQuantity - this.itemsInOrder;
        return (available < 0) ? 0 : available;
    };
    ProductItemGridComponent.prototype.isWebshopProductsUrl = function () {
        return (window.location.href.indexOf(configuration_helper_1.ConfigurationHelper.getWebshopBaseUrl()) > -1
            && window.location.href.indexOf('products') > -1);
    };
    ProductItemGridComponent.prototype.viewProductDetail = function () {
        var productDetailUrl = "/products/detail;type=" + this.product.type + ";priceListNo=" + this.product.priceListNo + ";priceListLineNo=" + this.product.priceListLineNo;
        this.analyticsService.trackProductViewDetail(this.product.totalDescription1, productDetailUrl);
        if (this.isWebshopProductsUrl()) {
            this.productService.pushProductState(this.product);
        }
        else {
            var location_1 = ProductItemGridComponent.winRef.nativeWindow.location.pathname;
            this.productService.pushWebsiteProductState(this.product, location_1);
        }
        this.viewProductDetailClickEvent.emit(productDetailUrl);
    };
    ProductItemGridComponent.prototype.setPriceAndItemsPerUnitBasedOnSelectedUnit = function () {
        if (this.selectedUnit === ProductItemGridComponent.UNIT_VALUE_TYPE || this.selectedUnit === ProductItemGridComponent.TRAY_VALUE_TYPE) {
            this.itemsPerUnit = this.product.qtyByBox;
            this.priceBasedOnSelectedUnit = this.product.unitPrice;
            this.priceBasedOnSelectedUnitWithDiscount = this.product.unitDiscountPrice;
        }
        if (this.selectedUnit === ProductItemGridComponent.LAYER_VALUE_TYPE) {
            this.itemsPerUnit = this.product.qtyByBox * this.product.qtyPackingsByLayer;
            this.priceBasedOnSelectedUnit = this.product.layerPrice;
            this.priceBasedOnSelectedUnitWithDiscount = this.product.layerDiscountPrice;
        }
        if (this.selectedUnit === ProductItemGridComponent.TROLLEY_VALUE_TYPE) {
            this.itemsPerUnit = this.product.qtyByTrolley;
            this.priceBasedOnSelectedUnit = this.product.trolleyPrice;
            this.priceBasedOnSelectedUnitWithDiscount = this.product.trolleyDiscountPrice;
        }
        this.minimumOrderQuantity = this.getMinimumOrderQuantity();
        this.quantityValue = this.minimumOrderQuantity;
        this.form.get('numberOfUnits').patchValue(this.quantityValue);
    };
    ProductItemGridComponent.prototype.getMinimumOrderQuantity = function () {
        return this.productService.getMinimumOrderQuantity(this.product, this.itemsPerUnit);
    };
    ProductItemGridComponent.prototype.getMaximumOrderQuantity = function () {
        return Math.floor(this.getAvailableQuantity() / this.itemsPerUnit);
    };
    ProductItemGridComponent.prototype.updateItemsInOrder = function (lines) {
        var _this = this;
        if (lines) {
            var activeLine = lines.find(function (line) { return line.priceListLineNo === _this.product.priceListLineNo
                && line.priceListNo === _this.product.priceListNo; });
            if (activeLine !== undefined &&
                (activeLine.shoppingCartMode || this.shoppingCartService.isShoppingCartLineEditable(activeLine))) {
                this.itemsInOrder = activeLine.quantity;
            }
        }
    };
    ProductItemGridComponent.prototype.createAvailableUnitOptions = function () {
        var optionsArray = [];
        var optionObject = {};
        if (this.productPrices.showUnitOption()) {
            optionObject = {
                value: ProductItemGridComponent.UNIT_VALUE_TYPE,
                qty: this.product.qtyByBox,
                price: this.productPrices.getUnitOrTrayNormalOrDiscountPrice()
            };
            optionsArray.push(optionObject);
        }
        if (this.productPrices.showTrayOption()) {
            optionObject = {
                value: ProductItemGridComponent.TRAY_VALUE_TYPE,
                qty: this.product.qtyByBox,
                price: this.productPrices.getUnitOrTrayNormalOrDiscountPrice()
            };
            optionsArray.push(optionObject);
        }
        if (this.productPrices.showLayerOption()) {
            optionObject = {
                value: ProductItemGridComponent.LAYER_VALUE_TYPE,
                qty: this.product.qtyPackingsByLayer,
                qtyBox: this.product.qtyByBox,
                price: this.productPrices.getLayerNormalOrDiscountPrice()
            };
            optionsArray.push(optionObject);
        }
        if (this.productPrices.showTrolleyOption()) {
            optionObject = {
                value: ProductItemGridComponent.TROLLEY_VALUE_TYPE,
                qty: this.product.qtyPackingsByTrolley,
                qtyBox: this.product.qtyByBox,
                price: this.productPrices.getTrolleyNormalOrDiscountPrice()
            };
            optionsArray.push(optionObject);
        }
        return optionsArray;
    };
    ProductItemGridComponent.winRef = new window_ref_service_1.WindowRef();
    ProductItemGridComponent.UNIT_VALUE_TYPE = 'unit';
    ProductItemGridComponent.TRAY_VALUE_TYPE = 'tray';
    ProductItemGridComponent.LAYER_VALUE_TYPE = 'layer';
    ProductItemGridComponent.TROLLEY_VALUE_TYPE = 'trolley';
    return ProductItemGridComponent;
}(productitem_common_component_1.ProductItemCommonComponent));
exports.ProductItemGridComponent = ProductItemGridComponent;
