"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var DateService = (function () {
    function DateService() {
    }
    DateService.prototype.getIsoString = function (date) {
        if (date) {
            var slicedIsoDate = this.treatAsUTC(date).toISOString().slice(0, 10);
            return slicedIsoDate;
        }
        return null;
    };
    DateService.prototype.getDaysBetween = function (startDate, endDate) {
        var millisecondsPerDay = 24 * 60 * 60 * 1000;
        startDate.setHours(0, 0, 0, 0);
        var diff = this.treatAsUTC(endDate).getTime() - this.treatAsUTC(startDate).getTime();
        return Math.floor((diff) / millisecondsPerDay);
    };
    DateService.prototype.treatAsUTC = function (date) {
        var result = new Date(date);
        result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
        return result;
    };
    return DateService;
}());
exports.DateService = DateService;
