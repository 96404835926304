const LogRocket = require('logrocket');
import {ApplicationRef, NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {genericDeclarations, genericImports, genericProviders, initWindowObject} from './generic/configuration';
import {StartOrderWrapperComponent} from './sitespecific/start.order.wrapper.component';
import {LoginWrapperComponent} from './sitespecific/login.wrapper.component';
import {ShoppingcartHeaderWrapperComponent} from './sitespecific/shoppingcart.header.wrapper.component';
import {ShoppingcartHeaderMobileWrapperComponent} from './sitespecific/shoppingcart.header.mobile.wrapper.component';
import {SpotlightProductsComponent} from './sitespecific/spotlight.products.component';
import {APP_BASE_HREF} from '@angular/common';
import {PricelistsWrapperComponent} from './sitespecific/pricelists.wrapper.component';
import {PendingOrdersWrapperComponent} from './sitespecific/pending.orders.wrapper.component';
import {RedirectToShopService} from './sitespecific/redirect.to.shop.service';
import {RedirectToShopGuard} from "./sitespecific/redirect.to.shop.guard";
import {PlanthunterOverviewComponent} from "./sitespecific/planthunter.overview.component";
import {PlanthunterDetailComponent} from "./sitespecific/planthunter.detail.component";
import {PlanthunterAmountOfNewHuntsWrapperComponent} from "./sitespecific/planthunter.amount.of.new.hunts.wrapper.component";

// Register the window variables in the shop code
initWindowObject();

let entryComponents = [
	ShoppingcartHeaderWrapperComponent,
	ShoppingcartHeaderMobileWrapperComponent,
	StartOrderWrapperComponent,
	LoginWrapperComponent,
	SpotlightProductsComponent,
	PricelistsWrapperComponent,
	PendingOrdersWrapperComponent,
    PlanthunterOverviewComponent,
    PlanthunterDetailComponent,
    PlanthunterAmountOfNewHuntsWrapperComponent
];

let entryComponentsMapper = {
	'ShoppingcartHeaderWrapperComponent': ShoppingcartHeaderWrapperComponent,
	'ShoppingcartHeaderMobileWrapperComponent': ShoppingcartHeaderMobileWrapperComponent,
	'StartOrderWrapperComponent': StartOrderWrapperComponent,
	'LoginWrapperComponent': LoginWrapperComponent,
	'SpotlightProductsComponent': SpotlightProductsComponent,
	'PricelistsWrapperComponent': PricelistsWrapperComponent,
	'PendingOrdersWrapperComponent': PendingOrdersWrapperComponent,
	'PlanthunterOverviewComponent': PlanthunterOverviewComponent,
	'PlanthunterDetailComponent': PlanthunterDetailComponent,
	'PlanthunterAmountOfNewHuntsWrapperComponent': PlanthunterAmountOfNewHuntsWrapperComponent
};

// Makes sure that all relative router events, from the generic angular components,
// are redirected to the correct absolute webshop url.
const appRoutes: Routes = [
	{path: '**', redirectTo: '', canActivate: [RedirectToShopGuard]}
];

@NgModule({
	imports: [
		genericImports,

		// See RedirectToShopGuard for how we handle the router navigation in the website codebase.
		RouterModule.forRoot(appRoutes, {
			initialNavigation: false
		})
	],
	exports: [RouterModule],
	declarations: [
		genericDeclarations, entryComponents
	],
	providers: [
        genericProviders, RedirectToShopService, RedirectToShopGuard, {provide: APP_BASE_HREF, useValue: '/'}
	],
	entryComponents: entryComponents
})
export class AppModule {
	ngDoBootstrap(appRef: ApplicationRef) {
		let windowObj = (<any>window);

		// To bootstrap your component, add the component to the entryComponents array and
		// add the 'data-bootstrap' attribute to your selector,
		// with as value the class name of the component.
		var domElementsToBootstrap = windowObj.document.querySelectorAll('[data-bootstrap]');

		for (let element in domElementsToBootstrap) {
			if (domElementsToBootstrap.hasOwnProperty(element)) {

				let domElement = domElementsToBootstrap[element];

				let componentToBootstrapName = domElement.getAttribute('data-bootstrap');

				// If null, the selector from the component is used
				// to find the dom element to bootstrap the component.
				// See example in spotlight-products.jsp how to bootstrap a component multiple times on the page
				// with this logic.
				let domRootElementForBootstrapping = domElement.getAttribute('data-rootelement');

				let componentToBootstrap = entryComponentsMapper[componentToBootstrapName];

				if (componentToBootstrap) {
					appRef.bootstrap(componentToBootstrap, domRootElementForBootstrapping);
				}
			}
		}
	}
}
