"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("@angular/common");
var i2 = require("./render.checkout.icon");
var styles_RenderCheckoutIconComponent = [];
var RenderType_RenderCheckoutIconComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RenderCheckoutIconComponent, data: {} });
exports.RenderType_RenderCheckoutIconComponent = RenderType_RenderCheckoutIconComponent;
function View_RenderCheckoutIconComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, ":svg:svg", [["class", "icon-truck"], ["viewBox", "0 0 46 24"]], [[1, "width", 0], [1, "height", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵeld(2, 0, null, null, 0, ":svg:use", [[":xlink:href", "/images/icons/svg-sprite.svg#truck"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n    "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iconWidth; var currVal_1 = _co.iconHeight; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_RenderCheckoutIconComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, ":svg:svg", [["class", "icon-shoppingcart"], ["viewBox", "0 0 27 24"]], [[1, "width", 0], [1, "height", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵeld(2, 0, null, null, 0, ":svg:use", [[":xlink:href", "/images/icons/svg-sprite.svg#shoppingcart"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n    "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iconWidth; var currVal_1 = _co.iconHeight; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_RenderCheckoutIconComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RenderCheckoutIconComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RenderCheckoutIconComponent_2)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fastCheckout; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.fastCheckout; _ck(_v, 5, 0, currVal_1); }, null); }
exports.View_RenderCheckoutIconComponent_0 = View_RenderCheckoutIconComponent_0;
function View_RenderCheckoutIconComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "render-checkout-icon", [], null, null, null, View_RenderCheckoutIconComponent_0, RenderType_RenderCheckoutIconComponent)), i0.ɵdid(1, 49152, null, 0, i2.RenderCheckoutIconComponent, [], null, null)], null, null); }
exports.View_RenderCheckoutIconComponent_Host_0 = View_RenderCheckoutIconComponent_Host_0;
var RenderCheckoutIconComponentNgFactory = i0.ɵccf("render-checkout-icon", i2.RenderCheckoutIconComponent, View_RenderCheckoutIconComponent_Host_0, { fastCheckout: "fastCheckout", iconWidth: "iconWidth", iconHeight: "iconHeight" }, {}, []);
exports.RenderCheckoutIconComponentNgFactory = RenderCheckoutIconComponentNgFactory;
