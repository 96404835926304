/**
 * Helper class which holds all translations for the CartLineRemoveComponent.
 */
export class CartLineRemoveTranslations {
    label: string;
    seconds: string;
    minutes: string;
    hours: string;
    days: string;

    constructor(label: string, seconds: string, minutes: string, hours: string, days: string) {
        this.label = label;
        this.seconds = seconds;
        this.minutes = minutes;
        this.hours = hours;
        this.days = days;
    }
}
