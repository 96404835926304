import {share} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient as BaseHttpClient, HttpHeaders} from '@angular/common/http';
import {BaseService} from './base.service';
import {Router} from '@angular/router';

/**
 * Custom httpClient to automatically set the content type
 *
 * @author evandongen
 */
@Injectable()
export class HttpClient {

	private http: BaseHttpClient;

	private router: Router;

	constructor(http: BaseHttpClient,
				router: Router) {
		this.http = http;
		this.router = router;
	}

	public static deleteCookie(name) {
		this.setSessionCookie(name, '', -1);
	}

	public static saveJsonInCookie(name, jsonObject, expiresInDays = null) {
		this.setSessionCookie(name, jsonObject ? JSON.stringify(jsonObject) : null, expiresInDays, true);
	}

	public static getCookie(name, encoded = false) {
		let value = '; ' + document.cookie;
		const parts = value.split('; ' + name + '=');
		if (parts.length !== 2) {
			return null;
		}

		value = parts.pop().split(';').shift();
		if (encoded) {
			return atob(this.replaceAll(value, 'EQUALS_SIGN', '='));
		}
		return value;
	}

	private static setSessionCookie(name, value, expiresInDays, encode = false) {
		// We also save json in cookies (to share them with the website),
		// so we have to encode the value, because otherwise the value,
		// would contain invalid cookie value chars.
		if (encode && value) {
			value = this.replaceAll(btoa(value), '=', 'EQUALS_SIGN');
		}

		const windowObj = (<any>window);

		const expireTime = this.getCookieExpireTimeValue(expiresInDays);

		let cookieValue = `site=${value};domain=${windowObj.cookieDomain}; path=/;`;

		if (expireTime) {
			cookieValue += ` expires=${expireTime};`;
		}

		document.cookie = cookieValue;
	}

	private static getCookieExpireTimeValue(expiresInDays) {
		if (expiresInDays == null) {
			return null;
		}

		const expiresDate = new Date();

		// Get unix milliseconds at current time plus number of days
		expiresDate.setTime(+expiresDate + (expiresInDays * 86400000)); // 24 * 60 * 60 * 1000

		return expiresDate.toUTCString();
	}

	private static replaceAll(string, find, replace) {
		return string.replace(new RegExp(find, 'g'), replace);
	}

	getDefaultHeaders() {
		return new HttpHeaders({'Content-Type': 'application/json;charset=UTF-8'});
	}

	getText(url) {
		const $observable = this.http.get(url, {responseType: 'text'}).pipe(share());

		this.showFeedbackOnError($observable);

		return $observable;
	}

	get<T>(url, specificHeaders: HttpHeaders = null) {
		const $observable = this.http.get<T>(url, {
			headers: specificHeaders ? specificHeaders : this.getDefaultHeaders(),
			withCredentials: true
		}).pipe(share());

		this.showFeedbackOnError($observable);

		return $observable;
	}

	post<T>(url, data) {
		const $observable = this.http.post<T>(url, data, {
			headers: this.getDefaultHeaders(),
			withCredentials: true
		}).pipe(share());

		this.showFeedbackOnError($observable);

		return $observable;
	}

	private showFeedbackOnError($observable) {
		$observable.toPromise().catch(errorResponse => {
			let errorShown = false;

			try {
				const error = errorResponse.error;

				if (error.feedback) {
					BaseService.showErrorFeedback(error.feedback);
					errorShown = true;
				}
			} catch (e) {
				// Do nothing
			}

			// If there wasn't a specific error to show, show a generic message to the user.
			if (!errorShown) {
				// Don't show the notification toast on the login page when a unknown username/password is used.
				// Only show the form error messages.
				if (this.router.url === '/login' || this.router.url === '/') {
					return;
				}

				BaseService.showErrorFeedback('An unexpected error has occurred. Please try again.');
			}
		});
	}
}
