"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var RedirectToShopGuard = (function () {
    function RedirectToShopGuard() {
    }
    RedirectToShopGuard.prototype.canActivate = function (route, state) {
        return false;
    };
    return RedirectToShopGuard;
}());
exports.RedirectToShopGuard = RedirectToShopGuard;
