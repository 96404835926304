<ul class="header-tabs hide-sm">
	<li [ngClass]="{'active': selectedOrder === allProductsValue}">
		<a class="cursor--force-hand" (click)="unSelectOrder()"
		   i18n="All products link@@all.products.link">All products</a>
	</li>

	<!-- If no orders, start new order -->
	<li class="header-orderswitcher active" *ngIf="showOrderSwitcher()">
		<div class="custom-select">
			<select id="orderswitcher" class="theme-primary-text-color" [ngModel]="selectedOrder" (ngModelChange)="changeOrder($event)">
				<option *ngFor="let order of activeOrders" [ngValue]="order">
                    <ng-template [ngIf]="order.deliveryDate">
                        <span render-day-of-week-abbr [day]="order.deliveryDate.shippingDayName"></span>
                    </ng-template>
					{{order.shipmentDate | customDate:'dd MMMM'}} - {{order.deliveryAddressName}} ({{order.shipToCode}})
				</option>
				<option [ngValue]="'new'" i18n="Start new order link text@@start.new.order.button">Start new order</option>
			</select>
		</div>
		<label class="header-orderswitcher_count" for="orderswitcher" *ngIf="activeOrders">
			({{activeOrders.length}})
		</label>
	</li>

	<li *ngIf="!showOrderSwitcher() && firstActiveOrder != null" class="theme-primary-highlight-color">
		<a class="header-link" (click)="changeOrder(firstActiveOrder)">
			<ng-template [ngIf]="firstActiveOrder.deliveryDate">
				<span render-day-of-week-abbr [day]="firstActiveOrder.deliveryDate.shippingDayName"></span>
			</ng-template>
			{{firstActiveOrder.shipmentDate | customDate:'dd MMMM'}} -
            {{firstActiveOrder.deliveryAddressName}}
            ({{firstActiveOrder.shipToCode}})
		</a>
	</li>

	<li *ngIf="!showOrderSwitcher() && firstActiveOrder == null">
		<a class="header-link" (click)="startNewOrder()" i18n="Start new order link text@@start.new.order.button">Start new
			order</a>
	</li>
</ul>


<div class="custom-select header-tabs--select hide-lg hide-md visible-sm">
    <select id="orderswitcherResponsive" [ngModel]="selectedOrder" (ngModelChange)="changeOrder($event)">

        <option [ngValue]="allProductsValue" i18n="All products link text@@all.products.link">All products</option>

        <option *ngFor="let order of activeOrders; trackBy: trackByOrder" [ngValue]="order">
            {{order.shipmentDate | customDate:'EEE dd MMMM'}} - {{order.deliveryAddressName}} ({{order.shipToCode}})
        </option>
        <option [ngValue]="'new'" i18n="Start new order link text@@start.new.order.button">Start new order</option>
    </select>
</div>
