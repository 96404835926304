import { Component, ViewChild, ElementRef } from '@angular/core';

/**
 * This component serves as a workaround for the strings that need to be internationalized but which aren't in
 * a template.
 *
 * @see https://github.com/angular/angular/issues/11405#issuecomment-254169729
 * @author Patrick van Zadel
 */
@Component({
    selector: 'reset-password-translations',
    styles: [`.translation {
                display: none;
            }`],
    template: `<span class="translation" #transSentResetEmail i18n="message: we sent an email@@forget.password.sent.success">
        We sent an email to your email address to reset your password.</span>

    <span class="translation" #transLinkExpired i18n="message: reset link expired@@reset.password.link.expired">
        This link has been used already or it has expired.
    </span>

    <span class="translation" #transResetSuccess i18n="message: reset success@@reset.password.success">
        Your password has been successfully reset. You will automatically be logged in.
    </span>
    `
})
export class ResetPasswordTranslationsComponent {
    @ViewChild('transSentResetEmail')
    forgotEmailSent: ElementRef;

    @ViewChild('transLinkExpired')
    resetLinkExpired: ElementRef;

    @ViewChild('transResetSuccess')
    resetPasswordSuccess: ElementRef;

    public getForgotEmailSent(): string {
        return this.forgotEmailSent.nativeElement.textContent;
    }

    public getResetLinkExpired(): string {
        return this.resetLinkExpired.nativeElement.textContent;
    }

    public getResetPasswordSuccess(): string {
        return this.resetPasswordSuccess.nativeElement.textContent;
    }
}
