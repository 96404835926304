<ng-template [ngIf]="!isFastCheckout()">
	<a (click)="viewShoppingcart()" class="header-shoppingcart-mini cursor--force-hand">
		<svg class="icon-shoppingcart" width="25" height="22">
			<use xlink:href="/images/icons/svg-sprite.svg#shoppingcart"></use>
		</svg>
		<span class="header-shoppingcart-mini-total">{{getShoppingCartCount()}}</span>
	</a>
</ng-template>

<ng-template [ngIf]="isFastCheckout()">
	<a (click)="viewOrderDetail()" class="header-shoppingcart-mini">
		<svg class="icon-truck" width="47" height="18">
			<use xlink:href="/images/icons/svg-sprite.svg#truck"></use>
		</svg>
		<span class="header-shoppingcart-mini-total">{{getShoppingCartCount()}}</span>
	</a>
</ng-template>