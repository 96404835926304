import { Component, ViewChild, ElementRef } from '@angular/core';

/**
 * This component serves as a workaround for the strings that need to be internationalized but which aren't in
 * a template.
 *
 * @see https://github.com/angular/angular/issues/11405#issuecomment-254169729
 * @author evandongen
 */
@Component({
    selector: 'order-translations',
    styles: [`.translation {
                display: none;
            }`],
    template: `<span class="translation" #transClosedForBarcode
        i18n="message: order is closed@@order.translations.barcode.add.denied.order.closed">
        The order is closed. Barcodes cannot be added anymore.</span>

    <span class="translation" #transClosed i18n="message: order is closed@@order.translations.order.closed">closed</span>

    <span class="translation" #transClosedIn i18n="message: order is closed in@@order.translations.order.closed.in">in</span>

    <span class="translation" #transBcError i18n="message: barcode error@@order.translations.barcode.add.error">
        An error occured while confirming your barcodes. Please try again.</span>

    <span class="translation" #transBcSuccess i18n="message: barcode success@@order.translations.barcode.add.success">
        Your barcodes have been confirmed, and will be attached on the cover of the products.</span>

    <span class="translation" #transScConfirmed  i18n="message: shopping cart confirmed@@order.translations.barcode.add.confirmed">
        Your order has been confirmed. If needed, your account manager will contact you about availability of products
    </span>

    <span class="translation" #transScError i18n="message: shopping cart error@@order.translations.confirm.error">
        Something went wrong confirming your order. Please try again or contact your account manager
    </span>

    <span class="translation" #transDay i18n="message: number of days, singular@@order.translations.day.single">day</span>
    <span class="translation" #transDays i18n="message: number of days, plural@@order.translations.day.plural">days</span>
    <span class="translation" #transTomorrow i18n="message: Tomorrow@@order.translations.day.tomorrow">Tomorrow</span>
    `
})
export class OrderTranslationsComponent  {

    @ViewChild('transClosedForBarcode')
    orderClosedForBarcode: ElementRef;

    @ViewChild('transClosed')
    orderClosed: ElementRef;

    @ViewChild('transClosedIn')
    orderClosedIn: ElementRef;

    @ViewChild('transBcError')
    barcodeError: ElementRef;

    @ViewChild('transBcSuccess')
    barcodeSuccess: ElementRef;

    @ViewChild('transScConfirmed')
    shoppingCartConfirmed: ElementRef;

    @ViewChild('transScError')
    shoppingCartError: ElementRef;

    @ViewChild('transDay')
    closingInDay: ElementRef;

    @ViewChild('transDays')
    closingInDays: ElementRef;

    @ViewChild('transTomorrow')
    closingTomorrow: ElementRef;

    public getOrderClosed(): string {
        return this.orderClosedForBarcode.nativeElement.textContent;
    }

    public getBarcodeError(): string {
        return this.barcodeError.nativeElement.textContent;
    }

    public getBarcodeSuccess(): string {
        return this.barcodeSuccess.nativeElement.textContent;
    }

    public getShoppingCartConfirmed(): string {
        return this.shoppingCartConfirmed.nativeElement.textContent;
    }

    public getShoppingCartError(): string {
        return this.shoppingCartError.nativeElement.textContent;
    }

    public getClosingInDay(): string {
        return this.closingInDay.nativeElement.textContent;
    }

    public getClosingInDays(): string {
        return this.closingInDays.nativeElement.textContent;
    }

    public getClosingTomorrow(): string {
        return this.closingTomorrow.nativeElement.textContent;
    }

    public getClosed(): string {
        return this.orderClosed.nativeElement.textContent;
    }

    public getClosedIn(): string {
        return this.orderClosedIn.nativeElement.textContent;
    }
}
