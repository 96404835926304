"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("./notfound.component");
var styles_NotfoundComponent = [];
var RenderType_NotfoundComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NotfoundComponent, data: {} });
exports.RenderType_NotfoundComponent = RenderType_NotfoundComponent;
function View_NotfoundComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 11, "div", [["class", "row content-container"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(3, 0, null, null, 8, "div", [["class", "col-12 col-sm-12 main-content mynovi"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n                "])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["404 - Page not found"])), (_l()(), i0.ɵted(-1, null, ["\n                "])), (_l()(), i0.ɵeld(8, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Page not found!"])), (_l()(), i0.ɵted(-1, null, ["\n            "])), (_l()(), i0.ɵted(-1, null, ["\n        "]))], null, null); }
exports.View_NotfoundComponent_0 = View_NotfoundComponent_0;
function View_NotfoundComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "notfound", [], null, null, null, View_NotfoundComponent_0, RenderType_NotfoundComponent)), i0.ɵdid(1, 49152, null, 0, i1.NotfoundComponent, [], null, null)], null, null); }
exports.View_NotfoundComponent_Host_0 = View_NotfoundComponent_Host_0;
var NotfoundComponentNgFactory = i0.ɵccf("notfound", i1.NotfoundComponent, View_NotfoundComponent_Host_0, {}, {}, []);
exports.NotfoundComponentNgFactory = NotfoundComponentNgFactory;
