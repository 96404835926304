"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var active_order_1 = require("../../../../classes/active.order");
var user_service_1 = require("../../../../service/user.service");
var delivery_service_1 = require("../../../../service/delivery.service");
var active_order_service_1 = require("../../../../service/active.order.service");
var PendingOrderRowComponent = (function () {
    function PendingOrderRowComponent(userService, activeOrderService, deliveryService, router) {
        this.userService = userService;
        this.activeOrderService = activeOrderService;
        this.deliveryService = deliveryService;
        this.router = router;
    }
    PendingOrderRowComponent.prototype.ngOnInit = function () {
        if (this.activeOrder.closingTime) {
            this.closingInHours = this.deliveryService.getClosingInHours(this.activeOrder.closingTime);
            this.renderedClosingTime = this.deliveryService.getClosingTimeString(this.activeOrder.closingTime, this.activeOrder.closingHour, this.closingInHours, this.closingInDay, this.closingInDays, this.closingTomorrow, this.closed, this.closedIn);
        }
    };
    PendingOrderRowComponent.prototype.showAddProducts = function () {
        if (this.userService.isUseFastCheckout()) {
            return this.closingInHours > 0;
        }
        return false;
    };
    PendingOrderRowComponent.prototype.addProducts = function (activeOrder) {
        this.activeOrderService.setSelectedOrder(activeOrder);
        this.router.navigate(['/products']);
    };
    return PendingOrderRowComponent;
}());
exports.PendingOrderRowComponent = PendingOrderRowComponent;
