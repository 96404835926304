"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var OrderTranslationsComponent = (function () {
    function OrderTranslationsComponent() {
    }
    OrderTranslationsComponent.prototype.getOrderClosed = function () {
        return this.orderClosedForBarcode.nativeElement.textContent;
    };
    OrderTranslationsComponent.prototype.getBarcodeError = function () {
        return this.barcodeError.nativeElement.textContent;
    };
    OrderTranslationsComponent.prototype.getBarcodeSuccess = function () {
        return this.barcodeSuccess.nativeElement.textContent;
    };
    OrderTranslationsComponent.prototype.getShoppingCartConfirmed = function () {
        return this.shoppingCartConfirmed.nativeElement.textContent;
    };
    OrderTranslationsComponent.prototype.getShoppingCartError = function () {
        return this.shoppingCartError.nativeElement.textContent;
    };
    OrderTranslationsComponent.prototype.getClosingInDay = function () {
        return this.closingInDay.nativeElement.textContent;
    };
    OrderTranslationsComponent.prototype.getClosingInDays = function () {
        return this.closingInDays.nativeElement.textContent;
    };
    OrderTranslationsComponent.prototype.getClosingTomorrow = function () {
        return this.closingTomorrow.nativeElement.textContent;
    };
    OrderTranslationsComponent.prototype.getClosed = function () {
        return this.orderClosed.nativeElement.textContent;
    };
    OrderTranslationsComponent.prototype.getClosedIn = function () {
        return this.orderClosedIn.nativeElement.textContent;
    };
    return OrderTranslationsComponent;
}());
exports.OrderTranslationsComponent = OrderTranslationsComponent;
