<ng-template [ngIf]="hasSelectedOrder()">

	<ng-template [ngIf]="shoppingbarHeaderWrapperAlreadyRendered">

		<shoppingcart-header-component (viewShoppingcartClickEvent)="viewShoppingcart()"
		                               (viewPendingOrdersClickEvent)="viewPendingOrders()"
		                               (viewOrderDetailClickEvent)="viewOrderDetail()">
		</shoppingcart-header-component>

		<order-tabs (clickedAllProductsEvent)="clickedAllProductsEvent()"></order-tabs>
	</ng-template>

	<ng-template [ngIf]="!shoppingbarHeaderWrapperAlreadyRendered">
		<div class="header-shopping-info header-shopping-info--preload-minheight">
			<div class="row">

				<shoppingcart-header-component (viewShoppingcartClickEvent)="viewShoppingcart()"
				                               (viewPendingOrdersClickEvent)="viewPendingOrders()"
				                               (viewOrderDetailClickEvent)="viewOrderDetail()">
				</shoppingcart-header-component>

				<order-tabs (clickedAllProductsEvent)="clickedAllProductsEvent()"></order-tabs>

			</div>
		</div>
	</ng-template>

</ng-template>