<div class="planthunter__products planthunter__products--summary" [ngClass]="{loading: loading}">
	<div class="loading-overlay"></div>
	<div class="overview-grid overview-grid--planthunter" [ngClass]="{'grid-spacing-start': planthunterProductList.length < 4}">
		<div class="loading-overlay"></div>
		<product-item-grid
				class="overview-product"
				*ngFor="let product of getShowAmountOfProducts()"
				[product]="product"
				(viewProductDetailClickEvent)="viewProductDetail($event)"
				[loggedIn]="isUserLoggedin()">
		</product-item-grid>
	</div>
</div>

<div class="planthunter__show-all" *ngIf="!isShowToggleMoreProduct()">
	<button type="button" *ngIf="!showAllProducts"
	        class="button--text underline"
	        (click)="toggleShowMorePlanthunterProducts()"
	        i18n="Planthunter show all hunts@@planthunter.show.hunts">
		Show all {{planthunter.firstName}}'s hunts
	</button>

	<button type="button" *ngIf="showAllProducts"
	        class="button--text underline"
	        (click)="toggleShowMorePlanthunterProducts()"
	        i18n="Planthunter show less hunts@@planthunter.show.less.hunts">
		Show less of {{planthunter.firstName}}'s hunts
	</button>
</div>
