"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("@angular/common");
var i2 = require("./shoppingcart.header.mobile.component");
var i3 = require("../../service/shoppingcart.service");
var i4 = require("../../service/user.service");
var styles_ShoppingcartHeaderMobileComponent = [];
var RenderType_ShoppingcartHeaderMobileComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ShoppingcartHeaderMobileComponent, data: {} });
exports.RenderType_ShoppingcartHeaderMobileComponent = RenderType_ShoppingcartHeaderMobileComponent;
function View_ShoppingcartHeaderMobileComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 9, "a", [["class", "header-shoppingcart-mini cursor--force-hand"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.viewShoppingcart() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(3, 0, null, null, 3, ":svg:svg", [["class", "icon-shoppingcart"], ["height", "22"], ["width", "25"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(5, 0, null, null, 0, ":svg:use", [[":xlink:href", "/images/icons/svg-sprite.svg#shoppingcart"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(8, 0, null, null, 1, "span", [["class", "header-shoppingcart-mini-total"]], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getShoppingCartCount(); _ck(_v, 9, 0, currVal_0); }); }
function View_ShoppingcartHeaderMobileComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 9, "a", [["class", "header-shoppingcart-mini"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.viewOrderDetail() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(3, 0, null, null, 3, ":svg:svg", [["class", "icon-truck"], ["height", "18"], ["width", "47"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(5, 0, null, null, 0, ":svg:use", [[":xlink:href", "/images/icons/svg-sprite.svg#truck"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(8, 0, null, null, 1, "span", [["class", "header-shoppingcart-mini-total"]], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getShoppingCartCount(); _ck(_v, 9, 0, currVal_0); }); }
function View_ShoppingcartHeaderMobileComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ShoppingcartHeaderMobileComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\n"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ShoppingcartHeaderMobileComponent_2)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isFastCheckout(); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isFastCheckout(); _ck(_v, 4, 0, currVal_1); }, null); }
exports.View_ShoppingcartHeaderMobileComponent_0 = View_ShoppingcartHeaderMobileComponent_0;
function View_ShoppingcartHeaderMobileComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "shoppingcart-header-mobile-component", [], null, null, null, View_ShoppingcartHeaderMobileComponent_0, RenderType_ShoppingcartHeaderMobileComponent)), i0.ɵdid(1, 49152, null, 0, i2.ShoppingcartHeaderMobileComponent, [i3.ShoppingCartService, i4.UserService], null, null)], null, null); }
exports.View_ShoppingcartHeaderMobileComponent_Host_0 = View_ShoppingcartHeaderMobileComponent_Host_0;
var ShoppingcartHeaderMobileComponentNgFactory = i0.ɵccf("shoppingcart-header-mobile-component", i2.ShoppingcartHeaderMobileComponent, View_ShoppingcartHeaderMobileComponent_Host_0, {}, { viewOrderDetailClickEvent: "viewOrderDetailClickEvent", viewShoppingcartClickEvent: "viewShoppingcartClickEvent" }, []);
exports.ShoppingcartHeaderMobileComponentNgFactory = ShoppingcartHeaderMobileComponentNgFactory;
