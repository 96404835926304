"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Logger = (function () {
    function Logger() {
    }
    Logger.prototype.trace = function (message, error) {
    };
    Logger.prototype.debug = function (message, error) {
    };
    Logger.prototype.warn = function (message, error) {
    };
    Logger.prototype.error = function (message, error) {
    };
    Logger.prototype.info = function (message, error) {
    };
    return Logger;
}());
exports.Logger = Logger;
