import {Component} from '@angular/core';
import {RedirectToShopService} from "./redirect.to.shop.service";

@Component({
    selector: 'pricelists-wrapper-component',
    templateUrl: './pricelists.wrapper.component.html'
})
export class PricelistsWrapperComponent {

    /**
     * @param {RedirectToShopService} redirectService
     * required to enable the 'redirect to shop' logic for this component.
     */
    constructor(redirectService: RedirectToShopService) {
    }
}