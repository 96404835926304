<ng-container [ngSwitch]="day">
                <ng-template [ngSwitchCase]="'Monday'">
                    <ng-container i18n="Day of week short- Mon@@day.of.week.short.mon">
                        Mon
                    </ng-container>
                </ng-template>
                <ng-template [ngSwitchCase]="'Tuesday'">
	                <ng-container i18n="Day of week short- Tue@@day.of.week.short.tue">
		                Tue
	                </ng-container>
                </ng-template>
                <ng-template [ngSwitchCase]="'Wednesday'">
	                <ng-container i18n="Day of week short- Wed@@day.of.week.short.wed">
		                Wed
	                </ng-container>
                </ng-template>
                <ng-template [ngSwitchCase]="'Thursday'">
	                <ng-container i18n="Day of week short- Thu@@day.of.week.short.thu">
		                Thu
	                </ng-container>
                </ng-template>
                <ng-template [ngSwitchCase]="'Friday'">
	                <ng-container i18n="Day of week short- Fri@@day.of.week.short.fri">
		                Fri
	                </ng-container>
                </ng-template>
                <ng-template [ngSwitchCase]="'Saturday'">
	                <ng-container i18n="Day of week short- Sat@@day.of.week.short.sat">
		                Sat
	                </ng-container>
                </ng-template>
                <ng-template [ngSwitchCase]="'Sunday'">
	                <ng-container i18n="Day of week short- Sun@@day.of.week.short.sun">
		                Sun
	                </ng-container>
                </ng-template>
            </ng-container>