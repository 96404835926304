import { Component, Input } from '@angular/core';

/**
 * Used to work around i18n limitations
 */
@Component({
    selector: '[render-day-of-week]',
    template: `<ng-container [ngSwitch]="day">
                    <ng-template [ngSwitchCase]="'Monday'">
                        <ng-container i18n="Day of week - Monday@@day.of.week.monday">
	                        Monday
                        </ng-container>
                    </ng-template>
                    <ng-template [ngSwitchCase]="'Tuesday'">
	                    <ng-container i18n="Day of week - Tuesday@@day.of.week.tuesday">
		                    Tuesday
	                    </ng-container>
                    </ng-template>
                    <ng-template [ngSwitchCase]="'Wednesday'">
	                    <ng-container i18n="Wednesday@@day.of.week.wednesday">
		                    Wednesday
	                    </ng-container>
                    </ng-template>
                    <ng-template [ngSwitchCase]="'Thursday'">
	                    <ng-container i18n="Thursday@@day.of.week.thursday">
		                    Thursday
	                    </ng-container>
                    </ng-template>
                    <ng-template [ngSwitchCase]="'Friday'">
	                    <ng-container i18n="Day of week - Friday@@day.of.week.friday">
		                    Friday
	                    </ng-container>
                    </ng-template>
                    <ng-template [ngSwitchCase]="'Saturday'">
	                    <ng-container i18n="Day of week - Saturday@@day.of.week.saturday">
		                    Saturday
	                    </ng-container>
                    </ng-template>
                    <ng-template [ngSwitchCase]="'Sunday'">
	                    <ng-container i18n="Day of week - Sunday@@day.of.week.sunday">
		                    Sunday
	                    </ng-container>
                    </ng-template>
            </ng-container>`
})
export class RenderDayOfWeekComponent {
    @Input() day: string;
}
