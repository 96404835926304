import {Component, EventEmitter, HostListener, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Logger} from '../../logging/default-log.service';
import {DeliveryAddress} from '../../classes/deliveryaddress';
import {DeliveryDate} from '../../classes/deliverydate';
import {ActiveOrder} from '../../classes/active.order';
import {DeliveryService} from '../../service/delivery.service';
import {ActiveOrderService} from '../../service/active.order.service';
import {ApplicationService} from '../../service/application.service';

/**
 * Show popup if new order needs to be started. Contains functionality to close when clicking _outside_ the popup.
 *
 * @author evandongen
 */
@Component({
    selector: 'start-order',
    templateUrl: 'start.order.component.html'
})
export class StartOrderComponent implements OnInit {
    loading = true;
    popupOpen = false;
    @Output() closed = new EventEmitter<boolean>();
    @Output() saved = new EventEmitter<boolean>();
    shipmentDates: DeliveryDate[];
    deliveryAddresses: DeliveryAddress[];
    startNew: boolean = true;
    selectedOrderSelect: ActiveOrder;
    // Form bindings existing order
    form: FormGroup;
    // Form bindings new order
    shipmentDate: DeliveryDate;
    deliveryAddress: DeliveryAddress;

    constructor(private deliveryService: DeliveryService,
                private formBuilder: FormBuilder,
                public applicationService: ApplicationService,
                private activeOrderService: ActiveOrderService,
                private logger: Logger) {

        // subscribers
        const fetchDeliveryDatesPromise = this.fetchDeliveryDates();

        const getDeliveryAddressesPromise = this.deliveryService.getDeliveryAddresses().toPromise();

        getDeliveryAddressesPromise.then(deliveryAddresses => {
            this.deliveryAddresses = deliveryAddresses;
            this.deliveryAddress = this.deliveryAddresses[0];
        });

        getDeliveryAddressesPromise.catch(error => {
            this.logger.error('Error while getting delivery addresses', error);
        });

        // Wait for both XHR calls to complete, before opening the popup,
        // to prevent issues with popup content which needs the data of the XHR calls.
        Promise.all([fetchDeliveryDatesPromise, getDeliveryAddressesPromise])
            .then(() => {
                this.loading = false;
                this.popupOpen = true;
                this.preSelectFirstActiveOrder();
            }).catch(() => {
            // If something fails: close the modal, so the component will be destroyed.
            // If not destroyed we end up with a component which cannot be 'activated' again.
            this.close();
        });
    }

    get activeOrders(): Array<ActiveOrder> {
        return this.activeOrderService.activeOrders;
    }

    getActiveOrderForDeliveryDate(deliveryDate: DeliveryDate) {
        return this.activeOrders.find(activeOrder => {
            return activeOrder.shipmentDate === deliveryDate.shipmentDate;
        });
    }

    getDeliveryDateForOrders(): Array<DeliveryDate> {
        return this.activeOrders
            .map(order => {
                return this.getDeliveryDateForOrder(order);
            })
            .filter(deliveryDate => deliveryDate != null && typeof deliveryDate !== 'undefined');
    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            'selectedOrder': ['', Validators.required],
            'shipmentDate': ['', Validators.required],
            'deliveryAddress': ['', Validators.required]
        });
    }

    changeDate() {
        this.shipmentDate = this.form.get('shipmentDate').value;
    }

    @HostListener('document:click', ['$event'])
    handleClick(event) {
        const clickedComponent = event.target;

        if (clickedComponent.className === 'modal-background') {
            this.close();
        }
    }

    getDeliveryDateForOrder(order: ActiveOrder): DeliveryDate {
        return this.shipmentDates.find(date => order && date && date.shipmentDate === order.shipmentDate);
    }

    /**
     * Close the popup and notify listeners
     */
    close(): void {
        // Make sure modal--hide is set, and close the popup application wide after 0.4s
        this.popupOpen = false;
        setTimeout(() => {
            this.closed.emit(false);
            this.applicationService.closePopup();
        }, 400);

    }

    /**
     * Process form submit and notify services
     * @param value
     */
    onSubmit(value: any) {
        if (this.loading) {
            return;
        }

        if (this.startNew) {

            // shipmendate is preselected and never null
            if (this.deliveryAddress) {

                this.loading = true;

                // subscribers
                this.deliveryService.getDeliveryDate(this.getOptionalProductForStartOrderScope(), this.shipmentDate.shipmentDate).subscribe(
                    shipmentDate => {
                        if (shipmentDate == null) {
                            this.logger.warn('Error while getting delivery date for ' + this.shipmentDate.shipmentDate +
                                '. Closing time may have passed. If this occurs frequently something else may be wrong. ' +
								'This error means an order can\'t be started for the selected delivery date!!!');
                            this.fetchDeliveryDates();
                            return;
                        }
                        this.shipmentDate = shipmentDate;

                        // Set application wide delivery address and shipment date
                        let newSelectedOrder = new ActiveOrder();

                        newSelectedOrder.shipmentDate = this.shipmentDate.shipmentDate;
                        newSelectedOrder.deliveryAddressName = this.deliveryAddress.name;
                        newSelectedOrder.shipToCode = this.deliveryAddress.code;
                        newSelectedOrder.volume = 0;

                        const existingOrder = this.activeOrderService.getActiveOrderForShipmentDateAndShipToCode(
                            newSelectedOrder.shipmentDate,
                            newSelectedOrder.shipToCode
                        );

                        if (existingOrder) {
                            newSelectedOrder = existingOrder;
                        } else {
                            this.activeOrderService.activeOrders.push(newSelectedOrder);
                        }

                        this.activeOrderService.setSelectedOrder(newSelectedOrder);

                        this.saved.emit();
                    },
                    error => {
                        this.loading = false;
                        this.logger.error('Error while getting delivery date for ' + this.shipmentDate.shipmentDate, error);
                    });
            }

            return;
        }

        if (this.selectedOrderSelect) {
            this.loading = true;

            // Add to existing order
            this.activeOrderService.setSelectedOrder(this.selectedOrderSelect);
            this.saved.emit();
        }
    }

    /**
     * Makes sure the first delivery date / active order is selected
     * when the user chooses for 'add to existing order'.
     */
    private preSelectFirstActiveOrder() {
        const deliveryDateForOrders = this.getDeliveryDateForOrders();

        if (deliveryDateForOrders && deliveryDateForOrders.length > 0) {
            this.selectedOrderSelect = this.getActiveOrderForDeliveryDate(deliveryDateForOrders[0]);
            this.form.get('selectedOrder').setValue(this.selectedOrderSelect);
        }
    }

    /**
     * Retrieve the delivery dates from the server and update the available delivery-/shipmentdates and the current
     * shipmentdate. The list of activeOrderDeliveryDates are used for selecting an already started active order.
     *
     * Note: the name shipmentDate and deliveryDate is used. Both are of type DeleveryDate. This type contains shipmentdate,
     * deliveryDate, closingTime, etc.
     */
    private fetchDeliveryDates() {
        const promise = this.deliveryService.getDeliveryDates(
            this.getOptionalProductForStartOrderScope()
        ).toPromise();

        promise.then(shipmentDates => {
            this.shipmentDates = shipmentDates;
            // Select the first entry
            if (shipmentDates.length > 0) {
                this.shipmentDate = shipmentDates[0];
                this.form.get('shipmentDate').setValue(this.shipmentDate);
            }
        });

        promise.catch(error => {
            this.logger.error('Error while getting delivery dates', error);
        });

        return promise;
    }

    private getOptionalProductForStartOrderScope() {
        return this.applicationService.startOrderPopupFocussedProduct;
    }
}
