import {Component, Input} from '@angular/core';

/**
 * Renders the default image if there is none for the product
 */
@Component({
	selector: '[render-product-image]',
	template: `<img *ngIf="!image" src="/images/fallback-product.png">
    	       <img *ngIf="image" src="https://img.noviflora.nl/Images/{{image}}{{getImageFormat()}}.jpg" alt="{{description}}">`
})
export class RenderProductImageComponent {
	@Input() image: string;
	@Input() description: string;
	@Input() imageFormat;

	getImageFormat() {
		if (this.imageFormat) {
			return '_' + this.imageFormat;
		}
		return null;
	}
}
