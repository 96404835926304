"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var LogRocket = require('logrocket');
var core_1 = require("@angular/core");
var ordertabs_component_1 = require("./component/order/ordertabs.component");
var active_order_service_1 = require("./service/active.order.service");
var platform_browser_1 = require("@angular/platform-browser");
var http_1 = require("@angular/common/http");
var ng_select_1 = require("@ng-select/ng-select");
var ng_lazy_load_1 = require("ng-lazy-load");
var forms_1 = require("@angular/forms");
var animations_1 = require("@angular/platform-browser/animations");
var render_day_of_week_1 = require("./component/order/render.day.of.week");
var render_day_of_week_abbr_1 = require("./component/order/render.day.of.week.abbr");
var custom_date_pipe_1 = require("./pipes/custom.date.pipe");
var date_service_1 = require("./service/date.service");
var default_log_service_1 = require("./logging/default-log.service");
var log_service_1 = require("./logging/log.service");
var exception_handler_1 = require("./logging/exception.handler");
var http_service_1 = require("./service/http.service");
var delivery_service_1 = require("./service/delivery.service");
var user_service_1 = require("./service/user.service");
var application_service_1 = require("./service/application.service");
var shoppingcart_service_1 = require("./service/shoppingcart.service");
var window_ref_service_1 = require("./service/window-ref.service");
var start_order_component_1 = require("./component/order/start.order.component");
var dynamic_modal_directive_1 = require("./component/modal/dynamic.modal.directive");
var add_comment_component_1 = require("./component/my/order/pending/add.comment.component");
var render_part_of_day_1 = require("./component/order/render.part.of.day");
var pricelists_component_1 = require("./component/my/pricelists/pricelists.component");
var cart_line_remove_component_1 = require("./component/cart/cart.line.remove.component");
var number_format_pipe_1 = require("./pipes/number.format.pipe");
var common_1 = require("@angular/common");
var login_component_1 = require("./component/login/login.component");
var forgot_password_component_1 = require("./component/login/forgot.password.component");
var reset_password_component_1 = require("./component/login/reset.password.component");
var reset_password_translations_component_1 = require("./component/login/reset.password.translations.component");
var cookies_disabled_translations_component_1 = require("./cookies.disabled.translations.component");
var shoppingcart_header_component_1 = require("./component/cart/shoppingcart.header.component");
var render_checkout_icon_1 = require("./component/cart/render.checkout.icon");
var shoppingcart_header_mobile_component_1 = require("./component/cart/shoppingcart.header.mobile.component");
var productitem_grid_component_1 = require("./component/products/productitem.grid.component");
var planthunter_overview_grid_component_1 = require("./component/planthunter/planthunter.overview.grid.component");
var render_product_image_1 = require("./component/products/render.product.image");
var product_size_1 = require("./component/products/product.size");
var filter_service_1 = require("./service/filter.service");
var product_service_1 = require("./service/product.service");
var pricelist_service_1 = require("./service/pricelist.service");
var pending_orders_component_1 = require("./component/my/order/pending/pending.orders.component");
var pending_order_row_component_1 = require("./component/my/order/pending/pending.order.row.component");
var pending_order_service_1 = require("./service/pending.order.service");
var order_translations_component_1 = require("./component/my/order.translations.component");
var salesperson_telephone_component_1 = require("./component/my/salesperson.telephone.component");
var planthunter_service_1 = require("./service/planthunter.service");
var planthunter_amount_of_new_hunts_component_1 = require("./component/planthunter/planthunter.amount.of.new.hunts.component");
var analytics_service_1 = require("./service/analytics.service");
var notfound_component_1 = require("./component/notfound/notfound.component");
var da_1 = require("@angular/common/locales/da");
var de_1 = require("@angular/common/locales/de");
var en_1 = require("@angular/common/locales/en");
var nl_1 = require("@angular/common/locales/nl");
var pl_1 = require("@angular/common/locales/pl");
var sv_1 = require("@angular/common/locales/sv");
var fi_1 = require("@angular/common/locales/fi");
var sk_1 = require("@angular/common/locales/sk");
var nb_1 = require("@angular/common/locales/nb");
var fr_1 = require("@angular/common/locales/fr");
var not_authorized_interceptor_service_1 = require("./service/not-authorized-interceptor.service");
common_1.registerLocaleData(da_1.default);
common_1.registerLocaleData(de_1.default);
common_1.registerLocaleData(en_1.default);
common_1.registerLocaleData(nl_1.default);
common_1.registerLocaleData(pl_1.default);
common_1.registerLocaleData(sv_1.default);
common_1.registerLocaleData(fi_1.default);
common_1.registerLocaleData(sk_1.default);
common_1.registerLocaleData(nb_1.default);
common_1.registerLocaleData(fr_1.default);
function initWindowObject() {
    window.LogRocket = LogRocket;
    window.storage = (function () {
        try {
            var uid = new Date;
            var storage = void 0;
            var result = void 0;
            (storage = window.localStorage).setItem(uid, uid);
            result = storage.getItem(uid) == uid;
            storage.removeItem(uid);
            return result && storage;
        }
        catch (exception) {
        }
    }());
}
exports.initWindowObject = initWindowObject;
exports.genericImports = [
    platform_browser_1.BrowserModule,
    http_1.HttpClientModule,
    ng_select_1.NgSelectModule,
    forms_1.FormsModule,
    forms_1.ReactiveFormsModule,
    animations_1.BrowserAnimationsModule,
    ng_lazy_load_1.LazyLoadModule,
    forms_1.ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' })
];
exports.genericDeclarations = [
    custom_date_pipe_1.CustomDatePipe,
    number_format_pipe_1.NumberFormatPipe,
    render_day_of_week_1.RenderDayOfWeekComponent,
    render_day_of_week_abbr_1.RenderDayOfWeekAbbreviationComponent,
    ordertabs_component_1.OrderTabsComponent,
    start_order_component_1.StartOrderComponent,
    add_comment_component_1.AddCommentComponent,
    dynamic_modal_directive_1.DynamicModalDirective,
    render_part_of_day_1.RenderPartOfDayComponent,
    shoppingcart_header_component_1.ShoppingcartHeaderComponent,
    shoppingcart_header_mobile_component_1.ShoppingcartHeaderMobileComponent,
    render_checkout_icon_1.RenderCheckoutIconComponent,
    cart_line_remove_component_1.CartLineRemoveComponent,
    login_component_1.LoginComponent,
    forgot_password_component_1.ForgotPasswordComponent,
    reset_password_component_1.ResetPasswordComponent,
    reset_password_translations_component_1.ResetPasswordTranslationsComponent,
    cookies_disabled_translations_component_1.CookiesDisabledTranslationsComponent,
    productitem_grid_component_1.ProductItemGridComponent,
    planthunter_overview_grid_component_1.PlanthunterOverviewGridComponent,
    render_product_image_1.RenderProductImageComponent,
    product_size_1.ProductSizeComponent,
    pricelists_component_1.PricelistsComponent,
    pending_orders_component_1.PendingOrdersComponent,
    pending_order_row_component_1.PendingOrderRowComponent,
    order_translations_component_1.OrderTranslationsComponent,
    salesperson_telephone_component_1.SalespersonTelephoneComponent,
    planthunter_amount_of_new_hunts_component_1.PlanthunterAmountOfNewHuntsComponent,
    notfound_component_1.NotfoundComponent
];
exports.genericProviders = [
    common_1.DatePipe,
    date_service_1.DateService,
    http_service_1.HttpClient,
    active_order_service_1.ActiveOrderService,
    delivery_service_1.DeliveryService,
    user_service_1.UserService,
    application_service_1.ApplicationService,
    shoppingcart_service_1.ShoppingCartService,
    filter_service_1.FilterService,
    product_service_1.ProductService,
    pricelist_service_1.PricelistService,
    pending_order_service_1.PendingOrderService,
    planthunter_service_1.PlanthunterService,
    analytics_service_1.AnalyticsService,
    window_ref_service_1.WindowRef,
    { provide: http_1.HTTP_INTERCEPTORS, useClass: not_authorized_interceptor_service_1.NotAuthorizedInterceptor, multi: true },
    { provide: default_log_service_1.Logger, useClass: log_service_1.LogService },
    { provide: core_1.ErrorHandler, useClass: exception_handler_1.SystemMonitorErrorHandler }
];
