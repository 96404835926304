/**
 * Maintains the state of the filters, delivery date and product features which influence
 * the products displayed.
 *
 * @see FilterService
 */
export class FilterState {
	constructor(public features: Array<string>,
	            public shipmentDate?: Date,
	            public searchText?: string,
	            public sorting?: string,
	            public type?: number,
	            public pageSize: number = 102,
	            public potSizeFrom?: number,
	            public potSizeTo?: number,
	            public priceFrom?: number,
	            public priceTo?: number,
	            public heightFrom?: number,
	            public heightTo?: number,
	            public clearFeatureFilter?: number,
	            public languageCode?: string,
	            public orderHistoryDateFrom?: Date,
	            public orderHistoryDateTo?: Date,
	            public page?: number) {
	}

	static toTime(date: Date): number {
		return date.getTime();
	}

	updateShipmentDates(shipmentDate: Date) {
		this.shipmentDate = shipmentDate;
	}

	updateLanguage(languageCode: string) {
		this.languageCode = languageCode;
	}

	/**
	 * Shorthand to get a complete set of url parameters to send to the backend
	 *
	 * @returns {string}
	 */
	getParams() {
		const params: Array<string> = [];

		if (this.page) {
			params.push(`page=${this.page}`);
		}

		if (this.features.length > 0) {
			params.push(`filters=${this.features.join(',')}`);
		}

		if (this.shipmentDate) {
			params.push(`shipmentDate=${this.shipmentDate}`);
		}

		if (this.searchText) {
			params.push(`searchText=${encodeURIComponent(this.searchText)}`);
		}

		if (this.potSizeFrom) {
			params.push(`potSizeFrom=${this.potSizeFrom}`);
		}

		if (this.potSizeTo) {
			params.push(`potSizeTo=${this.potSizeTo}`);
		}

		if (this.priceFrom) {
			params.push(`priceFrom=${this.priceFrom}`);
		}

		if (this.priceTo) {
			params.push(`priceTo=${this.priceTo}`);
		}

		if (this.heightFrom) {
			params.push(`potHeightFrom=${this.heightFrom}`);
		}

		if (this.heightTo) {
			params.push(`potHeightTo=${this.heightTo}`);
		}

		if (this.clearFeatureFilter) {
			params.push(`clearFeatureFilter=${this.clearFeatureFilter}`);
		}

		if (this.languageCode) {
			params.push(`languageCode=${this.languageCode}`);
		}

		if (this.orderHistoryDateFrom) {
			params.push(`orderHistoryDateFrom=${FilterState.toTime(this.orderHistoryDateFrom)}`);
		}

		if (this.orderHistoryDateTo) {
			params.push(`orderHistoryDateTo=${FilterState.toTime(this.orderHistoryDateTo)}`);
		}

		if (this.pageSize) {
			params.push(`size=${this.pageSize}`);
		}

		if (this.sorting) {
			const sortKey = this.getProductsActiveSortFieldKey();
			params.push(`sorting=${sortKey}`);

			const sortDirection = this.getProductsActiveSortOrder();

			if (sortKey && sortDirection) {
				params.push(`sortingDirection=${sortDirection}`);
			}
		}

		if (this.type) {
			params.push(`type=${this.type}`);
		}

		return params.join('&');
	}

	/**
	 * @returns string the SortField key for the active products sort.
	 */
	getProductsActiveSortFieldKey(): string {
		if (!this.sorting) {
			return null;
		}
		const sortFieldParts: string[] = this.sorting.split('|');
		return sortFieldParts[0];
	}

	/**
	 * @returns string the SortField order for the active products sort.
	 */
	getProductsActiveSortOrder(): string {
		if (!this.sorting) {
			return null;
		}

		const sortFieldParts: string[] = this.sorting.split('|');
		return sortFieldParts[1];
	}

	/**
	 * @returns string the active SortField key and order normalized so we can use it as query param in the rest url.
	 */
	getProductsActiveSortFieldAndOrderAsParam(): string {
		const key = this.getProductsActiveSortFieldKey();
		const value = this.getProductsActiveSortOrder();

		if (!key) {
			return null;
		}

		let queryParam = key;

		if (value) {
			queryParam = `${key} ${value}`;
		}

		return encodeURIComponent(queryParam);
	}
}
