import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';
import {DateService} from '../service/date.service';

/*
 * Simple pipe to make sure formatted dates are treated as UTC
 *
 * @author ervandongen
 */
@Pipe({name: 'customDate'})
export class CustomDatePipe implements PipeTransform {

	private datePipe: DatePipe;

	constructor(private dateService: DateService, @Inject(LOCALE_ID) private locale: string) {
		this.datePipe = new DatePipe(locale);
	}

	transform(value: Date, format: string): string {
		if (value !== undefined) {
			return this.datePipe.transform(this.dateService.treatAsUTC(value), format);
		}

		return '';
	}
}
