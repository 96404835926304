<div *ngIf="isEditable" class="delete-button-container" [ngClass]="{disabled: buttonDisabled}">
	<button (click)="remove()" [title]="isFastCheckoutMode ? expiryTimeHumanReadable : ''" [disabled]="buttonDisabled">
		<svg class="icon-cross-small" width="11" height="11" viewBox="0 0 10 10">
			<use xlink:href="/images/icons/svg-sprite.svg#cross-small"></use>
		</svg>
	</button>

	<div *ngIf="isFastCheckoutMode" [title]="expiryTimeHumanReadable" class="delete-button-icon">
		<svg class="delete-timer" width="44" height="44" viewport="0 0 22 22" version="1.1"
		     xmlns="http://www.w3.org/2000/svg">
			<circle r="16" cx="22" cy="22" fill="transparent" stroke-dasharray="100" stroke-dashoffset="0"></circle>
			<circle class="delete-timer-bar" r="16" cx="22" cy="22"
			        fill="transparent" stroke-dasharray="100" stroke-dashoffset="0"
			        [style.stroke-dashoffset]="100 + expiryPercentageLeft + 'px'"></circle>
		</svg>
	</div>

</div>
