<div class="overview-product_size">
            <span>
                <svg class="icon-potsize" width="16" height="11" viewBox="0 0 16 11">
                    <use xlink:href="/images/icons/svg-sprite.svg#potsize"></use>
                </svg>
                {{potSize ? potSize : 0}}
            </span>
            <span>
                <svg class="icon-potheight" width="16" height="11" viewBox="0 0 6 11">
                    <use xlink:href="/images/icons/svg-sprite.svg#potheight"></use>
                </svg>
                {{height ? height : 0}}
             </span>
        </div>