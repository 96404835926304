"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../generic/component/my/order/pending/pending.orders.component.ngfactory");
var i2 = require("../generic/component/my/order/pending/pending.orders.component");
var i3 = require("../generic/service/pending.order.service");
var i4 = require("../generic/service/application.service");
var i5 = require("@angular/router");
var i6 = require("../generic/logging/default-log.service");
var i7 = require("./pending.orders.wrapper.component");
var i8 = require("./redirect.to.shop.service");
var styles_PendingOrdersWrapperComponent = [];
var RenderType_PendingOrdersWrapperComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PendingOrdersWrapperComponent, data: {} });
exports.RenderType_PendingOrdersWrapperComponent = RenderType_PendingOrdersWrapperComponent;
function View_PendingOrdersWrapperComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "pending-orders", [], null, [[null, "startNewOrderClickEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("startNewOrderClickEvent" === en)) {
        var pd_0 = (_co.navigateToWebshopAndStartNewOrder() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_PendingOrdersComponent_0, i1.RenderType_PendingOrdersComponent)), i0.ɵdid(1, 49152, null, 0, i2.PendingOrdersComponent, [i3.PendingOrderService, i4.ApplicationService, i5.Router, i6.Logger], { maxAmount: [0, "maxAmount"] }, { startNewOrderClickEvent: "startNewOrderClickEvent" })], function (_ck, _v) { var currVal_0 = 3; _ck(_v, 1, 0, currVal_0); }, null); }
exports.View_PendingOrdersWrapperComponent_0 = View_PendingOrdersWrapperComponent_0;
function View_PendingOrdersWrapperComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "pending-orders-wrapper-component", [], null, null, null, View_PendingOrdersWrapperComponent_0, RenderType_PendingOrdersWrapperComponent)), i0.ɵdid(1, 49152, null, 0, i7.PendingOrdersWrapperComponent, [i8.RedirectToShopService], null, null)], null, null); }
exports.View_PendingOrdersWrapperComponent_Host_0 = View_PendingOrdersWrapperComponent_Host_0;
var PendingOrdersWrapperComponentNgFactory = i0.ɵccf("pending-orders-wrapper-component", i7.PendingOrdersWrapperComponent, View_PendingOrdersWrapperComponent_Host_0, {}, {}, []);
exports.PendingOrdersWrapperComponentNgFactory = PendingOrdersWrapperComponentNgFactory;
