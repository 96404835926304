"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ProductItemCommonComponent = (function () {
    function ProductItemCommonComponent() {
        this.show = false;
    }
    ProductItemCommonComponent.prototype.ngOnInit = function () {
        this.largeImage = this.getProductService().getImage(this.getProduct().image);
        this.anchorValue = this.getProductService().getAnchorValue(this.getProduct().type, this.getProduct().priceListNo, this.getProduct().priceListLineNo);
    };
    ProductItemCommonComponent.prototype.ngAfterViewInit = function () {
        if (this.anchorValue === this.getScrollToId()) {
            this.triggerProductMatchedFragmentEvent(this.anchorValue);
        }
    };
    return ProductItemCommonComponent;
}());
exports.ProductItemCommonComponent = ProductItemCommonComponent;
