import {Component} from '@angular/core';
import {ApplicationService} from '../generic/service/application.service';

@Component({
	selector: 'start-order-wrapper',
	templateUrl: './start.order.wrapper.component.html'
})
export class StartOrderWrapperComponent {
	constructor(public applicationService: ApplicationService) {
	}
}