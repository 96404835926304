import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CartLineRemoveTranslations} from '../../classes/cart.line.remove.translations';

@Component({
    selector: 'cart-line-remove',
    templateUrl: 'cart.line.remove.component.html'
})
export class CartLineRemoveComponent implements OnInit {
    expiryTimeHumanReadable: String;
    expiryPercentageLeft: Number;

    @Input() label: string;
    @Input() seconds: string;
    @Input() minutes: string;
    @Input() hours: string;
    @Input() days: string;
    @Input() isEditable: boolean = false;
    @Input() buttonDisabled: boolean = false;
    @Input() isFastCheckoutMode: boolean;
    @Input() expiryDate: Date;
    @Input() maxUserCartWaitTimeInSeconds: number;
    @Output() removeEvent = new EventEmitter();

    constructor() {

    }

    private static getExpiryTimeHumanReadable(translations: CartLineRemoveTranslations, expiryDate: Date): string {
        const timeLeftInSeconds = CartLineRemoveComponent.getExpiryTimeLeftInSeconds(expiryDate);
        const timeLeftInMinutes = timeLeftInSeconds / 60;
        const timeLeftInHours = timeLeftInMinutes / 60;
        const timeLeftInDays = timeLeftInHours / 24;

        if (Math.floor(timeLeftInDays) > 0) {
            return this.getExpiryTimeHumanReadableWithLabel(translations.label, Math.floor(timeLeftInDays) + ' ' + translations.days);
        }

        if (Math.floor(timeLeftInHours) > 0) {
            return this.getExpiryTimeHumanReadableWithLabel(translations.label, Math.floor(timeLeftInHours) + ' ' + translations.days);
        }

        if (Math.floor(timeLeftInMinutes) > 0) {
            return this.getExpiryTimeHumanReadableWithLabel(translations.label, Math.floor(timeLeftInMinutes) + ' ' + translations.minutes);
        }

        return this.getExpiryTimeHumanReadableWithLabel(translations.label, Math.floor(timeLeftInSeconds) + ' ' + translations.seconds);
    }

    private static getExpiryTimeHumanReadableWithLabel(label: string, timeLeft: string): string {
        return label + ' ' + timeLeft;
    }

    private static getExpiryTimeLeftInSeconds(expiryDate: Date) {
        return (expiryDate.getTime() - new Date().getTime()) / 1000;
    }

    private static getExpiryPercentageLeft(expiryDate: Date, maxUserCartWaitTimeInSeconds: number) {
        return CartLineRemoveComponent.getExpiryTimeLeftInSeconds(expiryDate) / maxUserCartWaitTimeInSeconds * 100;
    }

    ngOnInit(): void {
        // If we are not in fast checkout mode,
        // we don't have to show or update the 'time left' countdown.
        if (!this.isFastCheckoutMode) {
            return;
        }

        this.updateShoppingCartExpiryAttributes(this.expiryDate, this.maxUserCartWaitTimeInSeconds);

        const interval = setInterval(() => {

            // Disable the interval if the line is disabled
            if (!this.isEditable) {
                clearInterval(interval);
            }

            this.updateShoppingCartExpiryAttributes(this.expiryDate, this.maxUserCartWaitTimeInSeconds);

        }, 1000);
    }

    public remove() {
        // Emits the event to the parent component, so that the parent,
        // can handles the specific remove action.
        this.removeEvent.emit();
    }

    private updateShoppingCartExpiryAttributes(expiryDate: Date, maxUserCartWaitTimeInSeconds: number) {
        const translations = new CartLineRemoveTranslations(
            this.label, this.seconds, this.minutes, this.hours, this.days
        );

        this.expiryTimeHumanReadable = CartLineRemoveComponent.getExpiryTimeHumanReadable(translations, expiryDate);
        this.expiryPercentageLeft = CartLineRemoveComponent.getExpiryPercentageLeft(expiryDate, maxUserCartWaitTimeInSeconds);
    }
}
