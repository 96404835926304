<order-translations></order-translations>
<div class="row content-container">

	<div class="col-12 col-sm-12 main-content mynovi mynovi--pending">
		<div class="button-bar button-bar--justify hide-lg hide-md visible-sm button-bar--pending-orders-backtoproducts">
			<a [routerLink]="['/products']">
				<strong>
					<svg class="icon-arrow-left" width="6" height="10" viewBox="0 0 6 10">
						<use xlink:href="/images/icons/svg-sprite.svg#arrow-left"></use>
					</svg>
					<ng-container i18n="Back to products link text@@back.to.products">
						Back to products
					</ng-container>
				</strong>
			</a>
		</div>

		<div class="button-bar button-bar--topright  button-bar--mobile-block">
			<a class="button button--green theme-btn-success" (click)="startNewOrder()"
			   i18n="Start a new order link text@@start.a.new.order">Start a new order</a>
		</div>

		<h1 class="mynovi-title">
			<span *ngIf="!pendingOrders" style="opacity: 0.5">&#95;</span>
			<span *ngIf="pendingOrders">{{pendingOrders.length}}</span>
			<span i18n="Number of pending orders header@@number.of.pending.orders.header">pending orders</span>
		</h1>
		<p sales-person-telephone class="mynovi-intro"></p>

		<table class="mynovi-data-table mynovi-data-table--pending">
			<thead>
				<tr>
					<th scope="col" i18n="Pending order header: shipping date@@pending.order.overview.shipping.date">
						Shipping date &amp; location
					</th>
					<th scope="col" i18n="Pending order header: volume@@order.volume">
						Volume
					</th>
					<th scope="col" i18n="Pending order header: closes@@order.closes">
						Closes
					</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				<tr class="mynovi-data-table__loading">
					<td colspan="4" i18n="Loading text@@loading.text">Loading...</td>
				</tr>
				<tr *ngIf="noPendingOrders" class="mynovi-data-table__empty">
					<td colspan="4" i18n="No pending orders@@no.pending.orders">No pending orders</td>
				</tr>
				<tr pending-order-row [activeOrder]="order"
				    [closingInDay]="orderTranslationsComponent.getClosingInDay()"
				    [closingInDays]="orderTranslationsComponent.getClosingInDays()"
				    [closingTomorrow]="orderTranslationsComponent.getClosingTomorrow()"
				    [closed]="orderTranslationsComponent.getClosed()"
				    [closedIn]="orderTranslationsComponent.getClosedIn()"
				    *ngFor="let order of pendingOrders | slice:0:maxAmount;"></tr>
			</tbody>
		</table>

		<div *ngIf="maxAmount && pendingOrders && pendingOrders.length > maxAmount"
		     class="mynovi-data-table-link-to-mynovi">

			<button type="button" [routerLink]="['/my/pending/orders']"
			        class="pending-orders--left button--text">
				<ng-container i18n="Show all pending orders@@show.all.pending.orders">
					Show all pending orders
				</ng-container>
				<svg class="icon-arrow-right" width="7" height="12">
					<use xlink:href="/images/icons/svg-sprite.svg#arrow-right"></use>
				</svg>
			</button>

		</div>

		<div class="button-bar button-bar--right  button-bar--mobile-block button-bar--pending-orders-bottom">
			<a class="button button--green theme-btn-success" (click)="startNewOrder()"
			   i18n="Start a new order link text@@start.a.new.order">Start a new order</a>
		</div>
	</div>
</div>
