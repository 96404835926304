"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CartLineRemoveTranslations = (function () {
    function CartLineRemoveTranslations(label, seconds, minutes, hours, days) {
        this.label = label;
        this.seconds = seconds;
        this.minutes = minutes;
        this.hours = hours;
        this.days = days;
    }
    return CartLineRemoveTranslations;
}());
exports.CartLineRemoveTranslations = CartLineRemoveTranslations;
