import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from '../generic/service/user.service';
import {PlanthunterService} from '../generic/service/planthunter.service';
import {Planthunter} from '../generic/classes/planthunter';
import {ShopHelper} from './shop.helper';
import {ApplicationService} from '../generic/service/application.service';

@Component({
	selector: 'planthunter-detail-component',
	templateUrl: './planthunter.detail.component.html'
})
export class PlanthunterDetailComponent implements OnInit {

	loading: boolean = true;
	planthunterOverviewURL: string;
	planthunter: Planthunter;
	private applicationService: ApplicationService;
	private userService: UserService;
	private windowObj;
	show404: boolean = false;

	constructor(private planthunterService: PlanthunterService,
	            protected router: Router,
	            applicationService: ApplicationService,
	            userService: UserService) {

		this.applicationService = applicationService;
		this.userService = userService;

		this.windowObj = (<any>window);
		this.planthunterOverviewURL = this.windowObj.planthunterOverviewURL;
	}

	ngOnInit(): void {
		this.getPlanthunterIfUserIsLoggedIn();
	}

	private getPlanthunterIfUserIsLoggedIn() {
		if (this.isUserLoggedin()) {
			this.planthunterService.getSinglePlanthunter(this.windowObj.plantHunterId).then(
				planthunter => {
					this.planthunter = planthunter;
					this.loading = false;
				}
			).catch(error => {
				// Handle possible 404 and reset loading state
				this.loading = false;
				this.show404 = true;
			});

			return;
		}

		const planthunterDetailUrl = this.windowObj.location.href;
		this.windowObj.location.href = this.applicationService.getCmsLoginUrl(planthunterDetailUrl);
	}

	isUserLoggedin(): boolean {
		return this.userService.isLoggedIn();
	}

	viewProductDetail(detailUrl) {
		ShopHelper.goToWebshopUrl(detailUrl + ';origin=planthunter');
	}

	followPlanthunter(): void {
		this.planthunterService.followPlanthunter(this.planthunter.code).then(
			comment => this.planthunter.volgStatus = true
		);
	}

	unfollowPlanthunter(): void {
		this.planthunterService.unfollowPlanthunter(this.planthunter.code).then(
			comment => this.planthunter.volgStatus = false
		);
	}
}
