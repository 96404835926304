import {Injectable} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {ShopHelper} from './shop.helper';

/**
 * Redirects the visitor to the webshop when a router
 * change is requested (router changes are only triggered in the generic shop logic).
 *
 * To catch route changes in your component and redirect appropriately,
 * make sure to add this service to the components constructor.
 *
 * @see also RedirectToShopGuard.
 * @author Nick Groenewegen
 */
@Injectable()
export class RedirectToShopService {

    constructor(private router: Router) {

        this.router.events.subscribe((evt) => {

            if (evt instanceof NavigationStart) {
                ShopHelper.goToWebshopUrl(evt.url);
            }

        });
    }

}