<div class="row content-container">
	<div class="col-12 col-sm-12 main-content mynovi mynovi--order-detail">
		<h1 class="mynovi-title" *ngIf="getUser()" i18n="Pricelists header@@pricelists.header">
			Pricelists selected by {{getUser().amName}}
		</h1>
		<p sales-person-telephone class="mynovi-intro"></p>

		<table class="mynovi-data-table mynovi-data-table--pricelists">
			<thead>
			<tr>
				<th scope="col" i18n="table header 'name'@@table.header.name">Name</th>
				<th scope="col" i18n="table header 'description'@@table.header.description">Description</th>
				<th scope="col" i18n="table header 'available for'@@table.header.available.for">Available for</th>
				<th></th>
			</tr>
			</thead>
			<tbody>
			<tr class="mynovi-data-table__loading">
				<td colspan="4" i18n="@@loading.text">Loading &hellip;</td>
			</tr>
			<tr *ngIf="noPricelistsAvailable" class="mynovi-data-table__empty">
				<td colspan="4" i18n="@@no.pricelists.available">No pricelists available</td>
			</tr>
			<tr *ngFor="let priceList of pricelists">
				<th scope="row" class="mynovi-data-table_name-and-availability">
					<h4>{{priceList.priceListNo}}</h4>
					<p class="hide-md hide-lg visible-sm" i18n="Available for number of days@@pricelist.time.available">
						Available for {{getAvailableDays(priceList)}} days
					</p>
				</th>
				<td class="mynovi-data-table_description">
					{{priceList.description}}
				</td>
				<td class="mynovi-data-table_available-for" i18n="Available for days@@pricelist.time.available2">
					{{getAvailableDays(priceList)}} days
				</td>
				<td class="mynovi-data-table_buttons">
					<div class="button-bar">
						<a [routerLink]="['/products']"
                           [queryParams]="{mode: 'all', features: priceList.featureFilterId, clear: true}"
						   class="button button--secondary theme-btn-primary-outline"
						   i18n="view products link@@pricelists.view.products">
							View products
						</a>
					</div>
				</td>
			</tr>
			</tbody>
		</table>

	</div>
</div>
