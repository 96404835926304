import {Logger} from '../logging/default-log.service';

/**
 * Service that holds methods to check browsers and their respective features
 *
 * @author Patrick van Zadel
 */
export class BrowserService {
	logger: Logger;

	constructor(logger: Logger) {
		this.logger = logger;
	}

	public static isEdgeBrowser(): boolean {
		return /Edge\//.test(navigator.userAgent);
	}
}
