"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var GlobalValidator = (function () {
    function GlobalValidator() {
    }
    GlobalValidator.mailFormat = function (control) {
        var EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
        if (control.value !== null && (control.value.length <= 5 || !EMAIL_REGEXP.test(control.value))) {
            return { 'incorrectMailFormat': true };
        }
        return null;
    };
    GlobalValidator.minValue = function (minValue) {
        return function (control) {
            var value = +control.value;
            return value < minValue ? { 'minValue': { 'requiredMinValue': minValue, 'actualValue': value } } : null;
        };
    };
    return GlobalValidator;
}());
exports.GlobalValidator = GlobalValidator;
