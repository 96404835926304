"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var user_service_1 = require("./user.service");
var http_service_1 = require("./http.service");
var base_service_1 = require("./base.service");
var default_log_service_1 = require("../logging/default-log.service");
var configuration_helper_1 = require("../helper/configuration.helper");
var ApplicationService = (function (_super) {
    __extends(ApplicationService, _super);
    function ApplicationService(userService, http, logger) {
        var _this = _super.call(this, logger) || this;
        _this.userService = userService;
        _this.http = http;
        _this.loadedChanged = new rxjs_1.Subject();
        _this.showPopupExistingOrders = true;
        _this.isListView = false;
        _this.listViewSet = false;
        _this.APP_SETTINGS_KEY = 'app_settings';
        _this.PRODUCTS_VIEW_KEY = 'current_user_products_is_list_view';
        _this.CURRENCY_SYMBOLS = {
            'ALL': 'ALL',
            'AFN': '\u060b',
            'ARS': 'ARS',
            'AWG': 'AWG',
            'AUD': 'AUD',
            'AZN': 'AZN',
            'BSD': 'BSD',
            'BBD': 'BBD',
            'BYR': 'BYR',
            'BZD': 'BZD',
            'BMD': 'BMD',
            'BOB': 'BOB',
            'BAM': 'BAM',
            'BWP': 'BWP',
            'BGN': 'BGN',
            'BRL': 'BRL',
            'BND': 'BND',
            'KHR': 'KHR',
            'CAD': 'CAD',
            'KYD': 'KYD',
            'CLP': 'CLP',
            'CNY': 'CNY',
            'COP': 'COP',
            'CRC': 'CRC',
            'HRK': 'HRK',
            'CUP': 'CUP',
            'CZK': 'K\u010d',
            'DKK': 'DKK',
            'DOP': 'DOP',
            'XCD': 'XCD',
            'EGP': 'EGP',
            'SVC': 'SVC',
            'EEK': 'EEK',
            'EUR': '\u20AC',
            'FKP': 'FKP',
            'FJD': 'FJD',
            'GHC': 'GHC',
            'GIP': 'GIP',
            'GTQ': 'GTQ',
            'GGP': 'GGP',
            'GYD': 'GYD',
            'HNL': '\u004c',
            'HKD': 'HKD',
            'HUF': 'Ft',
            'ISK': 'ISK',
            'INR': '\u20b9',
            'IDR': 'IDR',
            'IRR': 'IRR',
            'IMP': 'IMP',
            'ILS': '\u20aa',
            'JMD': 'JMD',
            'JPY': '\u00A5',
            'JEP': 'JEP',
            'KZT': 'KZT',
            'KRW': '\u20a9',
            'KGS': 'KGS',
            'LAK': 'LAK',
            'LVL': 'LVL',
            'LBP': 'LBP',
            'LRD': 'LRD',
            'LTL': 'LTL',
            'MKD': 'MKD',
            'MYR': 'MYR',
            'MUR': 'MUR',
            'MXN': 'MXN',
            'MNT': 'MNT',
            'MZN': 'MZN',
            'NAD': 'NAD',
            'NPR': 'NPR',
            'ANG': 'ANG',
            'NZD': 'NZD',
            'NIO': 'NIO',
            'NGN': 'NGN',
            'KPW': 'KPW',
            'NOK': 'NOK',
            'OMR': 'OMR',
            'PKR': '\u20a8',
            'PAB': 'PAB',
            'PYG': 'PYG',
            'PEN': 'PEN',
            'PHP': '\u20b1',
            'PLN': '\u007A\u0142',
            'QAR': 'QAR',
            'RON': 'RON',
            'RUB': '\u20BD',
            'SHP': 'SHP',
            'SAR': 'SAR',
            'RSD': 'RSD',
            'SCR': 'SCR',
            'SGD': 'SGD',
            'SBD': 'SBD',
            'SOS': 'SOS',
            'ZAR': 'ZAR',
            'LKR': '\u20a8',
            'SEK': 'SEK',
            'CHF': 'CHF',
            'SRD': 'SRD',
            'SYP': 'SYP',
            'TWD': 'TWD',
            'THB': '\u0e3f',
            'TTD': 'TTD',
            'TRY': 'TL',
            'TRL': 'TRL',
            'TVD': 'TVD',
            'UAH': 'UAH',
            'GBP': '\u00A3',
            'USD': '\u0024',
            'UYU': 'UYU',
            'UZS': 'UZS',
            'VEF': 'Bs',
            'VND': '\u20ab',
            'YER': 'YER',
            'ZWD': 'ZWD'
        };
        _this.orderSelected = false;
        _this.showPopup = false;
        var user = userService.getUser();
        if (user && !_this.listViewSet) {
            _this.isListView = _this.getProductsListView();
            _this.listViewSet = true;
        }
        return _this;
    }
    Object.defineProperty(ApplicationService.prototype, "message", {
        get: function () {
            return this._message;
        },
        set: function (value) {
            this._message = value;
        },
        enumerable: true,
        configurable: true
    });
    ApplicationService.prototype.getProductsListView = function () {
        var isListView = false;
        var sessionValue = configuration_helper_1.ConfigurationHelper.getItem(this.PRODUCTS_VIEW_KEY);
        if (sessionValue) {
            isListView = JSON.parse(sessionValue);
        }
        else if (this.userService.isLoggedIn()) {
            isListView = this.userService.isUseFastCheckout();
        }
        return isListView;
    };
    ApplicationService.prototype.setProductsListView = function (isListView) {
        configuration_helper_1.ConfigurationHelper.setItem(this.PRODUCTS_VIEW_KEY, JSON.stringify(isListView));
    };
    ApplicationService.prototype.isShowPopup = function () {
        return this.showPopup;
    };
    ApplicationService.prototype.openPopup = function (onPopupSaveCallback, onPopupCloseCallback, showPopupExistingOrders, startOrderPopupFocussedProduct) {
        if (onPopupSaveCallback === void 0) { onPopupSaveCallback = function () {
        }; }
        if (onPopupCloseCallback === void 0) { onPopupCloseCallback = function () {
        }; }
        if (showPopupExistingOrders === void 0) { showPopupExistingOrders = true; }
        if (startOrderPopupFocussedProduct === void 0) { startOrderPopupFocussedProduct = null; }
        this.onStartOrderPopupSaveCallback = onPopupSaveCallback;
        this.onStartOrderPopupCloseCallback = onPopupCloseCallback;
        this.startOrderPopupFocussedProduct = startOrderPopupFocussedProduct;
        this.toggleBodyPopupClass(true);
        this.showPopup = true;
        this.showPopupExistingOrders = showPopupExistingOrders;
    };
    ApplicationService.prototype.openPopupForNew = function (onPopupSaveCallback, onPopupCloseCallback) {
        if (onPopupSaveCallback === void 0) { onPopupSaveCallback = function () {
        }; }
        if (onPopupCloseCallback === void 0) { onPopupCloseCallback = function () {
        }; }
        this.openPopup(onPopupSaveCallback, onPopupCloseCallback, false);
    };
    ApplicationService.prototype.closePopup = function () {
        this.toggleBodyPopupClass(false);
        this.showPopup = false;
        this.startOrderPopupFocussedProduct = null;
    };
    ApplicationService.prototype.toggleBodyPopupClass = function (add) {
        var body = document.querySelector('body');
        body.classList.toggle('popupOpen', add);
    };
    ApplicationService.prototype.showMessage = function () {
        return this._message && !this._message.isShown;
    };
    ApplicationService.prototype.logout = function () {
        configuration_helper_1.ConfigurationHelper.clearLocalStorage();
        this.orderSelected = false;
    };
    ApplicationService.prototype.getUserCurrency = function () {
        var user = this.userService.getUser();
        var defaultCurrencyCode = this.CURRENCY_SYMBOLS['EUR'];
        if (!user) {
            return defaultCurrencyCode;
        }
        var userCurrency = user != null ? user.currencyCode : null;
        if (userCurrency) {
            var currencyCode = userCurrency.toUpperCase();
            return this.CURRENCY_SYMBOLS[currencyCode] !== undefined ? this.CURRENCY_SYMBOLS[currencyCode] : defaultCurrencyCode;
        }
        return defaultCurrencyCode;
    };
    ApplicationService.prototype.getAppSettings = function () {
        var sessionValue = configuration_helper_1.ConfigurationHelper.getItem(this.APP_SETTINGS_KEY);
        var settings = null;
        if (sessionValue) {
            settings = JSON.parse(sessionValue);
        }
        return settings;
    };
    ApplicationService.prototype.getRegisterUrl = function () {
        var languageCode = this.getWebsiteLanguageUrlIso();
        var path;
        switch (languageCode) {
            case 'de':
                path = 'service/become-a-customer';
                break;
            case 'nl':
                path = 'service/klant-worden';
                break;
            case 'sv':
                path = 'tjanster/bli-kund-hos-noviflora';
                break;
            case 'en':
            default:
                path = 'service/become-a-customer';
        }
        return this.getUrl(path, languageCode);
    };
    ApplicationService.prototype.getCmsLoginUrl = function (returnUrl) {
        var cmsLoginUrl = this.getUrl('login', null);
        if (returnUrl) {
            return cmsLoginUrl + ("?returnUrl=" + returnUrl);
        }
        return cmsLoginUrl;
    };
    ApplicationService.prototype.getCmsUrl = function () {
        return this.getUrl(null, null);
    };
    ApplicationService.prototype.getUrl = function (path, forceLanguage) {
        var cmsBaseUrl = configuration_helper_1.ConfigurationHelper.getCmsBaseUrl();
        var endOfUrlRegex = /\/$/;
        if (!cmsBaseUrl) {
            return '';
        }
        var languageCode;
        if (!forceLanguage) {
            languageCode = this.getWebsiteLanguageUrlIso();
        }
        else {
            languageCode = forceLanguage;
        }
        var testCmsBaseUrlForSlash = endOfUrlRegex.test(cmsBaseUrl);
        var siteBasePath = testCmsBaseUrlForSlash ? 'site/' : '/site/';
        var url = cmsBaseUrl + siteBasePath + languageCode.toLowerCase();
        if (path) {
            url = url + '/' + path;
        }
        return url;
    };
    ApplicationService.prototype.getWebsiteLanguageUrlIso = function () {
        var name = 'websiteLanguageUrlIso=';
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return 'en';
    };
    return ApplicationService;
}(base_service_1.BaseService));
exports.ApplicationService = ApplicationService;
