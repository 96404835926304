"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var RenderProductImageComponent = (function () {
    function RenderProductImageComponent() {
    }
    RenderProductImageComponent.prototype.getImageFormat = function () {
        if (this.imageFormat) {
            return '_' + this.imageFormat;
        }
        return null;
    };
    return RenderProductImageComponent;
}());
exports.RenderProductImageComponent = RenderProductImageComponent;
