"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("@angular/common");
var i2 = require("./cart.line.remove.component");
var styles_CartLineRemoveComponent = [];
var RenderType_CartLineRemoveComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CartLineRemoveComponent, data: {} });
exports.RenderType_CartLineRemoveComponent = RenderType_CartLineRemoveComponent;
function View_CartLineRemoveComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "delete-button-icon"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 5, ":svg:svg", [["class", "delete-timer"], ["height", "44"], ["version", "1.1"], ["viewport", "0 0 22 22"], ["width", "44"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(4, 0, null, null, 0, ":svg:circle", [["cx", "22"], ["cy", "22"], ["fill", "transparent"], ["r", "16"], ["stroke-dasharray", "100"], ["stroke-dashoffset", "0"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(6, 0, null, null, 0, ":svg:circle", [["class", "delete-timer-bar"], ["cx", "22"], ["cy", "22"], ["fill", "transparent"], ["r", "16"], ["stroke-dasharray", "100"], ["stroke-dashoffset", "0"]], [[4, "stroke-dashoffset", null]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.expiryTimeHumanReadable; _ck(_v, 0, 0, currVal_0); var currVal_1 = ((100 + _co.expiryPercentageLeft) + "px"); _ck(_v, 6, 0, currVal_1); }); }
function View_CartLineRemoveComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "div", [["class", "delete-button-container"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(2, { disabled: 0 }), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(4, 0, null, null, 6, "button", [], [[8, "title", 0], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.remove() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(6, 0, null, null, 3, ":svg:svg", [["class", "icon-cross-small"], ["height", "11"], ["viewBox", "0 0 10 10"], ["width", "11"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(8, 0, null, null, 0, ":svg:use", [[":xlink:href", "/images/icons/svg-sprite.svg#cross-small"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵted(-1, null, ["\n\n\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CartLineRemoveComponent_2)), i0.ɵdid(13, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "delete-button-container"; var currVal_1 = _ck(_v, 2, 0, _co.buttonDisabled); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = _co.isFastCheckoutMode; _ck(_v, 13, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.isFastCheckoutMode ? _co.expiryTimeHumanReadable : ""); var currVal_3 = _co.buttonDisabled; _ck(_v, 4, 0, currVal_2, currVal_3); }); }
function View_CartLineRemoveComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_CartLineRemoveComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isEditable; _ck(_v, 1, 0, currVal_0); }, null); }
exports.View_CartLineRemoveComponent_0 = View_CartLineRemoveComponent_0;
function View_CartLineRemoveComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "cart-line-remove", [], null, null, null, View_CartLineRemoveComponent_0, RenderType_CartLineRemoveComponent)), i0.ɵdid(1, 114688, null, 0, i2.CartLineRemoveComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_CartLineRemoveComponent_Host_0 = View_CartLineRemoveComponent_Host_0;
var CartLineRemoveComponentNgFactory = i0.ɵccf("cart-line-remove", i2.CartLineRemoveComponent, View_CartLineRemoveComponent_Host_0, { label: "label", seconds: "seconds", minutes: "minutes", hours: "hours", days: "days", isEditable: "isEditable", buttonDisabled: "buttonDisabled", isFastCheckoutMode: "isFastCheckoutMode", expiryDate: "expiryDate", maxUserCartWaitTimeInSeconds: "maxUserCartWaitTimeInSeconds" }, { removeEvent: "removeEvent" }, []);
exports.CartLineRemoveComponentNgFactory = CartLineRemoveComponentNgFactory;
