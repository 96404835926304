<div class="header-shoppingcart" *ngIf="showShoppingCart">
	<div class="header-shoppingcart-overview" [ngClass]="{show: showShoppingCartOverview}" #shoppingCart>
		<table *ngIf="getShoppingCartCount() > 0">
			<thead>
				<tr>
					<th scope="col" i18n="Header for 'product' in shopping cart popup in product overview@@product.title">
						Product
					</th>
					<th scope="col" i18n="Header for 'amount' in shopping cart popup in product overview@@amount.title">
						Amount
					</th>
					<th scope="col" i18n="Header for 'volume' in shopping cart popup in product overview@@order.volume">
						Volume
					</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let shoppingCartLine of getPopupItems()">
					<td class="header-shoppingcart_product theme-primary-text-color">
						{{shoppingCartLine.description}}
					</td>
					<td class="header-shoppingcart_amount" *ngIf="shoppingCartLine.qtyByBox >= 1"
					    i18n="Label shown for shopping cart line, eg: 1 x Tray (x6)@@shoppingcart.amount.tray">
						{{getQuantity(shoppingCartLine)}} (x {{shoppingCartLine.qtyByBox}})
					</td>
					<td class="header-shoppingcart_volume" i18n="Calculated volume (CC)@@calculated.volume">
						{{shoppingCartLine.volume | numberFormat:false}} CC
					</td>
					<td class="header-shoppingcart_delete">

						<cart-line-remove
								*ngIf="isEditable(shoppingCartLine)"
								(removeEvent)="removeProduct(shoppingCartLine)"
								i18n-label="@@shoppingcart.time.remaining" label="Time remaining:"
								i18n-seconds="@@shoppingcart.time.remaining.seconds" seconds="seconds"
								i18n-minutes="@@shoppingcart.time.remaining.minutes" minutes="minutes"
								i18n-hours="@@shoppingcart.time.remaining.hours" hours="hours"
								i18n-days="@@shoppingcart.time.remaining.days" days="days"
								[isFastCheckoutMode]="!shoppingCartLine.shoppingCartMode"
								[expiryDate]="getExpiryDate(shoppingCartLine)"
								[maxUserCartWaitTimeInSeconds]="getUserCartWaitTimeInSeconds()"
								[isEditable]="isEditable(shoppingCartLine)">
						</cart-line-remove>
					</td>
				</tr>
			</tbody>
		</table>

		<table *ngIf="getShoppingCartCount() == 0">
			<tbody>
				<tr>
					<td i18n="Text to display in shopping cart popup when there are no items in it@@shoppingcart.dropdown.empty">
						No Items in your cart yet
					</td>
				</tr>
			</tbody>
		</table>

		<div class="button-bar"
		     [ngClass]="{'button-bar--justify': isFastCheckout(), 'button-bar--right': !isFastCheckout()}">
			<ng-template [ngIf]="!isFastCheckout() && getShoppingCartCount() > 0">
				<a class="button" (click)="viewShoppingcart()">
					<b i18n="Link text 'view shopping cart@@shoppingcart.dropdown.view.button">View shopping cart</b>
					<ng-container i18n="text shown after 'view shopping cart: (x products)@@shoppingcart.dropdown.view.button.amount">
						({{getShoppingCartCount()}} products)
					</ng-container>
				</a>
			</ng-template>

			<ng-template [ngIf]="isFastCheckout()">
				<a class="cursor--force-hand" (click)="viewPendingOrders();"
				   i18n="Link text 'view pending orders'@@shoppingcart.dropdown.view.pending.orders">
					View pending orders
				</a>
				<a (click)="viewOrderDetail()" class="button theme-btn-secondary" *ngIf="getShoppingCartCount() > 0"
				   i18n="Link text 'View order (x products)'@@shoppingcart.dropdown.view.order">
					View order ({{getShoppingCartCount()}} products)
				</a>
			</ng-template>
		</div>
	</div>

	<button (click)="showShoppingCartOverview = !showShoppingCartOverview"
	        class="header-shoppingcart-button" #shoppingCartButton>

		<ng-container i18n="Label shown after calculated volume (CC)@@calculated.volume">
			{{getVolume() | numberFormat:false}} CC
		</ng-container>

		<span class="header-shoppingcart_icon">
			<a>
				<render-checkout-icon [iconWidth]="39" [iconHeight]="15"
				                      [fastCheckout]="isFastCheckout()"></render-checkout-icon>
			</a>

            <span class="header-shoppingcart-icon_count theme-success-color"
                  [ngClass]="{'product-added-animation': shoppingCartUpdated}">{{getShoppingCartCount()}}</span>
        </span>

		<svg class="icon-arrow-down" width="12" height="7" viewBox="0 0 12 7">
			<use xlink:href="/images/icons/svg-sprite.svg#arrow-down"></use>
		</svg>
	</button>
</div>
