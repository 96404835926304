<!-- .modal--show starts 'show' animation -->
<!-- .modal--hide starts 'hides' animation-->

<div [ngClass]="{loading: loading}">

	<div class="loading-overlay loading-overlay--fixed"></div>

	<div class="modal"
	     [ngClass]="{'modal--show': popupOpen, 'modal--hide': !popupOpen}">
		<div class="modal-background"></div>

		<div class="modal-container" id="modal-start-order">
			<h1 class="modal-title" i18n="Start new order link text@@start.new.order.link.text">Start a new order</h1>

			<!-- This state should never happen, but better safe than sorry-->
			<div *ngIf="!shipmentDates || shipmentDates.length < 1">

				There are no available delivery dates found for this product.
				Please try again or contact us if the problem continues to occur.

				<div class="button-bar button-bar--right">
					<button type="button" class="button" (click)="close()"
					        i18n="Modal dialog: button: close@@close.button">
						Close
					</button>
				</div>
			</div>

			<div *ngIf="shipmentDates && shipmentDates.length > 0">
				<p class="modal-intro" i18n="Modal dialog: add/start new intro@@add.or.start.order">
					Add your product to an existing order or start a new one.
				</p>

				<div class="modal-form">

					<form [formGroup]="form" (ngSubmit)="onSubmit(form.value)">

						<div class="form-row">
							<label (click)="startNew = false"
							       *ngIf="getDeliveryDateForOrders() && getDeliveryDateForOrders().length > 0 && applicationService.showPopupExistingOrders">
								<input type="radio" name="order-destination" value="existing-order"
								       checked="{{startNew ? '' : 'checked'}}">
								<span class="checkbox-icon"></span>
								<ng-container i18n="Modal dialog: add to existing@@add.to.existing">
									Add to existing order
								</ng-container>
							</label>
						</div>

						<ng-template [ngIf]="!startNew">
							<div class="form-row">
								<label class="form-label" for="activeOrder" i18n="@@shipping.date">Shipping date</label>

								<div class="form-input-container">
									<div class="custom-select">
										<select name="activeOrder" id="activeOrder"
										        [formControl]="form.controls['selectedOrder']">
											<option *ngFor="let deliveryDate of getDeliveryDateForOrders()"
													[ngValue]="getActiveOrderForDeliveryDate(deliveryDate)">

												<span render-day-of-week
													  [day]="deliveryDate.shippingDayName"></span>
													{{deliveryDate.shipmentDate | customDate:'dd-MM'}}
													<span>
														<span i18n="Delivery label in start new order popup@@start.new.order.popup.delivery">(Delivery:</span>
														<span render-day-of-week-abbr [day]="deliveryDate.deliveryDayName"></span>{{deliveryDate.deliveryDate | customDate:'dd-MM'}}
														<span render-part-of-day [partOfDay]="deliveryDate.dagDeelAankomst"></span>)
													</span>
											</option>
										</select>
									</div>
									<p *ngIf="selectedOrderSelect">
										{{selectedOrderSelect.deliveryAddressName}}
										({{selectedOrderSelect.shipToCode}})<br />
										{{selectedOrderSelect.volume | numberFormat:false}} CC <!-- Translatable?? -->
									</p>
								</div>
							</div>
						</ng-template>

						<div class="form-row">
							<label (click)="startNew = true">
								<input type="radio" name="order-destination" value="new-order"
								       checked="{{startNew ? 'checked' : ''}}">
								<span class="checkbox-icon"></span>
								<ng-container i18n="Modal dialog: start new order@@start.new.order.button">
									Start a new order
								</ng-container>
							</label>
						</div>

						<ng-template [ngIf]="startNew">
							<div class="form-row">
								<label class="form-label" for="shipmentDate" i18n="@@shipping.date">Shipping date</label>

								<div class="form-input-container">
									<div class="custom-select">
										<select name="shipmentDate" id="shipmentDate"
										        [formControl]="form.controls['shipmentDate']"
												(change)="changeDate()">
											<option *ngFor="let shipping of shipmentDates" [ngValue]="shipping">
												<span render-day-of-week [day]="shipping.shippingDayName"></span>
												{{shipping.shipmentDate | customDate:'dd-MM'}}
												<span>
													<span i18n="Delivery label in start new order popup@@start.new.order.popup.delivery">(Delivery:</span>
													<span render-day-of-week-abbr [day]="shipping.deliveryDayName"></span> {{shipping.deliveryDate | customDate:'dd-MM'}}
													<span render-part-of-day [partOfDay]="shipping.dagDeelAankomst"></span>)
												</span>
											</option>
										</select>
									</div>
								</div>
							</div>

							<div class="form-row modal-start-order__addresses">
								<label class="form-label" i18n="@@delivery.address">Delivery address</label>

								<div class="form-input-container">

									<div class="delivery-address-option"
									     *ngFor="let address of deliveryAddresses; let i = index">
										<input type="radio" name="deliveryAddress" id="deliveryAddress_{{i}}"
										       value="{{address.code}}"
										       [formControl]="form.controls['deliveryAddress']"
										       (click)="deliveryAddress = address" [checked]="i === 0" />

										<label htmlFor="deliveryAddress_{{i}}">
											<span class="checkbox-icon"></span>

											<div class="address">
												<h3>{{address.name}} ({{address.code}})</h3>
												<p>
													{{address.address}}<br />
													{{address.postcode}} {{address.city}}<br />
													{{address.country}}
												</p>
											</div>

										</label>
									</div>
								</div>
							</div>
						</ng-template>

						<div class="button-bar button-bar--right">
							<button type="button" class="button theme-btn-primary" (click)="close()"
							        i18n="Modal dialog: button: close@@close.button">
								Close
							</button>
							<button type="submit" class="button button--green theme-btn-success" *ngIf="startNew"
							        i18n="Modal dialog: button: start new order@@start.new.order.button">
								Start new order
							</button>
							<button type="submit" class="button button--green theme-btn-success" *ngIf="!startNew"
							        i18n="Modal dialog: button: add and continue@@add.continue.order.button">
								Add and continue order
							</button>
						</div>

					</form>

				</div>

			</div>
		</div>
	</div>

</div>
