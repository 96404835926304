"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../generic/component/cart/shoppingcart.header.component.ngfactory");
var i2 = require("../generic/component/cart/shoppingcart.header.component");
var i3 = require("../generic/service/shoppingcart.service");
var i4 = require("../generic/service/user.service");
var i5 = require("../generic/logging/default-log.service");
var i6 = require("../generic/component/order/ordertabs.component.ngfactory");
var i7 = require("../generic/component/order/ordertabs.component");
var i8 = require("../generic/service/application.service");
var i9 = require("../generic/service/active.order.service");
var i10 = require("@angular/common");
var i11 = require("./shoppingcart.header.wrapper.component");
var styles_ShoppingcartHeaderWrapperComponent = [];
var RenderType_ShoppingcartHeaderWrapperComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ShoppingcartHeaderWrapperComponent, data: {} });
exports.RenderType_ShoppingcartHeaderWrapperComponent = RenderType_ShoppingcartHeaderWrapperComponent;
function View_ShoppingcartHeaderWrapperComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 2, "shoppingcart-header-component", [], null, [[null, "viewShoppingcartClickEvent"], [null, "viewPendingOrdersClickEvent"], [null, "viewOrderDetailClickEvent"], ["document", "click"], ["document", "touchstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).handleClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("document:touchstart" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).handleTouchstart($event) !== false);
        ad = (pd_1 && ad);
    } if (("viewShoppingcartClickEvent" === en)) {
        var pd_2 = (_co.viewShoppingcart() !== false);
        ad = (pd_2 && ad);
    } if (("viewPendingOrdersClickEvent" === en)) {
        var pd_3 = (_co.viewPendingOrders() !== false);
        ad = (pd_3 && ad);
    } if (("viewOrderDetailClickEvent" === en)) {
        var pd_4 = (_co.viewOrderDetail() !== false);
        ad = (pd_4 && ad);
    } return ad; }, i1.View_ShoppingcartHeaderComponent_0, i1.RenderType_ShoppingcartHeaderComponent)), i0.ɵdid(2, 180224, null, 0, i2.ShoppingcartHeaderComponent, [i3.ShoppingCartService, i4.UserService, i5.Logger], null, { viewPendingOrdersClickEvent: "viewPendingOrdersClickEvent", viewOrderDetailClickEvent: "viewOrderDetailClickEvent", viewShoppingcartClickEvent: "viewShoppingcartClickEvent" }), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\n\t\t"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "order-tabs", [], null, [[null, "clickedAllProductsEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickedAllProductsEvent" === en)) {
        var pd_0 = (_co.clickedAllProductsEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_OrderTabsComponent_0, i6.RenderType_OrderTabsComponent)), i0.ɵdid(6, 180224, null, 0, i7.OrderTabsComponent, [i8.ApplicationService, i9.ActiveOrderService], null, { clickedAllProductsEvent: "clickedAllProductsEvent" }), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], null, null); }
function View_ShoppingcartHeaderWrapperComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 11, "div", [["class", "header-shopping-info header-shopping-info--preload-minheight"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(3, 0, null, null, 8, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\n\t\t\t\t"])), (_l()(), i0.ɵeld(5, 0, null, null, 2, "shoppingcart-header-component", [], null, [[null, "viewShoppingcartClickEvent"], [null, "viewPendingOrdersClickEvent"], [null, "viewOrderDetailClickEvent"], ["document", "click"], ["document", "touchstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 6).handleClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("document:touchstart" === en)) {
        var pd_1 = (i0.ɵnov(_v, 6).handleTouchstart($event) !== false);
        ad = (pd_1 && ad);
    } if (("viewShoppingcartClickEvent" === en)) {
        var pd_2 = (_co.viewShoppingcart() !== false);
        ad = (pd_2 && ad);
    } if (("viewPendingOrdersClickEvent" === en)) {
        var pd_3 = (_co.viewPendingOrders() !== false);
        ad = (pd_3 && ad);
    } if (("viewOrderDetailClickEvent" === en)) {
        var pd_4 = (_co.viewOrderDetail() !== false);
        ad = (pd_4 && ad);
    } return ad; }, i1.View_ShoppingcartHeaderComponent_0, i1.RenderType_ShoppingcartHeaderComponent)), i0.ɵdid(6, 180224, null, 0, i2.ShoppingcartHeaderComponent, [i3.ShoppingCartService, i4.UserService, i5.Logger], null, { viewPendingOrdersClickEvent: "viewPendingOrdersClickEvent", viewOrderDetailClickEvent: "viewOrderDetailClickEvent", viewShoppingcartClickEvent: "viewShoppingcartClickEvent" }), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\n\t\t\t\t"])), (_l()(), i0.ɵeld(9, 0, null, null, 1, "order-tabs", [], null, [[null, "clickedAllProductsEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickedAllProductsEvent" === en)) {
        var pd_0 = (_co.clickedAllProductsEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_OrderTabsComponent_0, i6.RenderType_OrderTabsComponent)), i0.ɵdid(10, 180224, null, 0, i7.OrderTabsComponent, [i8.ApplicationService, i9.ActiveOrderService], null, { clickedAllProductsEvent: "clickedAllProductsEvent" }), (_l()(), i0.ɵted(-1, null, ["\n\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], null, null); }
function View_ShoppingcartHeaderWrapperComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\n\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ShoppingcartHeaderWrapperComponent_2)), i0.ɵdid(2, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\n\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ShoppingcartHeaderWrapperComponent_3)), i0.ɵdid(5, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shoppingbarHeaderWrapperAlreadyRendered; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.shoppingbarHeaderWrapperAlreadyRendered; _ck(_v, 5, 0, currVal_1); }, null); }
function View_ShoppingcartHeaderWrapperComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ShoppingcartHeaderWrapperComponent_1)), i0.ɵdid(1, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasSelectedOrder(); _ck(_v, 1, 0, currVal_0); }, null); }
exports.View_ShoppingcartHeaderWrapperComponent_0 = View_ShoppingcartHeaderWrapperComponent_0;
function View_ShoppingcartHeaderWrapperComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "shoppingcart-header-wrapper-component", [], null, null, null, View_ShoppingcartHeaderWrapperComponent_0, RenderType_ShoppingcartHeaderWrapperComponent)), i0.ɵdid(1, 49152, null, 0, i11.ShoppingcartHeaderWrapperComponent, [i9.ActiveOrderService], null, null)], null, null); }
exports.View_ShoppingcartHeaderWrapperComponent_Host_0 = View_ShoppingcartHeaderWrapperComponent_Host_0;
var ShoppingcartHeaderWrapperComponentNgFactory = i0.ɵccf("shoppingcart-header-wrapper-component", i11.ShoppingcartHeaderWrapperComponent, View_ShoppingcartHeaderWrapperComponent_Host_0, {}, {}, []);
exports.ShoppingcartHeaderWrapperComponentNgFactory = ShoppingcartHeaderWrapperComponentNgFactory;
