import { Component, Input } from '@angular/core';

/**
 * Normal binding doesnt work here, attribute binding is necessary ..
 *
 * @see http://stackoverflow.com/questions/36099392/how-to-use-angular-2-extrapolation-in-html-element-attributes
 */
@Component({
    selector: 'render-checkout-icon',
    template: `
    <svg class="icon-truck" [attr.width]="iconWidth" [attr.height]="iconHeight" viewBox="0 0 46 24" *ngIf="fastCheckout">
        <use xlink:href="/images/icons/svg-sprite.svg#truck"></use>
    </svg>
    <svg class="icon-shoppingcart" [attr.width]="iconWidth" [attr.height]="iconHeight" viewBox="0 0 27 24" *ngIf="!fastCheckout">
        <use xlink:href="/images/icons/svg-sprite.svg#shoppingcart"></use>
    </svg>`
})
export class RenderCheckoutIconComponent {
    @Input() fastCheckout: boolean;
    @Input() iconWidth: string;
    @Input() iconHeight: string;

}
