"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var shop_helper_1 = require("./shop.helper");
var redirect_to_shop_service_1 = require("./redirect.to.shop.service");
var PendingOrdersWrapperComponent = (function () {
    function PendingOrdersWrapperComponent(redirectService) {
    }
    PendingOrdersWrapperComponent.prototype.navigateToWebshopAndStartNewOrder = function () {
        shop_helper_1.ShopHelper.goToWebshopUrl("/products;newOrder=true");
    };
    return PendingOrdersWrapperComponent;
}());
exports.PendingOrdersWrapperComponent = PendingOrdersWrapperComponent;
