"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var shop_helper_1 = require("./shop.helper");
var RedirectToShopService = (function () {
    function RedirectToShopService(router) {
        this.router = router;
        this.router.events.subscribe(function (evt) {
            if (evt instanceof router_1.NavigationStart) {
                shop_helper_1.ShopHelper.goToWebshopUrl(evt.url);
            }
        });
    }
    return RedirectToShopService;
}());
exports.RedirectToShopService = RedirectToShopService;
