"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../generic/component/login/login.component.ngfactory");
var i2 = require("../generic/component/login/login.component");
var i3 = require("../generic/service/application.service");
var i4 = require("@angular/router");
var i5 = require("../generic/service/user.service");
var i6 = require("@angular/forms");
var i7 = require("../generic/logging/default-log.service");
var i8 = require("./login.wrapper.component");
var styles_LoginWrapperComponent = [];
var RenderType_LoginWrapperComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LoginWrapperComponent, data: {} });
exports.RenderType_LoginWrapperComponent = RenderType_LoginWrapperComponent;
function View_LoginWrapperComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "login-component", [], null, null, null, i1.View_LoginComponent_0, i1.RenderType_LoginComponent)), i0.ɵdid(1, 114688, null, 0, i2.LoginComponent, [i3.ApplicationService, i4.ActivatedRoute, i5.UserService, i6.FormBuilder, i7.Logger], { successRedirect: [0, "successRedirect"] }, null)], function (_ck, _v) { var currVal_0 = "/site"; _ck(_v, 1, 0, currVal_0); }, null); }
exports.View_LoginWrapperComponent_0 = View_LoginWrapperComponent_0;
function View_LoginWrapperComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "login-wrapper-component", [], null, null, null, View_LoginWrapperComponent_0, RenderType_LoginWrapperComponent)), i0.ɵdid(1, 49152, null, 0, i8.LoginWrapperComponent, [], null, null)], null, null); }
exports.View_LoginWrapperComponent_Host_0 = View_LoginWrapperComponent_Host_0;
var LoginWrapperComponentNgFactory = i0.ɵccf("login-wrapper-component", i8.LoginWrapperComponent, View_LoginWrapperComponent_Host_0, {}, {}, []);
exports.LoginWrapperComponentNgFactory = LoginWrapperComponentNgFactory;
