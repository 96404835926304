"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("./product.size");
var styles_ProductSizeComponent = [];
var RenderType_ProductSizeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProductSizeComponent, data: {} });
exports.RenderType_ProductSizeComponent = RenderType_ProductSizeComponent;
function View_ProductSizeComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 17, "div", [["class", "overview-product_size"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n            "])), (_l()(), i0.ɵeld(2, 0, null, null, 6, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n                "])), (_l()(), i0.ɵeld(4, 0, null, null, 3, ":svg:svg", [["class", "icon-potsize"], ["height", "11"], ["viewBox", "0 0 16 11"], ["width", "16"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n                    "])), (_l()(), i0.ɵeld(6, 0, null, null, 0, ":svg:use", [[":xlink:href", "/images/icons/svg-sprite.svg#potsize"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n                "])), (_l()(), i0.ɵted(8, null, ["\n                ", "\n            "])), (_l()(), i0.ɵted(-1, null, ["\n            "])), (_l()(), i0.ɵeld(10, 0, null, null, 6, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n                "])), (_l()(), i0.ɵeld(12, 0, null, null, 3, ":svg:svg", [["class", "icon-potheight"], ["height", "11"], ["viewBox", "0 0 6 11"], ["width", "16"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n                    "])), (_l()(), i0.ɵeld(14, 0, null, null, 0, ":svg:use", [[":xlink:href", "/images/icons/svg-sprite.svg#potheight"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n                "])), (_l()(), i0.ɵted(16, null, ["\n                ", "\n             "])), (_l()(), i0.ɵted(-1, null, ["\n        "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.potSize ? _co.potSize : 0); _ck(_v, 8, 0, currVal_0); var currVal_1 = (_co.height ? _co.height : 0); _ck(_v, 16, 0, currVal_1); }); }
exports.View_ProductSizeComponent_0 = View_ProductSizeComponent_0;
function View_ProductSizeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "product-size", [], null, null, null, View_ProductSizeComponent_0, RenderType_ProductSizeComponent)), i0.ɵdid(1, 49152, null, 0, i1.ProductSizeComponent, [], null, null)], null, null); }
exports.View_ProductSizeComponent_Host_0 = View_ProductSizeComponent_Host_0;
var ProductSizeComponentNgFactory = i0.ɵccf("product-size", i1.ProductSizeComponent, View_ProductSizeComponent_Host_0, { potSize: "potSize", height: "height" }, {}, []);
exports.ProductSizeComponentNgFactory = ProductSizeComponentNgFactory;
