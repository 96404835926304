"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ResetPasswordTranslationsComponent = (function () {
    function ResetPasswordTranslationsComponent() {
    }
    ResetPasswordTranslationsComponent.prototype.getForgotEmailSent = function () {
        return this.forgotEmailSent.nativeElement.textContent;
    };
    ResetPasswordTranslationsComponent.prototype.getResetLinkExpired = function () {
        return this.resetLinkExpired.nativeElement.textContent;
    };
    ResetPasswordTranslationsComponent.prototype.getResetPasswordSuccess = function () {
        return this.resetPasswordSuccess.nativeElement.textContent;
    };
    return ResetPasswordTranslationsComponent;
}());
exports.ResetPasswordTranslationsComponent = ResetPasswordTranslationsComponent;
