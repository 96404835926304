"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Administration = (function () {
    function Administration(administrationUrl, token) {
        this.administrationUrl = administrationUrl;
        this.token = token;
    }
    return Administration;
}());
exports.Administration = Administration;
