import {Product} from './product';

export class Planthunter {
    bannerUrl: string;
    code: string;
    description: string;
    email: string;
    followerCount: number;
    functionDescription: string;
    huntCount: number;
    imageUrl: string;
    name: string;
    firstName: string;
    phoneDirect: string;
    phoneMobile: string;
    planthunterFeature: string;
    volgStatus: boolean;
    products: Product[];
}
