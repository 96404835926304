"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var DynamicModalDirective = (function () {
    function DynamicModalDirective(viewContainerRef) {
        this.viewContainerRef = viewContainerRef;
    }
    return DynamicModalDirective;
}());
exports.DynamicModalDirective = DynamicModalDirective;
