"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var configuration_helper_1 = require("../generic/helper/configuration.helper");
var ShopHelper = (function () {
    function ShopHelper() {
    }
    ShopHelper.goToWebshopUrl = function (url) {
        window.location.href = this.getWebshopUrl(url);
    };
    ShopHelper.getWebshopUrl = function (url) {
        return configuration_helper_1.ConfigurationHelper.getWebshopUrl(url);
    };
    return ShopHelper;
}());
exports.ShopHelper = ShopHelper;
