import {Component, EventEmitter, Inject, Input, Output, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {Logger} from '../../../../logging/default-log.service';
import {PendingOrder} from '../../../../classes/pending.order';
import {PendingOrderService} from '../../../../service/pending.order.service';
import {OrderTranslationsComponent} from '../../order.translations.component';
import {ApplicationService} from '../../../../service/application.service';

/**
 * Works with the same data as ActiveOrder, but to make sure everything is separated and still readable,
 * we've introduced a separate service and pojo.
 *
 * @author evandongen
 */
@Component({
    selector: 'pending-orders',
    templateUrl: 'pending.orders.component.html'
})
export class PendingOrdersComponent {

    @ViewChild(OrderTranslationsComponent) orderTranslationsComponent: OrderTranslationsComponent;

    @Input() maxAmount: number;
    @Output() startNewOrderClickEvent = new EventEmitter();
    pendingOrders: Array<PendingOrder>;
    noPendingOrders: boolean = false;

    constructor(private pendingOrderService: PendingOrderService,
                private applicationService: ApplicationService,
                private router: Router,
                private logger: Logger) {

        this.pendingOrderService.getPendingOrders().subscribe(
            orders => this.setPendingOrders(orders),
            error => this.logger.error('Failed to get pending orders', error)
        );
    }

    /**
     * Opens the popup for a new order and listens for a succesful close to redirect.
     *
     */
    startNewOrder() {
        this.startNewOrderClickEvent.emit();

        const self = this;

        this.applicationService.openPopup(() => {
                self.router.navigate(['/products']);
                this.applicationService.closePopup();
            }
        );
    }

    private setPendingOrders(pendingOrders: Array<PendingOrder>) {
        this.pendingOrders = pendingOrders;
        this.noPendingOrders = pendingOrders.length === 0;
    }
}
