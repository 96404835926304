"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var operators_1 = require("rxjs/operators");
var user_service_1 = require("./user.service");
var default_log_service_1 = require("../logging/default-log.service");
var NotAuthorizedInterceptor = (function () {
    function NotAuthorizedInterceptor(logger) {
        this.logger = logger;
    }
    NotAuthorizedInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        return next.handle(req)
            .pipe(operators_1.tap(function () {
        }, function (event) {
            if (event instanceof http_1.HttpErrorResponse) {
                var url = event.url;
                if (!url) {
                    _this.logger.error('SUPNOVICOM-526', event);
                    return;
                }
                if (url.indexOf('remotelogin') === -1 && (event.status === 401 || event.status === 403)
                    && (window.location.href.match(/\?/g) || []).length < 2) {
                    user_service_1.UserService.cleanupOldStorageValuesAndRedirectToLogin();
                }
            }
        }));
    };
    return NotAuthorizedInterceptor;
}());
exports.NotAuthorizedInterceptor = NotAuthorizedInterceptor;
