"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("@angular/common");
var i2 = require("./planthunter.amount.of.new.hunts.component");
var i3 = require("../../service/planthunter.service");
var styles_PlanthunterAmountOfNewHuntsComponent = [];
var RenderType_PlanthunterAmountOfNewHuntsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PlanthunterAmountOfNewHuntsComponent, data: {} });
exports.RenderType_PlanthunterAmountOfNewHuntsComponent = RenderType_PlanthunterAmountOfNewHuntsComponent;
function View_PlanthunterAmountOfNewHuntsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "planthunter-nav-count"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.newHunts; _ck(_v, 1, 0, currVal_0); }); }
function View_PlanthunterAmountOfNewHuntsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_PlanthunterAmountOfNewHuntsComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.newHunts > 0); _ck(_v, 1, 0, currVal_0); }, null); }
exports.View_PlanthunterAmountOfNewHuntsComponent_0 = View_PlanthunterAmountOfNewHuntsComponent_0;
function View_PlanthunterAmountOfNewHuntsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "planthunter-amountofnewhunts-component", [], null, null, null, View_PlanthunterAmountOfNewHuntsComponent_0, RenderType_PlanthunterAmountOfNewHuntsComponent)), i0.ɵdid(1, 49152, null, 0, i2.PlanthunterAmountOfNewHuntsComponent, [i3.PlanthunterService], null, null)], null, null); }
exports.View_PlanthunterAmountOfNewHuntsComponent_Host_0 = View_PlanthunterAmountOfNewHuntsComponent_Host_0;
var PlanthunterAmountOfNewHuntsComponentNgFactory = i0.ɵccf("planthunter-amountofnewhunts-component", i2.PlanthunterAmountOfNewHuntsComponent, View_PlanthunterAmountOfNewHuntsComponent_Host_0, {}, {}, []);
exports.PlanthunterAmountOfNewHuntsComponentNgFactory = PlanthunterAmountOfNewHuntsComponentNgFactory;
