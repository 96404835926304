import {Component, ElementRef, ViewChild} from '@angular/core';

/**
 * This component serves as a workaround for the strings that need to be internationalized but which aren't in
 * a template.
 *
 * @see https://github.com/angular/angular/issues/11405#issuecomment-254169729
 * @author Patrick van Zadel
 */
@Component({
	selector: 'cookies-disabled-translations',
	styles: [`.translation {
                display: none;
            }`],
	// tslint:disable-next-line
	template: `<span class="translation" #cookiesDisabled i18n="message: cookies disabled@@cookies.disabled.message">
        Your cookies seem to be disabled. Some functionality like logging in and setting preferences will not work until cookies are enabled.</span>
    `
})
export class CookiesDisabledTranslationsComponent {
	@ViewChild('cookiesDisabled')
	cookiesDisabled: ElementRef;

	public getCookiesDisabled(): string {
		return this.cookiesDisabled.nativeElement.textContent;
	}
}
