"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var cart_line_remove_translations_1 = require("../../classes/cart.line.remove.translations");
var CartLineRemoveComponent = (function () {
    function CartLineRemoveComponent() {
        this.isEditable = false;
        this.buttonDisabled = false;
        this.removeEvent = new core_1.EventEmitter();
    }
    CartLineRemoveComponent.getExpiryTimeHumanReadable = function (translations, expiryDate) {
        var timeLeftInSeconds = CartLineRemoveComponent.getExpiryTimeLeftInSeconds(expiryDate);
        var timeLeftInMinutes = timeLeftInSeconds / 60;
        var timeLeftInHours = timeLeftInMinutes / 60;
        var timeLeftInDays = timeLeftInHours / 24;
        if (Math.floor(timeLeftInDays) > 0) {
            return this.getExpiryTimeHumanReadableWithLabel(translations.label, Math.floor(timeLeftInDays) + ' ' + translations.days);
        }
        if (Math.floor(timeLeftInHours) > 0) {
            return this.getExpiryTimeHumanReadableWithLabel(translations.label, Math.floor(timeLeftInHours) + ' ' + translations.days);
        }
        if (Math.floor(timeLeftInMinutes) > 0) {
            return this.getExpiryTimeHumanReadableWithLabel(translations.label, Math.floor(timeLeftInMinutes) + ' ' + translations.minutes);
        }
        return this.getExpiryTimeHumanReadableWithLabel(translations.label, Math.floor(timeLeftInSeconds) + ' ' + translations.seconds);
    };
    CartLineRemoveComponent.getExpiryTimeHumanReadableWithLabel = function (label, timeLeft) {
        return label + ' ' + timeLeft;
    };
    CartLineRemoveComponent.getExpiryTimeLeftInSeconds = function (expiryDate) {
        return (expiryDate.getTime() - new Date().getTime()) / 1000;
    };
    CartLineRemoveComponent.getExpiryPercentageLeft = function (expiryDate, maxUserCartWaitTimeInSeconds) {
        return CartLineRemoveComponent.getExpiryTimeLeftInSeconds(expiryDate) / maxUserCartWaitTimeInSeconds * 100;
    };
    CartLineRemoveComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.isFastCheckoutMode) {
            return;
        }
        this.updateShoppingCartExpiryAttributes(this.expiryDate, this.maxUserCartWaitTimeInSeconds);
        var interval = setInterval(function () {
            if (!_this.isEditable) {
                clearInterval(interval);
            }
            _this.updateShoppingCartExpiryAttributes(_this.expiryDate, _this.maxUserCartWaitTimeInSeconds);
        }, 1000);
    };
    CartLineRemoveComponent.prototype.remove = function () {
        this.removeEvent.emit();
    };
    CartLineRemoveComponent.prototype.updateShoppingCartExpiryAttributes = function (expiryDate, maxUserCartWaitTimeInSeconds) {
        var translations = new cart_line_remove_translations_1.CartLineRemoveTranslations(this.label, this.seconds, this.minutes, this.hours, this.days);
        this.expiryTimeHumanReadable = CartLineRemoveComponent.getExpiryTimeHumanReadable(translations, expiryDate);
        this.expiryPercentageLeft = CartLineRemoveComponent.getExpiryPercentageLeft(expiryDate, maxUserCartWaitTimeInSeconds);
    };
    return CartLineRemoveComponent;
}());
exports.CartLineRemoveComponent = CartLineRemoveComponent;
