import {Component, ComponentRef, EventEmitter, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Logger} from '../../../../logging/default-log.service';
import {HttpClient} from '../../../../service/http.service';
import {ApplicationService} from '../../../../service/application.service';
import {ConfigurationHelper} from '../../../../helper/configuration.helper';
import {DynamicModal} from '../../../modal/dynamic.modal.interface';
import {Subject} from 'rxjs';

/**
 * Retrieve the order comment and shows it in the popup
 *
 * @author Patrick van Zadel
 */
@Component({
    selector: 'add-comment',
    templateUrl: 'add.comment.component.html'
})
export class AddCommentComponent implements OnInit, DynamicModal {
    @Input() data: any;
    addedComment: Subject<boolean> = new Subject();
    commentValue: Subject<string> = new Subject();
    popupOpen: boolean = false;
    commentForm: FormGroup;
    hasComment: boolean = false;
    _componentRef: ComponentRef<any>;

    constructor(private http: HttpClient,
                private applicationService: ApplicationService,
                private formBuilder: FormBuilder,
                private logger: Logger) {

        this.popupOpen = true;
    }

    ngOnInit() {
        this.commentForm = this.formBuilder.group({
            'commentText': new FormControl({value: this.data.comment, disabled: !this.data.isEditable})
        });
    }

    close(saveComment = false): void {
        this.popupOpen = false;

        const body = document.querySelector('body');
        body.classList.toggle('popupOpen', false);

        setTimeout(() => {
            if (saveComment) {
                this.addedComment.next(true);
                this.commentValue.next(this.commentForm.get('commentText').value);
            }

            this._componentRef.destroy();
        }, 400);
    }

    /**
     * Process form submit and notify services
     * @param value
     */
    onSubmit(value: any) {
        if (this.commentForm.valid) {
            const url = ConfigurationHelper.getWebshopUrl('/rest/shoppingcart/comment');
            const commentValue = this.commentForm.get('commentText').value;

            const $xhr = this.http.post(url,
                JSON.stringify({
                    webUUID: this.data.webuuid,
                    comment: commentValue
                }));

            $xhr.subscribe(
                (result) => {
                    this.close(true);
                },
                (error) => {
                    this.logger.error('Error adding comment to shopping cart line', error);
                }
            );

            return $xhr;
        }

        return false;
    }
}
