"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var LogRocket = require('logrocket');
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var configuration_1 = require("./generic/configuration");
var start_order_wrapper_component_1 = require("./sitespecific/start.order.wrapper.component");
var login_wrapper_component_1 = require("./sitespecific/login.wrapper.component");
var shoppingcart_header_wrapper_component_1 = require("./sitespecific/shoppingcart.header.wrapper.component");
var shoppingcart_header_mobile_wrapper_component_1 = require("./sitespecific/shoppingcart.header.mobile.wrapper.component");
var spotlight_products_component_1 = require("./sitespecific/spotlight.products.component");
var pricelists_wrapper_component_1 = require("./sitespecific/pricelists.wrapper.component");
var pending_orders_wrapper_component_1 = require("./sitespecific/pending.orders.wrapper.component");
var redirect_to_shop_guard_1 = require("./sitespecific/redirect.to.shop.guard");
var planthunter_overview_component_1 = require("./sitespecific/planthunter.overview.component");
var planthunter_detail_component_1 = require("./sitespecific/planthunter.detail.component");
var planthunter_amount_of_new_hunts_wrapper_component_1 = require("./sitespecific/planthunter.amount.of.new.hunts.wrapper.component");
configuration_1.initWindowObject();
var entryComponents = [
    shoppingcart_header_wrapper_component_1.ShoppingcartHeaderWrapperComponent,
    shoppingcart_header_mobile_wrapper_component_1.ShoppingcartHeaderMobileWrapperComponent,
    start_order_wrapper_component_1.StartOrderWrapperComponent,
    login_wrapper_component_1.LoginWrapperComponent,
    spotlight_products_component_1.SpotlightProductsComponent,
    pricelists_wrapper_component_1.PricelistsWrapperComponent,
    pending_orders_wrapper_component_1.PendingOrdersWrapperComponent,
    planthunter_overview_component_1.PlanthunterOverviewComponent,
    planthunter_detail_component_1.PlanthunterDetailComponent,
    planthunter_amount_of_new_hunts_wrapper_component_1.PlanthunterAmountOfNewHuntsWrapperComponent
];
var entryComponentsMapper = {
    'ShoppingcartHeaderWrapperComponent': shoppingcart_header_wrapper_component_1.ShoppingcartHeaderWrapperComponent,
    'ShoppingcartHeaderMobileWrapperComponent': shoppingcart_header_mobile_wrapper_component_1.ShoppingcartHeaderMobileWrapperComponent,
    'StartOrderWrapperComponent': start_order_wrapper_component_1.StartOrderWrapperComponent,
    'LoginWrapperComponent': login_wrapper_component_1.LoginWrapperComponent,
    'SpotlightProductsComponent': spotlight_products_component_1.SpotlightProductsComponent,
    'PricelistsWrapperComponent': pricelists_wrapper_component_1.PricelistsWrapperComponent,
    'PendingOrdersWrapperComponent': pending_orders_wrapper_component_1.PendingOrdersWrapperComponent,
    'PlanthunterOverviewComponent': planthunter_overview_component_1.PlanthunterOverviewComponent,
    'PlanthunterDetailComponent': planthunter_detail_component_1.PlanthunterDetailComponent,
    'PlanthunterAmountOfNewHuntsWrapperComponent': planthunter_amount_of_new_hunts_wrapper_component_1.PlanthunterAmountOfNewHuntsWrapperComponent
};
var appRoutes = [
    { path: '**', redirectTo: '', canActivate: [redirect_to_shop_guard_1.RedirectToShopGuard] }
];
var AppModule = (function () {
    function AppModule() {
    }
    AppModule.prototype.ngDoBootstrap = function (appRef) {
        var windowObj = window;
        var domElementsToBootstrap = windowObj.document.querySelectorAll('[data-bootstrap]');
        for (var element in domElementsToBootstrap) {
            if (domElementsToBootstrap.hasOwnProperty(element)) {
                var domElement = domElementsToBootstrap[element];
                var componentToBootstrapName = domElement.getAttribute('data-bootstrap');
                var domRootElementForBootstrapping = domElement.getAttribute('data-rootelement');
                var componentToBootstrap = entryComponentsMapper[componentToBootstrapName];
                if (componentToBootstrap) {
                    appRef.bootstrap(componentToBootstrap, domRootElementForBootstrapping);
                }
            }
        }
    };
    return AppModule;
}());
exports.AppModule = AppModule;
