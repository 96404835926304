const LogRocket = require('logrocket');
import {ErrorHandler} from '@angular/core';
import {OrderTabsComponent} from './component/order/ordertabs.component';
import {ActiveOrderService} from './service/active.order.service';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgSelectModule} from '@ng-select/ng-select';
import {LazyLoadModule} from 'ng-lazy-load';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RenderDayOfWeekComponent} from './component/order/render.day.of.week';
import {RenderDayOfWeekAbbreviationComponent} from './component/order/render.day.of.week.abbr';
import {CustomDatePipe} from './pipes/custom.date.pipe';
import {DateService} from './service/date.service';
import {Logger} from './logging/default-log.service';
import {LogService} from './logging/log.service';
import {SystemMonitorErrorHandler} from './logging/exception.handler';
import {HttpClient as NoviHttpClient} from './service/http.service';
import {DeliveryService} from './service/delivery.service';
import {UserService} from './service/user.service';
import {ApplicationService} from './service/application.service';
import {ShoppingCartService} from './service/shoppingcart.service';
import {WindowRef} from './service/window-ref.service';
import {StartOrderComponent} from './component/order/start.order.component';
import {DynamicModalDirective} from './component/modal/dynamic.modal.directive';
import {AddCommentComponent} from './component/my/order/pending/add.comment.component';
import {RenderPartOfDayComponent} from './component/order/render.part.of.day';
import {PricelistsComponent} from './component/my/pricelists/pricelists.component';
import {CartLineRemoveComponent} from './component/cart/cart.line.remove.component';
import {NumberFormatPipe} from './pipes/number.format.pipe';
import {DatePipe, registerLocaleData} from '@angular/common';
import {LoginComponent} from './component/login/login.component';
import {ForgotPasswordComponent} from './component/login/forgot.password.component';
import {ResetPasswordComponent} from './component/login/reset.password.component';
import {ResetPasswordTranslationsComponent} from './component/login/reset.password.translations.component';
import {CookiesDisabledTranslationsComponent} from './cookies.disabled.translations.component';
import {ShoppingcartHeaderComponent} from './component/cart/shoppingcart.header.component';
import {RenderCheckoutIconComponent} from './component/cart/render.checkout.icon';
import {ShoppingcartHeaderMobileComponent} from './component/cart/shoppingcart.header.mobile.component';
import {ProductItemGridComponent} from './component/products/productitem.grid.component';
import {PlanthunterOverviewGridComponent} from './component/planthunter/planthunter.overview.grid.component';
import {RenderProductImageComponent} from './component/products/render.product.image';
import {ProductSizeComponent} from './component/products/product.size';
import {FilterService} from './service/filter.service';
import {ProductService} from './service/product.service';
import {PricelistService} from './service/pricelist.service';
import {PendingOrdersComponent} from './component/my/order/pending/pending.orders.component';
import {PendingOrderRowComponent} from './component/my/order/pending/pending.order.row.component';
import {PendingOrderService} from './service/pending.order.service';
import {OrderTranslationsComponent} from './component/my/order.translations.component';
import {SalespersonTelephoneComponent} from './component/my/salesperson.telephone.component';
import {PlanthunterService} from './service/planthunter.service';
import {PlanthunterAmountOfNewHuntsComponent} from './component/planthunter/planthunter.amount.of.new.hunts.component';
import {AnalyticsService} from './service/analytics.service';
import {NotfoundComponent} from './component/notfound/notfound.component';

import localeDA from '@angular/common/locales/da';
import localeDE from '@angular/common/locales/de';
import localeEN from '@angular/common/locales/en';
import localeNL from '@angular/common/locales/nl';
import localePL from '@angular/common/locales/pl';
import localeSV from '@angular/common/locales/sv';
import localeFI from '@angular/common/locales/fi';
import localeSK from '@angular/common/locales/sk';
import localeNB from '@angular/common/locales/nb';
import localeFR from '@angular/common/locales/fr';
import {NotAuthorizedInterceptor} from './service/not-authorized-interceptor.service';

// Needed for the localization of the CustomDatePipe.
// See https://angular.io/api/common/registerLocaleData
// for more information.
registerLocaleData(localeDA);
registerLocaleData(localeDE);
registerLocaleData(localeEN);
registerLocaleData(localeNL);
registerLocaleData(localePL);
registerLocaleData(localeSV);
registerLocaleData(localeFI);
registerLocaleData(localeSK);
registerLocaleData(localeNB);
registerLocaleData(localeFR);

/**
 * Export the initialsation of the Window Variables as a function.
 * Used to register global variables that are used in both the site and shop.
 */
export function initWindowObject() {
	// Expose as global var for both the shop and the site
	(<any>window).LogRocket = LogRocket;

	/**
	 * Check if localStorage is available for the user. Try by placing and removing something in localStorage
	 * If this does not work we return undefined. If it does we set the localStorage as a window.storage variable.
	 * @return {localStorage}
	 */
	(<any>window).storage = (function () {
		try {
			const uid = new Date;
			let storage;
			let result;
			(storage = (<any>window).localStorage).setItem(uid, uid);
			// This needs to stay falsey (== instead of ===) otherwise the code doesn't correctly check
			// the storage value. The following line disables tslint for that line.
			// tslint:disable-next-line
			result = storage.getItem(uid) == uid;
			storage.removeItem(uid);
			return result && storage;
		} catch (exception) {
		}
	}());
}

export const genericImports = [
	BrowserModule,
	HttpClientModule,
	NgSelectModule,
	FormsModule,
	ReactiveFormsModule,
	BrowserAnimationsModule,
	LazyLoadModule,
	ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'})
];

export const genericDeclarations = [
	CustomDatePipe,
	NumberFormatPipe,
	RenderDayOfWeekComponent,
	RenderDayOfWeekAbbreviationComponent,
	OrderTabsComponent,
	StartOrderComponent,
	AddCommentComponent,
	DynamicModalDirective,
	RenderPartOfDayComponent,
	ShoppingcartHeaderComponent,
	ShoppingcartHeaderMobileComponent,
	RenderCheckoutIconComponent,
	CartLineRemoveComponent,
	LoginComponent,
	ForgotPasswordComponent,
	ResetPasswordComponent,
	ResetPasswordTranslationsComponent,
	CookiesDisabledTranslationsComponent,
	ProductItemGridComponent,
	PlanthunterOverviewGridComponent,
	RenderProductImageComponent,
	ProductSizeComponent,
	PricelistsComponent,
	PendingOrdersComponent,
	PendingOrderRowComponent,
	OrderTranslationsComponent,
	SalespersonTelephoneComponent,
	PlanthunterAmountOfNewHuntsComponent,
	NotfoundComponent
];

export const genericProviders = [
	DatePipe,
	DateService,
	NoviHttpClient,
	ActiveOrderService,
	DeliveryService,
	UserService,
	ApplicationService,
	ShoppingCartService,
	FilterService,
	ProductService,
	PricelistService,
	PendingOrderService,
	PlanthunterService,
	AnalyticsService,
	WindowRef,

	{provide: HTTP_INTERCEPTORS, useClass: NotAuthorizedInterceptor, multi: true},
	{provide: Logger, useClass: LogService},
	{provide: ErrorHandler, useClass: SystemMonitorErrorHandler}
];
