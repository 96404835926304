<ng-container *ngIf="isUserLoggedin()">

	<div [ngClass]="{loading: loading}">
		<div class="loading-overlay"></div>
		<section *ngFor="let planthunter of planthunters">

			<div id="ID{{planthunter.planthunterFeature}}" class="planthunter__header">

				<div class="planthunter__hunter planthunter__hunter--summary">

					<figure class="planthunter__hunter__figure">
						<img src="{{planthunter.imageUrl}}" alt="{{planthunter.name}}" />
					</figure>

					<div class="planthunter__hunter__text">
						<h2 class="planthunter__hunter__name">
							<a href="{{getPlantHunterDetailPageURL(planthunter)}}">{{planthunter.name}}</a>
						</h2>

						<p class="planthunter__hunter__function">{{planthunter.functionDescription}}</p>

					<div class="planthunter__hunter__follow hide-lg" *ngIf="!planthunter.volgStatus">
						<button class="button button--small button--secondary button--text" (click)="followPlanthunter(planthunter)"
						        i18n="Planthunters start following@@planthunter.start.following">
							Follow {{planthunter.firstName}}
						</button>
					</div>

					<div class="planthunter__hunter__follow hide-lg" *ngIf="planthunter.volgStatus">
						<button class="button button--small button--green" (click)="unfollowPlanthunter(planthunter)">
							<ng-container i18n="Planthunter following link@@planthunter.following.link">
								Following
							</ng-container>
							&nbsp;
							<svg class="icon-check" width="14" height="12">
								<use xlink:href="/images/icons/svg-sprite.svg#check"></use>
							</svg>
						</button>
					</div>
				</div>

				<div class="planthunter__hunter__follow hide-sm" *ngIf="!planthunter.volgStatus">
					<button class="button button--small button--secondary button--text" (click)="followPlanthunter(planthunter)"
					        i18n="Planthunters start following@@planthunter.start.following">
						Follow {{planthunter.firstName}}
					</button>
				</div>

				<div class="planthunter__hunter__follow hide-sm" *ngIf="planthunter.volgStatus">
					<button class="button button--small button--green" (click)="unfollowPlanthunter(planthunter)">
						<ng-container i18n="Planthunter following link@@planthunter.following.link">
							Following
						</ng-container>
						&nbsp;
						<svg class="icon-check" width="14" height="12">
							<use xlink:href="/images/icons/svg-sprite.svg#check"></use>
						</svg>
					</button>
				</div>
			</div>
		</div>

			<planthunter-overview-grid-component
					[planthunter]="planthunter">
			</planthunter-overview-grid-component>

		</section>
	</div>
</ng-container>
