"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var user_service_1 = require("../generic/service/user.service");
var product_service_1 = require("../generic/service/product.service");
var planthunter_service_1 = require("../generic/service/planthunter.service");
var window_ref_service_1 = require("../generic/service/window-ref.service");
var PlanthunterOverviewComponent = (function () {
    function PlanthunterOverviewComponent(planthunterService, productSerivce, userService) {
        var _this = this;
        this.planthunterService = planthunterService;
        this.productSerivce = productSerivce;
        this.loading = true;
        var anchorHash = PlanthunterOverviewComponent.winRef.nativeWindow.location.hash;
        this._userService = userService;
        this.planthunterDetailURL = PlanthunterOverviewComponent.winRef.nativeWindow.planthunterDetailURL;
        this.planthunterService.getPlanthunters().then(function (planthunter) {
            _this._planthunters = planthunter;
            _this.loading = false;
            setTimeout(function () {
                _this.productSerivce.triggerScrollToProductOnWebsite(anchorHash);
            }, 700);
        });
    }
    Object.defineProperty(PlanthunterOverviewComponent.prototype, "planthunters", {
        get: function () {
            return this._planthunters;
        },
        enumerable: true,
        configurable: true
    });
    PlanthunterOverviewComponent.prototype.isUserLoggedin = function () {
        return this._userService.isLoggedIn();
    };
    PlanthunterOverviewComponent.prototype.getPlantHunterDetailPageURL = function (planthunter) {
        return this.planthunterDetailURL + "?plantHunterId=" + planthunter.planthunterFeature;
    };
    PlanthunterOverviewComponent.prototype.followPlanthunter = function (planthunter) {
        this.planthunterService.followPlanthunter(planthunter.code).then(function () { return planthunter.volgStatus = true; });
    };
    PlanthunterOverviewComponent.prototype.unfollowPlanthunter = function (planthunter) {
        this.planthunterService.unfollowPlanthunter(planthunter.code).then(function () { return planthunter.volgStatus = false; });
    };
    PlanthunterOverviewComponent.winRef = new window_ref_service_1.WindowRef();
    return PlanthunterOverviewComponent;
}());
exports.PlanthunterOverviewComponent = PlanthunterOverviewComponent;
