"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PasswordValidation = (function () {
    function PasswordValidation() {
    }
    PasswordValidation.MatchPassword = function (abstractControl) {
        var password = abstractControl.get('password').value;
        var confirmPassword = abstractControl.get('confirmpassword').value;
        if (password !== confirmPassword) {
            abstractControl.get('confirmpassword').setErrors({ MatchPassword: true });
            return false;
        }
        return true;
    };
    return PasswordValidation;
}());
exports.PasswordValidation = PasswordValidation;
