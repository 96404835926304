import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ResetPasswordTranslationsComponent} from './reset.password.translations.component';
import {PasswordValidation} from '../../classes/password.validation';
import {ApplicationService} from '../../service/application.service';
import {UserService} from '../../service/user.service';
import {Logger} from '../../logging/default-log.service';
import {Message} from '../../classes/message';
import {Administration} from '../../classes/administration';

@Component({
    selector: 'reset-password-component',
    templateUrl: 'reset.password.component.html'
})
export class ResetPasswordComponent implements OnInit {
    @ViewChild(ResetPasswordTranslationsComponent) resetPasswordTranslationsComponent: ResetPasswordTranslationsComponent;

    resetPasswordForm: FormGroup;
    passwordFieldType: string = 'password';
    resultUID: string;
    formError: string;
    successRedirect: string = '/products';

    constructor(public applicationService: ApplicationService,
                private route: ActivatedRoute,
                private userService: UserService,
                private formBuilder: FormBuilder,
                private logger: Logger) {

        this.resetPasswordForm = formBuilder.group({
            'password': [null, Validators.required],
            'confirmpassword': [null, Validators.required]
        }, {
            validator: PasswordValidation.MatchPassword
        });
    }

    ngOnInit() {
        if (this.route.snapshot.queryParams['id']) {
            this.resultUID = this.route.snapshot.queryParams['id'];
        }
    }

    onSubmit(form: any) {
        if (!this.resetPasswordForm.valid && !this.resultUID) {
            return;
        }

        this.userService.changePassword(form.confirmpassword, this.resultUID).then(result => {
            if (result.returnCode) {
                this.showMessage(new Message(this.resetPasswordTranslationsComponent.getResetPasswordSuccess(), 'confirmation', false));

                // If succesful then call the login request with the new credentials.
                this.loginUserAfterSuccessfulChange(result.loginID, form.confirmpassword);

                // Empty the form fields just in case
                this.resetPasswordForm.reset();

                return;
            }

            this.showMessage(new Message(this.resetPasswordTranslationsComponent.getResetLinkExpired(), 'error', false));
        });
    }

    /**
     * Uses the newly set credentials to automatically login the user.
     *
     * @param username
     * @param password
     */
    loginUserAfterSuccessfulChange(username: string, password: string) {
        this.userService.login(username, password).subscribe((result: Administration) => {
            if (result.administrationUrl) {
                window.location.href = result.administrationUrl + '?token=' + result.token;
            } else {
                window.location.href = this.successRedirect;
            }
        }, error => {
            this.logger.debug('Failed to login', error);
        });
    }

    /**
     * Toggle show/hide password
     */
    togglePassword() {
        this.passwordFieldType = (this.passwordFieldType === 'password') ? 'text' : 'password';
    }

    private showMessage(message: Message) {
        this.applicationService.message = message;
    }
}
