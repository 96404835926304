"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var default_log_service_1 = require("../generic/logging/default-log.service");
var product_service_1 = require("../generic/service/product.service");
var user_service_1 = require("../generic/service/user.service");
var shop_helper_1 = require("./shop.helper");
var filter_service_1 = require("../generic/service/filter.service");
var filter_state_1 = require("../../../../../shared-resources/noviflora-common-angular/generic/classes/filter.state");
var SpotlightProductsComponent = (function () {
    function SpotlightProductsComponent(productService, domElement, filterService, router, userService, logger) {
        this.productService = productService;
        this.logger = logger;
        this.loading = true;
        this.windowObj = window;
        this._userService = userService;
        var nativeDomElement = domElement.nativeElement;
        var filterQueryString = nativeDomElement.getAttribute('data-filterparams');
        var showViewAllProductsButton = nativeDomElement.getAttribute('data-showviewallproductsbutton');
        this.title = nativeDomElement.getAttribute('data-title');
        this.description = nativeDomElement.getAttribute('data-description');
        this.origin = nativeDomElement.getAttribute('data-origin');
        filterQueryString = this.addQueryParamIfNotExists(filterQueryString, 'mode', 'all');
        filterQueryString = this.addQueryParamIfNotExists(filterQueryString, 'sorting', 'random');
        if (showViewAllProductsButton === 'true') {
            this.showAllUrl = this.getShowAllProductsUrl(filterQueryString);
        }
        var filterState = new filter_state_1.FilterState([]);
        filterService.initFilterStateForRequestParams(filterState, router.parseUrl(filterQueryString).queryParams);
        filterService.initSelectedLanguage(filterState);
        this.loadProducts(filterState);
    }
    Object.defineProperty(SpotlightProductsComponent.prototype, "userService", {
        get: function () {
            return this._userService;
        },
        enumerable: true,
        configurable: true
    });
    SpotlightProductsComponent.prototype.ngOnDestroy = function () {
        if (this.selectedOrderChangedSubscription) {
            this.selectedOrderChangedSubscription.unsubscribe();
        }
    };
    SpotlightProductsComponent.prototype.viewProductDetail = function (detailUrl) {
        detailUrl += ";origin=" + this.origin;
        shop_helper_1.ShopHelper.goToWebshopUrl(detailUrl);
    };
    SpotlightProductsComponent.prototype.loadProducts = function (filterState) {
        var _this = this;
        this.productService.getProducts(filterState.getParams()).then(function (productPage) {
            _this.productPage = productPage;
            _this.loading = false;
            setTimeout(function () {
                var anchorHash = _this.windowObj.location.hash;
                _this.productService.triggerScrollToProductOnWebsite(anchorHash);
            }, 700);
        }).catch(function (error) {
            _this.loading = false;
            _this.logger.error('Error loading the products from the server', error);
        });
    };
    SpotlightProductsComponent.prototype.getShowAllProductsUrl = function (queryString) {
        queryString = this.removeURLParameter(queryString, 'sorting');
        queryString = this.removeURLParameter(queryString, 'pageSize');
        return shop_helper_1.ShopHelper.getWebshopUrl('products?' + queryString.split('?')[1]);
    };
    SpotlightProductsComponent.prototype.removeURLParameter = function (url, parameter) {
        var urlparts = url.split('?');
        if (urlparts.length >= 2) {
            var prefix = encodeURIComponent(parameter) + '=';
            var pars = urlparts[1].split(/[&;]/g);
            for (var i = pars.length; i-- > 0;) {
                if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                    pars.splice(i, 1);
                }
            }
            return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : "");
        }
        return url;
    };
    SpotlightProductsComponent.prototype.addQueryParamIfNotExists = function (queryString, paramName, paramValue) {
        if (queryString.indexOf(paramName) === -1) {
            queryString += queryString.indexOf('?') === -1 ? '?' : '&';
            queryString += paramName + "=" + paramValue;
        }
        return queryString;
    };
    return SpotlightProductsComponent;
}());
exports.SpotlightProductsComponent = SpotlightProductsComponent;
