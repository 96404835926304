"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../generic/component/my/pricelists/pricelists.component.ngfactory");
var i2 = require("../generic/component/my/pricelists/pricelists.component");
var i3 = require("../generic/service/user.service");
var i4 = require("../generic/service/pricelist.service");
var i5 = require("../generic/service/date.service");
var i6 = require("../generic/service/filter.service");
var i7 = require("../generic/logging/default-log.service");
var i8 = require("./pricelists.wrapper.component");
var i9 = require("./redirect.to.shop.service");
var styles_PricelistsWrapperComponent = [];
var RenderType_PricelistsWrapperComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PricelistsWrapperComponent, data: {} });
exports.RenderType_PricelistsWrapperComponent = RenderType_PricelistsWrapperComponent;
function View_PricelistsWrapperComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "my-pricelists", [], null, null, null, i1.View_PricelistsComponent_0, i1.RenderType_PricelistsComponent)), i0.ɵdid(1, 49152, null, 0, i2.PricelistsComponent, [i3.UserService, i4.PricelistService, i5.DateService, i6.FilterService, i7.Logger], null, null)], null, null); }
exports.View_PricelistsWrapperComponent_0 = View_PricelistsWrapperComponent_0;
function View_PricelistsWrapperComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "pricelists-wrapper-component", [], null, null, null, View_PricelistsWrapperComponent_0, RenderType_PricelistsWrapperComponent)), i0.ɵdid(1, 49152, null, 0, i8.PricelistsWrapperComponent, [i9.RedirectToShopService], null, null)], null, null); }
exports.View_PricelistsWrapperComponent_Host_0 = View_PricelistsWrapperComponent_Host_0;
var PricelistsWrapperComponentNgFactory = i0.ɵccf("pricelists-wrapper-component", i8.PricelistsWrapperComponent, View_PricelistsWrapperComponent_Host_0, {}, {}, []);
exports.PricelistsWrapperComponentNgFactory = PricelistsWrapperComponentNgFactory;
