"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var application_service_1 = require("../../service/application.service");
var active_order_service_1 = require("../../service/active.order.service");
var OrderTabsComponent = (function () {
    function OrderTabsComponent(applicationService, activeOrderService) {
        var _this = this;
        this.applicationService = applicationService;
        this.activeOrderService = activeOrderService;
        this.allProductsValue = 'allProducts';
        this.changeOrderEvent = new core_1.EventEmitter();
        this.clickedAllProductsEvent = new core_1.EventEmitter();
        this._selectedOrder = this.allProductsValue;
        this.setSelectStateForActiveOrder(activeOrderService.selectedOrder);
        this.selectedOrderChangedSubscription = activeOrderService.selectedOrderChangedSubject.subscribe(function (newActiveOrder) {
            _this.setSelectStateForActiveOrder(newActiveOrder);
        });
    }
    Object.defineProperty(OrderTabsComponent.prototype, "selectedOrder", {
        get: function () {
            return this._selectedOrder;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderTabsComponent.prototype, "activeOrders", {
        get: function () {
            return this.activeOrderService.activeOrders;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderTabsComponent.prototype, "firstActiveOrder", {
        get: function () {
            return this.activeOrders && this.activeOrders.length > 0 ? this.activeOrders.values().next().value : null;
        },
        enumerable: true,
        configurable: true
    });
    OrderTabsComponent.prototype.ngOnDestroy = function () {
        this.selectedOrderChangedSubscription.unsubscribe();
    };
    OrderTabsComponent.prototype.showOrderSwitcher = function () {
        return this.selectedOrder !== this.allProductsValue;
    };
    OrderTabsComponent.prototype.unSelectOrder = function () {
        this.activeOrderService.setSelectedOrder(null);
        this.changeOrderEvent.emit();
        this.clickedAllProductsEvent.emit();
    };
    OrderTabsComponent.prototype.startNewOrder = function () {
        this.applicationService.openPopup(this.applicationService.closePopup);
    };
    OrderTabsComponent.prototype.changeOrder = function (activeOrder) {
        if (activeOrder === 'new') {
            this.applicationService.openPopupForNew(this.applicationService.closePopup);
            return;
        }
        if (activeOrder === this.allProductsValue) {
            this.unSelectOrder();
            return;
        }
        this.activeOrderService.setSelectedOrder(activeOrder);
        this.changeOrderEvent.emit();
    };
    OrderTabsComponent.prototype.trackByOrder = function (index, order) {
        return order.shipmentDate;
    };
    OrderTabsComponent.prototype.setSelectStateForActiveOrder = function (newActiveOrder) {
        this._selectedOrder = newActiveOrder != null ? newActiveOrder : this.allProductsValue;
    };
    return OrderTabsComponent;
}());
exports.OrderTabsComponent = OrderTabsComponent;
