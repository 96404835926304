"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ActiveOrder = (function () {
    function ActiveOrder() {
    }
    Object.defineProperty(ActiveOrder.prototype, "id", {
        get: function () {
            return ActiveOrder.getId(this.shipmentDate, this.shipToCode);
        },
        enumerable: true,
        configurable: true
    });
    ActiveOrder.getId = function (shipmentDate, shipToCode) {
        return shipmentDate.toString() + '-' + shipToCode.toString();
    };
    return ActiveOrder;
}());
exports.ActiveOrder = ActiveOrder;
