"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ProductPrices = (function () {
    function ProductPrices(product, itemsInOrder) {
        this.product = product;
        this.itemsInOrder = itemsInOrder;
        this.TYPE_STOCK = 1;
    }
    ProductPrices.prototype.getOriginalPriceForProductType = function () {
        if (!this.product) {
            return 0;
        }
        if (this.showUnitOption() || this.showTrayOption()) {
            return this.product.unitPrice;
        }
        if (this.showLayerOption()) {
            return this.product.layerPrice;
        }
        if (this.showTrolleyOption()) {
            return this.product.trolleyPrice;
        }
        return this.product.unitPrice;
    };
    ProductPrices.prototype.getCheapestAvailablePrice = function () {
        if (!this.product) {
            return 0;
        }
        var pricesArray = [];
        if (this.showUnitOption() || this.showTrayOption()) {
            pricesArray.push(this.getUnitOrTrayNormalOrDiscountPrice());
        }
        if (this.showLayerOption()) {
            pricesArray.push(this.getLayerNormalOrDiscountPrice());
        }
        if (this.showTrolleyOption()) {
            pricesArray.push(this.getTrolleyNormalOrDiscountPrice());
        }
        if (pricesArray.length > 0) {
            return Math.min.apply(Math, pricesArray);
        }
        return this.getNormalOrDiscountPrice(this.product.saleDiscount, this.product.unitPrice, this.product.unitDiscountPrice);
    };
    ProductPrices.prototype.getUnitOrTrayNormalOrDiscountPrice = function () {
        return this.getNormalOrDiscountPrice(this.product.saleDiscount, this.product.unitPrice, this.product.unitDiscountPrice);
    };
    ProductPrices.prototype.getLayerNormalOrDiscountPrice = function () {
        return this.getNormalOrDiscountPrice(this.product.saleDiscount, this.product.layerPrice, this.product.layerDiscountPrice);
    };
    ProductPrices.prototype.getTrolleyNormalOrDiscountPrice = function () {
        return this.getNormalOrDiscountPrice(this.product.saleDiscount, this.product.trolleyPrice, this.product.trolleyDiscountPrice);
    };
    ProductPrices.prototype.getNormalOrDiscountPrice = function (saleDiscount, itemPrice, itemDiscountPrice) {
        if (saleDiscount > 0) {
            return itemDiscountPrice;
        }
        return itemPrice;
    };
    ProductPrices.prototype.showUnitOption = function () {
        return this.showBoxOption() && this.product.qtyByBox === 1;
    };
    ProductPrices.prototype.showTrayOption = function () {
        return this.showBoxOption() && this.product.qtyByBox > 1;
    };
    ProductPrices.prototype.showLayerOption = function () {
        if (this.isStockProduct()) {
            return this.getAvailableQuantity() >= (this.product.qtyByBox * this.product.qtyPackingsByLayer);
        }
        return true;
    };
    ProductPrices.prototype.showTrolleyOption = function () {
        if (this.isStockProduct()) {
            return this.getAvailableQuantity() >= this.product.qtyByTrolley;
        }
        return true;
    };
    ProductPrices.prototype.showBoxOption = function () {
        if (this.isStockProduct()) {
            return this.getAvailableQuantity() >= this.product.qtyByBox;
        }
        return true;
    };
    ProductPrices.prototype.isStockProduct = function () {
        return this.product.type === this.TYPE_STOCK;
    };
    ProductPrices.prototype.getAvailableQuantity = function () {
        var available = this.product.availableQuantity - this.itemsInOrder;
        return (available < 0) ? 0 : available;
    };
    return ProductPrices;
}());
exports.ProductPrices = ProductPrices;
