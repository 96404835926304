import {Component, EventEmitter, HostListener, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {animate, keyframes, state, style, transition, trigger, } from '@angular/animations';
import {ShoppingCartLine} from '../../classes/shoppingcartline';
import {Subscription} from 'rxjs';
import {ShoppingCartService} from '../../service/shoppingcart.service';
import {Logger} from '../../logging/default-log.service';
import {UserService} from '../../service/user.service';

@Component({
	selector: 'shoppingcart-header-component',
	templateUrl: 'shoppingcart.header.component.html'
})
export class ShoppingcartHeaderComponent implements OnDestroy {
	public showShoppingCartOverview: boolean = false;
	@Input() showShoppingCart: boolean = true;
	shoppingCartUpdated: boolean = false;

	@ViewChild('shoppingCart') shoppingCartElement;
	@ViewChild('shoppingCartButton') shoppingCartButton;
	@Output() viewPendingOrdersClickEvent = new EventEmitter();
	@Output() viewOrderDetailClickEvent = new EventEmitter();
	@Output() viewShoppingcartClickEvent = new EventEmitter();
	private shoppingcartUpdatedSubscription: Subscription;

	constructor(private shoppingCartService: ShoppingCartService,
				private userService: UserService,
				private logger: Logger) {

		this.shoppingcartUpdatedSubscription = this.shoppingCartService.getShoppingCartObservable().subscribe(
			() => this.onShoppingcartUpdated(),
			error => this.logger.error('Failed to subscribe to shoppingcart', error)
		);
	}

	get shoppingCartLines(): Array<ShoppingCartLine> {
		return this.shoppingCartService.activeShoppingCartLines;
	}

	isFastCheckout(): boolean {
		return this.userService.isUseFastCheckout();
	}

	getUserCartWaitTimeInSeconds(): number {
		return this.userService.getUserCartWaitTimeInSeconds();
	}

	ngOnDestroy(): void {
		this.shoppingcartUpdatedSubscription.unsubscribe();
	}

	viewOrderDetail() {
		this.showShoppingCartOverview = false;
		this.viewOrderDetailClickEvent.emit();
	}

	viewShoppingcart() {
		this.showShoppingCartOverview = false;
		this.viewShoppingcartClickEvent.emit();
	}

	viewPendingOrders() {
		this.showShoppingCartOverview = false;
		this.viewPendingOrdersClickEvent.emit();
	}

	/**
	 * Handles document on click events for the 'quick view' shopping cart.
	 * @param event
	 */
	@HostListener('document:click', ['$event'])
	handleClick(event) {
		this.closeShoppingCartIfNeeded(event);
	}

	/**
	 * Handles document on touch events for the 'quick view' shopping cart.
	 * Needed for iOS, otherwise shoppingcard won't close on the iPad
	 * @param event
	 */
	@HostListener('document:touchstart', ['$event'])
	handleTouchstart(event) {
		this.closeShoppingCartIfNeeded(event);
	}

	/**
	 * Gets the number of items in the shopping cart.
	 *
	 * @returns {number}
	 */
	getShoppingCartCount(): number {
		let size = 0;

		if (this.shoppingCartLines) {
			size = this.shoppingCartLines.length;
		}

		return size;
	}

	/**
	 * Check if a shopping cart line is still editable (delete)
	 *
	 * @param shoppingCartLine
	 * @returns {boolean}
	 */
	isEditable(shoppingCartLine: ShoppingCartLine): boolean {
		let editable = false;

		if (shoppingCartLine.shoppingCartMode) {
			editable = (!shoppingCartLine.confirmed);
		} else {
			editable = this.shoppingCartService.isShoppingCartLineEditable(shoppingCartLine);
		}

		return editable;
	}

	removeProduct(shoppingCartLine: ShoppingCartLine) {
		if (this.isEditable(shoppingCartLine)) {
			this.shoppingCartService.removeProduct(shoppingCartLine);
		} else {
			this.logger.error('Error: trying to delete shopping cart line which is not editable anymore');
		}
	}

	getExpiryDate(shoppingCartLine: ShoppingCartLine) {
		return this.shoppingCartService.getShoppingCartLineExpiryDate(shoppingCartLine);
	}

	/**
	 * Gets the total volume of the shopping cart lines, with two decimals
	 *
	 * @returns {number}
	 */
	getVolume(): number {
		let total = 0;
		if (this.shoppingCartLines) {
			this.shoppingCartLines.forEach(s => total += s.volume);
		}

		return total;
	}

	getQuantity(shoppingCartLine: ShoppingCartLine): number {
		const quantity = (shoppingCartLine.quantity / shoppingCartLine.qtyByBox);
		return Math.round(quantity);
	}

	getPopupItems(): Array<ShoppingCartLine> {
		if (this.shoppingCartLines) {
			return this.shoppingCartLines.slice(0, 3);
		}
		return this.shoppingCartLines;
	}

	/**
	 * Recursive function to check if a child element of the given parent was clicked
	 * @param clickedComponent
	 * @param parentComponent
	 * @returns {boolean}
	 */
	checkIfChildElementClicked(clickedComponent, parentComponent): boolean {
		let childClicked = false;
		do {
			if (clickedComponent === parentComponent) {
				childClicked = true;
			}
			clickedComponent = clickedComponent.parentNode;
		} while (clickedComponent);

		return childClicked;
	}

	/**
	 * Closes the shoppingcart if click or touch event is outside the shoppingcart
	 * */
	private closeShoppingCartIfNeeded(event) {
		if (this.shoppingCartElement) {
			const clickedComponent = event.target;

			// Check if the show/hide button was clicked
			const buttonClicked = this.checkIfChildElementClicked(clickedComponent, this.shoppingCartButton.nativeElement);

			if (!buttonClicked) {
				// Check for outside click if the button was not clicked
				const insideClicked = this.checkIfChildElementClicked(clickedComponent, this.shoppingCartElement.nativeElement);

				if (!insideClicked) {
					this.showShoppingCartOverview = false;
				}
			}
		}
	}

	private onShoppingcartUpdated() {
		if (!this.shoppingCartUpdated) {
			this.shoppingCartUpdated = true;
			setTimeout(() => {
				this.shoppingCartUpdated = false;
			}, 600);
		}
	}

}
