// Define the interface that all loggers must implement.
export interface ILogger {
    trace(message: string, error?: any): void;
    debug(message: string, error?: any): void;
    warn(message: string, error?: any): void;
    error(message: string, error?: any): void;
    info(message: string, error?: any): void;
}

// Set up the default logger. The default logger doesn't actually log anything; but, it
// provides the Dependency-Injection (DI) token that the rest of the application can use
// for dependency resolution. Each platform can then override this with a platform-
// specific logger implementation, like the LogService.
export class Logger implements ILogger {
    trace(message: string, error?: any): void {
        // ... the default logger does no work.
    }

    debug(message: string, error?: any): void {
        // ... the default logger does no work.
    }

    warn(message: string, error?: any): void {
        // ... the default logger does no work.
    }

    error(message: string, error?: any): void {
        // ... the default logger does no work.
    }

    info(message: string, error?: any): void {
        // ... the default logger does no work.
    }
}
