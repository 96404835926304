"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var user_service_1 = require("../../../service/user.service");
var pricelist_service_1 = require("../../../service/pricelist.service");
var default_log_service_1 = require("../../../logging/default-log.service");
var filter_service_1 = require("../../../service/filter.service");
var date_service_1 = require("../../../service/date.service");
var PricelistsComponent = (function () {
    function PricelistsComponent(userService, pricelistService, dateService, filterService, logger) {
        var _this = this;
        this.userService = userService;
        this.pricelistService = pricelistService;
        this.dateService = dateService;
        this.filterService = filterService;
        this.logger = logger;
        this.noPricelistsAvailable = false;
        this.pricelistService.getPricelists(filterService.getFilterState().shipmentDate).subscribe(function (pricelists) { return _this.setPricelists(pricelists); }, function (error) { return _this.logger.error('Failed to get price lists', error); });
    }
    PricelistsComponent.prototype.getUser = function () {
        return this.userService.getUser();
    };
    PricelistsComponent.prototype.getAvailableDays = function (priceList) {
        return this.dateService.getDaysBetween(new Date(), priceList.validUntil) + 1;
    };
    PricelistsComponent.prototype.setPricelists = function (pricelists) {
        this.pricelists = pricelists;
        this.noPricelistsAvailable = pricelists.length === 0;
    };
    return PricelistsComponent;
}());
exports.PricelistsComponent = PricelistsComponent;
