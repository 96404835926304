import {ConfigurationHelper} from '../generic/helper/configuration.helper';

export class ShopHelper {

    static goToWebshopUrl(url: string) {
        window.location.href = this.getWebshopUrl(url);
    }

    static getWebshopUrl(url: string): string {
        return ConfigurationHelper.getWebshopUrl(url);
    }

}