
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Pricelist} from '../classes/pricelist';
import {Logger} from '../logging/default-log.service';
import {BaseService} from './base.service';
import {HttpClient} from './http.service';
import {ConfigurationHelper} from '../helper/configuration.helper';

/**
 * Pricelist service
 *
 * @author hillebrand
 */
@Injectable()
export class PricelistService extends BaseService {

    constructor(private http: HttpClient, logger: Logger) {
        super(logger);
    }

    getPricelists(shipmentDate: Date): Observable<Pricelist[]> {
        let url = ConfigurationHelper.getWebshopUrl('/rest/pricelists');
        if (shipmentDate != null) {
            url += `?shipmentDate=${shipmentDate}`;
        }
        return this.http.get<Pricelist[]>(url);
    }
}
