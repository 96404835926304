"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var default_log_service_1 = require("../../../../logging/default-log.service");
var pending_order_service_1 = require("../../../../service/pending.order.service");
var order_translations_component_1 = require("../../order.translations.component");
var application_service_1 = require("../../../../service/application.service");
var PendingOrdersComponent = (function () {
    function PendingOrdersComponent(pendingOrderService, applicationService, router, logger) {
        var _this = this;
        this.pendingOrderService = pendingOrderService;
        this.applicationService = applicationService;
        this.router = router;
        this.logger = logger;
        this.startNewOrderClickEvent = new core_1.EventEmitter();
        this.noPendingOrders = false;
        this.pendingOrderService.getPendingOrders().subscribe(function (orders) { return _this.setPendingOrders(orders); }, function (error) { return _this.logger.error('Failed to get pending orders', error); });
    }
    PendingOrdersComponent.prototype.startNewOrder = function () {
        var _this = this;
        this.startNewOrderClickEvent.emit();
        var self = this;
        this.applicationService.openPopup(function () {
            self.router.navigate(['/products']);
            _this.applicationService.closePopup();
        });
    };
    PendingOrdersComponent.prototype.setPendingOrders = function (pendingOrders) {
        this.pendingOrders = pendingOrders;
        this.noPendingOrders = pendingOrders.length === 0;
    };
    return PendingOrdersComponent;
}());
exports.PendingOrdersComponent = PendingOrdersComponent;
