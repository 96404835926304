"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var base_service_1 = require("./base.service");
var filter_state_1 = require("../classes/filter.state");
var active_order_service_1 = require("./active.order.service");
var default_log_service_1 = require("../logging/default-log.service");
var user_service_1 = require("./user.service");
var configuration_helper_1 = require("../helper/configuration.helper");
var FilterService = (function (_super) {
    __extends(FilterService, _super);
    function FilterService(activeOrderService, userService, logger) {
        var _this = _super.call(this, logger) || this;
        _this.activeOrderService = activeOrderService;
        _this.userService = userService;
        _this.filterStateChanged = new rxjs_1.Subject();
        _this.PRODUCTS_ACTIVE_SORT_FIELD_KEY = 'current_user_products_active_sort_field';
        _this.PRODUCTS_DEFAULT_SORT = 'totalDescription1|ASC';
        _this.filterState = _this.getFilterState();
        _this.initSelectedLanguage(_this.filterState);
        return _this;
    }
    FilterService.prototype.getFilterState = function () {
        if (!this.filterState) {
            this.filterState = new filter_state_1.FilterState([]);
            this.filterState.page = 1;
        }
        if (!this.filterState.languageCode) {
            this.filterState.languageCode = this.userService.getUserDefaultLanguage();
        }
        this.filterState.type = this.getAssortmentType();
        var activeOrder = this.activeOrderService.selectedOrder;
        this.filterState.shipmentDate = activeOrder ? activeOrder.shipmentDate : null;
        return this.filterState;
    };
    FilterService.prototype.toggleFeature = function (featureId) {
        var index = this.getFilterState().features.indexOf(featureId);
        if (index === -1) {
            this.filterState.features.push(featureId);
        }
        else if (index > -1) {
            this.filterState.features.splice(index, 1);
        }
        this.notifyFilterStateChange(this.filterState);
    };
    FilterService.prototype.notifyFilterStateChange = function (filterState, resetPage) {
        if (resetPage === void 0) { resetPage = true; }
        if (resetPage) {
            filterState.page = 1;
        }
        this.filterStateChanged.next(filterState);
    };
    FilterService.prototype.changePage = function (page) {
        this.getFilterState().page = page;
        this.notifyFilterStateChange(this.filterState, false);
    };
    FilterService.prototype.isFeatureSelected = function (featureId) {
        return (this.getFilterState().features.indexOf(featureId) > -1);
    };
    FilterService.prototype.setSearchText = function (searchText) {
        this.getFilterState().searchText = searchText;
        this.notifyFilterStateChange(this.filterState);
    };
    FilterService.prototype.setPotSize = function (potSizeFrom, potSizeTo) {
        var filterState = this.getFilterState();
        filterState.potSizeFrom = potSizeFrom;
        filterState.potSizeTo = potSizeTo;
        this.notifyFilterStateChange(this.filterState);
    };
    FilterService.prototype.setPrice = function (priceFrom, priceTo) {
        var filterState = this.getFilterState();
        filterState.priceFrom = priceFrom;
        filterState.priceTo = priceTo;
        this.notifyFilterStateChange(this.filterState);
    };
    FilterService.prototype.setClearFeatureFilter = function (clearFeatureFilter) {
        this.getFilterState().clearFeatureFilter = clearFeatureFilter;
        this.notifyFilterStateChange(this.filterState);
    };
    FilterService.prototype.setOrderHistoryPeriod = function (notifyFilterStateChange, orderHistoryDateFrom, orderHistoryDateTo) {
        var filterState = this.getFilterState();
        filterState.orderHistoryDateFrom = orderHistoryDateFrom;
        filterState.orderHistoryDateTo = orderHistoryDateTo;
        if (notifyFilterStateChange) {
            this.notifyFilterStateChange(this.filterState);
        }
    };
    FilterService.prototype.setHeight = function (heightFrom, heightTo) {
        var filterState = this.getFilterState();
        filterState.heightFrom = heightFrom;
        filterState.heightTo = heightTo;
        this.notifyFilterStateChange(this.filterState);
    };
    FilterService.prototype.setShipmentDate = function (shipmentDate) {
        this.getFilterState().updateShipmentDates(shipmentDate);
        this.notifyFilterStateChange(this.filterState);
    };
    FilterService.prototype.getParamValue = function (params, key) {
        var value = params[key];
        if (!value || value.length < 1) {
            return null;
        }
        return decodeURIComponent(value);
    };
    FilterService.prototype.getParamNumberValue = function (params, key) {
        var paramValue = this.getParamValue(params, key);
        if (paramValue) {
            var value = Number.parseInt(paramValue, 10);
            return Number.isNaN(value) ? null : value;
        }
        return null;
    };
    FilterService.prototype.getParamFloatValue = function (params, key) {
        var paramValue = this.getParamValue(params, key);
        if (paramValue) {
            var value = Number.parseFloat(paramValue);
            return Number.isNaN(value) ? null : value;
        }
        return null;
    };
    FilterService.prototype.initFilterServiceFilterStateForRequestParams = function (params) {
        var filterState = this.getFilterState();
        this.initFilterStateForRequestParams(filterState, params);
        this.notifyFilterStateChange(filterState, false);
    };
    FilterService.prototype.clearFilterState = function (filterState) {
        filterState.page = 1;
        filterState.heightFrom = null;
        filterState.heightTo = null;
        filterState.potSizeFrom = null;
        filterState.potSizeTo = null;
        filterState.priceFrom = null;
        filterState.priceTo = null;
        filterState.pageSize = null;
        filterState.features = [];
        filterState.searchText = null;
        filterState.clearFeatureFilter = 0;
    };
    FilterService.prototype.resetHistoryView = function () {
        this.setHistoryView(false, true);
    };
    FilterService.prototype.initFilterStateForRequestParams = function (filterState, params) {
        var searchText = this.getParamValue(params, 'searchText');
        var sorting = this.getParamValue(params, 'sorting');
        var mode = this.getParamValue(params, 'mode');
        var clear = this.getParamValue(params, 'clear');
        var page = this.getParamNumberValue(params, 'page');
        var pageSize = this.getParamNumberValue(params, 'pageSize');
        var heightFrom = this.getParamNumberValue(params, 'heightFrom');
        var heightTo = this.getParamNumberValue(params, 'heightTo');
        var potSizeFrom = this.getParamNumberValue(params, 'potSizeFrom');
        var potSizeTo = this.getParamNumberValue(params, 'potSizeTo');
        var priceFrom = this.getParamFloatValue(params, 'priceFrom');
        var priceTo = this.getParamFloatValue(params, 'priceTo');
        var clearFeatureFilter = this.getParamNumberValue(params, 'clearFeatureFilter');
        var featuresParam = params['features'];
        if (clear) {
            this.clearFilterState(filterState);
        }
        if (searchText) {
            filterState.searchText = decodeURIComponent(searchText);
        }
        if (page) {
            filterState.page = page;
        }
        if (mode === 'history') {
            this.setAssortmentType(filterState, FilterService.ASSORTMENT_TYPE_HISTORY, true, false);
        }
        if (mode === 'all') {
            this.setAssortmentType(filterState, FilterService.ASSORTMENT_TYPE_ALL, true, false);
        }
        if (heightFrom) {
            filterState.heightFrom = heightFrom;
        }
        if (heightTo) {
            filterState.heightTo = heightTo;
        }
        if (potSizeFrom) {
            filterState.potSizeFrom = potSizeFrom;
        }
        if (potSizeTo) {
            filterState.potSizeTo = potSizeTo;
        }
        if (priceFrom) {
            filterState.priceFrom = priceFrom;
        }
        if (priceTo) {
            filterState.priceTo = priceTo;
        }
        if (pageSize) {
            filterState.pageSize = pageSize;
        }
        if (sorting) {
            filterState.sorting = sorting;
        }
        if (clearFeatureFilter) {
            filterState.clearFeatureFilter = clearFeatureFilter;
        }
        if (featuresParam && featuresParam.length > 0) {
            if (!Array.isArray(featuresParam)) {
                featuresParam = [featuresParam];
            }
            featuresParam.forEach(function (featureParam) {
                var decodedFeatureParam = decodeURIComponent(featureParam);
                if (filterState.features.indexOf(decodedFeatureParam) === -1) {
                    filterState.features.push(decodedFeatureParam);
                }
            });
        }
    };
    FilterService.prototype.setHistoryView = function (historyViewActive, clearFilters) {
        if (historyViewActive) {
            this.setAssortmentTypeToHistory(clearFilters);
        }
        else {
            this.setAssortmentTypeToAssortment(clearFilters);
        }
    };
    FilterService.prototype.initSelectedLanguage = function (filterState) {
        var language = this.userService.getUserDefaultLanguage();
        filterState.updateLanguage(language);
        return language;
    };
    FilterService.prototype.getSelectedLanguage = function () {
        var filterState = this.getFilterState();
        var selected = filterState.languageCode;
        if (!selected) {
            selected = this.initSelectedLanguage(filterState);
        }
        return (selected) ? selected : null;
    };
    FilterService.prototype.isOrderHistoryActive = function () {
        return FilterService.ASSORTMENT_TYPE_HISTORY === this.getAssortmentType();
    };
    FilterService.prototype.setAssortmentType = function (filterState, type, clearFeatures, notifyChange) {
        if (clearFeatures === void 0) { clearFeatures = true; }
        if (notifyChange === void 0) { notifyChange = true; }
        configuration_helper_1.ConfigurationHelper.setItem(FilterService.STORAGE_KEY_ACTIVE_ASSORTMENT_TYPE, type.toString());
        filterState.type = type;
        if (clearFeatures) {
            filterState.features = [];
        }
        if (notifyChange) {
            this.notifyFilterStateChange(filterState);
        }
    };
    FilterService.prototype.setAssortmentTypeToHistory = function (clearFilters) {
        if (clearFilters === void 0) { clearFilters = true; }
        this.setAssortmentType(this.getFilterState(), FilterService.ASSORTMENT_TYPE_HISTORY, clearFilters);
    };
    FilterService.prototype.setAssortmentTypeToAssortment = function (clearFilters) {
        if (clearFilters === void 0) { clearFilters = true; }
        this.setAssortmentType(this.getFilterState(), FilterService.ASSORTMENT_TYPE_ALL, clearFilters);
    };
    FilterService.prototype.getProductsActiveSortFieldKeyAndOrder = function () {
        if (this.filterState.sorting) {
            return this.filterState.sorting;
        }
        var sortField = configuration_helper_1.ConfigurationHelper.getItem(this.PRODUCTS_ACTIVE_SORT_FIELD_KEY);
        if (!sortField) {
            return this.PRODUCTS_DEFAULT_SORT;
        }
        return sortField;
    };
    FilterService.prototype.getProductsActiveSortFieldKey = function () {
        return this.getFilterState().getProductsActiveSortFieldKey();
    };
    FilterService.prototype.setProductsActiveSortFieldKey = function (filterState, sortFieldKey) {
        filterState.sorting = sortFieldKey;
        configuration_helper_1.ConfigurationHelper.setItem(this.PRODUCTS_ACTIVE_SORT_FIELD_KEY, sortFieldKey);
    };
    FilterService.prototype.getAssortmentType = function () {
        var assortmentType = configuration_helper_1.ConfigurationHelper.getItem(FilterService.STORAGE_KEY_ACTIVE_ASSORTMENT_TYPE);
        if (!assortmentType) {
            return FilterService.ASSORTMENT_TYPE_ALL;
        }
        return Number.parseFloat(assortmentType);
    };
    FilterService.STORAGE_KEY_ACTIVE_ASSORTMENT_TYPE = 'active_assortment_type';
    FilterService.ASSORTMENT_TYPE_ALL = -1;
    FilterService.ASSORTMENT_TYPE_HISTORY = 3;
    return FilterService;
}(base_service_1.BaseService));
exports.FilterService = FilterService;
