import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {UserService} from './user.service';
import {Message} from '../classes/message';
import {AppSettings} from '../classes/app.settings';
import {HttpClient} from './http.service';
import {BaseService} from './base.service';
import {Logger} from '../logging/default-log.service';
import {Product} from '../classes/product';
import {ConfigurationHelper} from '../helper/configuration.helper';

/**
 * Maintains some objects in session storage
 *
 * @author evandongen
 */
@Injectable()
export class ApplicationService extends BaseService {
	loadedChanged = new Subject<boolean>();

	showPopupExistingOrders = true;

	public onStartOrderPopupSaveCallback;

	public onStartOrderPopupCloseCallback;

	/**
	 * The product the 'start order' popup is triggered from.
	 */
	public startOrderPopupFocussedProduct: Product;

	isListView: boolean = false;

	private listViewSet = false;
	private APP_SETTINGS_KEY = 'app_settings';
	private PRODUCTS_VIEW_KEY = 'current_user_products_is_list_view';

	private CURRENCY_SYMBOLS = {
		'ALL': 'ALL',
		'AFN': '\u060b', // ؋
		'ARS': 'ARS',
		'AWG': 'AWG',
		'AUD': 'AUD',
		'AZN': 'AZN',
		'BSD': 'BSD',
		'BBD': 'BBD',
		'BYR': 'BYR',
		'BZD': 'BZD',
		'BMD': 'BMD',
		'BOB': 'BOB',
		'BAM': 'BAM',
		'BWP': 'BWP',
		'BGN': 'BGN',
		'BRL': 'BRL',
		'BND': 'BND',
		'KHR': 'KHR',
		'CAD': 'CAD',
		'KYD': 'KYD',
		'CLP': 'CLP',
		'CNY': 'CNY',
		'COP': 'COP',
		'CRC': 'CRC',
		'HRK': 'HRK',
		'CUP': 'CUP',
		'CZK': 'K\u010d', // Kč
		'DKK': 'DKK',
		'DOP': 'DOP',
		'XCD': 'XCD',
		'EGP': 'EGP',
		'SVC': 'SVC',
		'EEK': 'EEK',
		'EUR': '\u20AC', // €
		'FKP': 'FKP',
		'FJD': 'FJD',
		'GHC': 'GHC',
		'GIP': 'GIP',
		'GTQ': 'GTQ',
		'GGP': 'GGP',
		'GYD': 'GYD',
		'HNL': '\u004c', // L
		'HKD': 'HKD',
		'HUF': 'Ft',
		'ISK': 'ISK',
		'INR': '\u20b9', // ₹
		'IDR': 'IDR',
		'IRR': 'IRR',
		'IMP': 'IMP',
		'ILS': '\u20aa', // ₪
		'JMD': 'JMD',
		'JPY': '\u00A5', // ¥
		'JEP': 'JEP',
		'KZT': 'KZT',
		'KRW': '\u20a9', // ₩
		'KGS': 'KGS',
		'LAK': 'LAK',
		'LVL': 'LVL',
		'LBP': 'LBP',
		'LRD': 'LRD',
		'LTL': 'LTL',
		'MKD': 'MKD',
		'MYR': 'MYR',
		'MUR': 'MUR',
		'MXN': 'MXN',
		'MNT': 'MNT',
		'MZN': 'MZN',
		'NAD': 'NAD',
		'NPR': 'NPR',
		'ANG': 'ANG',
		'NZD': 'NZD',
		'NIO': 'NIO',
		'NGN': 'NGN',
		'KPW': 'KPW',
		'NOK': 'NOK',
		'OMR': 'OMR',
		'PKR': '\u20a8', // ₨
		'PAB': 'PAB',
		'PYG': 'PYG',
		'PEN': 'PEN',
		'PHP': '\u20b1', // ₱
		'PLN': '\u007A\u0142', // Zł
		'QAR': 'QAR',
		'RON': 'RON',
		'RUB': '\u20BD', // ₽
		'SHP': 'SHP',
		'SAR': 'SAR',
		'RSD': 'RSD',
		'SCR': 'SCR',
		'SGD': 'SGD',
		'SBD': 'SBD',
		'SOS': 'SOS',
		'ZAR': 'ZAR',
		'LKR': '\u20a8', // ₨
		'SEK': 'SEK',
		'CHF': 'CHF',
		'SRD': 'SRD',
		'SYP': 'SYP',
		'TWD': 'TWD',
		'THB': '\u0e3f', // ฿
		'TTD': 'TTD',
		'TRY': 'TL',
		'TRL': 'TRL',
		'TVD': 'TVD',
		'UAH': 'UAH',
		'GBP': '\u00A3', // £
		'USD': '\u0024', // $
		'UYU': 'UYU',
		'UZS': 'UZS',
		'VEF': 'Bs',
		'VND': '\u20ab', // ₫
		'YER': 'YER',
		'ZWD': 'ZWD'
	};


	private orderSelected = false;
	private showPopup = false;

	constructor(private userService: UserService, private http: HttpClient, logger: Logger) {
		super(logger);

		const user = userService.getUser();

		if (user && !this.listViewSet) {
			this.isListView = this.getProductsListView();
			this.listViewSet = true;
		}
	}

	private _message: Message;

	get message() {
		return this._message;
	}

	set message(value) {
		this._message = value;
	}

	getProductsListView(): boolean {
		let isListView: boolean = false;
		const sessionValue = ConfigurationHelper.getItem(this.PRODUCTS_VIEW_KEY);

		if (sessionValue) {
			isListView = JSON.parse(sessionValue);
		} else if (this.userService.isLoggedIn()) {
			isListView = this.userService.isUseFastCheckout();
		}

		return isListView;
	}

	setProductsListView(isListView: boolean) {
		ConfigurationHelper.setItem(this.PRODUCTS_VIEW_KEY, JSON.stringify(isListView));
	}

	isShowPopup(): boolean {
		return this.showPopup;
	}

	/**
	 * @param {()} onPopupSaveCallback (on default empty function which does nothing)
	 * @param {()} onPopupCloseCallback (on default empty function which does nothing)
	 */
	openPopup(onPopupSaveCallback = () => {
	          },
	          onPopupCloseCallback = () => {
	          }, showPopupExistingOrders: boolean = true, startOrderPopupFocussedProduct: Product = null) {

		this.onStartOrderPopupSaveCallback = onPopupSaveCallback;
		this.onStartOrderPopupCloseCallback = onPopupCloseCallback;
		this.startOrderPopupFocussedProduct = startOrderPopupFocussedProduct;

		this.toggleBodyPopupClass(true);
		this.showPopup = true;
		this.showPopupExistingOrders = showPopupExistingOrders;
	}

	openPopupForNew(onPopupSaveCallback = () => {
	                },
	                onPopupCloseCallback = () => {
	                }) {
		this.openPopup(onPopupSaveCallback, onPopupCloseCallback, false);
	}

	closePopup() {
		this.toggleBodyPopupClass(false);
		this.showPopup = false;
		this.startOrderPopupFocussedProduct = null;
	}

	toggleBodyPopupClass(add: boolean) {
		const body = document.querySelector('body');
		body.classList.toggle('popupOpen', add);
	}

	showMessage(): boolean {
		return this._message && !this._message.isShown;
	}

	/**
	 * Cleanup when users logs out
	 */
	logout() {
		ConfigurationHelper.clearLocalStorage();
		this.orderSelected = false;
	}

	/**
	 * Translate to the correct identifiers.
	 *
	 * @todo refactor to pipe
	 *
	 * @see http://www.xe.com/symbols.php
	 * @returns {string}
	 */
	getUserCurrency(): string {
		const user = this.userService.getUser();

		const defaultCurrencyCode = this.CURRENCY_SYMBOLS['EUR'];

		if (!user) {
			return defaultCurrencyCode;
		}

		const userCurrency = user != null ? user.currencyCode : null;

		if (userCurrency) {
			const currencyCode = userCurrency.toUpperCase();
			return this.CURRENCY_SYMBOLS[currencyCode] !== undefined ? this.CURRENCY_SYMBOLS[currencyCode] : defaultCurrencyCode;
		}

		return defaultCurrencyCode;
	}

	public getAppSettings(): AppSettings {
		const sessionValue = ConfigurationHelper.getItem(this.APP_SETTINGS_KEY);

		let settings: AppSettings = null;

		if (sessionValue) {
			settings = JSON.parse(sessionValue);
		}

		return settings;
	}

	public getRegisterUrl(): string {
		const languageCode = this.getWebsiteLanguageUrlIso();
		let path;
		switch (languageCode) {
			case 'de':
				path = 'service/become-a-customer';
				break;
			case 'nl':
				path = 'service/klant-worden';
				break;
			case 'sv':
				path = 'tjanster/bli-kund-hos-noviflora';
				break;
			case 'en':
			default:
				path = 'service/become-a-customer';
		}

		return this.getUrl(path, languageCode);
	}

	public getCmsLoginUrl(returnUrl: string): string {
		const cmsLoginUrl = this.getUrl('login', null);

		if (returnUrl) {
			return cmsLoginUrl + `?returnUrl=${returnUrl}`;
		}

		return cmsLoginUrl;
	}

	public getCmsUrl(): string {
		return this.getUrl(null, null);
	}

	/**
	 * Resolve url based on application settings
	 *
	 * @param path
	 * @param forceLanguage
	 * @returns {any}
	 */
	private getUrl(path: string, forceLanguage: string): string {
		const cmsBaseUrl = ConfigurationHelper.getCmsBaseUrl();
		const endOfUrlRegex = /\/$/;

		if (!cmsBaseUrl) {
			return '';
		}

		let languageCode;

		// Site url's can differ per language code, so forcing it to a specific language is necessary sometimes.
		if (!forceLanguage) {
			languageCode = this.getWebsiteLanguageUrlIso();
		} else {
			languageCode = forceLanguage;
		}

		const testCmsBaseUrlForSlash = endOfUrlRegex.test(cmsBaseUrl);
		const siteBasePath = testCmsBaseUrlForSlash ? 'site/' : '/site/';

		let url = cmsBaseUrl + siteBasePath + languageCode.toLowerCase();

		if (path) {
			url = url + '/' + path;
		}

		return url;
	}

	private getWebsiteLanguageUrlIso() {
		const name = 'websiteLanguageUrlIso=';
		const ca = document.cookie.split(';');
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) === ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) === 0) {
				return c.substring(name.length, c.length);
			}
		}
		return 'en';
	}
}
