"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var user_service_1 = require("../../service/user.service");
var SalespersonTelephoneComponent = (function () {
    function SalespersonTelephoneComponent(userService) {
        this.userService = userService;
    }
    SalespersonTelephoneComponent.prototype.getTelephoneNumber = function () {
        return this.userService.getUserSalesPersonTelephoneNumber();
    };
    return SalespersonTelephoneComponent;
}());
exports.SalespersonTelephoneComponent = SalespersonTelephoneComponent;
