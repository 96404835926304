<div id="{{anchorValue}}" class="overview-product--grid">

	<a *ngIf="showProduct"
	   (click)="viewProductDetail()"
	   class="overview-product_image"
	   render-product-image [image]="largeImage" [description]="product.totalDescription1">
	</a>

	<h2 *ngIf="showProduct"
	    class="overview-product_title">
		<a (click)="viewProductDetail()">
			{{product.totalDescription1}}
		</a>

		<div class="overview-product_subtitle" *ngIf="product.totalDescription2.trim().length > 0">
			{{product.totalDescription2}}
		</div>
	</h2>

	<div *ngIf="showProduct"
	     class="overview-product_info">
		<product-size [potSize]="product.potSize" [height]="product.potHeight"></product-size>

		<div class="overview-product_price" *ngIf="loggedIn">
		        <span class="overview-product_price--from">
		            <ng-container i18n="@@product.price.from">From</ng-container>
		        </span>

		<!-- only show when there's a discount -->
		<ng-template [ngIf]="product.saleDiscount > 0">
			<span class="original-price">{{userCurrency}} {{ productPrices.getOriginalPriceForProductType() | numberFormat:true }}</span>
			<strong>
				<span class="discount-tag theme-success-color">-{{product.saleDiscount}}%</span>
				{{userCurrency}} {{ productPrices.getCheapestAvailablePrice() | numberFormat:true }}
			</strong>
		</ng-template>

			<!-- product price, no discount -->
			<strong *ngIf="product.saleDiscount == 0">
				{{userCurrency}} {{ productPrices.getCheapestAvailablePrice() | numberFormat:true }}
			</strong>
		</div>
	</div>

	<div class="overview-product__add_placeholder">
		<ng-template [ngIf]="showProduct">
			<ng-template [ngIf]="productService.isTypeStock(product)">
				<div class="overview-product_info">
					<div class="overview-product_message">
						<strong class="text-green-notice theme-success-text-color"
						        i18n="@@product.quantity.stock.unit"
						        *ngIf="product.qtyByBox == 1">
							{{product.availableQuantity}} units in stock
						</strong>
						<strong class="text-green-notice theme-success-text-color"
						        i18n="@@product.quantity.stock.tray"
						        *ngIf="product.qtyByBox > 1">
							{{product.availableQuantity / product.qtyByBox}} trays in stock
						</strong>
					</div>
				</div>
			</ng-template>

			<div class="overview-product_add overview-product_not-available" *ngIf="!productPrices.showBoxOption()">
				<strong i18n="Not available text in product tile view@@productitem.grid.currently.unavailable">
					Currently unavailable
				</strong>
			</div>

			<form [formGroup]="form" (ngSubmit)="onSubmit(form.value)" *ngIf="productPrices.showBoxOption()">
				<div class="overview-product_add" *ngIf="loggedIn">
					<input *ngIf="!isStockProduct" type="number" name="numberOfUnits" class="numberOfUnits" required
					       min="{{minimumOrderQuantity}}" max="{{maxValue}}"
					       i18n-title="@@minimum.order.quantity"
					       title="The minimum order quantity is {{minimumOrderQuantity}} x {{itemsPerUnit}}"
					       (blur)="changeQuantity()" [formControl]="form.controls['numberOfUnits']"
					       autocomplete="off" />

					<input *ngIf="isStockProduct" type="number" name="numberOfUnits" class="numberOfUnits" required
					       min="{{minimumOrderQuantity}}" max="{{maxValue}}"
					       i18n-title="@@minimum.order.quantity"
					       title="The minimum order quantity is {{minimumOrderQuantity}} x {{itemsPerUnit}} The maximum order quantity is {{maxValue}} x {{itemsPerUnit}}"
					       (blur)="changeQuantity()" [formControl]="form.controls['numberOfUnits']"
					       autocomplete="off" />

					<div class="custom-select">

						<ng-select id="unit"
						           name="unit"
						           class="custom-ng-select"
						           (change)="onSelectedUnitChange($event)"
						           [items]="availableUnitOptions"
						           bindValue="value"
						           [multiple]="false"
						           [clearable]="false"
						           [markFirst]="true"
						           [searchable]="false"
						           [formControl]="form.controls['unit']">

							<ng-template ng-label-tmp let-item="item">
								<div class="select-label">
										<span class="option-quantity">x{{item.qty}}&nbsp;<span
												*ngIf="item.qtyBox">x{{item.qtyBox}}</span></span>
									<span class="option-price">{{item.price | numberFormat: true }}</span>
								</div>
							</ng-template>

							<ng-template ng-option-tmp let-item="item" let-index="index">
								<div class="select-label">
										<span class="option-quantity">x{{item.qty}}&nbsp;<span
												*ngIf="item.qtyBox">x{{item.qtyBox}}</span></span>
									<span class="option-price">{{item.price | numberFormat: true }}</span>
								</div>
							</ng-template>
						</ng-select>
					</div>
				</div>

			<div class="overview-product_button" *ngIf="loggedIn">
				<button type="submit"
				        [attr.disabled]="buttonsDisabled ? 'disabled' : null"
				        [ngClass]="{'button theme-btn-secondary': true, 'button--product-added': buttonShowPressed}">

						<ng-template [ngIf]="!showConfirmation">
							<strong>
								<ng-template [ngIf]="!useFastCheckout" i18n="@@add.to.cart.button">
									Add to cart
								</ng-template>
								<ng-template [ngIf]="useFastCheckout" i18n="@@buy.now.button">
									Buy now
								</ng-template>
							</strong>
							<ng-template [ngIf]="!buttonShowPressed">
								<render-checkout-icon [iconWidth]="31" [iconHeight]="16"
								                      [fastCheckout]="useFastCheckout"></render-checkout-icon>
							</ng-template>
							<ng-template [ngIf]="buttonShowPressed">
								<svg class="icon-spinner" width="14" height="14">
									<use xlink:href="/images/icons/svg-sprite.svg#spinner"></use>
								</svg>
							</ng-template>
						</ng-template>

						<ng-template [ngIf]="showConfirmation">
							<svg class="icon-check" width="16" height="14">
								<use xlink:href="/images/icons/svg-sprite.svg#check"></use>
							</svg>
							<strong i18n="Label shown after products were added@@buy.options.products.added">
								Products added
							</strong>
						</ng-template>

					</button>
				</div>

				<div class="overview-product_button" *ngIf="!loggedIn">
					<a (click)="viewProductDetail()" class="button theme-btn-secondary"
					   i18n="Label shown on the more info button if user is not logged in@@product.more.info">
						More info
					</a>
				</div>
			</form>

		</ng-template>
	</div>
</div>
