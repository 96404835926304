import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ActiveOrder} from '../../../../classes/active.order';
import {UserService} from '../../../../service/user.service';
import {DeliveryService} from '../../../../service/delivery.service';
import {ActiveOrderService} from '../../../../service/active.order.service';

/**
 * Uses an attribute selector since the extra element will destroy the table otherwise
 */
@Component({
    selector: '[pending-order-row]',
    templateUrl: 'pending.order.row.component.html'
})
export class PendingOrderRowComponent implements OnInit {
    @Input() activeOrder: ActiveOrder;
    @Input() closingInDay: string;
    @Input() closingInDays: string;
    @Input() closingTomorrow: string;
    @Input() closed: string;
    @Input() closedIn: string;

    renderedClosingTime: string;
    closingInHours: number;

    constructor(private userService: UserService,
                private activeOrderService: ActiveOrderService,
                private deliveryService: DeliveryService,
                private router: Router) {
    }

    ngOnInit() {
        if (this.activeOrder.closingTime) {
            this.closingInHours = this.deliveryService.getClosingInHours(this.activeOrder.closingTime);
            this.renderedClosingTime = this.deliveryService.getClosingTimeString(this.activeOrder.closingTime,
                this.activeOrder.closingHour, this.closingInHours, this.closingInDay, this.closingInDays,
                this.closingTomorrow, this.closed, this.closedIn);
        }
    }

    public showAddProducts(): boolean {
        if (this.userService.isUseFastCheckout()) {
            return this.closingInHours > 0;
        }

        return false;
    }

    public addProducts(activeOrder: ActiveOrder) {
        this.activeOrderService.setSelectedOrder(activeOrder);
        this.router.navigate(['/products']);
    }

}