export class Product {
    image: string;
    totalDescription1: string;
    totalDescription2: string;
    potSize: string;
    potHeight: string;
    type: number;
    priceListNo: string;
    priceListLineNo: string;
    minimumOrderQuantity: number;
    originalAvailableQuantity: number;
    qtyByBox: number;
    qtyPackingsByLayer: number;
    qtyPackingsByTrolley: number;
    qtyByTrolley: number;
    unitPrice: number;
    unitDiscountPrice: number;
    layerPrice: number;
    layerDiscountPrice: number;
    saleDiscount: number;
    trolleyPrice: number;
    trolleyDiscountPrice: number;
    availableQuantity: number;
    brandingImage: string;
    brandingDescription: string;
    vendorIsAuction: boolean;
    barcode: string;
    packing: string;
    producer: string;
    buyAllPrice: number;
}
