import {throwError as observableThrowError} from 'rxjs';
import {Logger} from '../logging/default-log.service';

/**
 * Base class with a few frequently used methods
 *
 * @author evandongen
 */
export class BaseService {
	logger: Logger;

	constructor(logger: Logger) {
		this.logger = logger;
	}

	public static is404Error(error: any) {
		return error.status === 404;
	}

	public static showErrorFeedback(message: string) {
		const windowObj = (<any>window);
		const notifier = windowObj.notifier;

		if (notifier) {
			notifier.show('', message, 'danger', '', 5000);
		}
	}

	protected handleError(error: any) {
		if (this.logger && !BaseService.is404Error(error)) {
			this.logger.error('error', error);
		}

		return observableThrowError(error);
	}
}
