import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Logger} from '../logging/default-log.service';
import {PendingOrder} from '../classes/pending.order';
import {BaseService} from './base.service';
import {HttpClient} from './http.service';
import {ConfigurationHelper} from '../helper/configuration.helper';

@Injectable()
export class PendingOrderService extends BaseService {

	constructor(private http: HttpClient,
				logger: Logger) {
		super(logger);
	}

	getPendingOrders(): Observable<PendingOrder[]> {
		const url = ConfigurationHelper.getWebshopUrl('/rest/order/pendingorders');
		return this.http.get<PendingOrder[]>(url);
	}
}
