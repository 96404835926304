"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var rxjs_1 = require("rxjs");
var base_service_1 = require("./base.service");
var http_service_1 = require("./http.service");
var delivery_service_1 = require("./delivery.service");
var user_service_1 = require("./user.service");
var default_log_service_1 = require("../logging/default-log.service");
var active_order_state_helper_1 = require("./active.order.state.helper");
var configuration_helper_1 = require("../helper/configuration.helper");
var ActiveOrderService = (function (_super) {
    __extends(ActiveOrderService, _super);
    function ActiveOrderService(http, userService, deliveryService, logger) {
        var _this = _super.call(this, logger) || this;
        _this.http = http;
        _this.userService = userService;
        _this.deliveryService = deliveryService;
        _this._selectedOrderChangedSubject = new rxjs_1.Subject();
        if (!userService.getUser()) {
            return _this;
        }
        _this.loadActiveOrders();
        return _this;
    }
    Object.defineProperty(ActiveOrderService.prototype, "selectedOrderChangedSubject", {
        get: function () {
            return this._selectedOrderChangedSubject;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ActiveOrderService.prototype, "selectedOrder", {
        get: function () {
            return this._selectedOrder;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ActiveOrderService.prototype, "activeOrders", {
        get: function () {
            return this._activeOrders;
        },
        enumerable: true,
        configurable: true
    });
    ActiveOrderService.prototype.getSavedActiveOrder = function () {
        var stringValue = active_order_state_helper_1.ActiveOrderStateHelper.getActiveOrderFromCookie();
        if (stringValue) {
            return JSON.parse(stringValue);
        }
        return null;
    };
    ActiveOrderService.prototype.reloadActiveOrders = function () {
        this.resetActiveOrders();
        this.loadActiveOrders();
    };
    ActiveOrderService.prototype.loadActiveOrders = function () {
        var _this = this;
        this.getActiveOrdersFromServer().subscribe(function (activeOrders) {
            _this._activeOrders = _this.filterOpenActiveOrdersAndAddCurrentSavedActiveOrderIfTransient(activeOrders);
            var activeOrder = _this.getSavedActiveOrder();
            if (activeOrder) {
                _this.setSelectedOrder(activeOrder);
            }
        }, function (error) {
            _this.resetActiveOrders();
        });
    };
    ActiveOrderService.prototype.getActiveOrderForShipmentDateAndShipToCode = function (shipmentDate, shipToCode) {
        return this.findActiveOrderForShipmentDateAndShipToCode(this.activeOrders, shipmentDate, shipToCode);
    };
    ActiveOrderService.prototype.findActiveOrderForShipmentDateAndShipToCode = function (activeOrders, shipmentDate, shipToCode) {
        return activeOrders.find(function (activeOrder) {
            return activeOrder.shipmentDate.toString() === shipmentDate.toString() && activeOrder.shipToCode === shipToCode;
        });
    };
    ActiveOrderService.prototype.getActiveOrderFromList = function (activeOrder) {
        if (typeof activeOrder !== 'undefined' && this.deliveryService.isActiveOrderStillOpen(activeOrder)) {
            return this.getActiveOrderForShipmentDateAndShipToCode(activeOrder.shipmentDate, activeOrder.shipToCode);
        }
        return null;
    };
    ActiveOrderService.prototype.removeSelectedOrder = function () {
        this.setSelectedOrder(null);
    };
    ActiveOrderService.prototype.setSelectedOrder = function (activeOrder) {
        var newSelectedOrder = activeOrder != null ? this.getActiveOrderFromList(activeOrder) : null;
        if (newSelectedOrder) {
            this._selectedOrder = newSelectedOrder;
        }
        else {
            this._selectedOrder = null;
        }
        active_order_state_helper_1.ActiveOrderStateHelper.saveActiveOrderInStorage(this._selectedOrder);
        this.selectedOrderChangedSubject.next(this._selectedOrder);
    };
    ActiveOrderService.prototype.hasSelectedOrder = function () {
        return this.selectedOrder != null;
    };
    ActiveOrderService.prototype.setOrderSelectedForShipmentDateAndDeliveryAddress = function (shipmentDate, shipToCode) {
        var matchingOrder = this.getActiveOrderForShipmentDateAndShipToCode(shipmentDate, shipToCode);
        this.setSelectedOrder(matchingOrder);
    };
    ActiveOrderService.prototype.getActiveOrdersFromServer = function () {
        var url = configuration_helper_1.ConfigurationHelper.getWebshopUrl('/public/rest/order/activeorders');
        return this.http.get(url)
            .pipe(operators_1.publishLast(), operators_1.refCount());
    };
    ActiveOrderService.prototype.getActiveOrdersWithUnconfirmedRowsFromServer = function () {
        var url = configuration_helper_1.ConfigurationHelper.getWebshopUrl('/rest/order/activeorderswithunconfirmedrows');
        var self = this;
        return this.http.get(url)
            .pipe(operators_1.map(function (orders) {
            return self.filterExpiredActiveOrders(self.deliveryService, orders);
        }), operators_1.publishLast(), operators_1.refCount());
    };
    ActiveOrderService.prototype.filterOpenActiveOrdersAndAddCurrentSavedActiveOrderIfTransient = function (activeOrders) {
        var activeOrder = this.getSavedActiveOrder();
        if (activeOrder) {
            var existingOrder = this.findActiveOrderForShipmentDateAndShipToCode(activeOrders, activeOrder.shipmentDate, activeOrder.shipToCode);
            if (!existingOrder) {
                activeOrders.push(activeOrder);
                active_order_state_helper_1.ActiveOrderStateHelper.saveActiveOrderInStorage(activeOrder);
            }
        }
        return this.filterExpiredActiveOrders(this.deliveryService, activeOrders);
    };
    ActiveOrderService.prototype.filterExpiredActiveOrders = function (deliveryService, activeOrders) {
        return activeOrders.filter(function (order) { return deliveryService.isActiveOrderStillOpen(order); });
    };
    ActiveOrderService.prototype.resetActiveOrders = function () {
        this._activeOrders = null;
    };
    return ActiveOrderService;
}(base_service_1.BaseService));
exports.ActiveOrderService = ActiveOrderService;
