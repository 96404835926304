"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_service_1 = require("./http.service");
var ActiveOrderStateHelper = (function () {
    function ActiveOrderStateHelper() {
    }
    ActiveOrderStateHelper.clearSelectedOrderFromCookie = function () {
        http_service_1.HttpClient.deleteCookie(ActiveOrderStateHelper.ACTIVE_ORDER_KEY);
    };
    ActiveOrderStateHelper.getActiveOrderFromCookie = function () {
        return http_service_1.HttpClient.getCookie(ActiveOrderStateHelper.ACTIVE_ORDER_KEY, true);
    };
    ActiveOrderStateHelper.saveActiveOrderInStorage = function (activeOrder) {
        if (activeOrder !== null) {
            http_service_1.HttpClient.saveJsonInCookie(ActiveOrderStateHelper.ACTIVE_ORDER_KEY, activeOrder);
        }
        else {
            ActiveOrderStateHelper.clearSelectedOrderFromCookie();
        }
    };
    ActiveOrderStateHelper.ACTIVE_ORDER_KEY = 'current_active_order';
    return ActiveOrderStateHelper;
}());
exports.ActiveOrderStateHelper = ActiveOrderStateHelper;
