import { Component } from '@angular/core';

@Component({
    selector: 'notfound',
    template: `
		<div class="row content-container">
			<div class="col-12 col-sm-12 main-content mynovi">
                <h1>404 - Page not found</h1>
                <p><strong>Page not found!</strong></p>
            </div>
        </div>`
})
export class NotfoundComponent { }