"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FilterState = (function () {
    function FilterState(features, shipmentDate, searchText, sorting, type, pageSize, potSizeFrom, potSizeTo, priceFrom, priceTo, heightFrom, heightTo, clearFeatureFilter, languageCode, orderHistoryDateFrom, orderHistoryDateTo, page) {
        if (pageSize === void 0) { pageSize = 102; }
        this.features = features;
        this.shipmentDate = shipmentDate;
        this.searchText = searchText;
        this.sorting = sorting;
        this.type = type;
        this.pageSize = pageSize;
        this.potSizeFrom = potSizeFrom;
        this.potSizeTo = potSizeTo;
        this.priceFrom = priceFrom;
        this.priceTo = priceTo;
        this.heightFrom = heightFrom;
        this.heightTo = heightTo;
        this.clearFeatureFilter = clearFeatureFilter;
        this.languageCode = languageCode;
        this.orderHistoryDateFrom = orderHistoryDateFrom;
        this.orderHistoryDateTo = orderHistoryDateTo;
        this.page = page;
    }
    FilterState.toTime = function (date) {
        return date.getTime();
    };
    FilterState.prototype.updateShipmentDates = function (shipmentDate) {
        this.shipmentDate = shipmentDate;
    };
    FilterState.prototype.updateLanguage = function (languageCode) {
        this.languageCode = languageCode;
    };
    FilterState.prototype.getParams = function () {
        var params = [];
        if (this.page) {
            params.push("page=" + this.page);
        }
        if (this.features.length > 0) {
            params.push("filters=" + this.features.join(','));
        }
        if (this.shipmentDate) {
            params.push("shipmentDate=" + this.shipmentDate);
        }
        if (this.searchText) {
            params.push("searchText=" + encodeURIComponent(this.searchText));
        }
        if (this.potSizeFrom) {
            params.push("potSizeFrom=" + this.potSizeFrom);
        }
        if (this.potSizeTo) {
            params.push("potSizeTo=" + this.potSizeTo);
        }
        if (this.priceFrom) {
            params.push("priceFrom=" + this.priceFrom);
        }
        if (this.priceTo) {
            params.push("priceTo=" + this.priceTo);
        }
        if (this.heightFrom) {
            params.push("potHeightFrom=" + this.heightFrom);
        }
        if (this.heightTo) {
            params.push("potHeightTo=" + this.heightTo);
        }
        if (this.clearFeatureFilter) {
            params.push("clearFeatureFilter=" + this.clearFeatureFilter);
        }
        if (this.languageCode) {
            params.push("languageCode=" + this.languageCode);
        }
        if (this.orderHistoryDateFrom) {
            params.push("orderHistoryDateFrom=" + FilterState.toTime(this.orderHistoryDateFrom));
        }
        if (this.orderHistoryDateTo) {
            params.push("orderHistoryDateTo=" + FilterState.toTime(this.orderHistoryDateTo));
        }
        if (this.pageSize) {
            params.push("size=" + this.pageSize);
        }
        if (this.sorting) {
            var sortKey = this.getProductsActiveSortFieldKey();
            params.push("sorting=" + sortKey);
            var sortDirection = this.getProductsActiveSortOrder();
            if (sortKey && sortDirection) {
                params.push("sortingDirection=" + sortDirection);
            }
        }
        if (this.type) {
            params.push("type=" + this.type);
        }
        return params.join('&');
    };
    FilterState.prototype.getProductsActiveSortFieldKey = function () {
        if (!this.sorting) {
            return null;
        }
        var sortFieldParts = this.sorting.split('|');
        return sortFieldParts[0];
    };
    FilterState.prototype.getProductsActiveSortOrder = function () {
        if (!this.sorting) {
            return null;
        }
        var sortFieldParts = this.sorting.split('|');
        return sortFieldParts[1];
    };
    FilterState.prototype.getProductsActiveSortFieldAndOrderAsParam = function () {
        var key = this.getProductsActiveSortFieldKey();
        var value = this.getProductsActiveSortOrder();
        if (!key) {
            return null;
        }
        var queryParam = key;
        if (value) {
            queryParam = key + " " + value;
        }
        return encodeURIComponent(queryParam);
    };
    return FilterState;
}());
exports.FilterState = FilterState;
