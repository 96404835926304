"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var user_service_1 = require("../../service/user.service");
var planthunter_service_1 = require("../../service/planthunter.service");
var planthunter_1 = require("../../classes/planthunter");
var configuration_helper_1 = require("../../helper/configuration.helper");
var PlanthunterOverviewGridComponent = (function () {
    function PlanthunterOverviewGridComponent(planthunterService, userService) {
        this.planthunterService = planthunterService;
        this.userService = userService;
        this.viewProductDetailClickEvent = new core_1.EventEmitter();
    }
    PlanthunterOverviewGridComponent.prototype.ngOnInit = function () {
        this.planthunterProductList = this.planthunter.products;
    };
    PlanthunterOverviewGridComponent.prototype.toggleShowMorePlanthunterProducts = function () {
        if (this.showAllProducts) {
            this.gotoActivePlanthunter(this.planthunter.planthunterFeature);
            this.showAllProducts = false;
            return;
        }
        if (this.allProductsFetched) {
            this.showAllProducts = true;
            return;
        }
        this.loading = true;
        this.getMorePlanthunterProducts(this.planthunter.planthunterFeature);
    };
    PlanthunterOverviewGridComponent.prototype.getMorePlanthunterProducts = function (planthunterFeature) {
        var _this = this;
        this.planthunterService.getMorePlanthunterProducts(planthunterFeature)
            .then(function (products) {
            _this.planthunterProductList = _this.planthunterProductList.concat(products);
            _this.showAllProducts = true;
            _this.allProductsFetched = true;
            _this.loading = false;
        });
    };
    PlanthunterOverviewGridComponent.prototype.getShowAmountOfProducts = function () {
        return this.showAllProducts ? this.planthunterProductList.slice(0, 99) : this.planthunterProductList.slice(0, 4);
    };
    PlanthunterOverviewGridComponent.prototype.isShowToggleMoreProduct = function () {
        return this.planthunterProductList.length < 5;
    };
    PlanthunterOverviewGridComponent.prototype.gotoActivePlanthunter = function (planthunterId) {
        var planthunterElement = document.querySelector('#ID' + planthunterId);
        var planthunterBoundingRect = planthunterElement.getBoundingClientRect();
        var planthunterElementYPosition = planthunterBoundingRect.top;
        var windowScrollY = (window.scrollY === undefined) ? window.pageYOffset : window.scrollY;
        window.scrollTo(0, planthunterElementYPosition + windowScrollY);
    };
    PlanthunterOverviewGridComponent.prototype.viewProductDetail = function (detailUrl) {
        window.location.href = configuration_helper_1.ConfigurationHelper.getWebshopUrl(detailUrl + ';origin=planthunter');
    };
    PlanthunterOverviewGridComponent.prototype.isUserLoggedin = function () {
        return this.userService.isLoggedIn();
    };
    return PlanthunterOverviewGridComponent;
}());
exports.PlanthunterOverviewGridComponent = PlanthunterOverviewGridComponent;
