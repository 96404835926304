"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var window_ref_service_1 = require("../service/window-ref.service");
var ConfigurationHelper = (function () {
    function ConfigurationHelper() {
    }
    ConfigurationHelper.getWebshopUrl = function (relativeUrl) {
        var webshopBaseUrl = ConfigurationHelper.getWebshopBaseUrl();
        webshopBaseUrl = webshopBaseUrl.endsWith('/') ? webshopBaseUrl.slice(0, -1) : webshopBaseUrl;
        if (!relativeUrl.startsWith('/')) {
            relativeUrl = '/' + relativeUrl;
        }
        return webshopBaseUrl + relativeUrl;
    };
    ConfigurationHelper.getWebshopBaseUrl = function () {
        return this.winRef.nativeWindow.webshopBaseUrl;
    };
    ConfigurationHelper.getCmsBaseUrl = function () {
        return this.winRef.nativeWindow.cmsBaseUrl;
    };
    ConfigurationHelper.getSettings = function () {
        return this.winRef.nativeWindow.shopSettings;
    };
    ConfigurationHelper.isLocalStorageAvailable = function () {
        return (!!this.winRef.nativeWindow.storage);
    };
    ConfigurationHelper.isCookiesAvailable = function () {
        return this.winRef.nativeWindow.navigator.cookieEnabled;
    };
    ConfigurationHelper.setItem = function (key, data) {
        if (this.isLocalStorageAvailable()) {
            this.winRef.nativeWindow.storage.setItem(key, data);
        }
    };
    ConfigurationHelper.getItem = function (key) {
        if (!this.isLocalStorageAvailable()) {
            return;
        }
        return this.winRef.nativeWindow.storage.getItem(key);
    };
    ConfigurationHelper.removeItem = function (key) {
        if (this.isLocalStorageAvailable()) {
            this.winRef.nativeWindow.storage.removeItem(key);
        }
    };
    ConfigurationHelper.clearLocalStorage = function () {
        if (this.isLocalStorageAvailable()) {
            this.winRef.nativeWindow.storage.clear();
        }
    };
    ConfigurationHelper.winRef = new window_ref_service_1.WindowRef();
    return ConfigurationHelper;
}());
exports.ConfigurationHelper = ConfigurationHelper;
