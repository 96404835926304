"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../generic/component/cart/shoppingcart.header.mobile.component.ngfactory");
var i2 = require("../generic/component/cart/shoppingcart.header.mobile.component");
var i3 = require("../generic/service/shoppingcart.service");
var i4 = require("../generic/service/user.service");
var i5 = require("@angular/common");
var i6 = require("./shoppingcart.header.mobile.wrapper.component");
var i7 = require("../generic/service/active.order.service");
var styles_ShoppingcartHeaderMobileWrapperComponent = [];
var RenderType_ShoppingcartHeaderMobileWrapperComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ShoppingcartHeaderMobileWrapperComponent, data: {} });
exports.RenderType_ShoppingcartHeaderMobileWrapperComponent = RenderType_ShoppingcartHeaderMobileWrapperComponent;
function View_ShoppingcartHeaderMobileWrapperComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 2, "shoppingcart-header-mobile-component", [], null, [[null, "viewShoppingcartClickEvent"], [null, "viewOrderDetailClickEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("viewShoppingcartClickEvent" === en)) {
        var pd_0 = (_co.viewShoppingcart() !== false);
        ad = (pd_0 && ad);
    } if (("viewOrderDetailClickEvent" === en)) {
        var pd_1 = (_co.viewOrderDetail() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_ShoppingcartHeaderMobileComponent_0, i1.RenderType_ShoppingcartHeaderMobileComponent)), i0.ɵdid(2, 49152, null, 0, i2.ShoppingcartHeaderMobileComponent, [i3.ShoppingCartService, i4.UserService], null, { viewOrderDetailClickEvent: "viewOrderDetailClickEvent", viewShoppingcartClickEvent: "viewShoppingcartClickEvent" }), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], null, null); }
function View_ShoppingcartHeaderMobileWrapperComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ShoppingcartHeaderMobileWrapperComponent_1)), i0.ɵdid(1, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasSelectedOrder(); _ck(_v, 1, 0, currVal_0); }, null); }
exports.View_ShoppingcartHeaderMobileWrapperComponent_0 = View_ShoppingcartHeaderMobileWrapperComponent_0;
function View_ShoppingcartHeaderMobileWrapperComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "shoppingcart-header-mobile-wrapper-component", [], null, null, null, View_ShoppingcartHeaderMobileWrapperComponent_0, RenderType_ShoppingcartHeaderMobileWrapperComponent)), i0.ɵdid(1, 49152, null, 0, i6.ShoppingcartHeaderMobileWrapperComponent, [i7.ActiveOrderService, i3.ShoppingCartService], null, null)], null, null); }
exports.View_ShoppingcartHeaderMobileWrapperComponent_Host_0 = View_ShoppingcartHeaderMobileWrapperComponent_Host_0;
var ShoppingcartHeaderMobileWrapperComponentNgFactory = i0.ɵccf("shoppingcart-header-mobile-wrapper-component", i6.ShoppingcartHeaderMobileWrapperComponent, View_ShoppingcartHeaderMobileWrapperComponent_Host_0, {}, {}, []);
exports.ShoppingcartHeaderMobileWrapperComponentNgFactory = ShoppingcartHeaderMobileWrapperComponentNgFactory;
