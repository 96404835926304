<ng-template [ngIf]="productPage && productPage.numberOfElements > 0">
	<h1 *ngIf="title">{{title}}</h1>
	<p *ngIf="description">{{description}}</p>

	<div class="js-overview-grid">
		<div [ngClass]="{loading: loading}" class="overview-grid overview-grid--cms">
			<div class="loading-overlay"></div>
			<ng-template [ngIf]="productPage">
				<product-item-grid #productItemGridComponent
						(viewProductDetailClickEvent)="viewProductDetail($event)"
						*ngFor="let product of productPage.content"
						[loggedIn]="userService.isLoggedIn()"
						[product]="product"
						(lazyLoad)="productItemGridComponent.doLoad($event)"
						class="overview-product">
				</product-item-grid>
			</ng-template>
		</div>

		<ng-template [ngIf]="productPage && showAllUrl">
			<div class="button-bar" style="text-align: center;">
				<a class="button button--secondary"
				   href="{{showAllUrl}}"
				   i18n="@@spotlight.products.show.more">
					Show more
				</a>
			</div>
		</ng-template>
	</div>
</ng-template>
