/**
 * Class containing the token and the url on which the administration resides. If a user logins in on a diferent
 * administration, the server tries to login to the correct administration. If this is successful, a token is returned
 * and the url where the administration resides. The user should be redirected to that url and with the token it is
 * logged in.
 */
export class Administration {
    constructor(public administrationUrl: string,
                public token: string) {
    }
}
