"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var http_service_1 = require("./http.service");
var base_service_1 = require("./base.service");
var administration_1 = require("../classes/administration");
var default_log_service_1 = require("../logging/default-log.service");
var active_order_state_helper_1 = require("./active.order.state.helper");
var configuration_helper_1 = require("../helper/configuration.helper");
var UserService = (function (_super) {
    __extends(UserService, _super);
    function UserService(http, logger) {
        var _this = _super.call(this, logger) || this;
        _this.http = http;
        _this.initUserFromStorage();
        return _this;
    }
    UserService.cleanupOldStorageValuesAndRedirectToLogin = function () {
        UserService.deleteUserInStorage();
        active_order_state_helper_1.ActiveOrderStateHelper.clearSelectedOrderFromCookie();
        window.location.href = '/login';
    };
    UserService.setUserInStorage = function (user) {
        http_service_1.HttpClient.saveJsonInCookie(this.USER_COOKIE_KEY, user ? user : null, 7);
    };
    UserService.deleteUserInStorage = function () {
        http_service_1.HttpClient.deleteCookie(this.USER_COOKIE_KEY);
    };
    UserService.prototype.initUserFromStorage = function () {
        if (!this.hasValidAuthTokenCookie()) {
            UserService.deleteUserInStorage();
            return;
        }
        if (!window.isLoggedIn) {
            UserService.deleteUserInStorage();
            http_service_1.HttpClient.deleteCookie(UserService.COOKIE_AUTH_TOKEN);
            return;
        }
        var userJsonString = http_service_1.HttpClient.getCookie(UserService.USER_COOKIE_KEY, true);
        if (userJsonString) {
            this.user = JSON.parse(userJsonString);
            return;
        }
        if (this.hasValidAuthTokenCookie()) {
            http_service_1.HttpClient.deleteCookie(UserService.COOKIE_AUTH_TOKEN);
        }
    };
    UserService.prototype.getUserCartWaitTimeInSeconds = function () {
        return this.user.cartWaitTime * 60;
    };
    UserService.prototype.getUserSalesPersonTelephoneNumber = function () {
        var user = this.getUser();
        var defaultTelephoneNumber = '+31 17 4671 414';
        if (!user || !user.amTelephone) {
            return defaultTelephoneNumber;
        }
        return user.amTelephone;
    };
    UserService.prototype.getUser = function () {
        return this.user;
    };
    UserService.prototype.isUseFastCheckout = function () {
        return true;
    };
    UserService.prototype.getUserDefaultLanguage = function () {
        var userLang = http_service_1.HttpClient.getCookie('webshopLanguage');
        if (userLang) {
            return userLang;
        }
        var user = this.getUser();
        return user ? user.language : 'ENG';
    };
    UserService.prototype.login = function (user, password) {
        var url = configuration_helper_1.ConfigurationHelper.getWebshopUrl('/public/rest/remotelogin');
        return this.http.post(url, JSON.stringify({
            username: user,
            password: password
        })).pipe(operators_1.map(function (jsonResponse) {
            if (jsonResponse.user) {
                UserService.setUserInStorage(jsonResponse.user);
                active_order_state_helper_1.ActiveOrderStateHelper.clearSelectedOrderFromCookie();
            }
            return new administration_1.Administration(jsonResponse.administrationUrl, jsonResponse.token);
        }));
    };
    UserService.prototype.logout = function () {
        var _this = this;
        this.http.post('/logout', null)
            .subscribe(function (response) {
            UserService.cleanupOldStorageValuesAndRedirectToLogin();
        }, function (error) { return _this.handleError(error); });
    };
    UserService.prototype.hasValidAuthTokenCookie = function () {
        var value = http_service_1.HttpClient.getCookie(UserService.COOKIE_AUTH_TOKEN);
        return typeof value !== 'undefined' && value != null;
    };
    UserService.prototype.isLoggedIn = function () {
        return this.hasValidAuthTokenCookie() && (typeof this.user !== 'undefined' && this.user !== null);
    };
    UserService.prototype.requestPasswordReset = function (user) {
        var url = configuration_helper_1.ConfigurationHelper.getWebshopUrl('/public/rest/password/requestreset');
        var userLanguage = this.getUserDefaultLanguage();
        return this.http.post(url, JSON.stringify({
            username: user,
            language: userLanguage
        })).pipe(operators_1.catchError(this.handleError)).toPromise();
    };
    UserService.prototype.changePassword = function (password, resetuid) {
        var url = configuration_helper_1.ConfigurationHelper.getWebshopUrl('/public/rest/password/reset');
        return this.http.post(url, JSON.stringify({
            newpassword: password,
            resetuid: resetuid
        })).pipe(operators_1.catchError(this.handleError)).toPromise();
    };
    UserService.prototype.updateUserState = function (user) {
        this.user = user;
        UserService.setUserInStorage(user);
    };
    UserService.prototype.updateFastcheckout = function (fastCheckout) {
        var _this = this;
        var $xhr = this.http
            .post('/user/fastcheckout', JSON.stringify({ fastCheckout: fastCheckout })).pipe(operators_1.share());
        $xhr.subscribe(function (user) { return _this.updateUserState(user); }, function (error) { return _this.handleError(error); });
        return $xhr;
    };
    UserService.COOKIE_AUTH_TOKEN = 'auth_token';
    UserService.USER_COOKIE_KEY = 'current_user';
    return UserService;
}(base_service_1.BaseService));
exports.UserService = UserService;
