import {Injectable} from '@angular/core';
import {Logger} from '../logging/default-log.service';

/**
 * This service holds general functionality to add/create analytics events all over te webshop/website.
 *
 * @author Patrick van Zadel
 */
@Injectable()
export class AnalyticsService {

	private logger: Logger;

	constructor(logger: Logger) {
		this.logger = logger;
	}

	/**
	 * Sends a trackEvent to Google Analytics if this is the production server.
	 *
	 * @param category
	 * @param action
	 * @param label
	 * @param value
	 * @param noninteraction
	 */
	private analyticsTrackEvent(category: string, action: string, label: string, value: string, noninteraction: boolean) {
		const windowObj = (<any>window);
		let _gaq = windowObj._gaq !== undefined ? windowObj._gaq : [];

		let opt_label = label ? label : null;
		let opt_value = value ? value : null;
		let opt_interaction = noninteraction ? noninteraction : false;

		if (typeof windowObj._gaq === undefined) {
			this.logger.info(`Analytics library could not be found`);
			this.logger.info(`_trackEvent - ${category} - ${action} - ${opt_label} - ${opt_value} - ${opt_interaction}`);
		}

		_gaq.push(['_trackEvent', category, action, opt_label, opt_value, opt_interaction]);
	}

	public trackProductViewDetail(productTitle: string, url: string) {
		this.analyticsTrackEvent('Products',
			'View product detail',
			`${productTitle} - ${url}`,
			null,
			true)
	}
}
