import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';

/**
 * This guard makes sure, the routing change is cancelled, so that the RedirectToShopService
 * can actually do the redirect to the shop url. Normally we should be able to do the actual
 * redirect to the shop in this guard, but because we don't have a
 * router outlet in the site html (and don't want to) the 'ActivatedRouteSnapshot' object, doesn't
 * contain the url the user requested to navigate to.
 *
 * @author Nick Groenewegen
 */
@Injectable()
export class RedirectToShopGuard implements CanActivate {
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        return false;
    }
}