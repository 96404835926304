"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../generic/component/order/start.order.component.ngfactory");
var i2 = require("../generic/component/order/start.order.component");
var i3 = require("../generic/service/delivery.service");
var i4 = require("@angular/forms");
var i5 = require("../generic/service/application.service");
var i6 = require("../generic/service/active.order.service");
var i7 = require("../generic/logging/default-log.service");
var i8 = require("@angular/common");
var i9 = require("./start.order.wrapper.component");
var styles_StartOrderWrapperComponent = [];
var RenderType_StartOrderWrapperComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_StartOrderWrapperComponent, data: {} });
exports.RenderType_StartOrderWrapperComponent = RenderType_StartOrderWrapperComponent;
function View_StartOrderWrapperComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "start-order", [], null, [[null, "saved"], [null, "closed"], ["document", "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("document:click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).handleClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("saved" === en)) {
        var pd_1 = (_co.applicationService.onStartOrderPopupSaveCallback() !== false);
        ad = (pd_1 && ad);
    } if (("closed" === en)) {
        var pd_2 = (_co.applicationService.onStartOrderPopupCloseCallback() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_StartOrderComponent_0, i1.RenderType_StartOrderComponent)), i0.ɵdid(1, 114688, null, 0, i2.StartOrderComponent, [i3.DeliveryService, i4.FormBuilder, i5.ApplicationService, i6.ActiveOrderService, i7.Logger], null, { closed: "closed", saved: "saved" }), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_StartOrderWrapperComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_StartOrderWrapperComponent_1)), i0.ɵdid(1, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.applicationService.isShowPopup(); _ck(_v, 1, 0, currVal_0); }, null); }
exports.View_StartOrderWrapperComponent_0 = View_StartOrderWrapperComponent_0;
function View_StartOrderWrapperComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "start-order-wrapper", [], null, null, null, View_StartOrderWrapperComponent_0, RenderType_StartOrderWrapperComponent)), i0.ɵdid(1, 49152, null, 0, i9.StartOrderWrapperComponent, [i5.ApplicationService], null, null)], null, null); }
exports.View_StartOrderWrapperComponent_Host_0 = View_StartOrderWrapperComponent_Host_0;
var StartOrderWrapperComponentNgFactory = i0.ɵccf("start-order-wrapper", i9.StartOrderWrapperComponent, View_StartOrderWrapperComponent_Host_0, {}, {}, []);
exports.StartOrderWrapperComponentNgFactory = StartOrderWrapperComponentNgFactory;
