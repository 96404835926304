"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var common_1 = require("@angular/common");
var date_service_1 = require("../service/date.service");
var CustomDatePipe = (function () {
    function CustomDatePipe(dateService, locale) {
        this.dateService = dateService;
        this.locale = locale;
        this.datePipe = new common_1.DatePipe(locale);
    }
    CustomDatePipe.prototype.transform = function (value, format) {
        if (value !== undefined) {
            return this.datePipe.transform(this.dateService.treatAsUTC(value), format);
        }
        return '';
    };
    return CustomDatePipe;
}());
exports.CustomDatePipe = CustomDatePipe;
