import {Component} from '@angular/core';
import {ActiveOrderService} from '../generic/service/active.order.service';
import {ShopHelper} from "./shop.helper";

@Component({
	selector: 'shoppingcart-header-wrapper-component',
	templateUrl: './shoppingcart.header.wrapper.component.html'
})
export class ShoppingcartHeaderWrapperComponent {
	shoppingbarHeaderWrapperAlreadyRendered: boolean;

	constructor(private activeOrderService: ActiveOrderService) {
		let windowObj = (<any>window);
		this.shoppingbarHeaderWrapperAlreadyRendered = windowObj.shoppingbarHeaderWrapperAlreadyRendered;
	}

	hasSelectedOrder() {
		return this.activeOrderService.hasSelectedOrder();
	}

	viewPendingOrders() {
		ShopHelper.goToWebshopUrl('my/pending/orders');
	}

	viewShoppingcart() {
		ShopHelper.goToWebshopUrl('products/shoppingcart');
	}

	clickedAllProductsEvent() {
		return ShopHelper.goToWebshopUrl('products');
	}

	viewOrderDetail() {
		let activeOrder = this.activeOrderService.getSavedActiveOrder();

		if (activeOrder !== null) {
			ShopHelper.goToWebshopUrl(`my/pending/orders/detail;shipmentDate=${activeOrder.shipmentDate};deliveryAddressCode=${activeOrder.shipToCode}`)
		}
	}
}