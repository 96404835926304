"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var CookiesDisabledTranslationsComponent = (function () {
    function CookiesDisabledTranslationsComponent() {
    }
    CookiesDisabledTranslationsComponent.prototype.getCookiesDisabled = function () {
        return this.cookiesDisabled.nativeElement.textContent;
    };
    return CookiesDisabledTranslationsComponent;
}());
exports.CookiesDisabledTranslationsComponent = CookiesDisabledTranslationsComponent;
