import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {Params} from '@angular/router';
import {BaseService} from './base.service';
import {FilterState} from '../classes/filter.state';
import {ActiveOrderService} from './active.order.service';
import {Logger} from '../logging/default-log.service';
import {UserService} from './user.service';
import {ConfigurationHelper} from '../helper/configuration.helper';

/**
 * Service to handle the filter state. Components can subscribe to this service.
 */
@Injectable()
export class FilterService extends BaseService {

	private static STORAGE_KEY_ACTIVE_ASSORTMENT_TYPE = 'active_assortment_type';

	private static ASSORTMENT_TYPE_ALL: number = -1;

	private static ASSORTMENT_TYPE_HISTORY: number = 3;

	filterStateChanged = new Subject<FilterState>();

	private filterState: FilterState;

	private PRODUCTS_ACTIVE_SORT_FIELD_KEY = 'current_user_products_active_sort_field';
	private PRODUCTS_DEFAULT_SORT = 'totalDescription1|ASC';

	constructor(private activeOrderService: ActiveOrderService,
	            private userService: UserService,
	            logger: Logger) {

		super(logger);

		this.filterState = this.getFilterState();

		this.initSelectedLanguage(this.filterState);
	}

	getFilterState(): FilterState {
		if (!this.filterState) {
			this.filterState = new FilterState([]);
			this.filterState.page = 1;
		}

		// Make sure to set the language code if it's not set
		if (!this.filterState.languageCode) {
			this.filterState.languageCode = this.userService.getUserDefaultLanguage();
		}

		this.filterState.type = this.getAssortmentType();

		const activeOrder = this.activeOrderService.selectedOrder;

		this.filterState.shipmentDate = activeOrder ? activeOrder.shipmentDate : null;

		return this.filterState;
	}

	toggleFeature(featureId: string) {
		const index = this.getFilterState().features.indexOf(featureId);

		if (index === -1) {
			// Add feature
			this.filterState.features.push(featureId);
		} else if (index > -1) {
			// Remove feature
			this.filterState.features.splice(index, 1);
		}

		this.notifyFilterStateChange(this.filterState);
	}

	public notifyFilterStateChange(filterState: FilterState, resetPage: boolean = true) {
		if (resetPage) {
			// If the filterstate changed, make sure to set the page to 1
			filterState.page = 1;
		}

		this.filterStateChanged.next(filterState);
	}

	changePage(page: number) {
		this.getFilterState().page = page;
		this.notifyFilterStateChange(this.filterState, false);
	}

	isFeatureSelected(featureId: string): boolean {
		return (this.getFilterState().features.indexOf(featureId) > -1);
	}

	setSearchText(searchText: string) {
		this.getFilterState().searchText = searchText;

		this.notifyFilterStateChange(this.filterState);
	}

	setPotSize(potSizeFrom: number, potSizeTo: number) {
		const filterState = this.getFilterState();

		filterState.potSizeFrom = potSizeFrom;
		filterState.potSizeTo = potSizeTo;

		this.notifyFilterStateChange(this.filterState);
	}

	setPrice(priceFrom: number, priceTo: number) {
		const filterState = this.getFilterState();

		filterState.priceFrom = priceFrom;
		filterState.priceTo = priceTo;

		this.notifyFilterStateChange(this.filterState);
	}

	setClearFeatureFilter(clearFeatureFilter: number) {
		this.getFilterState().clearFeatureFilter = clearFeatureFilter;
		this.notifyFilterStateChange(this.filterState);
	}

	setOrderHistoryPeriod(notifyFilterStateChange: boolean, orderHistoryDateFrom: Date, orderHistoryDateTo: Date) {
		const filterState = this.getFilterState();

		filterState.orderHistoryDateFrom = orderHistoryDateFrom;
		filterState.orderHistoryDateTo = orderHistoryDateTo;

		if (notifyFilterStateChange) {
			this.notifyFilterStateChange(this.filterState);
		}
	}

	setHeight(heightFrom: number, heightTo: number) {
		const filterState = this.getFilterState();
		filterState.heightFrom = heightFrom;
		filterState.heightTo = heightTo;

		this.notifyFilterStateChange(this.filterState);
	}

	setShipmentDate(shipmentDate: Date) {
		this.getFilterState().updateShipmentDates(shipmentDate);
		this.notifyFilterStateChange(this.filterState);
	}

	getParamValue(params: Params, key: string): string {
		const value = params[key];
		if (!value || value.length < 1) {
			return null;
		}
		return decodeURIComponent(value);
	}

	getParamNumberValue(params: Params, key: string): number {
		const paramValue = this.getParamValue(params, key);
		if (paramValue) {
			const value = Number.parseInt(paramValue, 10);
			return Number.isNaN(value) ? null : value;
		}
		return null;
	}

	getParamFloatValue(params: Params, key: string): number {
		const paramValue = this.getParamValue(params, key);
		if (paramValue) {
			const value = Number.parseFloat(paramValue);
			return Number.isNaN(value) ? null : value;
		}
		return null;
	}

	initFilterServiceFilterStateForRequestParams(params: Params) {
		const filterState = this.getFilterState();

		this.initFilterStateForRequestParams(filterState, params);
		this.notifyFilterStateChange(filterState, false);
	}

	clearFilterState(filterState: FilterState) {
		filterState.page = 1;
		filterState.heightFrom = null;
		filterState.heightTo = null;
		filterState.potSizeFrom = null;
		filterState.potSizeTo = null;
		filterState.priceFrom = null;
		filterState.priceTo = null;
		filterState.pageSize = null;
		filterState.features = [];
		filterState.searchText = null;
		filterState.clearFeatureFilter = 0;
	}

	public resetHistoryView() {
		this.setHistoryView(false, true);
	}

	/**
	 * This method checks if there are parameters in the url which should be used for filtering. If so it adds the
	 * value(s) to the filterstate. The parameter values are url encoded, and therefor should be decoded before adding
	 * to the filter state.
	 *
	 * @param filterState
	 * @param params
	 */
	initFilterStateForRequestParams(filterState: FilterState, params: Params) {

		const searchText = this.getParamValue(params, 'searchText');
		const sorting = this.getParamValue(params, 'sorting');
		const mode = this.getParamValue(params, 'mode');
		const clear = this.getParamValue(params, 'clear');

		const page = this.getParamNumberValue(params, 'page');
		const pageSize = this.getParamNumberValue(params, 'pageSize');
		const heightFrom = this.getParamNumberValue(params, 'heightFrom');
		const heightTo = this.getParamNumberValue(params, 'heightTo');
		const potSizeFrom = this.getParamNumberValue(params, 'potSizeFrom');
		const potSizeTo = this.getParamNumberValue(params, 'potSizeTo');
		const priceFrom = this.getParamFloatValue(params, 'priceFrom');
		const priceTo = this.getParamFloatValue(params, 'priceTo');
		const clearFeatureFilter = this.getParamNumberValue(params, 'clearFeatureFilter');

		let featuresParam = params['features'];

		if (clear) {
			this.clearFilterState(filterState);
		}

		if (searchText) {
			filterState.searchText = decodeURIComponent(searchText);
		}

		if (page) {
			filterState.page = page;
		}

		if (mode === 'history') {
			this.setAssortmentType(filterState, FilterService.ASSORTMENT_TYPE_HISTORY, true, false);
		}

		if (mode === 'all') {
			this.setAssortmentType(filterState, FilterService.ASSORTMENT_TYPE_ALL, true, false);
		}

		if (heightFrom) {
			filterState.heightFrom = heightFrom;
		}

		if (heightTo) {
			filterState.heightTo = heightTo;
		}

		if (potSizeFrom) {
			filterState.potSizeFrom = potSizeFrom;
		}

		if (potSizeTo) {
			filterState.potSizeTo = potSizeTo;
		}

		if (priceFrom) {
			filterState.priceFrom = priceFrom;
		}

		if (priceTo) {
			filterState.priceTo = priceTo;
		}

		if (pageSize) {
			filterState.pageSize = pageSize;
		}

		if (sorting) {
			filterState.sorting = sorting;
		}

		if (clearFeatureFilter) {
			filterState.clearFeatureFilter = clearFeatureFilter;
		}

		if (featuresParam && featuresParam.length > 0) {
			if (!Array.isArray(featuresParam)) {
				featuresParam = [featuresParam];
			}

			featuresParam.forEach(featureParam => {
				const decodedFeatureParam = decodeURIComponent(featureParam);

				if (filterState.features.indexOf(decodedFeatureParam) === -1) {
					// Add feature
					filterState.features.push(decodedFeatureParam);
				}
			});
		}
	}

	setHistoryView(historyViewActive: boolean, clearFilters: boolean) {
		if (historyViewActive) {
			this.setAssortmentTypeToHistory(clearFilters);
		} else {
			this.setAssortmentTypeToAssortment(clearFilters);
		}
	}

	initSelectedLanguage(filterState: FilterState) {
		const language = this.userService.getUserDefaultLanguage();
		filterState.updateLanguage(language);
		return language;
	}

	getSelectedLanguage(): string {
		const filterState = this.getFilterState();

		let selected = filterState.languageCode;

		if (!selected) {
			selected = this.initSelectedLanguage(filterState);
		}

		return (selected) ? selected : null;
	}

	isOrderHistoryActive(): boolean {
		return FilterService.ASSORTMENT_TYPE_HISTORY === this.getAssortmentType();
	}

	setAssortmentType(filterState: FilterState, type: number, clearFeatures: boolean = true, notifyChange: boolean = true) {
		ConfigurationHelper.setItem(FilterService.STORAGE_KEY_ACTIVE_ASSORTMENT_TYPE, type.toString());

		filterState.type = type;

		if (clearFeatures) {
			// Clear the features when switching between assortment types,
			// because the available features may differ.
			filterState.features = [];
		}

		if (notifyChange) {
			this.notifyFilterStateChange(filterState);
		}
	}

	setAssortmentTypeToHistory(clearFilters: boolean = true) {
		this.setAssortmentType(this.getFilterState(), FilterService.ASSORTMENT_TYPE_HISTORY, clearFilters);
	}

	setAssortmentTypeToAssortment(clearFilters: boolean = true) {
		this.setAssortmentType(this.getFilterState(), FilterService.ASSORTMENT_TYPE_ALL, clearFilters);
	}

	/**
	 * @returns string the active SortField key and order, from the local filterstate.
	 * If there's no filter state yet resolve it from the localstorage.
	 * Or fallback to the default sort if none is previously selected by the user.
	 */
	getProductsActiveSortFieldKeyAndOrder(): string {
		if (this.filterState.sorting) {
			return this.filterState.sorting;
		}

		const sortField = ConfigurationHelper.getItem(this.PRODUCTS_ACTIVE_SORT_FIELD_KEY);

		if (!sortField) {
			return this.PRODUCTS_DEFAULT_SORT;
		}

		return sortField;
	}

	/**
	 * @returns string the SortField key for the active products sort.
	 */
	getProductsActiveSortFieldKey(): string {
		return this.getFilterState().getProductsActiveSortFieldKey();
	}

	setProductsActiveSortFieldKey(filterState: FilterState, sortFieldKey: string) {
		filterState.sorting = sortFieldKey;

		ConfigurationHelper.setItem(this.PRODUCTS_ACTIVE_SORT_FIELD_KEY, sortFieldKey);
	}

	private getAssortmentType(): number {
		const assortmentType = ConfigurationHelper.getItem(FilterService.STORAGE_KEY_ACTIVE_ASSORTMENT_TYPE);

		if (!assortmentType) {
			return FilterService.ASSORTMENT_TYPE_ALL;
		}

		return Number.parseFloat(assortmentType);
	}
}
