"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var shoppingcart_service_1 = require("../../service/shoppingcart.service");
var default_log_service_1 = require("../../logging/default-log.service");
var user_service_1 = require("../../service/user.service");
var ShoppingcartHeaderComponent = (function () {
    function ShoppingcartHeaderComponent(shoppingCartService, userService, logger) {
        var _this = this;
        this.shoppingCartService = shoppingCartService;
        this.userService = userService;
        this.logger = logger;
        this.showShoppingCartOverview = false;
        this.showShoppingCart = true;
        this.shoppingCartUpdated = false;
        this.viewPendingOrdersClickEvent = new core_1.EventEmitter();
        this.viewOrderDetailClickEvent = new core_1.EventEmitter();
        this.viewShoppingcartClickEvent = new core_1.EventEmitter();
        this.shoppingcartUpdatedSubscription = this.shoppingCartService.getShoppingCartObservable().subscribe(function () { return _this.onShoppingcartUpdated(); }, function (error) { return _this.logger.error('Failed to subscribe to shoppingcart', error); });
    }
    Object.defineProperty(ShoppingcartHeaderComponent.prototype, "shoppingCartLines", {
        get: function () {
            return this.shoppingCartService.activeShoppingCartLines;
        },
        enumerable: true,
        configurable: true
    });
    ShoppingcartHeaderComponent.prototype.isFastCheckout = function () {
        return this.userService.isUseFastCheckout();
    };
    ShoppingcartHeaderComponent.prototype.getUserCartWaitTimeInSeconds = function () {
        return this.userService.getUserCartWaitTimeInSeconds();
    };
    ShoppingcartHeaderComponent.prototype.ngOnDestroy = function () {
        this.shoppingcartUpdatedSubscription.unsubscribe();
    };
    ShoppingcartHeaderComponent.prototype.viewOrderDetail = function () {
        this.showShoppingCartOverview = false;
        this.viewOrderDetailClickEvent.emit();
    };
    ShoppingcartHeaderComponent.prototype.viewShoppingcart = function () {
        this.showShoppingCartOverview = false;
        this.viewShoppingcartClickEvent.emit();
    };
    ShoppingcartHeaderComponent.prototype.viewPendingOrders = function () {
        this.showShoppingCartOverview = false;
        this.viewPendingOrdersClickEvent.emit();
    };
    ShoppingcartHeaderComponent.prototype.handleClick = function (event) {
        this.closeShoppingCartIfNeeded(event);
    };
    ShoppingcartHeaderComponent.prototype.handleTouchstart = function (event) {
        this.closeShoppingCartIfNeeded(event);
    };
    ShoppingcartHeaderComponent.prototype.getShoppingCartCount = function () {
        var size = 0;
        if (this.shoppingCartLines) {
            size = this.shoppingCartLines.length;
        }
        return size;
    };
    ShoppingcartHeaderComponent.prototype.isEditable = function (shoppingCartLine) {
        var editable = false;
        if (shoppingCartLine.shoppingCartMode) {
            editable = (!shoppingCartLine.confirmed);
        }
        else {
            editable = this.shoppingCartService.isShoppingCartLineEditable(shoppingCartLine);
        }
        return editable;
    };
    ShoppingcartHeaderComponent.prototype.removeProduct = function (shoppingCartLine) {
        if (this.isEditable(shoppingCartLine)) {
            this.shoppingCartService.removeProduct(shoppingCartLine);
        }
        else {
            this.logger.error('Error: trying to delete shopping cart line which is not editable anymore');
        }
    };
    ShoppingcartHeaderComponent.prototype.getExpiryDate = function (shoppingCartLine) {
        return this.shoppingCartService.getShoppingCartLineExpiryDate(shoppingCartLine);
    };
    ShoppingcartHeaderComponent.prototype.getVolume = function () {
        var total = 0;
        if (this.shoppingCartLines) {
            this.shoppingCartLines.forEach(function (s) { return total += s.volume; });
        }
        return total;
    };
    ShoppingcartHeaderComponent.prototype.getQuantity = function (shoppingCartLine) {
        var quantity = (shoppingCartLine.quantity / shoppingCartLine.qtyByBox);
        return Math.round(quantity);
    };
    ShoppingcartHeaderComponent.prototype.getPopupItems = function () {
        if (this.shoppingCartLines) {
            return this.shoppingCartLines.slice(0, 3);
        }
        return this.shoppingCartLines;
    };
    ShoppingcartHeaderComponent.prototype.checkIfChildElementClicked = function (clickedComponent, parentComponent) {
        var childClicked = false;
        do {
            if (clickedComponent === parentComponent) {
                childClicked = true;
            }
            clickedComponent = clickedComponent.parentNode;
        } while (clickedComponent);
        return childClicked;
    };
    ShoppingcartHeaderComponent.prototype.closeShoppingCartIfNeeded = function (event) {
        if (this.shoppingCartElement) {
            var clickedComponent = event.target;
            var buttonClicked = this.checkIfChildElementClicked(clickedComponent, this.shoppingCartButton.nativeElement);
            if (!buttonClicked) {
                var insideClicked = this.checkIfChildElementClicked(clickedComponent, this.shoppingCartElement.nativeElement);
                if (!insideClicked) {
                    this.showShoppingCartOverview = false;
                }
            }
        }
    };
    ShoppingcartHeaderComponent.prototype.onShoppingcartUpdated = function () {
        var _this = this;
        if (!this.shoppingCartUpdated) {
            this.shoppingCartUpdated = true;
            setTimeout(function () {
                _this.shoppingCartUpdated = false;
            }, 600);
        }
    };
    return ShoppingcartHeaderComponent;
}());
exports.ShoppingcartHeaderComponent = ShoppingcartHeaderComponent;
