<div class="row">
	<div [ngClass]="isShowRegisterBlock() ? 'col-7' : 'col-12'"
			class="col-sm-12">
		<div class="login-block">
			<h1>Log in</h1>

			<form [formGroup]="loginForm" (ngSubmit)="onSubmit(loginForm.value)">
				<div class="form-row" *ngIf="formError">
					<div class="form-input-container">
						<p class="form-input-error"
                           i18n="Login Error@@login.form.error">Login failed. Check your username/password.</p>
					</div>
                </div>

                <div class="form-row">
                    <div class="form-input-container">

                        <p class="form-input-error" *ngIf="loginForm.controls['email'].valid || !loginForm.controls['email'].touched"></p>
                        <p class="form-input-error"
                           *ngIf="!loginForm.controls['email'].valid
                                && loginForm.controls['email'].hasError('required')
                                && loginForm.controls['email'].touched"
                           i18n="Error: enter e-mail@@login.form.error.email.empty">Please enter an e-mailadress</p>

                        <p class="form-input-error" *ngIf="!loginForm.controls['email'].valid
                                && loginForm.controls['email'].hasError('incorrectMailFormat')
                                && loginForm.controls['email'].touched
                                && !loginForm.controls['email'].hasError('required')"
                           i18n="Error: enter valid e-mail@@login.form.error.email">Please enter a valid e-mailadress</p>
                    </div>
				</div>

				<div class="form-row">
					<label class="form-label" for="email" i18n="Login form: e-mail label@@login.form.email">
						E-mail
					</label>
					<div class="form-input-container">
						<input type="email" name="email" [formControl]="loginForm.controls['email']"
							   title="Please enter your E-mail" id="email" required autocomplete="off" />
					</div>
				</div>

				<div class="form-row">
					<label class="form-label" for="password" i18n="password label@@login.form.password">
						Password
					</label>
					<div class="form-input-container">
						<input attr.type="{{passwordFieldType}}" title="Please enter a password"
							   [formControl]="loginForm.controls['password']" name="password" id="password" required/>

						<button type="button" class="form-show-password"
								(click)="togglePassword()">
							<svg class="icon-eye" width="18" height="13" viewBox="0 0 18 13">
								<use xlink:href="/images/icons/svg-sprite.svg#eye"></use>
							</svg>
						</button>

						<div class="form-forgot-password">
							<a href="{{shopForgotPasswordUrl}}" i18n="forgot password text@@login.form.forgot.password">Forgot password?</a>
						</div>
					</div>
				</div>

				<div class="button-bar button-bar--right">
					<button type="submit" class="button theme-btn-secondary" i18n="Log in@@login.form.button">
						Log in
					</button>
				</div>
			</form>
		</div>
	</div>

	<div *ngIf="isShowRegisterBlock()"
			class="col-5 col-sm-12">
		<div class="login-block login-block--register">

			<h2 i18n="Login page: become a customer@@login.page.become.customer">Become a customer of Noviflora?</h2>

			<p i18n="Login page: register and receive info@@login.page.register.text">Register and receive login information</p>

			<div class="button-bar">
				<a href="{{applicationService.getRegisterUrl()}}" class="button button--secondary theme-btn-secondary-outline" i18n="register link@@login.page.register.link">Register</a>
			</div>
		</div>
	</div>
</div>
