import {Product} from '../../classes/product';
import {AfterViewInit, Input, OnInit} from '@angular/core';

export abstract class ProductItemCommonComponent implements OnInit, AfterViewInit {

	largeImage: string;
	anchorValue: string;
	@Input() show: boolean = false;

	abstract getProduct(): Product;

	ngOnInit() {
		this.largeImage = this.getProductService().getImage(this.getProduct().image);
		this.anchorValue = this.getProductService().getAnchorValue(
			this.getProduct().type,
			this.getProduct().priceListNo,
			this.getProduct().priceListLineNo
		);
	}

	ngAfterViewInit(): void {
		// If the current item equals the referring product detail, scroll here
		if (this.anchorValue === this.getScrollToId()) {
			this.triggerProductMatchedFragmentEvent(this.anchorValue);
		}
	}

	abstract getScrollToId();

	abstract triggerProductMatchedFragmentEvent(anchorValue: string);

	abstract getProductService();
}
