import { Pipe, PipeTransform } from '@angular/core';

/*
 * Simple pipe to format a number to 2 digits and a comma
 *
 * @author ervandongen
 */
@Pipe({name: 'numberFormat'})
export class NumberFormatPipe implements PipeTransform {

    transform(value: number, currency: boolean): string {

        if (!value) {
            return null;
        }

        const number = value.toFixed(2);

        if (currency) {
            return number.replace('.', ',');
        }

        return number;
    }
}
