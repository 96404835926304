"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var user_service_1 = require("../../service/user.service");
var global_validator_1 = require("../../global.validator");
var forms_1 = require("@angular/forms");
var default_log_service_1 = require("../../logging/default-log.service");
var application_service_1 = require("../../service/application.service");
var configuration_helper_1 = require("../../helper/configuration.helper");
var LoginComponent = (function () {
    function LoginComponent(applicationService, route, userService, formBuilder, logger) {
        this.applicationService = applicationService;
        this.route = route;
        this.userService = userService;
        this.logger = logger;
        this.passwordFieldType = 'password';
        this.successRedirect = '/products';
        this.shopSettings = configuration_helper_1.ConfigurationHelper.getSettings();
        this.windowObj = window;
        this.loginForm = formBuilder.group({
            'email': [null, forms_1.Validators.compose([forms_1.Validators.required, global_validator_1.GlobalValidator.mailFormat])],
            'password': [null, forms_1.Validators.required]
        });
    }
    LoginComponent.prototype.ngOnInit = function () {
        this.returnUrl = this.getReturnUrl();
        this.shopForgotPasswordUrl = configuration_helper_1.ConfigurationHelper.getWebshopUrl('/forgot-password');
    };
    LoginComponent.prototype.isShowRegisterBlock = function () {
        if (!this.shopSettings) {
            return true;
        }
        return !this.shopSettings.kvkShop;
    };
    LoginComponent.prototype.onSubmit = function (value) {
        var _this = this;
        this.userService.login(value.email, value.password).subscribe(function (result) {
            if (_this.returnUrl) {
                window.location.href = _this.returnUrl;
            }
            else if (result.administrationUrl) {
                window.location.href = result.administrationUrl + '?token=' + result.token;
            }
            else {
                window.location.href = _this.successRedirect;
            }
        }, function (error) {
            _this.logger.debug('Failed to login', error);
            _this.formError = 'Invalid username / password';
        });
    };
    LoginComponent.prototype.togglePassword = function () {
        this.passwordFieldType = (this.passwordFieldType === 'password') ? 'text' : 'password';
    };
    LoginComponent.prototype.getReturnUrl = function () {
        var routerReturnUrl = this.route.snapshot.queryParams['returnUrl'];
        var windowReturnUrl = this.getReturnUrlFromParameters(this.windowObj.location.search);
        if (routerReturnUrl) {
            return routerReturnUrl;
        }
        return windowReturnUrl ? windowReturnUrl : null;
    };
    LoginComponent.prototype.getReturnUrlFromParameters = function (search) {
        var hashes = search.slice(search.indexOf('?') + 1).split('&');
        return hashes[0].replace('returnUrl=', '');
    };
    return LoginComponent;
}());
exports.LoginComponent = LoginComponent;
