"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var default_log_service_1 = require("../logging/default-log.service");
var base_service_1 = require("./base.service");
var http_service_1 = require("./http.service");
var configuration_helper_1 = require("../helper/configuration.helper");
var PendingOrderService = (function (_super) {
    __extends(PendingOrderService, _super);
    function PendingOrderService(http, logger) {
        var _this = _super.call(this, logger) || this;
        _this.http = http;
        return _this;
    }
    PendingOrderService.prototype.getPendingOrders = function () {
        var url = configuration_helper_1.ConfigurationHelper.getWebshopUrl('/rest/order/pendingorders');
        return this.http.get(url);
    };
    return PendingOrderService;
}(base_service_1.BaseService));
exports.PendingOrderService = PendingOrderService;
