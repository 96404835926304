export class ProductPrices {
	private TYPE_STOCK: number = 1;

	constructor(public product: any,
	            public itemsInOrder: number) {
	}

	/**
	 * Gets the non-discounted price for a product. This is used when a product has a sale but the
	 * original price needs to still be shown above the discounted price
	 */
	public getOriginalPriceForProductType(): number {
		if (!this.product) {
			return 0;
		}

		if (this.showUnitOption() || this.showTrayOption()) {
			return this.product.unitPrice;
		}

		if (this.showLayerOption()) {
			return this.product.layerPrice;
		}

		if (this.showTrolleyOption()) {
			return this.product.trolleyPrice;
		}

		return this.product.unitPrice;
	}

	/**
	 * Calculates the cheapest price available for all types of products including
	 * the normal and discounted prices and will return the lowest available price to the view.
	 */
	public getCheapestAvailablePrice(): number {
		if (!this.product) {
			return 0;
		}

		const pricesArray = [];

		if (this.showUnitOption() || this.showTrayOption()) {
			pricesArray.push(this.getUnitOrTrayNormalOrDiscountPrice());
		}

		if (this.showLayerOption()) {
			pricesArray.push(this.getLayerNormalOrDiscountPrice());
		}

		if (this.showTrolleyOption()) {
			pricesArray.push(this.getTrolleyNormalOrDiscountPrice());
		}

		if (pricesArray.length > 0) {
			return Math.min(...pricesArray);
		}

		return this.getNormalOrDiscountPrice(
			this.product.saleDiscount,
			this.product.unitPrice,
			this.product.unitDiscountPrice
		);
	}

	/**
	 * Returns the unit or tray price with or without discount
	 *
	 * @return {number} price
	 */
	public getUnitOrTrayNormalOrDiscountPrice() {
		return this.getNormalOrDiscountPrice(
			this.product.saleDiscount,
			this.product.unitPrice,
			this.product.unitDiscountPrice
		);
	}

	/**
	 * Returns the layer price with or without discount
	 *
	 * @return {number} price
	 */
	public getLayerNormalOrDiscountPrice() {
		return this.getNormalOrDiscountPrice(
			this.product.saleDiscount,
			this.product.layerPrice,
			this.product.layerDiscountPrice
		);
	}

	/**
	 * Returns the trolley price with or without discount
	 *
	 * @return {number} price
	 */
	public getTrolleyNormalOrDiscountPrice() {
		return this.getNormalOrDiscountPrice(
			this.product.saleDiscount,
			this.product.trolleyPrice,
			this.product.trolleyDiscountPrice
		);
	}

	/**
	 * Calculate the discounted price and return the value based on the saleDiscount
	 *
	 * @param saleDiscount
	 * @param itemPrice
	 * @param itemDiscountPrice
	 * @return {number} price or discounted price
	 */
	public getNormalOrDiscountPrice(saleDiscount, itemPrice, itemDiscountPrice): number {
		if (saleDiscount > 0) {
			return itemDiscountPrice;
		}

		return itemPrice;
	}

	/**
	 * Check to see if a product has a quantity of 1. Meaning it's a Unit
	 *
	 * @returns {boolean}
	 */
	public showUnitOption() {
		return this.showBoxOption() && this.product.qtyByBox === 1;
	}

	/**
	 * Check to see if a product has a quantity higher then 1. Meaning it's a Tray
	 *
	 * @returns {boolean}
	 */
	public showTrayOption() {
		return this.showBoxOption() && this.product.qtyByBox > 1;
	}

	/**
	 * Check to see if a product is of type Layer
	 *
	 * @returns {boolean}
	 */
	public showLayerOption(): boolean {
		if (this.isStockProduct()) {
			return this.getAvailableQuantity() >= (this.product.qtyByBox * this.product.qtyPackingsByLayer);
		}

		return true;
	}

	/**
	 * Check to see if a product is of type Trolley
	 *
	 * @returns {boolean}
	 */
	public showTrolleyOption(): boolean {
		if (this.isStockProduct()) {
			return this.getAvailableQuantity() >= this.product.qtyByTrolley;
		}

		return true;
	}

	/**
	 * Check to see if what type of stock a product is for Unit or Tray
	 *
	 * @returns {boolean}
	 */
	public showBoxOption(): boolean {
		if (this.isStockProduct()) {
			return this.getAvailableQuantity() >= this.product.qtyByBox;
		}

		return true;
	}

	/**
	 * Only check quantity if type == 1
	 * @returns {boolean}
	 */
	private isStockProduct() {
		return this.product.type === this.TYPE_STOCK;
	}

	/**
	 * Take the quantity already ordered into account
	 *
	 * @returns {number}
	 */
	private getAvailableQuantity(): number {
		const available = this.product.availableQuantity - this.itemsInOrder;

		return (available < 0) ? 0 : available;
	}
}
