"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var default_log_service_1 = require("../logging/default-log.service");
var base_service_1 = require("./base.service");
var http_service_1 = require("./http.service");
var user_service_1 = require("./user.service");
var configuration_helper_1 = require("../helper/configuration.helper");
var PlanthunterConfig = (function () {
    function PlanthunterConfig() {
    }
    return PlanthunterConfig;
}());
exports.PlanthunterConfig = PlanthunterConfig;
var PlanthunterService = (function (_super) {
    __extends(PlanthunterService, _super);
    function PlanthunterService(http, logger, userService) {
        var _this = _super.call(this, logger) || this;
        _this.http = http;
        _this.userService = userService;
        return _this;
    }
    PlanthunterService.prototype.getAmountOfNewPlanthunterItems = function () {
        if (this.userService.isLoggedIn()) {
            var url = configuration_helper_1.ConfigurationHelper.getWebshopUrl('/rest/planthunter/newhunts');
            return this.http.get(url)
                .toPromise();
        }
        return Promise.resolve(-1);
    };
    PlanthunterService.prototype.getLanguageForPlanthunterCalls = function () {
        var language = window.planthunterWebshopLanguage;
        return language && language.length > 0 ? language : this.userService.getUserDefaultLanguage();
    };
    PlanthunterService.prototype.getPlanthunters = function () {
        var url = configuration_helper_1.ConfigurationHelper.getWebshopUrl("/rest/planthunters?language=" + this.getLanguageForPlanthunterCalls());
        return this.http.get(url).pipe(operators_1.catchError(this.handleError)).toPromise();
    };
    PlanthunterService.prototype.getMorePlanthunterProducts = function (planthunterId) {
        var url = configuration_helper_1.ConfigurationHelper.getWebshopUrl("/rest/planthunters/" + planthunterId + "/get-products?language=" + this.userService.getUserDefaultLanguage());
        return this.http.get(url)
            .pipe(operators_1.catchError(this.handleError))
            .toPromise();
    };
    PlanthunterService.prototype.getSinglePlanthunter = function (planthunterId) {
        var url = configuration_helper_1.ConfigurationHelper.getWebshopUrl("/rest/planthunter/" + planthunterId + "?language=" + this.getLanguageForPlanthunterCalls());
        return this.http.get(url)
            .pipe(operators_1.catchError(this.handleError))
            .toPromise();
    };
    PlanthunterService.prototype.followPlanthunter = function (planthunterId) {
        var url = configuration_helper_1.ConfigurationHelper.getWebshopUrl("/rest/planthunter/" + planthunterId + "/follow?language=" + this.getLanguageForPlanthunterCalls());
        return this.http.get(url).pipe(operators_1.catchError(this.handleError))
            .toPromise();
    };
    PlanthunterService.prototype.unfollowPlanthunter = function (planthunterId) {
        var url = configuration_helper_1.ConfigurationHelper.getWebshopUrl("/rest/planthunter/" + planthunterId + "/unfollow?language=" + this.getLanguageForPlanthunterCalls());
        return this.http.get(url).pipe(operators_1.catchError(this.handleError))
            .toPromise();
    };
    return PlanthunterService;
}(base_service_1.BaseService));
exports.PlanthunterService = PlanthunterService;
