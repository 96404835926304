"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var http_service_1 = require("./http.service");
var base_service_1 = require("./base.service");
var default_log_service_1 = require("../logging/default-log.service");
var configuration_helper_1 = require("../helper/configuration.helper");
var DeliveryService = (function (_super) {
    __extends(DeliveryService, _super);
    function DeliveryService(http, logger) {
        var _this = _super.call(this, logger) || this;
        _this.http = http;
        return _this;
    }
    DeliveryService.prototype.getDeliveryAddresses = function () {
        return this.http.get(configuration_helper_1.ConfigurationHelper.getWebshopUrl('/rest/deliveryaddresses'))
            .pipe(operators_1.catchError(this.handleError));
    };
    DeliveryService.prototype.getDeliveryAddressForCode = function (code) {
        return this.http.get(configuration_helper_1.ConfigurationHelper.getWebshopUrl('/rest/deliveryaddress?code=' + encodeURIComponent(code)))
            .pipe(operators_1.catchError(this.handleError));
    };
    DeliveryService.prototype.getDeliveryDate = function (findForProduct, shipmentDate) {
        var url = configuration_helper_1.ConfigurationHelper.getWebshopUrl("/rest/product/deliverydate?shipmentDate=" + shipmentDate + "&" + this.getProductUrlParams(findForProduct));
        return this.http.get(url)
            .pipe(operators_1.catchError(this.handleError));
    };
    DeliveryService.prototype.getDeliveryDates = function (findForProduct) {
        var url = configuration_helper_1.ConfigurationHelper.getWebshopUrl("/rest/product/deliverydates?" + this.getProductUrlParams(findForProduct));
        return this.http.get(url)
            .pipe(operators_1.catchError(this.handleError));
    };
    DeliveryService.prototype.getClosingInHours = function (closingTime) {
        var hours = 0;
        if (closingTime) {
            var now = Date.now();
            var diff = closingTime - now;
            if (diff > 0) {
                hours = Math.abs(diff) / 36e5;
            }
        }
        return hours;
    };
    DeliveryService.prototype.isActiveOrderStillOpen = function (activeOrder) {
        if (activeOrder.closingTime) {
            return this.getClosingInHours(activeOrder.closingTime) > 0;
        }
        return true;
    };
    DeliveryService.prototype.getClosingTimeStringForDeliveryDate = function (deliveryDate, closingInHours, dayTranslation, daysTranslation, tomorrowTranslation, closedTranslation, closedInTranslation) {
        return this.getClosingTimeString(deliveryDate.closingTime, deliveryDate.closingHour, closingInHours, dayTranslation, daysTranslation, tomorrowTranslation, closedTranslation, closedInTranslation);
    };
    DeliveryService.prototype.getClosingTimeString = function (closingTime, closingHour, closingInHours, dayTranslation, daysTranslation, tomorrowTranslation, closedTranslation, closedInTranslation) {
        if (closingInHours < 1) {
            return closedTranslation;
        }
        if (closingInHours >= 24) {
            var numberOfDays = Math.round(closingInHours / 24);
            return closedInTranslation + ' ' + numberOfDays + ' ' + (numberOfDays === 1 ? dayTranslation : daysTranslation);
        }
        if (this.checkClosingTimeToday(closingTime)) {
            return closingHour + ':00';
        }
        return tomorrowTranslation + ' ' + closingHour + ':00';
    };
    DeliveryService.prototype.getProductUrlParams = function (product) {
        if (!product) {
            return '';
        }
        return "type=" + product.type + "&priceListLineNo=" + product.priceListLineNo + "&priceListNo=" + product.priceListNo;
    };
    DeliveryService.prototype.checkClosingTimeToday = function (closingTime) {
        var currentDate = new Date().setHours(0, 0, 0, 0);
        var closingTimeDate = new Date(closingTime).setHours(0, 0, 0, 0);
        if (currentDate === closingTimeDate) {
            return true;
        }
        return false;
    };
    return DeliveryService;
}(base_service_1.BaseService));
exports.DeliveryService = DeliveryService;
