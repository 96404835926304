import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import {UserService} from './user.service';
import {Logger} from '../logging/default-log.service';

/**
 * Custom interceptor, which makes sure to redirect the user to the login page,
 * if an ajax call resulted in an forbidden or unauthorized http status.
 *
 * @author Nick Groenewegen
 */
@Injectable()
export class NotAuthorizedInterceptor implements HttpInterceptor {

	constructor(private logger: Logger) {
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req)
			.pipe(
				tap(
					() => {
					},
					event => {
						if (event instanceof HttpErrorResponse) {
							const url = event.url;

							// TODO: Verwijder deze logger line als het ticket gesloten is.
							if (!url) {
								this.logger.error('SUPNOVICOM-526', event);
								return;
							}

							// Don't redirect if the 'login' call resulted in a 403.
							if (url.indexOf('remotelogin') === -1 && (event.status === 401 || event.status === 403)
								&& (window.location.href.match(/\?/g) || []).length < 2) {
								UserService.cleanupOldStorageValuesAndRedirectToLogin();
							}
						}
					}
				)
			);
	}

}
