<span class="translation" #transClosedForBarcode
        i18n="message: order is closed@@order.translations.barcode.add.denied.order.closed">
        The order is closed. Barcodes cannot be added anymore.</span>

    <span class="translation" #transClosed i18n="message: order is closed@@order.translations.order.closed">closed</span>

    <span class="translation" #transClosedIn i18n="message: order is closed in@@order.translations.order.closed.in">in</span>

    <span class="translation" #transBcError i18n="message: barcode error@@order.translations.barcode.add.error">
        An error occured while confirming your barcodes. Please try again.</span>

    <span class="translation" #transBcSuccess i18n="message: barcode success@@order.translations.barcode.add.success">
        Your barcodes have been confirmed, and will be attached on the cover of the products.</span>

    <span class="translation" #transScConfirmed  i18n="message: shopping cart confirmed@@order.translations.barcode.add.confirmed">
        Your order has been confirmed. If needed, your account manager will contact you about availability of products
    </span>

    <span class="translation" #transScError i18n="message: shopping cart error@@order.translations.confirm.error">
        Something went wrong confirming your order. Please try again or contact your account manager
    </span>

    <span class="translation" #transDay i18n="message: number of days, singular@@order.translations.day.single">day</span>
    <span class="translation" #transDays i18n="message: number of days, plural@@order.translations.day.plural">days</span>
    <span class="translation" #transTomorrow i18n="message: Tomorrow@@order.translations.day.tomorrow">Tomorrow</span>
    