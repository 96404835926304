"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var http_service_1 = require("./http.service");
var rxjs_1 = require("rxjs");
var base_service_1 = require("./base.service");
var application_service_1 = require("./application.service");
var user_service_1 = require("./user.service");
var default_log_service_1 = require("../logging/default-log.service");
var active_order_service_1 = require("./active.order.service");
var configuration_helper_1 = require("../helper/configuration.helper");
var ShoppingCartService = (function (_super) {
    __extends(ShoppingCartService, _super);
    function ShoppingCartService(http, applicationService, activeOrderService, userService, logger) {
        var _this = _super.call(this, logger) || this;
        _this.http = http;
        _this.applicationService = applicationService;
        _this.activeOrderService = activeOrderService;
        _this.userService = userService;
        _this.shoppingCartSubject = new rxjs_1.Subject();
        _this.shoppingCartObservable = _this.shoppingCartSubject.asObservable();
        if (!userService.getUser()) {
            return _this;
        }
        _this.activeOrderService.selectedOrderChangedSubject.subscribe(function (newActiveOrder) {
            _this.updateShoppingCartForActiveOrder(newActiveOrder);
        }, function (error) { return _this.logger.error('Failed to subscribe to order changed', error); });
        return _this;
    }
    Object.defineProperty(ShoppingCartService.prototype, "activeShoppingCartLines", {
        get: function () {
            return this._activeShoppingCartLines;
        },
        enumerable: true,
        configurable: true
    });
    ShoppingCartService.prototype.getShoppingCartObservable = function () {
        return this.shoppingCartObservable;
    };
    ShoppingCartService.prototype.isShoppingCartLineEditable = function (shoppingCartLine) {
        var shoppingCartLineExpiryDate = this.getShoppingCartLineExpiryDate(shoppingCartLine);
        if (shoppingCartLineExpiryDate === null) {
            return false;
        }
        if (shoppingCartLine.confirmed > 0 || shoppingCartLine.expired) {
            return false;
        }
        return new Date() < shoppingCartLineExpiryDate;
    };
    ShoppingCartService.prototype.getShoppingCartLineExpiryDate = function (shoppingCartLine) {
        var inserted = shoppingCartLine.inserted;
        var waitTimeInSeconds = this.userService.getUserCartWaitTimeInSeconds();
        if (waitTimeInSeconds) {
            waitTimeInSeconds = waitTimeInSeconds * 1000;
            var expiryDate = inserted + waitTimeInSeconds;
            return new Date(expiryDate);
        }
        return null;
    };
    ShoppingCartService.prototype.getShoppingCart = function (addressCode, shipmentDate, sorting, sortingDirection) {
        var params = [];
        params.push("shipToCode=" + encodeURIComponent(addressCode));
        params.push("shipmentDate=" + shipmentDate);
        if (sorting && sortingDirection) {
            params.push("sorting=" + sorting);
            params.push("sortingDirection=" + sortingDirection);
        }
        var paramsJoined = params.join('&');
        return this.getLinesForSpecificCart(paramsJoined);
    };
    ShoppingCartService.prototype.getPendingOrderLines = function (addressCode, shipmentDate, sorting, sortingDirection) {
        var params = [];
        params.push("shipToCode=" + encodeURIComponent(addressCode));
        params.push("shipmentDate=" + shipmentDate);
        if (sorting && sortingDirection) {
            params.push("sorting=" + sorting);
            params.push("sortingDirection=" + sortingDirection);
        }
        var paramsJoined = params.join('&');
        return this.getLinesForSpecificPendingOrder(paramsJoined);
    };
    ShoppingCartService.prototype.updateShoppingCartForActiveOrder = function (newActiveOrder) {
        var _this = this;
        var params = [];
        if (newActiveOrder) {
            params.push("shipToCode=" + encodeURIComponent(newActiveOrder.shipToCode));
            params.push("shipmentDate=" + newActiveOrder.shipmentDate);
        }
        var urlParams = params.join('&');
        this.getLinesForSpecificCart(urlParams).subscribe(function (data) {
            _this._activeShoppingCartLines = data;
            _this.shoppingCartSubject.next(_this._activeShoppingCartLines);
        }, function (error) {
            _this.shoppingCartSubject.error(error);
            _this.resetActiveShoppingCart();
        });
    };
    ShoppingCartService.prototype.orderProduct = function (product, quantity, buyAll) {
        var _this = this;
        var selectedOrder = this.activeOrderService.selectedOrder;
        if (selectedOrder) {
            if (buyAll) {
                return this.buyAll(product, selectedOrder);
            }
            else {
                return this.saveOrderProduct(product, quantity, selectedOrder);
            }
        }
        this.applicationService.openPopup(function () {
            var $productOrderDone = _this.orderProduct(product, quantity, buyAll);
            if ($productOrderDone == null) {
                return;
            }
            $productOrderDone.subscribe(function () {
                _this.applicationService.closePopup();
            }, function () {
                _this.applicationService.closePopup();
            });
        }, function () {
        }, true, product);
        return null;
    };
    ShoppingCartService.prototype.removeProduct = function (shoppingCartLine) {
        var _this = this;
        var url = configuration_helper_1.ConfigurationHelper.getWebshopUrl('/rest/shoppingcart/remove');
        this.http.post(url, JSON.stringify({
            priceListLineNo: shoppingCartLine.priceListLineNo,
            priceListNo: shoppingCartLine.priceListNo,
            type: shoppingCartLine.type,
            shipToCode: shoppingCartLine.shipToCode,
            shipmentDate: shoppingCartLine.shipmentDate,
            shoppingCartNo: shoppingCartLine.shoppingCartNo,
            shoppingCartMode: shoppingCartLine.shoppingCartMode
        })).subscribe(function (result) { return _this.notifyShoppingCartChange(shoppingCartLine.shipToCode, shoppingCartLine.shipmentDate); }, function (error) { return _this.handleError(error); });
    };
    ShoppingCartService.prototype.changeQuantity = function (shoppingCartLine) {
        var _this = this;
        var url = configuration_helper_1.ConfigurationHelper.getWebshopUrl('/rest/shoppingcart/changeQuantity');
        this.http.post(url, JSON.stringify({
            priceListLineNo: shoppingCartLine.priceListLineNo,
            priceListNo: shoppingCartLine.priceListNo,
            quantity: shoppingCartLine.quantity,
            type: shoppingCartLine.type,
            shipToCode: shoppingCartLine.shipToCode,
            shipmentDate: shoppingCartLine.shipmentDate,
            shoppingCartNo: shoppingCartLine.shoppingCartNo,
            shoppingCartMode: shoppingCartLine.shoppingCartMode
        })).subscribe(function (result) { return _this.notifyShoppingCartChange(shoppingCartLine.shipToCode, shoppingCartLine.shipmentDate); }, function (error) { return _this.handleError(error); });
    };
    ShoppingCartService.prototype.notifyShoppingCartChange = function (addressCode, shipmentDate) {
        var _this = this;
        this.getShoppingCart(addressCode, shipmentDate, null, null).subscribe(function (lines) {
            _this._activeShoppingCartLines = lines;
            _this.shoppingCartSubject.next(lines);
        }, function (error) { return _this.handleError(error); });
    };
    ShoppingCartService.prototype.getNumberOfItemsInOrderForProduct = function (product) {
        if (this._activeShoppingCartLines) {
            var activeLine = this._activeShoppingCartLines.find(function (line) { return line.priceListLineNo === product.priceListLineNo
                && line.priceListNo === product.priceListNo; });
            if (activeLine !== undefined &&
                (activeLine.shoppingCartMode || this.isShoppingCartLineEditable(activeLine))) {
                return activeLine.quantity;
            }
        }
        return 0;
    };
    ShoppingCartService.prototype.buyAll = function (product, activeOrder) {
        var _this = this;
        var url = configuration_helper_1.ConfigurationHelper.getWebshopUrl('/rest/shoppingcart/buyall');
        var $xhr = this.http.post(url, JSON.stringify({
            priceListLineNo: product.priceListLineNo,
            priceListNo: product.priceListNo,
            type: product.type,
            shipmentDate: activeOrder.shipmentDate,
            shipToCode: encodeURIComponent(activeOrder.shipToCode),
            shoppingCartMode: !this.userService.isUseFastCheckout()
        })).pipe(operators_1.share());
        $xhr.subscribe(function (result) { return _this.notifyShoppingCartChange(activeOrder.shipToCode, activeOrder.shipmentDate); }, function (error) { return _this.handleError(error); });
        return $xhr;
    };
    ShoppingCartService.prototype.confirmAll = function (activeOrder) {
        var url = configuration_helper_1.ConfigurationHelper.getWebshopUrl('/rest/shoppingcart/confirmall');
        return this.http.post(url, JSON.stringify({
            shipmentDate: activeOrder.shipmentDate,
            shipToCode: encodeURIComponent(activeOrder.shipToCode)
        }));
    };
    ShoppingCartService.prototype.resetActiveShoppingCart = function () {
        this._activeShoppingCartLines = null;
    };
    ShoppingCartService.prototype.getLinesForSpecificCart = function (params) {
        var url = configuration_helper_1.ConfigurationHelper.getWebshopUrl('/rest/shoppingcart');
        if (params) {
            url += '?' + params;
        }
        return this.http.get(url)
            .pipe(operators_1.share());
    };
    ShoppingCartService.prototype.getLinesForSpecificPendingOrder = function (params) {
        var url = configuration_helper_1.ConfigurationHelper.getWebshopUrl('/rest/pendingorderdetails');
        if (params) {
            url += '?' + params;
        }
        return this.http.get(url);
    };
    ShoppingCartService.prototype.saveOrderProduct = function (product, quantity, activeOrder) {
        var _this = this;
        var url = configuration_helper_1.ConfigurationHelper.getWebshopUrl('/rest/shoppingcart/order');
        var $xhr = this.http.post(url, JSON.stringify({
            priceListLineNo: product.priceListLineNo,
            priceListNo: product.priceListNo,
            type: product.type,
            quantity: quantity,
            shipmentDate: activeOrder.shipmentDate,
            shipToCode: encodeURIComponent(activeOrder.shipToCode),
            shoppingCartMode: !this.userService.isUseFastCheckout()
        })).pipe(operators_1.share());
        $xhr.subscribe(function (result) { return _this.notifyShoppingCartChange(activeOrder.shipToCode, activeOrder.shipmentDate); }, function (error) { return _this.logger.error('Failed to add product to order', error); });
        return $xhr;
    };
    return ShoppingCartService;
}(base_service_1.BaseService));
exports.ShoppingCartService = ShoppingCartService;
