"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var BaseService = (function () {
    function BaseService(logger) {
        this.logger = logger;
    }
    BaseService.is404Error = function (error) {
        return error.status === 404;
    };
    BaseService.showErrorFeedback = function (message) {
        var windowObj = window;
        var notifier = windowObj.notifier;
        if (notifier) {
            notifier.show('', message, 'danger', '', 5000);
        }
    };
    BaseService.prototype.handleError = function (error) {
        if (this.logger && !BaseService.is404Error(error)) {
            this.logger.error('error', error);
        }
        return rxjs_1.throwError(error);
    };
    return BaseService;
}());
exports.BaseService = BaseService;
