<td scope="row" class="mynovi-data-table_datelocation">
	<h4>{{activeOrder.shipmentDate | customDate:'EEEE dd MMMM'}}</h4>
	<p>{{activeOrder.deliveryAddressName}} ({{activeOrder.shipToCode}})</p>
</td>
<td class="mynovi-data-table_volume">
	{{activeOrder.volume | numberFormat:false}} CC
</td>
<td class="mynovi-data-table_closing">
	<span class="mynovi-data-table_closing_text" *ngIf="closingInHours > 0"
		  i18n="prefix for renderedClosingTime@@pending.order.closing.prefix">closes</span>
	{{renderedClosingTime}}
</td>
<td class="mynovi-data-table_buttons">
	<div class="button-bar">
		<button type="button" (click)="addProducts(activeOrder)" class="button hide-sm theme-btn-primary"
		   *ngIf="showAddProducts()"
		   i18n="Add products link@@add.products">Add products</button>

		<button type="button" [routerLink]="['/my/pending/orders/detail', {shipmentDate: activeOrder.shipmentDate, deliveryAddressCode: activeOrder.shipToCode}]"
		   class="button button--secondary theme-btn-primary-outline" i18n="View order link@@view.order">
			View order
		</button>
	</div>
</td>
