import {Component} from '@angular/core';
import {ActiveOrderService} from '../generic/service/active.order.service';
import {ShoppingcartHeaderWrapperComponent} from './shoppingcart.header.wrapper.component';
import {ShoppingCartService} from '../generic/service/shoppingcart.service';

@Component({
	selector: 'shoppingcart-header-mobile-wrapper-component',
	templateUrl: './shoppingcart.header.mobile.wrapper.component.html'
})
export class ShoppingcartHeaderMobileWrapperComponent extends ShoppingcartHeaderWrapperComponent {

	constructor(activeOrderService: ActiveOrderService, shoppingcartService: ShoppingCartService) {
		super(activeOrderService);

		// Trigger the initial load of the shoppingcart contents,
		// if there's an active order selected.
		// This code is not in the ShoppingcartHeaderWrapperComponent,
		// because otherwise the code is triggered twice (also by this child implementation).
		let activeOrder = activeOrderService.getSavedActiveOrder();

		if (activeOrder) {
			shoppingcartService.updateShoppingCartForActiveOrder(activeOrder);
		}
	}
}