"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("@angular/common");
var i2 = require("./render.part.of.day");
var styles_RenderPartOfDayComponent = [];
var RenderType_RenderPartOfDayComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RenderPartOfDayComponent, data: {} });
exports.RenderType_RenderPartOfDayComponent = RenderType_RenderPartOfDayComponent;
function View_RenderPartOfDayComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n                    "])), (_l()(), i0.ɵeld(1, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["morning"])), (_l()(), i0.ɵted(-1, null, ["\n                "]))], null, null); }
function View_RenderPartOfDayComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n                    "])), (_l()(), i0.ɵeld(1, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["afternoon"])), (_l()(), i0.ɵted(-1, null, ["\n                "]))], null, null); }
function View_RenderPartOfDayComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n                    "])), (_l()(), i0.ɵeld(1, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["evening"])), (_l()(), i0.ɵted(-1, null, ["\n                "]))], null, null); }
function View_RenderPartOfDayComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, null, null, null, null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i1.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i0.ɵted(-1, null, ["\n                "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RenderPartOfDayComponent_1)), i0.ɵdid(4, 278528, null, 0, i1.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i1.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵted(-1, null, ["\n                "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RenderPartOfDayComponent_2)), i0.ɵdid(7, 278528, null, 0, i1.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i1.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵted(-1, null, ["\n                "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RenderPartOfDayComponent_3)), i0.ɵdid(10, 278528, null, 0, i1.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i1.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵted(-1, null, ["\n            "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.partOfDay; _ck(_v, 1, 0, currVal_0); var currVal_1 = 1; _ck(_v, 4, 0, currVal_1); var currVal_2 = 2; _ck(_v, 7, 0, currVal_2); var currVal_3 = 3; _ck(_v, 10, 0, currVal_3); }, null); }
exports.View_RenderPartOfDayComponent_0 = View_RenderPartOfDayComponent_0;
function View_RenderPartOfDayComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["render-part-of-day", ""]], null, null, null, View_RenderPartOfDayComponent_0, RenderType_RenderPartOfDayComponent)), i0.ɵdid(1, 49152, null, 0, i2.RenderPartOfDayComponent, [], null, null)], null, null); }
exports.View_RenderPartOfDayComponent_Host_0 = View_RenderPartOfDayComponent_Host_0;
var RenderPartOfDayComponentNgFactory = i0.ɵccf("[render-part-of-day]", i2.RenderPartOfDayComponent, View_RenderPartOfDayComponent_Host_0, { partOfDay: "partOfDay" }, {}, []);
exports.RenderPartOfDayComponentNgFactory = RenderPartOfDayComponentNgFactory;
