"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("@angular/common");
var i2 = require("./render.product.image");
var styles_RenderProductImageComponent = [];
var RenderType_RenderProductImageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RenderProductImageComponent, data: {} });
exports.RenderType_RenderProductImageComponent = RenderType_RenderProductImageComponent;
function View_RenderProductImageComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [["src", "/images/fallback-product.png"]], null, null, null, null, null))], null, null); }
function View_RenderProductImageComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4], [8, "alt", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(2, "https://img.noviflora.nl/Images/", _co.image, "", _co.getImageFormat(), ".jpg"); var currVal_1 = i0.ɵinlineInterpolate(1, "", _co.description, ""); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_RenderProductImageComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_RenderProductImageComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n    \t       "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RenderProductImageComponent_2)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.image; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.image; _ck(_v, 4, 0, currVal_1); }, null); }
exports.View_RenderProductImageComponent_0 = View_RenderProductImageComponent_0;
function View_RenderProductImageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["render-product-image", ""]], null, null, null, View_RenderProductImageComponent_0, RenderType_RenderProductImageComponent)), i0.ɵdid(1, 49152, null, 0, i2.RenderProductImageComponent, [], null, null)], null, null); }
exports.View_RenderProductImageComponent_Host_0 = View_RenderProductImageComponent_Host_0;
var RenderProductImageComponentNgFactory = i0.ɵccf("[render-product-image]", i2.RenderProductImageComponent, View_RenderProductImageComponent_Host_0, { image: "image", description: "description", imageFormat: "imageFormat" }, {}, []);
exports.RenderProductImageComponentNgFactory = RenderProductImageComponentNgFactory;
