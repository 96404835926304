"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var base_service_1 = require("./base.service");
var router_1 = require("@angular/router");
var HttpClient = (function () {
    function HttpClient(http, router) {
        this.http = http;
        this.router = router;
    }
    HttpClient.deleteCookie = function (name) {
        this.setSessionCookie(name, '', -1);
    };
    HttpClient.saveJsonInCookie = function (name, jsonObject, expiresInDays) {
        if (expiresInDays === void 0) { expiresInDays = null; }
        this.setSessionCookie(name, jsonObject ? JSON.stringify(jsonObject) : null, expiresInDays, true);
    };
    HttpClient.getCookie = function (name, encoded) {
        if (encoded === void 0) { encoded = false; }
        var value = '; ' + document.cookie;
        var parts = value.split('; ' + name + '=');
        if (parts.length !== 2) {
            return null;
        }
        value = parts.pop().split(';').shift();
        if (encoded) {
            return atob(this.replaceAll(value, 'EQUALS_SIGN', '='));
        }
        return value;
    };
    HttpClient.setSessionCookie = function (name, value, expiresInDays, encode) {
        if (encode === void 0) { encode = false; }
        if (encode && value) {
            value = this.replaceAll(btoa(value), '=', 'EQUALS_SIGN');
        }
        var windowObj = window;
        var expireTime = this.getCookieExpireTimeValue(expiresInDays);
        var cookieValue = name + "=" + value + ";domain=" + windowObj.cookieDomain + "; path=/;";
        if (expireTime) {
            cookieValue += " expires=" + expireTime + ";";
        }
        document.cookie = cookieValue;
    };
    HttpClient.getCookieExpireTimeValue = function (expiresInDays) {
        if (expiresInDays == null) {
            return null;
        }
        var expiresDate = new Date();
        expiresDate.setTime(+expiresDate + (expiresInDays * 86400000));
        return expiresDate.toUTCString();
    };
    HttpClient.replaceAll = function (string, find, replace) {
        return string.replace(new RegExp(find, 'g'), replace);
    };
    HttpClient.prototype.getDefaultHeaders = function () {
        return new http_1.HttpHeaders({ 'Content-Type': 'application/json;charset=UTF-8' });
    };
    HttpClient.prototype.getText = function (url) {
        var $observable = this.http.get(url, { responseType: 'text' }).pipe(operators_1.share());
        this.showFeedbackOnError($observable);
        return $observable;
    };
    HttpClient.prototype.get = function (url, specificHeaders) {
        if (specificHeaders === void 0) { specificHeaders = null; }
        var $observable = this.http.get(url, {
            headers: specificHeaders ? specificHeaders : this.getDefaultHeaders(),
            withCredentials: true
        }).pipe(operators_1.share());
        this.showFeedbackOnError($observable);
        return $observable;
    };
    HttpClient.prototype.post = function (url, data) {
        var $observable = this.http.post(url, data, {
            headers: this.getDefaultHeaders(),
            withCredentials: true
        }).pipe(operators_1.share());
        this.showFeedbackOnError($observable);
        return $observable;
    };
    HttpClient.prototype.showFeedbackOnError = function ($observable) {
        var _this = this;
        $observable.toPromise().catch(function (errorResponse) {
            var errorShown = false;
            try {
                var error = errorResponse.error;
                if (error.feedback) {
                    base_service_1.BaseService.showErrorFeedback(error.feedback);
                    errorShown = true;
                }
            }
            catch (e) {
            }
            if (!errorShown) {
                if (_this.router.url === '/login' || _this.router.url === '/') {
                    return;
                }
                base_service_1.BaseService.showErrorFeedback('An unexpected error has occurred. Please try again.');
            }
        });
    };
    return HttpClient;
}());
exports.HttpClient = HttpClient;
