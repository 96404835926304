import {Component, ElementRef, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {ProductPage} from '../generic/classes/product.page';
import {Logger} from '../generic/logging/default-log.service';
import {ProductService} from '../generic/service/product.service';
import {UserService} from '../generic/service/user.service';
import {ShopHelper} from './shop.helper';
import {Subscription} from "rxjs";
import {FilterService} from '../generic/service/filter.service';
import {FilterState} from '../../../../../shared-resources/noviflora-common-angular/generic/classes/filter.state';

@Component({
	templateUrl: './spotlight.products.component.html'
})
export class SpotlightProductsComponent implements OnDestroy {
	productPage: ProductPage;
	loading: boolean = true;
	showAllUrl: string;
	title: string;
	description: string;
	origin: string;

	private selectedOrderChangedSubscription: Subscription;
	private readonly _userService: UserService;
	private windowObj = (<any>window);

	constructor(private productService: ProductService,
				domElement: ElementRef,
				filterService: FilterService,
				router: Router,
				userService: UserService,
				private logger: Logger) {

		this._userService = userService;

		let nativeDomElement = domElement.nativeElement;

		let filterQueryString = nativeDomElement.getAttribute('data-filterparams');
		let showViewAllProductsButton = nativeDomElement.getAttribute('data-showviewallproductsbutton');
		this.title = nativeDomElement.getAttribute('data-title');
		this.description = nativeDomElement.getAttribute('data-description');
		this.origin = nativeDomElement.getAttribute('data-origin');

		// If no mode is defined in the smartobject url, use the default 'all' mode.
		filterQueryString = this.addQueryParamIfNotExists(filterQueryString, 'mode', 'all');

		// If no sorting is defined in the smartobject url, use the default 'random' sorting.
		filterQueryString = this.addQueryParamIfNotExists(filterQueryString, 'sorting', 'random');

		if (showViewAllProductsButton === 'true') {
			this.showAllUrl = this.getShowAllProductsUrl(filterQueryString);
		}

		let filterState = new FilterState([]);

		filterService.initFilterStateForRequestParams(
			filterState,
			router.parseUrl(filterQueryString).queryParams
		);

		filterService.initSelectedLanguage(filterState);

		// Remove everything before the querystring '?', including the question mark itself,
		// as it is added by the productService logic.
		this.loadProducts(filterState);
	}

	get userService(): UserService {
		return this._userService;
	}

	ngOnDestroy() {
		if (this.selectedOrderChangedSubscription) {
			this.selectedOrderChangedSubscription.unsubscribe();
		}
	}

	viewProductDetail(detailUrl) {
		detailUrl += `;origin=${this.origin}`;

		ShopHelper.goToWebshopUrl(detailUrl);
	}

	loadProducts(filterState: FilterState) {
		this.productService.getProducts(filterState.getParams()).then(productPage => {
			this.productPage = productPage;
			this.loading = false;

			setTimeout(() => {
				const anchorHash = this.windowObj.location.hash;
				this.productService.triggerScrollToProductOnWebsite(anchorHash);
			}, 700);
		}).catch(error => {
			this.loading = false;
			this.logger.error('Error loading the products from the server', error)
		});
	}

	private getShowAllProductsUrl(queryString: string) {
		// Remove query params, which should not be kept when navigating to the 'all products' overview.
		queryString = this.removeURLParameter(queryString, 'sorting');
		queryString = this.removeURLParameter(queryString, 'pageSize');

		return ShopHelper.getWebshopUrl('products?' + queryString.split('?')[1]);
	}

	private removeURLParameter(url, parameter) {
		let urlparts = url.split('?');

		if (urlparts.length >= 2) {

			let prefix = encodeURIComponent(parameter) + '=';
			let pars = urlparts[1].split(/[&;]/g);

			//reverse iteration as may be destructive
			for (let i = pars.length; i-- > 0;) {
				//idiom for string.startsWith
				if (pars[i].lastIndexOf(prefix, 0) !== -1) {
					pars.splice(i, 1);
				}
			}

			return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : "");
		}

		return url;
	}

	private addQueryParamIfNotExists(queryString, paramName, paramValue) {
		if (queryString.indexOf(paramName) === -1) {
			queryString += queryString.indexOf('?') === -1 ? '?' : '&';
			queryString += `${paramName}=${paramValue}`;
		}

		return queryString;
	}
}
