/**
 * Defines a new user message.
 *
 * Possible values for 'type' are:
 *  - confirmation
 *  - warning
 *  - error
 *
 *  @author hillebrand
 */
export class Message {
    text: string;
    isShown: boolean = false;
    // keeps the message on screen even after browsing away
    // this is used for error messages pertaining to localStorage
    isPersistent: boolean = false;
    type: string;

    constructor(text: string, type: string, isPersistent: boolean) {
        this.text = text || null;
        this.type = type || null;
        this.isPersistent = isPersistent || false;
    }
}
