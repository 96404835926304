"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var forms_1 = require("@angular/forms");
var reset_password_translations_component_1 = require("./reset.password.translations.component");
var password_validation_1 = require("../../classes/password.validation");
var application_service_1 = require("../../service/application.service");
var user_service_1 = require("../../service/user.service");
var default_log_service_1 = require("../../logging/default-log.service");
var message_1 = require("../../classes/message");
var ResetPasswordComponent = (function () {
    function ResetPasswordComponent(applicationService, route, userService, formBuilder, logger) {
        this.applicationService = applicationService;
        this.route = route;
        this.userService = userService;
        this.formBuilder = formBuilder;
        this.logger = logger;
        this.passwordFieldType = 'password';
        this.successRedirect = '/products';
        this.resetPasswordForm = formBuilder.group({
            'password': [null, forms_1.Validators.required],
            'confirmpassword': [null, forms_1.Validators.required]
        }, {
            validator: password_validation_1.PasswordValidation.MatchPassword
        });
    }
    ResetPasswordComponent.prototype.ngOnInit = function () {
        if (this.route.snapshot.queryParams['id']) {
            this.resultUID = this.route.snapshot.queryParams['id'];
        }
    };
    ResetPasswordComponent.prototype.onSubmit = function (form) {
        var _this = this;
        if (!this.resetPasswordForm.valid && !this.resultUID) {
            return;
        }
        this.userService.changePassword(form.confirmpassword, this.resultUID).then(function (result) {
            if (result.returnCode) {
                _this.showMessage(new message_1.Message(_this.resetPasswordTranslationsComponent.getResetPasswordSuccess(), 'confirmation', false));
                _this.loginUserAfterSuccessfulChange(result.loginID, form.confirmpassword);
                _this.resetPasswordForm.reset();
                return;
            }
            _this.showMessage(new message_1.Message(_this.resetPasswordTranslationsComponent.getResetLinkExpired(), 'error', false));
        });
    };
    ResetPasswordComponent.prototype.loginUserAfterSuccessfulChange = function (username, password) {
        var _this = this;
        this.userService.login(username, password).subscribe(function (result) {
            if (result.administrationUrl) {
                window.location.href = result.administrationUrl + '?token=' + result.token;
            }
            else {
                window.location.href = _this.successRedirect;
            }
        }, function (error) {
            _this.logger.debug('Failed to login', error);
        });
    };
    ResetPasswordComponent.prototype.togglePassword = function () {
        this.passwordFieldType = (this.passwordFieldType === 'password') ? 'text' : 'password';
    };
    ResetPasswordComponent.prototype.showMessage = function (message) {
        this.applicationService.message = message;
    };
    return ResetPasswordComponent;
}());
exports.ResetPasswordComponent = ResetPasswordComponent;
