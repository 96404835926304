"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var default_log_service_1 = require("../../../../logging/default-log.service");
var http_service_1 = require("../../../../service/http.service");
var application_service_1 = require("../../../../service/application.service");
var configuration_helper_1 = require("../../../../helper/configuration.helper");
var rxjs_1 = require("rxjs");
var AddCommentComponent = (function () {
    function AddCommentComponent(http, applicationService, formBuilder, logger) {
        this.http = http;
        this.applicationService = applicationService;
        this.formBuilder = formBuilder;
        this.logger = logger;
        this.addedComment = new rxjs_1.Subject();
        this.commentValue = new rxjs_1.Subject();
        this.popupOpen = false;
        this.hasComment = false;
        this.popupOpen = true;
    }
    AddCommentComponent.prototype.ngOnInit = function () {
        this.commentForm = this.formBuilder.group({
            'commentText': new forms_1.FormControl({ value: this.data.comment, disabled: !this.data.isEditable })
        });
    };
    AddCommentComponent.prototype.close = function (saveComment) {
        var _this = this;
        if (saveComment === void 0) { saveComment = false; }
        this.popupOpen = false;
        var body = document.querySelector('body');
        body.classList.toggle('popupOpen', false);
        setTimeout(function () {
            if (saveComment) {
                _this.addedComment.next(true);
                _this.commentValue.next(_this.commentForm.get('commentText').value);
            }
            _this._componentRef.destroy();
        }, 400);
    };
    AddCommentComponent.prototype.onSubmit = function (value) {
        var _this = this;
        if (this.commentForm.valid) {
            var url = configuration_helper_1.ConfigurationHelper.getWebshopUrl('/rest/shoppingcart/comment');
            var commentValue = this.commentForm.get('commentText').value;
            var $xhr = this.http.post(url, JSON.stringify({
                webUUID: this.data.webuuid,
                comment: commentValue
            }));
            $xhr.subscribe(function (result) {
                _this.close(true);
            }, function (error) {
                _this.logger.error('Error adding comment to shopping cart line', error);
            });
            return $xhr;
        }
        return false;
    };
    return AddCommentComponent;
}());
exports.AddCommentComponent = AddCommentComponent;
