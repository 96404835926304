<div [ngClass]="{loading: loading}">
	<div class="loading-overlay"></div>
</div>

<ng-container *ngIf="isUserLoggedin() && !loading">

	<section *ngIf="!show404" class="cms-header cms-header--planthunter"
			 [style.background-image]="'url(' + planthunter.bannerUrl + ')'">
		<div class="row">
			<div class="col-12">
				<div class="cms-header__inner">
				</div>
			</div>
		</div>
	</section>

	<div class="row">

		<div class="col-12 col-sm-12">
			<section>
				<div class="cms-article__back-link">
					<a href="{{planthunterOverviewURL}}">
						<svg class="icon-arrow-left" width="6" height="10">
							<use xmlns:xlink="http://www.w3.org/1999/xlink"
							     xlink:href="/images/icons/svg-sprite.svg#arrow-left"></use>
						</svg>
						<ng-container i18n="Back to planthunters overview link text@@planthunter.back.to.overview">
							Back to overview
						</ng-container>
					</a>
				</div>

				<div *ngIf="!show404" class="planthunter__header">

					<div class="planthunter__hunter planthunter__hunter--detail">

						<figure class="planthunter__hunter__figure">
							<img src="{{planthunter.imageUrl}}" alt="{{planthunter.name}}" />
						</figure>

						<div class="planthunter__hunter__text">
							<h2 class="planthunter__hunter__name">
								{{planthunter.name}}
							</h2>
							<p class="planthunter__hunter__function">{{planthunter.functionDescription}}</p>

							<p class="planthunter__hunter__follow hide-lg" *ngIf="!planthunter.volgStatus">
								<button class="button button--small button--secondary button--text" (click)="followPlanthunter()"
								        i18n="Planthunters start following@@planthunter.start.following">
									Follow {{planthunter.firstName}}
								</button>
							</p>

							<p class="planthunter__hunter__follow hide-lg" *ngIf="planthunter.volgStatus">
								<button class="button button--small button--green" (click)="unfollowPlanthunter()">
									<ng-container i18n="Planthunter following link@@planthunter.following.link">
										Following
									</ng-container>
									&nbsp;
									<svg class="icon-check" width="14" height="12">
										<use xlink:href="/images/icons/svg-sprite.svg#check"></use>
									</svg>
								</button>
							</p>

							<div class="planthunter__hunter__description hide-sm">
								<div [innerHTML]="planthunter.description"></div>

								<div class="planthunter__hunter__summary">
									<span>
										<svg class="icon-search" width="16" height="16">
											<use xmlns:xlink="http://www.w3.org/1999/xlink"
											     xlink:href="/images/icons/svg-sprite.svg#search"></use>
										</svg>
										<ng-container i18n="Planthunter hunts amount@@planthunter.hunts.amount">
											{{planthunter.huntCount}} hunts
										</ng-container>
									</span>
									&nbsp;&nbsp;&nbsp;
									<span>
										<svg class="icon-user" width="16" height="19"
										     style="vertical-align: text-top;">
											<use xmlns:xlink="http://www.w3.org/1999/xlink"
											     xlink:href="/images/icons/svg-sprite.svg#user"></use>
										</svg>
										<ng-container i18n="Planthunter followers amount@@planthunter.followers.amount">
											{{planthunter.followerCount}} followers
										</ng-container>
									</span>
									&nbsp;&nbsp;&nbsp;
									<span>
										<img src="/template/assets/images/icon-speechbubble.svg"
										     style="vertical-align: text-top; display: inline-block; margin-right: 2px;"
										     width="16" height="16" alt="" />
										<a href="mailto:{{planthunter.email}}" style="text-decoration: underline;"
										   i18n="Planthunter contact@@planthunter.contact">
											Contact {{planthunter.firstName}}
										</a>
									</span>

								</div>
							</div>

						</div>

						<div class="planthunter__hunter__follow hide-sm" *ngIf="!planthunter.volgStatus">
							<button class="button button--small button--secondary button--text" (click)="followPlanthunter(planthunter)"
							        i18n="Planthunters start following@@planthunter.start.following">
								Follow {{planthunter.firstName}}
							</button>
						</div>

						<div class="planthunter__hunter__follow hide-sm" *ngIf="planthunter.volgStatus">
							<button class="button button--small button--green" (click)="unfollowPlanthunter(planthunter)">
								<ng-container i18n="Planthunter following link@@planthunter.following.link">
									Following
								</ng-container>
								&nbsp;
								<svg class="icon-check" width="14" height="12">
									<use xlink:href="/images/icons/svg-sprite.svg#check"></use>
								</svg>
							</button>
						</div>

					</div>

					<div class="planthunter__hunter__description hide-lg">
						<div [innerHTML]="planthunter.description"></div>

						<div class="planthunter__hunter__summary">
							<span>
								<svg class="icon-search" width="16" height="16">
									<use xmlns:xlink="http://www.w3.org/1999/xlink"
									     xlink:href="/images/icons/svg-sprite.svg#search"></use>
								</svg>
								<ng-container i18n="Planthunter hunts amount@@planthunter.hunts.amount">
									{{planthunter.huntCount}} hunts
								</ng-container>
							</span>
							<span>
								<svg class="icon-user" width="16" height="19"
								     style="vertical-align: text-top;">
									<use xmlns:xlink="http://www.w3.org/1999/xlink"
									     xlink:href="/images/icons/svg-sprite.svg#user"></use>
								</svg>
								<ng-container i18n="Planthunter followers amount@@planthunter.followers.amount">
									{{planthunter.followerCount}} followers
								</ng-container>
							</span>
							<span>
								<img src="/template/assets/images/icon-speechbubble.svg"
								     style="vertical-align: text-top; display: inline-block; margin-right: 2px;"
								     width="16" height="16" alt="" />
								<a href="mailto:{{planthunter.email}}" style="text-decoration: underline;"
								   i18n="Planthunter contact@@planthunter.contact">
									Contact {{planthunter.firstName}}
								</a>
							</span>
						</div>
					</div>

				</div>
			</section>

			<section *ngIf="!show404" class="planthunter-detail-products">
				<h2 i18n="Planthunters latest hunts@@planthunter.latest.hunts">
					{{planthunter.firstName}}'s latest hunts:
				</h2>

				<div class="planthunter__products">

					<div class="overview-grid overview-grid--planthunter">
						<div class="loading-overlay"></div>
						<product-item-grid
								class="overview-product"
								*ngFor="let product of planthunter.products"
								[product]="product"
								(viewProductDetailClickEvent)="viewProductDetail($event)"
								[loggedIn]="isUserLoggedin()">
						</product-item-grid>
					</div>

				</div>

			</section>

			<notfound *ngIf="show404"></notfound>
		</div>

	</div>

</ng-container>
