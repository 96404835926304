"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var shoppingcart_service_1 = require("../../service/shoppingcart.service");
var user_service_1 = require("../../service/user.service");
var ShoppingcartHeaderMobileComponent = (function () {
    function ShoppingcartHeaderMobileComponent(shoppingCartService, userService) {
        this.shoppingCartService = shoppingCartService;
        this.userService = userService;
        this.viewOrderDetailClickEvent = new core_1.EventEmitter();
        this.viewShoppingcartClickEvent = new core_1.EventEmitter();
    }
    ShoppingcartHeaderMobileComponent.prototype.isFastCheckout = function () {
        return this.userService.isUseFastCheckout();
    };
    Object.defineProperty(ShoppingcartHeaderMobileComponent.prototype, "shoppingCartLines", {
        get: function () {
            return this.shoppingCartService.activeShoppingCartLines;
        },
        enumerable: true,
        configurable: true
    });
    ShoppingcartHeaderMobileComponent.prototype.viewOrderDetail = function () {
        this.viewOrderDetailClickEvent.emit();
    };
    ShoppingcartHeaderMobileComponent.prototype.viewShoppingcart = function () {
        this.viewShoppingcartClickEvent.emit();
    };
    ShoppingcartHeaderMobileComponent.prototype.getShoppingCartCount = function () {
        var size = 0;
        if (this.shoppingCartLines) {
            size = this.shoppingCartLines.length;
        }
        return size;
    };
    return ShoppingcartHeaderMobileComponent;
}());
exports.ShoppingcartHeaderMobileComponent = ShoppingcartHeaderMobileComponent;
