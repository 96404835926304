import {Component} from '@angular/core';
import {ShopHelper} from "./shop.helper";
import {RedirectToShopService} from './redirect.to.shop.service'

@Component({
    selector: 'pending-orders-wrapper-component',
    templateUrl: './pending.orders.wrapper.component.html'
})
export class PendingOrdersWrapperComponent {

    /**
     * @param {RedirectToShopService} redirectService
     * required to enable the 'redirect to shop' logic for this component.
     */
    constructor(redirectService: RedirectToShopService) {
    }

    navigateToWebshopAndStartNewOrder(): void {
        ShopHelper.goToWebshopUrl("/products;newOrder=true")
    }
}