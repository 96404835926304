import {ILogger} from './default-log.service';
import {Injectable} from '@angular/core';
import {ConfigurationHelper} from '../helper/configuration.helper';

@Injectable()
export class LogService implements ILogger {

	/**
	 * @param error
	 * @return {string} the error message which was embedded in the error,
	 * or null if none found.
	 * @private
	 */
	private static _extractMessageFromJsonBody(errorResponse: any): string {
		try {
			if (!errorResponse && !errorResponse.error) {
				return null;
			}

			return errorResponse.error.message;
		} catch (e) {
			return null;
		}
	}

	/* tslint:disable:no-console */
	trace(message: string, error?: any): void {
		if (error) {
			const errorMessage = message + ' - ' + this.getErrorMessage(error);
			if (console && console.trace) { console.trace(errorMessage); }
			this.logOnServer(error, errorMessage, 'TRACE');
		} else {
			if (console && console.trace) { console.trace(message); }
		}
	}

	debug(message: string, error?: any): void {
		if (error) {
			const errorMessage = message + ' - ' + this.getErrorMessage(error);
			if (console && console.log) { console.log(errorMessage); }
			this.logOnServer(error, errorMessage, 'DEBUG');
		} else {
			if (console && console.log) { console.log(message); }
		}
	}

	warn(message: string, error?: any): void {
		if (error) {
			const errorMessage = message + ' - ' + this.getErrorMessage(error);
			if (console && console.warn) { console.warn(errorMessage); }
			this.logOnServer(error, errorMessage, 'WARN');
		} else {
			if (console && console.warn) { console.warn(message); }
		}
	}

	error(message: string, error?: any): void {
		if (error) {
			const errorMessage = message + ' - ' + this.getErrorMessage(error);
			if (console && console.error) { console.error(errorMessage); }
			this.logOnServer(error, errorMessage, 'ERROR');
		} else {
			if (console && console.error) { console.error(message); }
		}
	}

	info(message: string, error?: any): void {
		if (error) {
			const errorMessage = message + ' - ' + this.getErrorMessage(error);
			if (console && console.info) { console.info(errorMessage); }
			this.logOnServer(error, errorMessage, 'INFO');
		} else {
			if (console && console.info) { console.info(message); }
		}
	}

	private logOnLogRocket(error: any, message: string, severity: string) {
		const LogRocket = (<any>window).LogRocket;

		if (!LogRocket) {
			return;
		}

		if (error) {
			LogRocket.captureException(error, {
				extra: {
					message: message,
					severity: severity
				}
			});

			return;
		}

		LogRocket.captureMessage(message, {
			extra: {
				message: message,
				severity: severity
			}
		});
	}

	/** @internal */
	private logOnServer(error: any, message: string, severity: string): void {

		this.logOnLogRocket(error, message, severity);

		const xhr = new XMLHttpRequest();
		xhr.open('POST', ConfigurationHelper.getWebshopUrl('/public/rest/servermonitorjs/log.web'), true);
		xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
		xhr.send(`message=${message}&severity=${severity}`);
	}

	/** @internal */
	private getErrorMessage(error: any): string {

		let message = `EXCEPTION: ${this._extractMessage(error)}`;

		if (!error) {
			return message;
		}

		// Add all properties from the
		// error object to the error message.
		try {
			message += '\n';

			for (const key in error) {
				if (error.hasOwnProperty(key)) {
					message += '\n' + key + ' -> ' + JSON.stringify(error[key]);
				}
			}
		} catch (e) {
			// do nothing.
		}

		return message;
	}

	/** @internal */
	private _extractMessage(error: any): string {

		const message = LogService._extractMessageFromJsonBody(error);

		if (message) {
			return message;
		}

		return error instanceof Error ? error.message : error.toString();
	}

}
