import {ShopSettings} from '../classes/shop.settings';
import {WindowRef} from '../service/window-ref.service';

export class ConfigurationHelper {

	private static winRef: WindowRef = new WindowRef();

	/**
	 * @param {string} relativeUrl
	 * @return {string} the absolute webshop url.
	 */
	static getWebshopUrl(relativeUrl: string): string {
		let webshopBaseUrl = ConfigurationHelper.getWebshopBaseUrl();

		webshopBaseUrl = webshopBaseUrl.endsWith('/') ? webshopBaseUrl.slice(0, -1) : webshopBaseUrl;

		if (!relativeUrl.startsWith('/')) {
			relativeUrl = '/' + relativeUrl;
		}

		return webshopBaseUrl + relativeUrl;
	}

	/**
	 * @return {string} the base url for the webshop.
	 */
	static getWebshopBaseUrl(): string {
		return this.winRef.nativeWindow.webshopBaseUrl;
	}

	/**
	 * @return {string} the base url for the cms/site.
	 */
	static getCmsBaseUrl(): string {
		return this.winRef.nativeWindow.cmsBaseUrl;
	}

	/**
	 *
	 * @return {ShopSettings} the shop settings for the currently active shop.
	 * These settings are added in the index.html with Thymeleaf code as variable on the window object,
	 * so they can be used in Angular code.
	 */
	static getSettings(): ShopSettings {
		return this.winRef.nativeWindow.shopSettings;
	}

	/**
	 * This is used to conditionally show elements based on if the user has localStorage enabled
	 * window.storage is set as a global var in app.module.ts
	 * @return {boolean} the localStorage object for the window if available
	 */
	static isLocalStorageAvailable(): boolean {
		return (!!this.winRef.nativeWindow.storage);
	}

	/**
	 * Check to see if the browser allows cookies.
	 * Currently browsers handle the disabling of local storage and cookies differently
	 * Most browsers allow you only to disable cookies but also disable localStorage with it.
	 * Other browsers allow you to disable them seperately
	 * @return {boolean} the value of the navigator.cookieEnabled property
	 */
	static isCookiesAvailable(): boolean {
		return this.winRef.nativeWindow.navigator.cookieEnabled;
	}

	/**
	 * Function that first checks if localStorage is available then writes to localStorage
	 * LocalStorage setItem
	 */
	static setItem(key: string, data: string) {
		if (this.isLocalStorageAvailable()) {
			this.winRef.nativeWindow.storage.setItem(key, data);
		}
	}

	/**
	 * Function that first checks if localStorage is available then reads an item from localStorage
	 * @return {localStorage} localStorage item by key
	 */
	static getItem(key: string) {
		if (!this.isLocalStorageAvailable()) {
			return;
		}

		return this.winRef.nativeWindow.storage.getItem(key);
	}

	/**
	 * Function that first checks if localStorage is available then removes a item in localStorage
	 */
	static removeItem(key: string) {
		if (this.isLocalStorageAvailable()) {
			this.winRef.nativeWindow.storage.removeItem(key);
		}
	}

	/**
	 * Function that first checks if localStorage is available then clears the localStorage
	 */
	static clearLocalStorage() {
		if (this.isLocalStorageAvailable()) {
			this.winRef.nativeWindow.storage.clear();
		}
	}
}
