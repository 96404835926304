import {map, publishLast, refCount} from 'rxjs/operators';
import {ActiveOrder} from '../classes/active.order';
import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';


import {BaseService} from './base.service';
import {HttpClient} from './http.service';
import {DeliveryService} from './delivery.service';
import {UserService} from './user.service';
import {Logger} from '../logging/default-log.service';
import {ActiveOrderStateHelper} from './active.order.state.helper';
import {ConfigurationHelper} from '../helper/configuration.helper';

/**
 * This functionality is used in the navbar to show the active orders
 *
 * @author evandongen
 */
@Injectable()
export class ActiveOrderService extends BaseService {
	private _selectedOrderChangedSubject = new Subject<ActiveOrder>();

	private _selectedOrder: ActiveOrder;

	private _activeOrders: ActiveOrder[];

	constructor(private http: HttpClient,
				private userService: UserService,
				private deliveryService: DeliveryService,
				logger: Logger) {

		super(logger);

		// There are no active orders for non-logged in users.
		if (!userService.getUser()) {
			return;
		}

		this.loadActiveOrders();
	}

	get selectedOrderChangedSubject(): Subject<ActiveOrder> {
		return this._selectedOrderChangedSubject;
	}

	get selectedOrder(): ActiveOrder {
		return this._selectedOrder;
	}

	get activeOrders(): ActiveOrder[] {
		return this._activeOrders;
	}

	getSavedActiveOrder(): ActiveOrder {
		const stringValue = ActiveOrderStateHelper.getActiveOrderFromCookie();

		if (stringValue) {
			return JSON.parse(stringValue);
		}

		return null;
	}

	/**
	 * First resets the active orders and then refreshes
	 * them by retrieving the active orders from the shop backend.
	 */
	public reloadActiveOrders() {
		this.resetActiveOrders();
		this.loadActiveOrders();
	}

	/**
	 * Gets the active orders from the backend.
	 */
	public loadActiveOrders() {
		this.getActiveOrdersFromServer().subscribe(
			activeOrders => {
				this._activeOrders = this.filterOpenActiveOrdersAndAddCurrentSavedActiveOrderIfTransient(activeOrders);

				const activeOrder = this.getSavedActiveOrder();

				if (activeOrder) {
					this.setSelectedOrder(activeOrder);
				}
			},
			error => {
				this.resetActiveOrders();
			});
	}

	getActiveOrderForShipmentDateAndShipToCode(shipmentDate, shipToCode) {
		return this.findActiveOrderForShipmentDateAndShipToCode(this.activeOrders, shipmentDate, shipToCode);
	}

	findActiveOrderForShipmentDateAndShipToCode(activeOrders: ActiveOrder[], shipmentDate, shipToCode) {
		return activeOrders.find(activeOrder => {
			return activeOrder.shipmentDate.toString() === shipmentDate.toString() && activeOrder.shipToCode === shipToCode;
		});
	}

	/**
	 * Finds the matching active order from the current list of active orders. Checks it by closing time.
	 * @param activeOrder
	 * @returns {any}
	 */
	public getActiveOrderFromList(activeOrder: ActiveOrder) {

		// Make sure to filter out the expired activeOrder
		if (typeof activeOrder !== 'undefined' && this.deliveryService.isActiveOrderStillOpen(activeOrder)) {
			return this.getActiveOrderForShipmentDateAndShipToCode(activeOrder.shipmentDate, activeOrder.shipToCode);
		}

		return null;
	}

	/**
	 * In some cases, all selected order state needs to be removed
	 */
	removeSelectedOrder() {
		this.setSelectedOrder(null);
	}

	setSelectedOrder(activeOrder: ActiveOrder) {
		const newSelectedOrder = activeOrder != null ? this.getActiveOrderFromList(activeOrder) : null;

		// If selected order is set and it's still open, set it
		if (newSelectedOrder) {
			this._selectedOrder = newSelectedOrder;
		} else {
			// Make sure to unset the active order if it's not valid anymore
			this._selectedOrder = null;
		}

		ActiveOrderStateHelper.saveActiveOrderInStorage(this._selectedOrder);

		this.selectedOrderChangedSubject.next(this._selectedOrder);
	}

	hasSelectedOrder() {
		return this.selectedOrder != null;
	}

	setOrderSelectedForShipmentDateAndDeliveryAddress(shipmentDate, shipToCode) {
		const matchingOrder = this.getActiveOrderForShipmentDateAndShipToCode(shipmentDate, shipToCode);
		this.setSelectedOrder(matchingOrder);
	}

	/**
	 * Gets the active orders
	 *
	 * @returns {Observable<R>}
	 */
	getActiveOrdersFromServer(): Observable<ActiveOrder[]> {
		const url = ConfigurationHelper.getWebshopUrl('/public/rest/order/activeorders');

		return this.http.get<ActiveOrder[]>(url)
			.pipe(
				publishLast(),
				refCount());
	}

	/**
	 * Gets only the active orders with unconfirmed rows
	 *
	 * @returns {Observable<R>}
	 */
	getActiveOrdersWithUnconfirmedRowsFromServer(): Observable<ActiveOrder[]> {
		const url = ConfigurationHelper.getWebshopUrl('/rest/order/activeorderswithunconfirmedrows');

		const self = this;

		return this.http.get<ActiveOrder[]>(url)
			.pipe(
				map(orders => {
					return self.filterExpiredActiveOrders(self.deliveryService, orders);
				}),
				publishLast(),
				refCount()
			);
	}

	private filterOpenActiveOrdersAndAddCurrentSavedActiveOrderIfTransient(activeOrders: ActiveOrder[]): ActiveOrder[] {
		// Check if the shipment date has been selected and add it to the list if it isn't in it yet
		const activeOrder: ActiveOrder = this.getSavedActiveOrder();

		if (activeOrder) {
			const existingOrder = this.findActiveOrderForShipmentDateAndShipToCode(activeOrders,
				activeOrder.shipmentDate, activeOrder.shipToCode);

			// If the current saved active order is not yet present in the active orders list,
			// it means, the user didn't add any products yet to this order.
			// So we have to add it manually to the active orders list.
			if (!existingOrder) {
				// Make sure the list of activeOrders contains the activeOrder as well. This is the case if a new order
				// is created and no products are in it yet.
				activeOrders.push(activeOrder);
				ActiveOrderStateHelper.saveActiveOrderInStorage(activeOrder);
			}
		}

		// Make sure to filter out the active orders which have expired
		return this.filterExpiredActiveOrders(this.deliveryService, activeOrders);
	}

	private filterExpiredActiveOrders(deliveryService: DeliveryService, activeOrders: ActiveOrder[]) {
		return activeOrders.filter(order => deliveryService.isActiveOrderStillOpen(order));
	}

	private resetActiveOrders(): void {
		this._activeOrders = null;
	}

}
