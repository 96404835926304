"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var configuration_helper_1 = require("../helper/configuration.helper");
var LogService = (function () {
    function LogService() {
    }
    LogService._extractMessageFromJsonBody = function (errorResponse) {
        try {
            if (!errorResponse && !errorResponse.error) {
                return null;
            }
            return errorResponse.error.message;
        }
        catch (e) {
            return null;
        }
    };
    LogService.prototype.trace = function (message, error) {
        if (error) {
            var errorMessage = message + ' - ' + this.getErrorMessage(error);
            if (console && console.trace) {
                console.trace(errorMessage);
            }
            this.logOnServer(error, errorMessage, 'TRACE');
        }
        else {
            if (console && console.trace) {
                console.trace(message);
            }
        }
    };
    LogService.prototype.debug = function (message, error) {
        if (error) {
            var errorMessage = message + ' - ' + this.getErrorMessage(error);
            if (console && console.log) {
                console.log(errorMessage);
            }
            this.logOnServer(error, errorMessage, 'DEBUG');
        }
        else {
            if (console && console.log) {
                console.log(message);
            }
        }
    };
    LogService.prototype.warn = function (message, error) {
        if (error) {
            var errorMessage = message + ' - ' + this.getErrorMessage(error);
            if (console && console.warn) {
                console.warn(errorMessage);
            }
            this.logOnServer(error, errorMessage, 'WARN');
        }
        else {
            if (console && console.warn) {
                console.warn(message);
            }
        }
    };
    LogService.prototype.error = function (message, error) {
        if (error) {
            var errorMessage = message + ' - ' + this.getErrorMessage(error);
            if (console && console.error) {
                console.error(errorMessage);
            }
            this.logOnServer(error, errorMessage, 'ERROR');
        }
        else {
            if (console && console.error) {
                console.error(message);
            }
        }
    };
    LogService.prototype.info = function (message, error) {
        if (error) {
            var errorMessage = message + ' - ' + this.getErrorMessage(error);
            if (console && console.info) {
                console.info(errorMessage);
            }
            this.logOnServer(error, errorMessage, 'INFO');
        }
        else {
            if (console && console.info) {
                console.info(message);
            }
        }
    };
    LogService.prototype.logOnLogRocket = function (error, message, severity) {
        var LogRocket = window.LogRocket;
        if (!LogRocket) {
            return;
        }
        if (error) {
            LogRocket.captureException(error, {
                extra: {
                    message: message,
                    severity: severity
                }
            });
            return;
        }
        LogRocket.captureMessage(message, {
            extra: {
                message: message,
                severity: severity
            }
        });
    };
    LogService.prototype.logOnServer = function (error, message, severity) {
        this.logOnLogRocket(error, message, severity);
        var xhr = new XMLHttpRequest();
        xhr.open('POST', configuration_helper_1.ConfigurationHelper.getWebshopUrl('/public/rest/servermonitorjs/log.web'), true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.send("message=" + message + "&severity=" + severity);
    };
    LogService.prototype.getErrorMessage = function (error) {
        var message = "EXCEPTION: " + this._extractMessage(error);
        if (!error) {
            return message;
        }
        try {
            message += '\n';
            for (var key in error) {
                if (error.hasOwnProperty(key)) {
                    message += '\n' + key + ' -> ' + JSON.stringify(error[key]);
                }
            }
        }
        catch (e) {
        }
        return message;
    };
    LogService.prototype._extractMessage = function (error) {
        var message = LogService._extractMessageFromJsonBody(error);
        if (message) {
            return message;
        }
        return error instanceof Error ? error.message : error.toString();
    };
    return LogService;
}());
exports.LogService = LogService;
