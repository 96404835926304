import {Component, EventEmitter, OnDestroy, Output} from '@angular/core';
import {Subscription} from 'rxjs';
import {ApplicationService} from '../../service/application.service';
import {ActiveOrderService} from '../../service/active.order.service';
import {ActiveOrder} from '../../classes/active.order';

/**
 * @author evandongen
 */
@Component({
	selector: 'order-tabs',
	templateUrl: 'ordertabs.component.html'
})
export class OrderTabsComponent implements OnDestroy {
	allProductsValue = 'allProducts';
	@Output() changeOrderEvent = new EventEmitter();
	@Output() clickedAllProductsEvent = new EventEmitter();
	private selectedOrderChangedSubscription: Subscription;

	private _selectedOrder: any = this.allProductsValue;

	constructor(public applicationService: ApplicationService,
				public activeOrderService: ActiveOrderService) {

		this.setSelectStateForActiveOrder(activeOrderService.selectedOrder);

		this.selectedOrderChangedSubscription = activeOrderService.selectedOrderChangedSubject.subscribe(newActiveOrder => {
			this.setSelectStateForActiveOrder(newActiveOrder);
		});
	}

	get selectedOrder() {
		return this._selectedOrder;
	}

	get activeOrders(): Array<ActiveOrder> {
		return this.activeOrderService.activeOrders;
	}

	get firstActiveOrder(): ActiveOrder {
		return this.activeOrders && this.activeOrders.length > 0 ? this.activeOrders.values().next().value : null;
	}

	ngOnDestroy() {
		this.selectedOrderChangedSubscription.unsubscribe();
	}

	showOrderSwitcher(): boolean {
		return this.selectedOrder !== this.allProductsValue;
	}

	unSelectOrder(): void {
		this.activeOrderService.setSelectedOrder(null);
		this.changeOrderEvent.emit();
		this.clickedAllProductsEvent.emit();
	}

	/**
	 * Opens the starts new order dialog,
	 * and after the submit closes this opened popup.
	 * The generic logic in StartOrderComponent handles the activation of the 'active order'.
	 */
	startNewOrder(): void {
		this.applicationService.openPopup(this.applicationService.closePopup);
	}

	/**
	 * Submit handler - check if 'add new' is selected or if another order was selected
	 *
	 * @param activeOrder
	 */
	changeOrder(activeOrder: Object) {

		if (activeOrder === 'new') {

			this.applicationService.openPopupForNew(
				this.applicationService.closePopup
			);

			return;
		}

		if (activeOrder === this.allProductsValue) {
			this.unSelectOrder();
			return;
		}

		this.activeOrderService.setSelectedOrder(<ActiveOrder>activeOrder);
		this.changeOrderEvent.emit();
	}

	trackByOrder(index, order) {
		return order.shipmentDate;
	}

	private setSelectStateForActiveOrder(newActiveOrder) {
		this._selectedOrder = newActiveOrder != null ? newActiveOrder : this.allProductsValue;
	}
}
