import {Component} from '@angular/core';
import {UserService} from '../generic/service/user.service';
import {ProductService} from '../generic/service/product.service';
import {PlanthunterService} from '../generic/service/planthunter.service';
import {Planthunter} from '../generic/classes/planthunter';
import {WindowRef} from '../generic/service/window-ref.service';

@Component({
	selector: 'planthunter-overview-component',
	templateUrl: './planthunter.overview.component.html'
})
export class PlanthunterOverviewComponent {
	private static winRef: WindowRef = new WindowRef();
	loading: boolean = true;
	planthunterDetailURL: string;
	private _userService: UserService;
	private _planthunters: Planthunter[];

	constructor(private planthunterService: PlanthunterService,
	            private productSerivce: ProductService,
	            userService: UserService) {
		const anchorHash = PlanthunterOverviewComponent.winRef.nativeWindow.location.hash;
		this._userService = userService;
		this.planthunterDetailURL = PlanthunterOverviewComponent.winRef.nativeWindow.planthunterDetailURL;

		this.planthunterService.getPlanthunters().then(
			planthunter => {
				this._planthunters = planthunter;
				this.loading = false;

				setTimeout(() => {
					this.productSerivce.triggerScrollToProductOnWebsite(anchorHash);
				}, 700);
			}
		);
	}

	get planthunters(): Planthunter[] {
		return this._planthunters;
	}

	isUserLoggedin(): boolean {
		return this._userService.isLoggedIn();
	}

	getPlantHunterDetailPageURL(planthunter: Planthunter): string {
		return `${this.planthunterDetailURL}?plantHunterId=${planthunter.planthunterFeature}`;
	}

	followPlanthunter(planthunter: Planthunter): void {
		this.planthunterService.followPlanthunter(planthunter.code).then(
			() => planthunter.volgStatus = true
		);
	}

	unfollowPlanthunter(planthunter: Planthunter): void {
		this.planthunterService.unfollowPlanthunter(planthunter.code).then(
			() => planthunter.volgStatus = false
		);
	}
}
