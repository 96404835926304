import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewRef
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgOption} from '@ng-select/ng-select';
import {IntersectionState, LazyLoadService} from 'ng-lazy-load';
import {Subscription} from 'rxjs';
import {Product} from '../../classes/product';
import {ShoppingCartService} from '../../service/shoppingcart.service';
import {ActiveOrderService} from '../../service/active.order.service';
import {UserService} from '../../service/user.service';
import {ProductService} from '../../service/product.service';
import {ShoppingCartLine} from '../../classes/shoppingcartline';
import {ProductItemCommonComponent} from './productitem.common.component';
import {ProductPrices} from '../../classes/product.prices';
import {ConfigurationHelper} from '../../helper/configuration.helper';
import {BrowserService} from '../../service/browser.service';
import {WindowRef} from '../../service/window-ref.service';
import {AnalyticsService} from '../../service/analytics.service';

/**
 * This component has animations. When adding a product, the state changes from normal to added. After a
 * timeout of 2 seconds, it animates back to the normal state.
 *
 * @author evandongen
 */
@Component({
	selector: 'product-item-grid',
	templateUrl: 'productitem.grid.component.html'
})
export class ProductItemGridComponent extends ProductItemCommonComponent implements OnInit, OnDestroy, AfterViewInit {
	private static winRef: WindowRef = new WindowRef();
	// Unit Types
	static readonly UNIT_VALUE_TYPE = 'unit';
	static readonly TRAY_VALUE_TYPE = 'tray';
	static readonly LAYER_VALUE_TYPE = 'layer';
	static readonly TROLLEY_VALUE_TYPE = 'trolley';

	@Input() product: Product;
	@Input() showMoreInfoButton: boolean = false;
	@Input() loggedIn: boolean;
	@Input() useFastCheckout: boolean;
	@Input() scrollToId: string;
	@Input() userCurrency: string;
	@Input() buttonsDisabled: boolean;
	@Output() productMatchedFragmentEvent = new EventEmitter();
	@Output() viewProductDetailClickEvent = new EventEmitter();
	form: FormGroup;
	priceBasedOnSelectedUnit: number;
	priceBasedOnSelectedUnitWithDiscount: number;
	trolleyPriceWithDiscount: number;
	quantityValue: number;
	minimumOrderQuantity: number;
	maxValue: number;
	selectedUnit: string;
	itemsPerUnit: number;
	itemsInOrder: number = 0;
	buttonShowPressed: boolean = false;
	showConfirmation: boolean = false;
	stockProduct: boolean;
	showProduct: boolean = false;
	availableUnitOptions: NgOption[];
	productPrices: ProductPrices;

	private shoppingcartSubscription: Subscription;

	constructor(private lazyLoadService: LazyLoadService,
	            private shoppingCartService: ShoppingCartService,
	            private formBuilder: FormBuilder,
	            private element: ElementRef,
	            private activeOrderService: ActiveOrderService,
	            private userService: UserService,
	            private analyticsService: AnalyticsService,
	            private changeDetectorRef: ChangeDetectorRef,
	            public productService: ProductService,) {
		super();
	}

	doLoad(state: IntersectionState) {
		this.showProduct = true;
	}

	triggerProductMatchedFragmentEvent(anchorValue: string) {
		this.productMatchedFragmentEvent.emit(anchorValue);
	}

	getProduct(): Product {
		return this.product;
	}

	getProductService() {
		return this.productService;
	}

	getScrollToId() {
		return this.scrollToId;
	}

	ngOnDestroy(): void {
		if (this.shoppingcartSubscription) {
			this.shoppingcartSubscription.unsubscribe();
		}

		this.changeDetectorRef.detach();
	}

	isFastCheckout(): boolean {
		return this.userService.isUseFastCheckout();
	}

	/**
	 * Check if the product is loaded on the website by matching the url to '/site/{lang}/'
	 */
	isWebsite(): boolean {
		const location = ProductItemGridComponent.winRef.nativeWindow.location.pathname;
		const regexLocation = /^\/(site)\/([a-z]{2})\//gm;

		return location.match(regexLocation);
	}

	ngAfterViewInit() {
		super.ngAfterViewInit();
	}

	ngOnInit() {
		super.ngOnInit();

		if (BrowserService.isEdgeBrowser() || this.isWebsite()) {
			this.showProduct = true;
		} else {
			this.lazyLoadService.isDevMode = false;
			this.lazyLoadService.loadAheadCount = 10;
		}

		this.form = this.formBuilder.group({
			'numberOfUnits': ['', Validators.required],
			'unit': [this.product.qtyByBox, Validators.required]
		});

		this.itemsPerUnit = this.product.qtyByBox;
		this.minimumOrderQuantity = this.getMinimumOrderQuantity();
		this.maxValue = this.getMaxValue();
		this.quantityValue = this.productService.getMinimumOrderQuantity(this.product, this.product.qtyByBox);
		this.stockProduct = this.isStockProduct();

		if (this.loggedIn) {
			this.shoppingcartSubscription = this.shoppingCartService.getShoppingCartObservable().subscribe(
				lines => this.updateItemsInOrder(lines)
			);
		}

		this.productPrices = new ProductPrices(this.product, this.itemsInOrder);

		if (this.productPrices.showUnitOption()) {
			this.selectedUnit = 'unit';
		}

		if (this.productPrices.showTrayOption()) {
			this.selectedUnit = 'tray';
		}

		this.setPriceAndItemsPerUnitBasedOnSelectedUnit();

		this.trolleyPriceWithDiscount = this.product.trolleyDiscountPrice;

		this.availableUnitOptions = this.createAvailableUnitOptions();

		this.form.get('numberOfUnits').setValue(this.quantityValue);
		this.form.get('unit').setValue(this.selectedUnit);
	}

	onSelectedUnitChange($event) {
		this.selectedUnit = $event.value;

		this.changeQuantity();
		this.setPriceAndItemsPerUnitBasedOnSelectedUnit();
	}

	/**
	 * Checks if the combination of quantity and order unit is not less than the minimnum quantity or
	 * larger than the available quantity.
	 */
	changeQuantity() {
		const minimum = this.minimumOrderQuantity;

		if (this.stockProduct) {
			const orderQuantity = this.quantityValue * this.itemsPerUnit;

			const availableQuantity = this.getAvailableQuantity();

			if (availableQuantity > 0 && this.quantityValue < minimum) {
				this.quantityValue = minimum;
				this.form.get('numberOfUnits').patchValue(this.quantityValue);
			}

			if (availableQuantity > 0 && orderQuantity > availableQuantity) {
				this.quantityValue = this.getMaximumOrderQuantity();
				this.form.get('numberOfUnits').patchValue(this.quantityValue);
			}
		}

		// Make sure the value in the input is altered if the minimum is > the current value
		if (minimum > this.quantityValue || minimum > this.form.get('numberOfUnits').value) {
			this.quantityValue = minimum;
			this.form.get('numberOfUnits').patchValue(this.quantityValue);
		}
	}

	/**
	 * returning null will disable the 'max' attribute.
	 *
	 * @returns {any}
	 */
	public getMaxValue(): number {
		if (this.stockProduct) {
			return this.getMaximumOrderQuantity();
		}

		return null;
	}

	/**
	 * @param value
	 */
	onSubmit(value: any) {
		if (this.buttonShowPressed) {
			return;
		}

		if (this.form.invalid) {
			return;
		}

		const numberOfItemsToOrder: number = value.numberOfUnits * this.itemsPerUnit;

		// Don't show the spinner if the user is first to select an order in the popup.
		if (this.activeOrderService.hasSelectedOrder()) {
			this.buttonShowPressed = true;
			// Because some parts of the component are rendered from a value (show) that changes in the parent we need
			// to detect the changes in the child too since the parent uses the onPush strategy.
			// For all other interactivity inside this specific component that don't rely on parent values we do not
			// need to detect the changes
			this.changeDetectorRef.detectChanges();
		}

		const productOrderDone = this.shoppingCartService.orderProduct(this.product, numberOfItemsToOrder, false);

		// Can be null if the user had first to select an (new) active order.
		if (productOrderDone == null) {
			return;
		}

		productOrderDone.subscribe(() => {
			if (this.buttonShowPressed === true) {
				this.showConfirmation = true;
				this.changeDetectorRef.detectChanges();
				this.productService.triggerProductAddedToCartEvent(this.product);

				setTimeout(() => {
					this.showConfirmation = false;
					this.buttonShowPressed = false;

					// Because we do this change detection in a timeout we need to first check
					// if the view has been destroyed at all so we don't try to detecht changes
					// on a view that might be destroyed by angular through for example navigation
					// TODO: SUPNOVICOM-901 Extend ChangeDetectorRef code to allow for simple view destruction checking
					if (!(this.changeDetectorRef as ViewRef).destroyed) {
						this.changeDetectorRef.detectChanges();
					}
				}, 2000);
			}
		}, () => {
			this.buttonShowPressed = false;
			this.changeDetectorRef.detectChanges();
		});

	}

	/**
	 * Only check quantity if type == 1
	 * @returns {boolean}
	 */
	public isStockProduct() {
		return this.product.type === this.productService.TYPE_STOCK;
	}

	/**
	 * Take the quantity already ordered into account
	 *
	 * @returns {number}
	 */
	public getAvailableQuantity(): number {
		const available = this.product.availableQuantity - this.itemsInOrder;

		return (available < 0) ? 0 : available;
	}

	/**
	 * Checks if the current url is the products page.
	 *
	 * @returns {boolean}
	 */
	isWebshopProductsUrl(): boolean {
		return (window.location.href.indexOf(ConfigurationHelper.getWebshopBaseUrl()) > -1
			&& window.location.href.indexOf('products') > -1);
	}

	/**
	 * Redirects to the product detail page but sets a browser state before the actual routing.
	 */
	viewProductDetail() {
		const productDetailUrl = `/products/detail;type=${this.product.type};priceListNo=${this.product.priceListNo};priceListLineNo=${this.product.priceListLineNo}`;
		this.analyticsService.trackProductViewDetail(this.product.totalDescription1, productDetailUrl);

		// Only 'push' the product state if you navigate from the webshop products page to be able to go 'back'
		// to a specific #anchor in the page.
		if (this.isWebshopProductsUrl()) {
			this.productService.pushProductState(this.product);
		} else {
			const location = ProductItemGridComponent.winRef.nativeWindow.location.pathname;
			this.productService.pushWebsiteProductState(this.product, location);
		}

		this.viewProductDetailClickEvent.emit(productDetailUrl);
	}

	private setPriceAndItemsPerUnitBasedOnSelectedUnit() {
		if (this.selectedUnit === ProductItemGridComponent.UNIT_VALUE_TYPE || this.selectedUnit === ProductItemGridComponent.TRAY_VALUE_TYPE) {
			this.itemsPerUnit = this.product.qtyByBox;
			this.priceBasedOnSelectedUnit = this.product.unitPrice;
			this.priceBasedOnSelectedUnitWithDiscount = this.product.unitDiscountPrice;
		}

		if (this.selectedUnit === ProductItemGridComponent.LAYER_VALUE_TYPE) {
			this.itemsPerUnit = this.product.qtyByBox * this.product.qtyPackingsByLayer;
			this.priceBasedOnSelectedUnit = this.product.layerPrice;
			this.priceBasedOnSelectedUnitWithDiscount = this.product.layerDiscountPrice;
		}

		if (this.selectedUnit === ProductItemGridComponent.TROLLEY_VALUE_TYPE) {
			this.itemsPerUnit = this.product.qtyByTrolley;
			this.priceBasedOnSelectedUnit = this.product.trolleyPrice;
			this.priceBasedOnSelectedUnitWithDiscount = this.product.trolleyDiscountPrice;
		}

		this.minimumOrderQuantity = this.getMinimumOrderQuantity();
		this.quantityValue = this.minimumOrderQuantity;
		this.form.get('numberOfUnits').patchValue(this.quantityValue);
	}

	/**
	 * Makes sure the minimum value to be ordered of this unit, is based on the items per unit.
	 * @returns {number}
	 */
	private getMinimumOrderQuantity(): number {
		return this.productService.getMinimumOrderQuantity(this.product, this.itemsPerUnit);
	}

	private getMaximumOrderQuantity(): number {
		return Math.floor(this.getAvailableQuantity() / this.itemsPerUnit);
	}

	private updateItemsInOrder(lines: ShoppingCartLine[]) {

		if (lines) {
			const activeLine = lines.find(line => line.priceListLineNo === this.product.priceListLineNo
				&& line.priceListNo === this.product.priceListNo);

			if (activeLine !== undefined &&
				(activeLine.shoppingCartMode || this.shoppingCartService.isShoppingCartLineEditable(activeLine))) {
				this.itemsInOrder = activeLine.quantity;
			}
		}
	}

	/**
	 * Create an Array for the price select box per product. Each product has their own prices and quantities
	 * @return {Array}
	 */
	private createAvailableUnitOptions() {
		// Initialise an empty array
		const optionsArray = [];
		let optionObject = {};

		// Create the array based on availabilty
		if (this.productPrices.showUnitOption()) {
			optionObject = {
				value: ProductItemGridComponent.UNIT_VALUE_TYPE,
				qty: this.product.qtyByBox,
				price: this.productPrices.getUnitOrTrayNormalOrDiscountPrice()
			};
			optionsArray.push(optionObject);
		}

		if (this.productPrices.showTrayOption()) {
			optionObject = {
				value: ProductItemGridComponent.TRAY_VALUE_TYPE,
				qty: this.product.qtyByBox,
				price: this.productPrices.getUnitOrTrayNormalOrDiscountPrice()
			};
			optionsArray.push(optionObject);
		}

		if (this.productPrices.showLayerOption()) {
			optionObject = {
				value: ProductItemGridComponent.LAYER_VALUE_TYPE,
				qty: this.product.qtyPackingsByLayer,
				qtyBox: this.product.qtyByBox,
				price: this.productPrices.getLayerNormalOrDiscountPrice()
			};
			optionsArray.push(optionObject);
		}

		if (this.productPrices.showTrolleyOption()) {
			optionObject = {
				value: ProductItemGridComponent.TROLLEY_VALUE_TYPE,
				qty: this.product.qtyPackingsByTrolley,
				qtyBox: this.product.qtyByBox,
				price: this.productPrices.getTrolleyNormalOrDiscountPrice()
			};
			optionsArray.push(optionObject);
		}

		return optionsArray;
	}
}
