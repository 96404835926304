import {catchError, map, share} from 'rxjs/operators';
import {Injectable} from '@angular/core';

import {User} from '../classes/user';
import {HttpClient} from './http.service';
import {BaseService} from './base.service';
import {Administration} from '../classes/administration';
import {Logger} from '../logging/default-log.service';
import {Observable} from 'rxjs';
import {ActiveOrderStateHelper} from './active.order.state.helper';
import {ConfigurationHelper} from '../helper/configuration.helper';

/**
 * User service for login and user operations
 *
 * @author evandongen
 */
@Injectable()
export class UserService extends BaseService {

	private static COOKIE_AUTH_TOKEN: string = 'auth_token';
	private static USER_COOKIE_KEY: string = 'current_user';

	private user: User;

	constructor(private http: HttpClient, logger: Logger) {
		super(logger);
		this.initUserFromStorage();
	}

	static cleanupOldStorageValuesAndRedirectToLogin() {
		UserService.deleteUserInStorage();
		ActiveOrderStateHelper.clearSelectedOrderFromCookie();

		window.location.href = '/login';
	}

	static setUserInStorage(user: User) {
		// CAUTION: when changing the expires date, the auth_token expires date should also be adjusted,
		// to the new amount of days in the java TokenUtils class.
		HttpClient.saveJsonInCookie(this.USER_COOKIE_KEY, user ? user : null, 7);
	}

	static deleteUserInStorage() {
		HttpClient.deleteCookie(this.USER_COOKIE_KEY);
	}

	initUserFromStorage() {

		if (!this.hasValidAuthTokenCookie()) {
			// If there's no valid auth token, we should,
			// remove the storage state.
			UserService.deleteUserInStorage();
			return;
		}

		// Can happen if the auth_token is not valid anymore
		// This window var is both set in the site and shop.
		if (!(<any>window).isLoggedIn) {
			UserService.deleteUserInStorage();
			HttpClient.deleteCookie(UserService.COOKIE_AUTH_TOKEN);
			return;
		}

		const userJsonString = HttpClient.getCookie(UserService.USER_COOKIE_KEY, true);

		if (userJsonString) {
			this.user = JSON.parse(userJsonString);
			return;
		}

		// If there is an auth token cookie, but no user in a cookie,
		// the auth token cookie should be removed.
		if (this.hasValidAuthTokenCookie()) {
			HttpClient.deleteCookie(UserService.COOKIE_AUTH_TOKEN);
		}
	}

	getUserCartWaitTimeInSeconds(): number {
		return this.user.cartWaitTime * 60;
	}

	/**
	 * @returns {string} the telephone number of the sales person for the current user or the default if
	 * user is empty or telephone number is empty
	 */
	getUserSalesPersonTelephoneNumber(): string {
		const user = this.getUser();

		const defaultTelephoneNumber = '+31 17 4671 414';

		if (!user || !user.amTelephone) {
			return defaultTelephoneNumber;
		}

		return user.amTelephone;
	}

	getUser(): User {
		return this.user;
	}

	isUseFastCheckout(): boolean {
		// CAUTION: temp. forced due to problems at Noviflora, see for more information:
		// SUPNOVICOM-176 Spoed: winkelwagen modus uitschakelen nav vraag Sander
		return true;
	}

	getUserDefaultLanguage(): string {
		const userLang = HttpClient.getCookie('webshopLanguage');

		if (userLang) {
			return userLang;
		}

		const user = this.getUser();
		return user ? user.language : 'ENG';
	}

	login(user: string, password: string) {
		const url = ConfigurationHelper.getWebshopUrl('/public/rest/remotelogin');

		return this.http.post<any>(url, JSON.stringify({
			username: user,
			password: password
		})).pipe(map(jsonResponse => {

			if (jsonResponse.user) {
				UserService.setUserInStorage(jsonResponse.user);
				ActiveOrderStateHelper.clearSelectedOrderFromCookie();
			}

			return new Administration(jsonResponse.administrationUrl, jsonResponse.token);
		}));
	}

	logout() {
		this.http.post('/logout', null)
			.subscribe(
				response => {
					UserService.cleanupOldStorageValuesAndRedirectToLogin();
				},
				error => this.handleError(error)
			);
	}

	hasValidAuthTokenCookie(): boolean {
		const value = HttpClient.getCookie(UserService.COOKIE_AUTH_TOKEN);
		return typeof value !== 'undefined' && value != null;
	}

	isLoggedIn(): boolean {
		return this.hasValidAuthTokenCookie() && (typeof this.user !== 'undefined' && this.user !== null);
	}

	requestPasswordReset(user: string): Promise<any> {
		const url = ConfigurationHelper.getWebshopUrl('/public/rest/password/requestreset');
		const userLanguage = this.getUserDefaultLanguage();

		return this.http.post<any>(url, JSON.stringify({
			username: user,
			language: userLanguage
		})).pipe(
			catchError(this.handleError)
		).toPromise();
	}

	changePassword(password: string, resetuid: string): Promise<any> {
		const url = ConfigurationHelper.getWebshopUrl('/public/rest/password/reset');

		return this.http.post<any>(url, JSON.stringify({
			newpassword: password,
			resetuid: resetuid
		})).pipe(
			catchError(this.handleError)
		).toPromise();
	}

	/**
	 * Call this method to override the current user object in this user store,
	 * and update the storage with the new value, so when the page is refreshed,
	 * we get the new updated user state.
	 *
	 * @param {User} user the new user state.
	 */
	updateUserState(user: User) {
		this.user = user;
		UserService.setUserInStorage(user);
	}

	/**
	 * Update the fast checkout option and announce the updated user to the subscribers
	 *
	 * @param fastCheckout
	 */
	updateFastcheckout(fastCheckout: boolean): Observable<User> {
		const $xhr = this.http
			.post<User>('/user/fastcheckout', JSON.stringify({fastCheckout: fastCheckout})).pipe(
				share(), );

		$xhr.subscribe(
			user => this.updateUserState(user),
			error => this.handleError(error));

		return $xhr;
	}

}
