"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var user_service_1 = require("../../service/user.service");
var global_validator_1 = require("../../global.validator");
var http_service_1 = require("../../service/http.service");
var forms_1 = require("@angular/forms");
var default_log_service_1 = require("../../logging/default-log.service");
var application_service_1 = require("../../service/application.service");
var message_1 = require("../../classes/message");
var reset_password_translations_component_1 = require("./reset.password.translations.component");
var configuration_helper_1 = require("../../helper/configuration.helper");
var ForgotPasswordComponent = (function () {
    function ForgotPasswordComponent(applicationService, http, route, userService, formBuilder, logger) {
        this.applicationService = applicationService;
        this.http = http;
        this.route = route;
        this.userService = userService;
        this.formBuilder = formBuilder;
        this.logger = logger;
        this.formError = false;
        this.shopSettings = configuration_helper_1.ConfigurationHelper.getSettings();
        this.forgotPasswordForm = formBuilder.group({
            'email': [null, forms_1.Validators.compose([forms_1.Validators.required, global_validator_1.GlobalValidator.mailFormat])]
        });
    }
    ForgotPasswordComponent.prototype.ngOnInit = function () {
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
    };
    ForgotPasswordComponent.prototype.isShowRegisterBlock = function () {
        if (!this.shopSettings) {
            return true;
        }
        return !this.shopSettings.kvkShop;
    };
    ForgotPasswordComponent.prototype.onSubmit = function (form) {
        var _this = this;
        if (!this.forgotPasswordForm.valid) {
            return;
        }
        this.formError = false;
        this.userService.requestPasswordReset(form.email).then(function (result) {
            if (result.returnCode) {
                _this.showMessage(new message_1.Message(_this.resetPasswordTranslationsComponent.getForgotEmailSent(), 'confirmation', false));
                _this.forgotPasswordForm.reset();
                return;
            }
            _this.formError = true;
        });
    };
    ForgotPasswordComponent.prototype.showMessage = function (message) {
        this.applicationService.message = message;
    };
    return ForgotPasswordComponent;
}());
exports.ForgotPasswordComponent = ForgotPasswordComponent;
