import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserService} from '../../service/user.service';
import {PlanthunterService} from '../../service/planthunter.service';
import {Planthunter} from '../../classes/planthunter';
import {ConfigurationHelper} from '../../helper/configuration.helper';
import {Product} from '../../classes/product';

/**
 * Planthunter product grid overview with added load more button
 *
 * @author Patrick van Zadel
 */
@Component({
	selector: 'planthunter-overview-grid-component',
	templateUrl: './planthunter.overview.grid.component.html'
})
export class PlanthunterOverviewGridComponent implements OnInit {
	@Input() planthunter: Planthunter;
	@Output() viewProductDetailClickEvent = new EventEmitter();

	loading: boolean;
	planthunterDetailURL: string;
	planthunterProductList: Product[];
	showAllProducts: boolean;
	allProductsFetched: boolean;

	constructor(private planthunterService: PlanthunterService,
				private userService: UserService) {
	}

	ngOnInit() {
		this.planthunterProductList = this.planthunter.products;
	}

	toggleShowMorePlanthunterProducts() {
		if (this.showAllProducts) {
			this.gotoActivePlanthunter(this.planthunter.planthunterFeature);
			this.showAllProducts = false;
			return;
		}

		if (this.allProductsFetched) {
			this.showAllProducts = true;
			return;
		}

		this.loading = true;
		this.getMorePlanthunterProducts(this.planthunter.planthunterFeature);
	}

	getMorePlanthunterProducts(planthunterFeature: string) {
		this.planthunterService.getMorePlanthunterProducts(planthunterFeature)
		.then(
			products => {
				this.planthunterProductList = [...this.planthunterProductList, ...products];
				this.showAllProducts = true;
				this.allProductsFetched = true;
				this.loading = false;
			}
		);
	}

	getShowAmountOfProducts() {
		return this.showAllProducts ? this.planthunterProductList.slice(0, 99) : this.planthunterProductList.slice(0, 4);
	}

	isShowToggleMoreProduct(): boolean {
		return this.planthunterProductList.length < 5;
	}

	gotoActivePlanthunter(planthunterId: string) {
		const planthunterElement = document.querySelector('#ID' + planthunterId);
		const planthunterBoundingRect = planthunterElement.getBoundingClientRect();
		const planthunterElementYPosition = planthunterBoundingRect.top;
		// Check if window.scrollY is available and use that. Otherwise fallback to pageYOfsset (for IE)
		const windowScrollY = (window.scrollY === undefined) ? window.pageYOffset : window.scrollY;

		window.scrollTo(0, planthunterElementYPosition + windowScrollY);
	}

	viewProductDetail(detailUrl) {
		window.location.href = ConfigurationHelper.getWebshopUrl(detailUrl + ';origin=planthunter');
	}

	isUserLoggedin(): boolean {
		return this.userService.isLoggedIn();
	}
}
