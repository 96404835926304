import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';

import {Logger} from '../logging/default-log.service';
import {BaseService} from './base.service';
import {HttpClient} from './http.service';
import {Planthunter} from '../classes/planthunter';
import {UserService} from './user.service';
import {ConfigurationHelper} from '../helper/configuration.helper';
import {Product} from '../classes/product';

export class PlanthunterConfig {
	planthunterDetailURL: string;
}

/**
 * @author Hillebrand
 */
@Injectable()
export class PlanthunterService extends BaseService {

	constructor(private http: HttpClient,
				logger: Logger,
				private userService: UserService) {
		super(logger);
	}

	getAmountOfNewPlanthunterItems(): Promise<number> {
		if (this.userService.isLoggedIn()) {
			const url = ConfigurationHelper.getWebshopUrl('/rest/planthunter/newhunts');
			return this.http.get<number>(url)
				.toPromise();
		}
		return Promise.resolve(-1);
	}

	/**
	 * @return the language which should be used for the planthunter calls.
	 * @see "java code (NoviWebsiteFrontControllerCustomizer) for more information"
	 */
	getLanguageForPlanthunterCalls(): String {
		const language = (<any>window).planthunterWebshopLanguage;

		return language && language.length > 0 ? language : this.userService.getUserDefaultLanguage();
	}

	getPlanthunters(): Promise<Planthunter[]> {
		const url = ConfigurationHelper.getWebshopUrl(
			`/rest/planthunters?language=${this.getLanguageForPlanthunterCalls()}`
		);

		return this.http.get<Planthunter[]>(url).pipe(
			catchError(this.handleError)
		).toPromise();
	}

	getMorePlanthunterProducts(planthunterId: string): Promise<Product[]> {
		const url = ConfigurationHelper.getWebshopUrl(
			`/rest/planthunters/${planthunterId}/get-products?language=${this.userService.getUserDefaultLanguage()}`
		);

		return this.http.get<Product[]>(url)
			.pipe(
				catchError(this.handleError)
			)
			.toPromise();
	}

	getSinglePlanthunter(planthunterId: string): Promise<Planthunter> {
		const url = ConfigurationHelper.getWebshopUrl(
			`/rest/planthunter/${planthunterId}?language=${this.getLanguageForPlanthunterCalls()}`
		);

		return this.http.get<Planthunter>(url)
			.pipe(
				catchError(this.handleError)
			)
			.toPromise();
	}

	followPlanthunter(planthunterId: string): Promise<void> {
		const url = ConfigurationHelper.getWebshopUrl(
			`/rest/planthunter/${planthunterId}/follow?language=${this.getLanguageForPlanthunterCalls()}`
		);

		return this.http.get<void>(url).pipe(
			catchError(this.handleError))
			.toPromise();
	}

	unfollowPlanthunter(planthunterId: string): Promise<void> {
		const url = ConfigurationHelper.getWebshopUrl(
			`/rest/planthunter/${planthunterId}/unfollow?language=${this.getLanguageForPlanthunterCalls()}`
		);

		return this.http.get<void>(url).pipe(
			catchError(this.handleError))
			.toPromise();
	}
}
