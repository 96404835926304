import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from './http.service';

import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {DeliveryDate} from '../classes/deliverydate';
import {DeliveryAddress} from '../classes/deliveryaddress';
import {ActiveOrder} from '../classes/active.order';
import {Logger} from '../logging/default-log.service';
import {Product} from '../classes/product';
import {ConfigurationHelper} from '../helper/configuration.helper';

@Injectable()
export class DeliveryService extends BaseService {

	constructor(private http: HttpClient, logger: Logger) {
		super(logger);
	}

	getDeliveryAddresses(): Observable<DeliveryAddress[]> {
		return this.http.get<DeliveryAddress[]>(ConfigurationHelper.getWebshopUrl('/rest/deliveryaddresses'))
			.pipe(
				catchError(this.handleError)
			);
	}

	getDeliveryAddressForCode(code: string): Observable<DeliveryAddress> {
		return this.http.get<DeliveryAddress>(ConfigurationHelper.getWebshopUrl('/rest/deliveryaddress?code=' + encodeURIComponent(code)))
			.pipe(
				catchError(this.handleError)
			);
	}

	/**
	 * Get a DeliveryDate object for the given Date
	 *
	 * @param findForProduct can be null when you don't want to filter the delivery date for a specific product.
	 * @param shipmentDate
	 * @returns {Observable<DeliveryDate>}
	 */
	getDeliveryDate(findForProduct: Product, shipmentDate: Date): Observable<DeliveryDate> {
		const url = ConfigurationHelper.getWebshopUrl(
			`/rest/product/deliverydate?shipmentDate=${shipmentDate}&${this.getProductUrlParams(findForProduct)}`
		);

		return this.http.get<DeliveryDate>(url)
			.pipe(
				catchError(this.handleError)
			);
	}

	/**
	 * @param {Product} findForProduct can be null when you don't want to filter the delivery date for a specific product.
	 * @returns {Observable<DeliveryDate[]>}
	 */
	getDeliveryDates(findForProduct: Product): Observable<DeliveryDate[]> {
		const url = ConfigurationHelper.getWebshopUrl(
			`/rest/product/deliverydates?${this.getProductUrlParams(findForProduct)}`
		);

		return this.http.get<DeliveryDate[]>(url)
			.pipe(
				catchError(this.handleError)
			);
	}

	public getClosingInHours(closingTime: number): number {
		let hours = 0;

		if (closingTime) {
			const now = Date.now();
			const diff = closingTime - now;

			if (diff > 0) {
				hours = Math.abs(diff) / 36e5;
			}
		}

		return hours;
	}

	/**
	 * Check if the given ActiveOrder is still open
	 *
	 * @param activeOrder
	 * @returns {boolean}
	 */
	public isActiveOrderStillOpen(activeOrder: ActiveOrder): boolean {

		// Only check if deliveryDate object is not null
		if (activeOrder.closingTime) {
			return this.getClosingInHours(activeOrder.closingTime) > 0;
		}

		// Otherwise, it's a new order that hasn't come back from the server yet.
		return true;
	}

	public getClosingTimeStringForDeliveryDate(deliveryDate: DeliveryDate, closingInHours: number,
											   dayTranslation: string, daysTranslation: string,
											   tomorrowTranslation: string, closedTranslation: string, closedInTranslation: string): string {

		return this.getClosingTimeString(deliveryDate.closingTime, deliveryDate.closingHour, closingInHours,
			dayTranslation, daysTranslation, tomorrowTranslation, closedTranslation, closedInTranslation);
	}

	/**
	 * Tries to format the closing time string which differs between 'closed', '15:00' (an hour in the day),
	 * or the number of days if larger than 24
	 */
	public getClosingTimeString(closingTime: number, closingHour: number, closingInHours: number,
								dayTranslation: string, daysTranslation: string, tomorrowTranslation: string,
								closedTranslation: string, closedInTranslation: string): string {

		if (closingInHours < 1) {
			return closedTranslation;
		}

		if (closingInHours >= 24) {
			const numberOfDays: number = Math.round(closingInHours / 24);
			return closedInTranslation + ' ' + numberOfDays + ' ' + (numberOfDays === 1 ? dayTranslation : daysTranslation);
		}

		if (this.checkClosingTimeToday(closingTime)) {
			// determine exact hour
			return closingHour + ':00';
		}

		return tomorrowTranslation + ' ' + closingHour + ':00';
	}

	private getProductUrlParams(product: Product): string {
		if (!product) {
			return '';
		}

		return `type=${product.type}&priceListLineNo=${product.priceListLineNo}&priceListNo=${product.priceListNo}`;
	}

	/**
	 * Compare given timestamp with today to check if both timestamps are on the same day
	 *
	 * @param closingTime
	 * @returns {boolean}
	 */
	private checkClosingTimeToday(closingTime: number) {
		const currentDate = new Date().setHours(0, 0, 0, 0);
		const closingTimeDate = new Date(closingTime).setHours(0, 0, 0, 0);

		if (currentDate === closingTimeDate) {
			return true;
		}

		return false;
	}
}
