"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var default_log_service_1 = require("../../logging/default-log.service");
var active_order_1 = require("../../classes/active.order");
var delivery_service_1 = require("../../service/delivery.service");
var active_order_service_1 = require("../../service/active.order.service");
var application_service_1 = require("../../service/application.service");
var StartOrderComponent = (function () {
    function StartOrderComponent(deliveryService, formBuilder, applicationService, activeOrderService, logger) {
        var _this = this;
        this.deliveryService = deliveryService;
        this.formBuilder = formBuilder;
        this.applicationService = applicationService;
        this.activeOrderService = activeOrderService;
        this.logger = logger;
        this.loading = true;
        this.popupOpen = false;
        this.closed = new core_1.EventEmitter();
        this.saved = new core_1.EventEmitter();
        this.startNew = true;
        var fetchDeliveryDatesPromise = this.fetchDeliveryDates();
        var getDeliveryAddressesPromise = this.deliveryService.getDeliveryAddresses().toPromise();
        getDeliveryAddressesPromise.then(function (deliveryAddresses) {
            _this.deliveryAddresses = deliveryAddresses;
            _this.deliveryAddress = _this.deliveryAddresses[0];
        });
        getDeliveryAddressesPromise.catch(function (error) {
            _this.logger.error('Error while getting delivery addresses', error);
        });
        Promise.all([fetchDeliveryDatesPromise, getDeliveryAddressesPromise])
            .then(function () {
            _this.loading = false;
            _this.popupOpen = true;
            _this.preSelectFirstActiveOrder();
        }).catch(function () {
            _this.close();
        });
    }
    Object.defineProperty(StartOrderComponent.prototype, "activeOrders", {
        get: function () {
            return this.activeOrderService.activeOrders;
        },
        enumerable: true,
        configurable: true
    });
    StartOrderComponent.prototype.getActiveOrderForDeliveryDate = function (deliveryDate) {
        return this.activeOrders.find(function (activeOrder) {
            return activeOrder.shipmentDate === deliveryDate.shipmentDate;
        });
    };
    StartOrderComponent.prototype.getDeliveryDateForOrders = function () {
        var _this = this;
        return this.activeOrders
            .map(function (order) {
            return _this.getDeliveryDateForOrder(order);
        })
            .filter(function (deliveryDate) { return deliveryDate != null && typeof deliveryDate !== 'undefined'; });
    };
    StartOrderComponent.prototype.ngOnInit = function () {
        this.form = this.formBuilder.group({
            'selectedOrder': ['', forms_1.Validators.required],
            'shipmentDate': ['', forms_1.Validators.required],
            'deliveryAddress': ['', forms_1.Validators.required]
        });
    };
    StartOrderComponent.prototype.changeDate = function () {
        this.shipmentDate = this.form.get('shipmentDate').value;
    };
    StartOrderComponent.prototype.handleClick = function (event) {
        var clickedComponent = event.target;
        if (clickedComponent.className === 'modal-background') {
            this.close();
        }
    };
    StartOrderComponent.prototype.getDeliveryDateForOrder = function (order) {
        return this.shipmentDates.find(function (date) { return order && date && date.shipmentDate === order.shipmentDate; });
    };
    StartOrderComponent.prototype.close = function () {
        var _this = this;
        this.popupOpen = false;
        setTimeout(function () {
            _this.closed.emit(false);
            _this.applicationService.closePopup();
        }, 400);
    };
    StartOrderComponent.prototype.onSubmit = function (value) {
        var _this = this;
        if (this.loading) {
            return;
        }
        if (this.startNew) {
            if (this.deliveryAddress) {
                this.loading = true;
                this.deliveryService.getDeliveryDate(this.getOptionalProductForStartOrderScope(), this.shipmentDate.shipmentDate).subscribe(function (shipmentDate) {
                    if (shipmentDate == null) {
                        _this.logger.warn('Error while getting delivery date for ' + _this.shipmentDate.shipmentDate +
                            '. Closing time may have passed. If this occurs frequently something else may be wrong. ' +
                            'This error means an order can\'t be started for the selected delivery date!!!');
                        _this.fetchDeliveryDates();
                        return;
                    }
                    _this.shipmentDate = shipmentDate;
                    var newSelectedOrder = new active_order_1.ActiveOrder();
                    newSelectedOrder.shipmentDate = _this.shipmentDate.shipmentDate;
                    newSelectedOrder.deliveryAddressName = _this.deliveryAddress.name;
                    newSelectedOrder.shipToCode = _this.deliveryAddress.code;
                    newSelectedOrder.volume = 0;
                    var existingOrder = _this.activeOrderService.getActiveOrderForShipmentDateAndShipToCode(newSelectedOrder.shipmentDate, newSelectedOrder.shipToCode);
                    if (existingOrder) {
                        newSelectedOrder = existingOrder;
                    }
                    else {
                        _this.activeOrderService.activeOrders.push(newSelectedOrder);
                    }
                    _this.activeOrderService.setSelectedOrder(newSelectedOrder);
                    _this.saved.emit();
                }, function (error) {
                    _this.loading = false;
                    _this.logger.error('Error while getting delivery date for ' + _this.shipmentDate.shipmentDate, error);
                });
            }
            return;
        }
        if (this.selectedOrderSelect) {
            this.loading = true;
            this.activeOrderService.setSelectedOrder(this.selectedOrderSelect);
            this.saved.emit();
        }
    };
    StartOrderComponent.prototype.preSelectFirstActiveOrder = function () {
        var deliveryDateForOrders = this.getDeliveryDateForOrders();
        if (deliveryDateForOrders && deliveryDateForOrders.length > 0) {
            this.selectedOrderSelect = this.getActiveOrderForDeliveryDate(deliveryDateForOrders[0]);
            this.form.get('selectedOrder').setValue(this.selectedOrderSelect);
        }
    };
    StartOrderComponent.prototype.fetchDeliveryDates = function () {
        var _this = this;
        var promise = this.deliveryService.getDeliveryDates(this.getOptionalProductForStartOrderScope()).toPromise();
        promise.then(function (shipmentDates) {
            _this.shipmentDates = shipmentDates;
            if (shipmentDates.length > 0) {
                _this.shipmentDate = shipmentDates[0];
                _this.form.get('shipmentDate').setValue(_this.shipmentDate);
            }
        });
        promise.catch(function (error) {
            _this.logger.error('Error while getting delivery dates', error);
        });
        return promise;
    };
    StartOrderComponent.prototype.getOptionalProductForStartOrderScope = function () {
        return this.applicationService.startOrderPopupFocussedProduct;
    };
    return StartOrderComponent;
}());
exports.StartOrderComponent = StartOrderComponent;
