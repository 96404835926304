import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UserService} from '../../service/user.service';
import {GlobalValidator} from '../../global.validator';
import {HttpClient} from '../../service/http.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Logger} from '../../logging/default-log.service';
import {ApplicationService} from '../../service/application.service';
import {Message} from '../../classes/message';
import {ResetPasswordTranslationsComponent} from './reset.password.translations.component';
import {ShopSettings} from '../../classes/shop.settings';
import {ConfigurationHelper} from '../../helper/configuration.helper';

@Component({
    selector: 'forgot-password-component',
    templateUrl: 'forgot.password.component.html',
})
export class ForgotPasswordComponent implements OnInit {
    @ViewChild(ResetPasswordTranslationsComponent) resetPasswordTranslationsComponent: ResetPasswordTranslationsComponent;

    forgotPasswordForm: FormGroup;
    formError: boolean = false;
    returnUrl: string;

    shopSettings: ShopSettings = ConfigurationHelper.getSettings();

    constructor(public applicationService: ApplicationService,
                private http: HttpClient,
                private route: ActivatedRoute,
                private userService: UserService,
                private formBuilder: FormBuilder,
                private logger: Logger) {

        this.forgotPasswordForm = formBuilder.group({
            'email': [null, Validators.compose([Validators.required, GlobalValidator.mailFormat])]
        });
    }

    ngOnInit() {
        // get return url from route parameters
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
    }

    isShowRegisterBlock() {
        // Can be null if we're on the 'CMS website' login page.
        if (!this.shopSettings) {
            return true;
        }

        return !this.shopSettings.kvkShop;
    }

    onSubmit(form: any) {
        if (!this.forgotPasswordForm.valid) {
            return;
        }

        this.formError = false;

        this.userService.requestPasswordReset(form.email).then(result => {
            if (result.returnCode) {
                this.showMessage(new Message(this.resetPasswordTranslationsComponent.getForgotEmailSent(), 'confirmation', false));
                this.forgotPasswordForm.reset();

                return;
            }

            this.formError = true;
        });
    }

    private showMessage(message: Message) {
        this.applicationService.message = message;
    }
}
