"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Message = (function () {
    function Message(text, type, isPersistent) {
        this.isShown = false;
        this.isPersistent = false;
        this.text = text || null;
        this.type = type || null;
        this.isPersistent = isPersistent || false;
    }
    return Message;
}());
exports.Message = Message;
