"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var user_service_1 = require("../generic/service/user.service");
var planthunter_service_1 = require("../generic/service/planthunter.service");
var shop_helper_1 = require("./shop.helper");
var application_service_1 = require("../generic/service/application.service");
var PlanthunterDetailComponent = (function () {
    function PlanthunterDetailComponent(planthunterService, router, applicationService, userService) {
        this.planthunterService = planthunterService;
        this.router = router;
        this.loading = true;
        this.show404 = false;
        this.applicationService = applicationService;
        this.userService = userService;
        this.windowObj = window;
        this.planthunterOverviewURL = this.windowObj.planthunterOverviewURL;
    }
    PlanthunterDetailComponent.prototype.ngOnInit = function () {
        this.getPlanthunterIfUserIsLoggedIn();
    };
    PlanthunterDetailComponent.prototype.getPlanthunterIfUserIsLoggedIn = function () {
        var _this = this;
        if (this.isUserLoggedin()) {
            this.planthunterService.getSinglePlanthunter(this.windowObj.plantHunterId).then(function (planthunter) {
                _this.planthunter = planthunter;
                _this.loading = false;
            }).catch(function (error) {
                _this.loading = false;
                _this.show404 = true;
            });
            return;
        }
        var planthunterDetailUrl = this.windowObj.location.href;
        this.windowObj.location.href = this.applicationService.getCmsLoginUrl(planthunterDetailUrl);
    };
    PlanthunterDetailComponent.prototype.isUserLoggedin = function () {
        return this.userService.isLoggedIn();
    };
    PlanthunterDetailComponent.prototype.viewProductDetail = function (detailUrl) {
        shop_helper_1.ShopHelper.goToWebshopUrl(detailUrl + ';origin=planthunter');
    };
    PlanthunterDetailComponent.prototype.followPlanthunter = function () {
        var _this = this;
        this.planthunterService.followPlanthunter(this.planthunter.code).then(function (comment) { return _this.planthunter.volgStatus = true; });
    };
    PlanthunterDetailComponent.prototype.unfollowPlanthunter = function () {
        var _this = this;
        this.planthunterService.unfollowPlanthunter(this.planthunter.code).then(function (comment) { return _this.planthunter.volgStatus = false; });
    };
    return PlanthunterDetailComponent;
}());
exports.PlanthunterDetailComponent = PlanthunterDetailComponent;
