import {FormControl} from '@angular/forms';

export class GlobalValidator {

	static mailFormat(control: FormControl): ValidationResult {

		const EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

		if (control.value !== null && (control.value.length <= 5 || !EMAIL_REGEXP.test(control.value))) {
			return {'incorrectMailFormat': true};
		}

		return null;
	}

	static minValue(minValue) {
		return function (control) {
			const value = +control.value;

			return value < minValue ? {'minValue': {'requiredMinValue': minValue, 'actualValue': value}} : null;
		};
	}
}

interface ValidationResult {
	[key: string]: boolean;
}
