"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var active_order_service_1 = require("../generic/service/active.order.service");
var shop_helper_1 = require("./shop.helper");
var ShoppingcartHeaderWrapperComponent = (function () {
    function ShoppingcartHeaderWrapperComponent(activeOrderService) {
        this.activeOrderService = activeOrderService;
        var windowObj = window;
        this.shoppingbarHeaderWrapperAlreadyRendered = windowObj.shoppingbarHeaderWrapperAlreadyRendered;
    }
    ShoppingcartHeaderWrapperComponent.prototype.hasSelectedOrder = function () {
        return this.activeOrderService.hasSelectedOrder();
    };
    ShoppingcartHeaderWrapperComponent.prototype.viewPendingOrders = function () {
        shop_helper_1.ShopHelper.goToWebshopUrl('my/pending/orders');
    };
    ShoppingcartHeaderWrapperComponent.prototype.viewShoppingcart = function () {
        shop_helper_1.ShopHelper.goToWebshopUrl('products/shoppingcart');
    };
    ShoppingcartHeaderWrapperComponent.prototype.clickedAllProductsEvent = function () {
        return shop_helper_1.ShopHelper.goToWebshopUrl('products');
    };
    ShoppingcartHeaderWrapperComponent.prototype.viewOrderDetail = function () {
        var activeOrder = this.activeOrderService.getSavedActiveOrder();
        if (activeOrder !== null) {
            shop_helper_1.ShopHelper.goToWebshopUrl("my/pending/orders/detail;shipmentDate=" + activeOrder.shipmentDate + ";deliveryAddressCode=" + activeOrder.shipToCode);
        }
    };
    return ShoppingcartHeaderWrapperComponent;
}());
exports.ShoppingcartHeaderWrapperComponent = ShoppingcartHeaderWrapperComponent;
