import { Component, Input } from '@angular/core';

/**
 * Used to work around i18n limitations
 */
@Component({
    selector: '[render-part-of-day]',
    template: `<ng-container [ngSwitch]="partOfDay">
                <ng-template [ngSwitchCase]="1">
                    <ng-container i18n="part of day - morning@@part.of.day.morning">
	                    morning
                    </ng-container>
                </ng-template>
                <ng-template [ngSwitchCase]="2">
                    <ng-container i18n="part of day - afternoon@@part.of.day.afternoon">
	                    afternoon
                    </ng-container>
                </ng-template>
                <ng-template [ngSwitchCase]="3">
                    <ng-container i18n="part of day - evening@@part.of.day.evening">
	                    evening
                    </ng-container>
                </ng-template>
            </ng-container>`
})
export class RenderPartOfDayComponent {
    @Input() partOfDay: number;
}
