"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var planthunter_service_1 = require("../../service/planthunter.service");
var PlanthunterAmountOfNewHuntsComponent = (function () {
    function PlanthunterAmountOfNewHuntsComponent(planthunterService) {
        var _this = this;
        this.planthunterService = planthunterService;
        this.newHunts = 0;
        this.planthunterService.getAmountOfNewPlanthunterItems().then(function (amountOfNewPlanthunterItems) {
            _this.newHunts = amountOfNewPlanthunterItems;
        });
    }
    return PlanthunterAmountOfNewHuntsComponent;
}());
exports.PlanthunterAmountOfNewHuntsComponent = PlanthunterAmountOfNewHuntsComponent;
