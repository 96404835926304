"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var active_order_service_1 = require("../generic/service/active.order.service");
var shoppingcart_header_wrapper_component_1 = require("./shoppingcart.header.wrapper.component");
var shoppingcart_service_1 = require("../generic/service/shoppingcart.service");
var ShoppingcartHeaderMobileWrapperComponent = (function (_super) {
    __extends(ShoppingcartHeaderMobileWrapperComponent, _super);
    function ShoppingcartHeaderMobileWrapperComponent(activeOrderService, shoppingcartService) {
        var _this = _super.call(this, activeOrderService) || this;
        var activeOrder = activeOrderService.getSavedActiveOrder();
        if (activeOrder) {
            shoppingcartService.updateShoppingCartForActiveOrder(activeOrder);
        }
        return _this;
    }
    return ShoppingcartHeaderMobileWrapperComponent;
}(shoppingcart_header_wrapper_component_1.ShoppingcartHeaderWrapperComponent));
exports.ShoppingcartHeaderMobileWrapperComponent = ShoppingcartHeaderMobileWrapperComponent;
