import {Component} from '@angular/core';
import {UserService} from '../../../service/user.service';
import {User} from '../../../classes/user';
import {PricelistService} from '../../../service/pricelist.service';
import {Pricelist} from '../../../classes/pricelist';
import {Logger} from '../../../logging/default-log.service';
import {FilterService} from '../../../service/filter.service';
import {DateService} from '../../../service/date.service';

@Component({
    selector: 'my-pricelists',
    templateUrl: 'pricelists.component.html'
})
export class PricelistsComponent {
    public user: User;
    public pricelists: Pricelist[];
    public noPricelistsAvailable: boolean = false;

    constructor(private userService: UserService,
                private pricelistService: PricelistService,
                private dateService: DateService,
                private filterService: FilterService,
                private logger: Logger) {

        this.pricelistService.getPricelists(filterService.getFilterState().shipmentDate).subscribe(
            pricelists => this.setPricelists(pricelists),
            error => this.logger.error('Failed to get price lists', error)
        );
    }

    getUser(): User {
        return this.userService.getUser();
    }

    /**
     * Gets the number of days between now and the valid until date.
     *
     * @param priceList
     * @returns {number}
     */
    public getAvailableDays(priceList: Pricelist): number {
        return this.dateService.getDaysBetween(new Date(), priceList.validUntil) + 1;
    }

    private setPricelists(pricelists) {
        this.pricelists = pricelists;
        this.noPricelistsAvailable = pricelists.length === 0;
    }
}
