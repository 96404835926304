import {LocationStrategy} from '@angular/common';
import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';

import {Observable, Subject} from 'rxjs';
import {BaseService} from './base.service';
import {HttpClient} from './http.service';
import {ApplicationService} from './application.service';
import {Logger} from '../logging/default-log.service';
import {FilterState} from '../classes/filter.state';
import {Product} from '../classes/product';
import {LruCache} from '../classes/lru.cache';
import {ProductDetail} from '../classes/product.detail';
import {FilterService} from './filter.service';
import {ProductPage} from '../classes/product.page';
import {ProductsAndFeatures} from '../classes/products.and.features';
import {ConfigurationHelper} from '../helper/configuration.helper';

/**
 * Contains the calls to the backend and some business logic for determining the prices.
 *
 */
@Injectable()
export class ProductService extends BaseService {

	public TYPE_STOCK: number = 1;
	private productsUrl = ConfigurationHelper.getWebshopUrl('/public/rest/products-and-features');
	private detailUrl = ConfigurationHelper.getWebshopUrl('/public/rest/products/detail');

	private productAddedToCart = new Subject<Product>();

	productAdded$ = this.productAddedToCart.asObservable();

	/**
	 * Cache of max 1 'products and features' entry,
	 * which is used to make navigating to a product detail page
	 * and back to the overview a lot faster.
	 *
	 * @type {LruCache<Promise<ProductsAndFeatures>>}
	 */
	private productsAndFeaturesResultCache = new LruCache<Promise<ProductsAndFeatures>>(1);

	constructor(private http: HttpClient,
	            private applicationService: ApplicationService,
	            private filterService: FilterService,
	            private locationStrategy: LocationStrategy,
	            logger: Logger) {
		super(logger);
	}

	/**
	 * Get a single product
	 *
	 * @param type
	 * @param priceListNo
	 * @param priceListLineNo
	 * @returns {Observable<R>}
	 */
	getSingle(filterState: FilterState, type: number, priceListNo: string, priceListLineNo: string): Observable<ProductDetail> {

		let url = this.detailUrl + `?type=${type}&priceListLineNo=${priceListLineNo}&priceListNo=${priceListNo}`;

		if (filterState) {
			url += '&' + filterState.getParams();
		}

		return this.http.get<ProductDetail>(url)
			.pipe(
				catchError(this.handleError)
			);
	}

	loadProducts(): Promise<ProductPage> {
		return this.loadProductsAndFeatures(false)
			.then(productsAndFeatures => productsAndFeatures.productPage);
	}

	loadProductsAndFeatures(includeFeatures: boolean = true): Promise<ProductsAndFeatures> {
		const filterState = this.filterService.getFilterState();
		return this.getProductsAndFeatures(filterState.getParams(), includeFeatures);
	}

	getProducts(filtersQueryString: string): Promise<ProductPage> {
		return this.getProductsAndFeatures(filtersQueryString, false)
			.then(productsAndFeatures => productsAndFeatures.productPage);
	}

	/**
	 * Returns the products (and optional features).
	 * If the result was already cached (eg. when the user browses to a product detail and back),
	 * the result is returned from the cache.
	 *
	 * @param {string} filtersQueryString
	 * @param {boolean} includeFeatures
	 * @returns {Promise<ProductsAndFeatures>}
	 */
	getProductsAndFeatures(filtersQueryString: string, includeFeatures: boolean = true): Promise<ProductsAndFeatures> {
		let url = this.productsUrl + '?includefeatures=' + includeFeatures;

		if (filtersQueryString) {
			url += '&' + filtersQueryString;
		}

		const cachedValue = this.productsAndFeaturesResultCache.get(url);

		if (cachedValue) {
			return cachedValue;
		}

		const $promise = this.http.get<ProductsAndFeatures>(url).toPromise();

		// Cache the Promise with the URL as cacheKey.
		this.productsAndFeaturesResultCache.put(url, $promise);

		return $promise;
	}

	/**
	 * Generate an anchor value. Such a value has to start with letter and not a number
	 *
	 * @param product
	 * @returns {string}
	 */
	getAnchorValue(type: number, priceListNo: string, priceListLineNo: string) {
		return `ID${type}${priceListNo}${priceListLineNo}`;
	}

	/**
	 * Get 'large' image
	 *
	 * @returns {string}
	 */
	getImage(productImage: String): string {
		const src: string[] = productImage.split('.');

		if (!src[0]) {
			return null;
		}

		return src[0];
	}

	/**
	 * Gets the miminum order quantity based on the items per ordered unit (tray/layer/trolley)
	 *
	 * @param product
	 * @param itemsPerUnit
	 * @returns {number}
	 */
	getMinimumOrderQuantity(product: Product, itemsPerUnit: number) {
		// Make sure itemsPerUnit is a number by casting it
		if (+itemsPerUnit === 0) {
			return 1;
		}

		let minimum = product.minimumOrderQuantity / itemsPerUnit;

		if (minimum < 1) {
			minimum = 1;
		}

		return Math.ceil(minimum);
	}

	isTypeStock(product: Product) {
		return product.type === this.TYPE_STOCK;
	}

	/**
	 * Push the location state to the winodw history with the necessary parameters to build the correct url.
	 *
	 * @param {Product} product
	 * @param {string} baseUrl
	 * @param {string} title (currently not used. use empty string)
	 * @param {string} queryParam
	 */
	pushLocationState(product: Product, baseUrl: string, title: string, queryParam: string): any {
		const fragmentValue = this.getAnchorValue(product.type, product.priceListNo, product.priceListLineNo);
		const newStateObject = {productId: fragmentValue};

		this.locationStrategy.pushState(newStateObject, title, baseUrl + '#' + fragmentValue, queryParam);
	}

	/**
	 * Pushes the state specifically for the shops '/products' overview page
	 *
	 * @param product
	 */
	pushProductState(product: Product): any {
		this.pushLocationState(product, '/products', '', '');
	}

	/**
	 * Pushes the state specifically for the website side of things. This can be pushed from places where products are
	 * visible on the site like: hunters or blogs
	 * @param product
	 * @param baseUrl
	 */
	pushWebsiteProductState(product: Product, baseUrl: string) {
		this.pushLocationState(product, baseUrl, '', '');
	}

	triggerProductAddedToCartEvent(product: Product) {
		this.productAddedToCart.next(product);
	}

	/**
	 * Triggers the scrollTo event for a product whenever it's called. It requires the anchorvalue
	 * read from the window.location.hash value.
	 *
	 * This code is a workaround for the current state of things. This is perfectly fine code but can be finetuned
	 * and will be revisited in a upcoming project/ticket
	 * TODO: NOVILAVIC-11 Hunters integreren op KVK shops - This code will need to be refactored after this ticket
	 *
	 * @param anchorValue {string}
	 */
	triggerScrollToProductOnWebsite(anchorValue: string): void {
		if (!anchorValue) {
			return;
		}

		const productElement = document.querySelector(anchorValue);

		if (productElement) {
			window.scrollTo(0, productElement.getBoundingClientRect().top);
		}
	}

	/**
	 * Temporarily overrides the Date.prototype.toJSON method,
	 * so we can post dates in 'time format' which is expected at the rest endpoint.
	 *
	 * @param {FilterState} filterState
	 * @returns {string} the json data to post.
	 */
	private getFilterPostDataInJsonString(filterState: FilterState) {
		const defaultDateToJsonFunction = Date.prototype.toJSON;

		Date.prototype.toJSON = function () {
			return FilterState.toTime(this).toString();
		};

		const jsonDataString = JSON.stringify(filterState);

		Date.prototype.toJSON = defaultDateToJsonFunction;

		return jsonDataString;
	}

	/**
	 * Calculate the buy all price based on the availableQuantity
	 *
	 * @returns {string}
	 */
	private buyAllSalePrice(product: Product): number {
		let price = product.unitPrice;

		if (product.availableQuantity >= product.qtyByTrolley) {
			price = product.trolleyPrice;
		} else if (product.availableQuantity >= product.qtyByBox * product.qtyPackingsByLayer) {
			price = product.layerPrice;
		}

		return price;
	}

}
