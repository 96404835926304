import {HttpClient} from './http.service';
import {ActiveOrder} from '../classes/active.order';

export class ActiveOrderStateHelper {

    private static ACTIVE_ORDER_KEY = 'current_active_order';

    public static clearSelectedOrderFromCookie() {
        HttpClient.deleteCookie(ActiveOrderStateHelper.ACTIVE_ORDER_KEY);
    }

    static getActiveOrderFromCookie() {
        return HttpClient.getCookie(ActiveOrderStateHelper.ACTIVE_ORDER_KEY, true);
    }

    static saveActiveOrderInStorage(activeOrder: ActiveOrder) {
        if (activeOrder !== null) {
            HttpClient.saveJsonInCookie(ActiveOrderStateHelper.ACTIVE_ORDER_KEY, activeOrder);
        } else {
            ActiveOrderStateHelper.clearSelectedOrderFromCookie();
        }
    }

}