import {Component, EventEmitter, Output} from '@angular/core';
import {ShoppingCartLine} from '../../classes/shoppingcartline';
import {ShoppingCartService} from '../../service/shoppingcart.service';
import {UserService} from '../../service/user.service';

@Component({
	selector: 'shoppingcart-header-mobile-component',
	templateUrl: 'shoppingcart.header.mobile.component.html',
})
export class ShoppingcartHeaderMobileComponent {
    @Output() viewOrderDetailClickEvent = new EventEmitter();
	@Output() viewShoppingcartClickEvent = new EventEmitter();

	constructor(private shoppingCartService: ShoppingCartService,
                private userService: UserService) {

	}

    isFastCheckout(): boolean {
        return this.userService.isUseFastCheckout();
    }

	get shoppingCartLines(): Array<ShoppingCartLine> {
		return this.shoppingCartService.activeShoppingCartLines;
	}

	viewOrderDetail() {
		this.viewOrderDetailClickEvent.emit();
	}

	viewShoppingcart() {
		this.viewShoppingcartClickEvent.emit();
	}

	/**
	 * Gets the number of items in the shopping cart.
	 *
	 * @returns {number}
	 */
	getShoppingCartCount(): number {
		let size = 0;

		if (this.shoppingCartLines) {
			size = this.shoppingCartLines.length;
		}

		return size;
	}
}