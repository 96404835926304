import {Component} from '@angular/core';
import {PlanthunterService} from '../../service/planthunter.service';

@Component({
    selector: 'planthunter-amountofnewhunts-component',
    template: `<span class="planthunter-nav-count" *ngIf="newHunts > 0">{{newHunts}}</span>`
})
export class PlanthunterAmountOfNewHuntsComponent {

    newHunts: number = 0;

    constructor(private planthunterService: PlanthunterService) {
        this.planthunterService.getAmountOfNewPlanthunterItems().then(amountOfNewPlanthunterItems => {
            this.newHunts = amountOfNewPlanthunterItems;
        });
    }
}
