"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var common_1 = require("@angular/common");
var operators_1 = require("rxjs/operators");
var rxjs_1 = require("rxjs");
var base_service_1 = require("./base.service");
var http_service_1 = require("./http.service");
var application_service_1 = require("./application.service");
var default_log_service_1 = require("../logging/default-log.service");
var filter_state_1 = require("../classes/filter.state");
var lru_cache_1 = require("../classes/lru.cache");
var filter_service_1 = require("./filter.service");
var configuration_helper_1 = require("../helper/configuration.helper");
var ProductService = (function (_super) {
    __extends(ProductService, _super);
    function ProductService(http, applicationService, filterService, locationStrategy, logger) {
        var _this = _super.call(this, logger) || this;
        _this.http = http;
        _this.applicationService = applicationService;
        _this.filterService = filterService;
        _this.locationStrategy = locationStrategy;
        _this.TYPE_STOCK = 1;
        _this.productsUrl = configuration_helper_1.ConfigurationHelper.getWebshopUrl('/public/rest/products-and-features');
        _this.detailUrl = configuration_helper_1.ConfigurationHelper.getWebshopUrl('/public/rest/products/detail');
        _this.productAddedToCart = new rxjs_1.Subject();
        _this.productAdded$ = _this.productAddedToCart.asObservable();
        _this.productsAndFeaturesResultCache = new lru_cache_1.LruCache(1);
        return _this;
    }
    ProductService.prototype.getSingle = function (filterState, type, priceListNo, priceListLineNo) {
        var url = this.detailUrl + ("?type=" + type + "&priceListLineNo=" + priceListLineNo + "&priceListNo=" + priceListNo);
        if (filterState) {
            url += '&' + filterState.getParams();
        }
        return this.http.get(url)
            .pipe(operators_1.catchError(this.handleError));
    };
    ProductService.prototype.loadProducts = function () {
        return this.loadProductsAndFeatures(false)
            .then(function (productsAndFeatures) { return productsAndFeatures.productPage; });
    };
    ProductService.prototype.loadProductsAndFeatures = function (includeFeatures) {
        if (includeFeatures === void 0) { includeFeatures = true; }
        var filterState = this.filterService.getFilterState();
        return this.getProductsAndFeatures(filterState.getParams(), includeFeatures);
    };
    ProductService.prototype.getProducts = function (filtersQueryString) {
        return this.getProductsAndFeatures(filtersQueryString, false)
            .then(function (productsAndFeatures) { return productsAndFeatures.productPage; });
    };
    ProductService.prototype.getProductsAndFeatures = function (filtersQueryString, includeFeatures) {
        if (includeFeatures === void 0) { includeFeatures = true; }
        var url = this.productsUrl + '?includefeatures=' + includeFeatures;
        if (filtersQueryString) {
            url += '&' + filtersQueryString;
        }
        var cachedValue = this.productsAndFeaturesResultCache.get(url);
        if (cachedValue) {
            return cachedValue;
        }
        var $promise = this.http.get(url).toPromise();
        this.productsAndFeaturesResultCache.put(url, $promise);
        return $promise;
    };
    ProductService.prototype.getAnchorValue = function (type, priceListNo, priceListLineNo) {
        return "ID" + type + priceListNo + priceListLineNo;
    };
    ProductService.prototype.getImage = function (productImage) {
        var src = productImage.split('.');
        if (!src[0]) {
            return null;
        }
        return src[0];
    };
    ProductService.prototype.getMinimumOrderQuantity = function (product, itemsPerUnit) {
        if (+itemsPerUnit === 0) {
            return 1;
        }
        var minimum = product.minimumOrderQuantity / itemsPerUnit;
        if (minimum < 1) {
            minimum = 1;
        }
        return Math.ceil(minimum);
    };
    ProductService.prototype.isTypeStock = function (product) {
        return product.type === this.TYPE_STOCK;
    };
    ProductService.prototype.pushLocationState = function (product, baseUrl, title, queryParam) {
        var fragmentValue = this.getAnchorValue(product.type, product.priceListNo, product.priceListLineNo);
        var newStateObject = { productId: fragmentValue };
        this.locationStrategy.pushState(newStateObject, title, baseUrl + '#' + fragmentValue, queryParam);
    };
    ProductService.prototype.pushProductState = function (product) {
        this.pushLocationState(product, '/products', '', '');
    };
    ProductService.prototype.pushWebsiteProductState = function (product, baseUrl) {
        this.pushLocationState(product, baseUrl, '', '');
    };
    ProductService.prototype.triggerProductAddedToCartEvent = function (product) {
        this.productAddedToCart.next(product);
    };
    ProductService.prototype.triggerScrollToProductOnWebsite = function (anchorValue) {
        if (!anchorValue) {
            return;
        }
        var productElement = document.querySelector(anchorValue);
        if (productElement) {
            window.scrollTo(0, productElement.getBoundingClientRect().top);
        }
    };
    ProductService.prototype.getFilterPostDataInJsonString = function (filterState) {
        var defaultDateToJsonFunction = Date.prototype.toJSON;
        Date.prototype.toJSON = function () {
            return filter_state_1.FilterState.toTime(this).toString();
        };
        var jsonDataString = JSON.stringify(filterState);
        Date.prototype.toJSON = defaultDateToJsonFunction;
        return jsonDataString;
    };
    ProductService.prototype.buyAllSalePrice = function (product) {
        var price = product.unitPrice;
        if (product.availableQuantity >= product.qtyByTrolley) {
            price = product.trolleyPrice;
        }
        else if (product.availableQuantity >= product.qtyByBox * product.qtyPackingsByLayer) {
            price = product.layerPrice;
        }
        return price;
    };
    return ProductService;
}(base_service_1.BaseService));
exports.ProductService = ProductService;
