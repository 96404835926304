"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var NumberFormatPipe = (function () {
    function NumberFormatPipe() {
    }
    NumberFormatPipe.prototype.transform = function (value, currency) {
        if (!value) {
            return null;
        }
        var number = value.toFixed(2);
        if (currency) {
            return number.replace('.', ',');
        }
        return number;
    };
    return NumberFormatPipe;
}());
exports.NumberFormatPipe = NumberFormatPipe;
