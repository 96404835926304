"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("./salesperson.telephone.component");
var i2 = require("../../service/user.service");
var styles_SalespersonTelephoneComponent = [];
var RenderType_SalespersonTelephoneComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SalespersonTelephoneComponent, data: {} });
exports.RenderType_SalespersonTelephoneComponent = RenderType_SalespersonTelephoneComponent;
function View_SalespersonTelephoneComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["Questions? Please don't hesitate to call us: ", ""])), (_l()(), i0.ɵted(-1, null, ["\n    "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getTelephoneNumber(); _ck(_v, 2, 0, currVal_0); }); }
exports.View_SalespersonTelephoneComponent_0 = View_SalespersonTelephoneComponent_0;
function View_SalespersonTelephoneComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["sales-person-telephone", ""]], null, null, null, View_SalespersonTelephoneComponent_0, RenderType_SalespersonTelephoneComponent)), i0.ɵdid(1, 49152, null, 0, i1.SalespersonTelephoneComponent, [i2.UserService], null, null)], null, null); }
exports.View_SalespersonTelephoneComponent_Host_0 = View_SalespersonTelephoneComponent_Host_0;
var SalespersonTelephoneComponentNgFactory = i0.ɵccf("[sales-person-telephone]", i1.SalespersonTelephoneComponent, View_SalespersonTelephoneComponent_Host_0, {}, {}, []);
exports.SalespersonTelephoneComponentNgFactory = SalespersonTelephoneComponentNgFactory;
