import { UserService } from '../../service/user.service';
import { Component, Input } from '@angular/core';

/**
 * This i18n text is shown in different places and has a parameter based on the telephone number of the sales person
 * of the current user.
 *
 * @author evandongen
 */
@Component({
    selector: '[sales-person-telephone]',
    template: `
        <span i18n="@@questions.phone.number">Questions? Please don’t hesitate to call us: {{getTelephoneNumber()}}</span>
    `
})
export class SalespersonTelephoneComponent {
    constructor(private userService: UserService) {
    }

    getTelephoneNumber() {
        return this.userService.getUserSalesPersonTelephoneNumber();
    }
}