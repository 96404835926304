import { ErrorHandler, Injectable } from '@angular/core';
import { Logger } from './default-log.service';

@Injectable()
export class SystemMonitorErrorHandler extends ErrorHandler {

    constructor(private logger: Logger) {
        super();
        this.logger = logger;
    }

    handleError(error: any): void {
        this.logger.error('Unhandled exception occured', error);

        super.handleError(error);
    }
}
