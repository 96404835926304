"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var BrowserService = (function () {
    function BrowserService(logger) {
        this.logger = logger;
    }
    BrowserService.isEdgeBrowser = function () {
        return /Edge\//.test(navigator.userAgent);
    };
    return BrowserService;
}());
exports.BrowserService = BrowserService;
