import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UserService} from '../../service/user.service';
import {GlobalValidator} from '../../global.validator';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Logger} from '../../logging/default-log.service';
import {ApplicationService} from '../../service/application.service';
import {Administration} from '../../classes/administration';
import {ConfigurationHelper} from '../../helper/configuration.helper';
import {ShopSettings} from '../../classes/shop.settings';

@Component({
	selector: 'login-component',
	templateUrl: 'login.component.html',
})
export class LoginComponent implements OnInit {
	private windowObj;
	loginForm: FormGroup;
	passwordFieldType: string = 'password';
	formError: string;
	returnUrl: string;
	shopForgotPasswordUrl: string;
	@Input() successRedirect: string = '/products';
	shopSettings: ShopSettings = ConfigurationHelper.getSettings();

	constructor(public applicationService: ApplicationService,
	            private route: ActivatedRoute,
	            private userService: UserService,
	            formBuilder: FormBuilder,
	            private logger: Logger) {

		this.windowObj = (<any>window);

		this.loginForm = formBuilder.group({
			'email': [null, Validators.compose([Validators.required, GlobalValidator.mailFormat])],
			'password': [null, Validators.required]
		});
	}

	ngOnInit() {
		this.returnUrl = this.getReturnUrl();
		this.shopForgotPasswordUrl = ConfigurationHelper.getWebshopUrl('/forgot-password');
	}

	isShowRegisterBlock() {
		// Can be null if we're on the 'CMS website' login page.
		if (!this.shopSettings) {
			return true;
		}

		return !this.shopSettings.kvkShop;
	}

	onSubmit(value: any) {
		this.userService.login(value.email, value.password).subscribe((result: Administration) => {
			if (this.returnUrl) {
				window.location.href = this.returnUrl;
			} else if (result.administrationUrl) {
				window.location.href = result.administrationUrl + '?token=' + result.token;
			} else {
				window.location.href = this.successRedirect;
			}
		}, error => {
			this.logger.debug('Failed to login', error);
			this.formError = 'Invalid username / password';
		});
	}

	/**
	 * Toggle show/hide password
	 */
	togglePassword() {
		this.passwordFieldType = (this.passwordFieldType === 'password') ? 'text' : 'password';
	}

	/**
	 * Returns the url that we need to return to after login. In this case we have two seperate
	 * ways a returnUrl can be set. Either by a queryParam from Angular Router or by a url parameter
	 * from plain javascript.
	 * The Angular Router queryParam has the priority and will be used if both the queryParam and
	 * a url parameter from plain javascript exist.
	 */
	getReturnUrl() {
		const routerReturnUrl = this.route.snapshot.queryParams['returnUrl'];
		const windowReturnUrl = this.getReturnUrlFromParameters(this.windowObj.location.search);

		if (routerReturnUrl) {
			return routerReturnUrl;
		}

		return windowReturnUrl ? windowReturnUrl : null;
	}

	/**
	 * Get URL Paramaters and expose them as an object.
	 *
	 * @param search
	 */
	getReturnUrlFromParameters(search) {
		const hashes = search.slice(search.indexOf('?') + 1).split('&');
		return hashes[0].replace('returnUrl=', '');
	}
}
