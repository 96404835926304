"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var application_service_1 = require("../generic/service/application.service");
var StartOrderWrapperComponent = (function () {
    function StartOrderWrapperComponent(applicationService) {
        this.applicationService = applicationService;
    }
    return StartOrderWrapperComponent;
}());
exports.StartOrderWrapperComponent = StartOrderWrapperComponent;
