export class ActiveOrder {

    closingHour: number;
    closingTime: number;
    deliveryAddress: string;
    deliveryAddressName: string;
    deliveryCity: string;
    deliveryOption: number;
    shipmentDate: Date;
    shipToCode: string;
    volume: number;
    // Used to add an extra option to the select and differ from the 'real' items.
    public dummyValue: string;

    get id(): string {
        return ActiveOrder.getId(this.shipmentDate, this.shipToCode);
    }

    public static getId(shipmentDate, shipToCode): string {
        return shipmentDate.toString() + '-' + shipToCode.toString();
    }
}
