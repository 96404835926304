import {AbstractControl} from '@angular/forms';

/**
 * This is a custom validation to validate a password field agains a confirmpassword field
 *
 * @Author Patrick van Zadel
 */
export class PasswordValidation {

    static MatchPassword(abstractControl: AbstractControl) {
        const password = abstractControl.get('password').value;
        const confirmPassword = abstractControl.get('confirmpassword').value;

        if (password !== confirmPassword) {
            abstractControl.get('confirmpassword').setErrors( {MatchPassword: true});

            return false;
        }

        return true;
    }
}
